import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate'
import * as Commons from '../Commons';

const withAdminNoticeDraftModal = (WrappedComponent) => {
    return class AdminNoticeDraftModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeDraft: false,
                templateDataChoosen: null,
            }
        }

        adminNoticeDraftModal = (props) => {
            let hasHistoryDetails = props.noticeHistoryDetails && props.noticeHistoryDetails.length > 0;
            let areAllDefault = props.currentFormValues && props.initialFormValue ?
                props.currentFormValues.type.toString() === props.initialFormValue.PostForm.type.toString() &&
                props.currentFormValues.product.toString() === props.initialFormValue.PostForm.product.toString() &&
                props.currentFormValues.title === props.initialFormValue.PostForm.title &&
                props.currentFormValues.content === props.initialFormValue.PostForm.content &&
                props.currentFormValues.publish_date.toString() === props.initialFormValue.PostForm.publish_date.toString() &&
                props.currentFormValues.publish_type.toString() === props.initialFormValue.PostForm.publish_type.toString() ? true : false : true

            return (
                <Modal show={this.state.showNoticeDraft} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal} className="modal-edit-post" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ修正{props.noticeEditDetails.status}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.formInputs}
                            radioInput={props.radioInput}
                            selectInput={props.selectInput}
                            initialValues={props.initialFormValue}
                            publishDate={props.noticeEditDetails.publish_preferred_date === null ? "0" : "1"}
                            publishPreferredDate={Commons.getClientDatetimeDisplay(props.noticeEditDetails.publish_preferred_date)}
                            editMode={true}
                            validate={props.adminNoticeSelectors.checkErrors}
                            handleSubmitDraftModal={this.handleSubmitDraftModal}
                            form={"admin-draft-post"}
                            handleShowApplicationConfirmModal={this.handleShowApplicationConfirmModal}
                            handleShowDraftConfirmModal={this.handleShowDraftConfirmModal}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowChooseTemplateModal={this.handleShowChooseTemplateModal}
                            templateDataChoosen={this.state.templateDataChoosen}
                            showNoticeDraft={this.state.showNoticeDraft}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 admin-draft-modal-footer">
                            <div className={`w-100 d-inline-flex ${hasHistoryDetails ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                <input type="hidden" name="submit_action" />
                                <button type="button" form="admin-draft-post" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                    document.querySelector('.modal.show .noticeSubmitButton').click()
                                    document.querySelector('.modal.show input[name=submit_action]').value = "handleShowApplicationConfirmModal"
                                }}>承認申請</button>
                                <button type="button" variant="warning" form="admin-draft-post" className="btn btn-90 mr-2 btn-outline-primary" onClick={() => {
                                    document.querySelector('.modal.show .noticeSubmitButton').click()
                                    document.querySelector('.modal.show input[name=submit_action]').value = "handleShowDraftConfirmModal"
                                }}>下書保存</button>
                                {
                                    props.noticeEditDetails.status === "（下書き）" ?
                                        <button className="btn btn-90 mr-2 btn-outline-danger" onClick={() => { this.handleShowDeleteModal(props.noticeEditDetails.post_id) }}>下書削除</button>
                                        : ""
                                }
                                <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal}>キャンセル</button>
                            </div>
                            {
                                hasHistoryDetails ?
                                    <>
                                        <h5 className="mx-4">修正履歴</h5>
                                        {
                                            props.noticeHistoryDetails.map((news, idx) => {
                                                var initialFormHistoryValue = {
                                                    NoticeHistoryPostForm: {
                                                        post_id: news.id,
                                                        edit_date: news.updated_date ? Commons.getClientDatetimeDisplay(news.updated_date) : "",
                                                        type: news.category_name,
                                                        product: news.product_name,
                                                        title: news.title,
                                                        content: news.content,
                                                        applicant: news.applied_by ? news.applied_by : "",
                                                        history_publish_date: news.publish_preferred_date == null ? "0" : "1",
                                                        publish_preferred_date: Commons.getClientDatetimeDisplay(news.publish_preferred_date),
                                                        history_publish_type: news.publish_type,
                                                        remarks: news.remarks ? news.remarks : ""
                                                    }
                                                };

                                                return (
                                                    <div className="bg-light" key={news.post_id + idx}>
                                                        <AdminFormTemplate
                                                            formInputs={props.noticeHistoryPostForm}
                                                            radioInput={props.radioInput}
                                                            initialValues={initialFormHistoryValue}
                                                            publishDate={news.publish_preferred_date === null ? "0" : "1"}
                                                            publishPreferredDate={Commons.getClientDatetimeDisplay(news.publish_preferred_date)}
                                                            form={"admin-history-form" + news.id}
                                                            handleFormOnchange={this.handleFormOnchange}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : null
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowDraftModal = (value, event) => {
            event.target.blur();
            Promise.all([
                this.showLoader(),
                this.props.getNoticeEditDetails(value).then(
                    () => {
                        this.setState({
                            initialFormValue: {
                                PostForm: {
                                    post_id: this.props.noticeEditDetails.post_id,
                                    type: this.props.noticeEditDetails.post_category_id,
                                    product: this.props.noticeEditDetails.product_id,
                                    title: this.props.noticeEditDetails.title,
                                    content: this.props.noticeEditDetails.content,
                                    publish_date: this.props.noticeEditDetails.publish_preferred_date === null ? "0" : "1",
                                    publish_preferred_date: this.props.noticeEditDetails.publish_preferred_date,
                                    publish_type: this.props.noticeEditDetails.publish_type,
                                    remarks: this.props.noticeEditDetails.remarks ? this.props.noticeEditDetails.remarks : ""
                                }
                            }
                        })
                        this.props.getNoticeHistoryDetails(value).then(
                            () => {
                                this.setState({ showNoticeDraft: true })
                            }
                        )
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseDraftModal = () => {
            this.setState({
                showNoticeDraft: false,
                templateDataChoosen: null
            });
        }

        handleSubmitDraftModal = (id, values) => {
            let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;
            let formattedValue = {
                category_id: values.type,
                product_id: values.product,
                publish_preferred_date: preferred_date,
                publish_type: values.publish_type,
                title: values.title,
                content: values.content
            }

            this.setState({
                noticeData: {
                    id: id,
                    type: "draft",
                    payload: formattedValue
                },
                templateDataChoosen: null
            });
        }

        handleShowDeleteModal = (id) => {
            this.setState({
                noticeDeleteId: id,
                showNoticeDeleteModal: true,
                templateDataChoosen: null
            });
        }
    }
}

export {
    withAdminNoticeDraftModal
}