import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate';
import * as Commons from '../Commons';

const withAdminNoticeApprovalModal = (WrappedComponent) => {
    return class AdminNoticeApprovalModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeApprovalModal: false,
                showEditNoticeModalFromApprovalModal: false
            }
        }

        adminNoticeApprovalModal = (props) => {
            let template = [];
            if (props.noticeApprovalDetails) {
                let applicationType = props.noticeApprovalDetails.application_type ? props.noticeApprovalDetails.application_type : "";
                let hasHistoryDetail = props.noticeApprovalHistoryDetails.length > 0;

                template = (
                    <Modal show={this.state.showNoticeApprovalModal} keyboard={false} size="lg" onHide={this.handleCloseNoticeApprovalModal} className="modal-edit-post" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>承認管理 ＞ 承認（お知らせ{applicationType}）</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <label>
                                <strong>申請番号: </strong>
                                {props.noticeApprovalDetails.application_number}
                            </label>
                            <AdminFormTemplate
                                formInputs={props.approvalPostForm}
                                radioInput={props.radioInput}
                                initialValues={props.initialFormValue}
                                publishDate={props.noticeApprovalDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(props.noticeApprovalDetails.publish_preferred_date)}
                                confirmApprovalMode={true}
                                form={"admin-approval-form"}
                                handleShowApprovalConfirmModal={this.handleShowApprovalConfirmModal}
                                handleShowApprovalRejectModal={this.handleShowApprovalRejectModal}
                                handleSubmitApprovalModal={this.handleSubmitApprovalModal}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="pt-0 border-top-0">
                            <div className="w-100 approval-modal-footer">
                                <div className={`w-100 d-inline-flex ${hasHistoryDetail ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                    <input type="hidden" name="submit_action" />
                                    <button type="button" form="admin-approval-document" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                        this.handleShowApprovalEditNoticeModal(props.initialFormValue.postId)
                                    }}>編集</button>
                                    <button type="button" className="btn btn-90 mr-2 btn-success" onClick={() => {
                                        this.handleSubmitApprovalModal(props.initialFormValue.ApprovalPostForm.post_id, props.initialFormValue.ApprovalPostForm)
                                        this.handleShowApprovalConfirmModal()
                                    }} disabled={props.noticeApprovalDetails.application_status === "承認待ち" ? false : true}>承認</button>
                                    <button type="button" form="admin-approval-form" className="btn btn-90 mr-2 btn-outline-danger" onClick={() => {
                                        this.handleShowApprovalRejectModal(props.initialFormValue.ApprovalPostForm.post_id)
                                    }} disabled={props.noticeApprovalDetails.application_status === "承認待ち" ? false : true}>却下</button>
                                    <button className="btn btn-90 btn-secondary" onClick={this.handleCloseNoticeApprovalModal}>キャンセル</button>
                                </div>
                                {
                                    hasHistoryDetail ?
                                        <>
                                            <h5>修正履歴</h5>
                                            {
                                                props.noticeApprovalHistoryDetails.map((news, idx) => {
                                                    var initialFormHistoryValue = {
                                                        ApprovalHistoryPostForm: {
                                                            post_id: news.id,
                                                            edit_date: news.updated_date ? Commons.getClientDatetimeDisplay(news.updated_date) : "",
                                                            type: news.category_name,
                                                            product: news.product_name,
                                                            title: news.title,
                                                            content: news.content,
                                                            applicant: news.applied_by ? news.applied_by : "",
                                                            history_publish_date: news.publish_preferred_date == null ? "0" : "1",
                                                            publish_preferred_date: Commons.getClientDatetimeDisplay(news.publish_preferred_date),
                                                            history_publish_type: news.publish_type,
                                                            remarks: news.remarks ? news.remarks : ""
                                                        }
                                                    };

                                                    return (
                                                        <div className="bg-light" key={news.id + "-" + idx}>
                                                            <AdminFormTemplate
                                                                formInputs={props.approvalHistoryPostForm}
                                                                radioInput={props.radioInput}
                                                                initialValues={initialFormHistoryValue}
                                                                publishDate={news.publish_preferred_date === null ? "0" : "1"}
                                                                publishPreferredDate={Commons.getClientDatetimeDisplay(news.publish_preferred_date)}
                                                                form={"admin-history-form" + news.id}
                                                                handleFormOnchange={this.handleFormOnchange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </Modal.Footer>
                    </Modal>
                );
            }
            return template;
        }

        handleShowNoticeApprovalModal = (application_id, post_id) => {
            Promise.all([
                this.showLoader(),
                this.props.getNoticeApprovalDetails(application_id).then(
                    () => {
                        this.setState({
                            initialFormValue: {
                                ApprovalPostForm: {
                                    post_id: this.props.noticeApprovalDetails.application_id,
                                    type: this.props.noticeApprovalDetails.category_name,
                                    product: this.props.noticeApprovalDetails.product_name,
                                    title: this.props.noticeApprovalDetails.title,
                                    content: this.props.noticeApprovalDetails.content,
                                    applicant: this.props.noticeApprovalDetails.created_by,
                                    publish_date: this.props.noticeApprovalDetails.publish_preferred_date == null ? 0 : 1,
                                    publish_preferred_date: this.props.noticeApprovalDetails.publish_preferred_date,
                                    publish_type: this.props.noticeApprovalDetails.publish_type
                                },
                                postId: post_id
                            }
                        })
                        this.props.getNoticeApprovalHistoryDetails(post_id).then(
                            () => {
                                this.setState({
                                    showNoticeApprovalModal: true,
                                    showEditNoticeModalFromApprovalModal: true
                                })
                            }
                        )
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseNoticeApprovalModal = () => {
            this.setState({
                showNoticeApprovalModal: false,
                showEditNoticeModalFromApprovalModal: false
            })
        }
    }
}

export {
    withAdminNoticeApprovalModal
}