import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate'
import * as Commons from '../../components/Commons';

const withAdminNewDocumentTemplate = (WrappedComponent) => {
    return class AdminNewDocumentTemplate extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalNewDocument: false
            }
        }

        adminNewDocumentTemplate = (props) => {
            var areAllDefault = props.currentFormValues ?
                props.currentFormValues.attachment === "0" &&
                    props.currentFormValues.product === "" &&
                    props.currentFormValues.title === "" &&
                    props.currentFormValues.content === "" &&
                    props.currentFormValues.external_link === "" &&
                    props.currentFormValues.publish_date === "0" ? true : false : true

            return (
                <Modal show={this.state.showModalNewDocument} keyboard={false} onHide={areAllDefault ? this.handleCloseModalNewDocument : this.handleShowDiscardModal} className="modal-new-post" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント投稿</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.formInputs}
                            radioInput={props.radioInput}
                            selectInput={props.productLookupList}
                            uploaded_files={props.uploaded_files}
                            handleShowApplicationConfirmModal={this.handleShowApplicationConfirmModal}
                            handleShowDraftConfirmModal={this.handleShowDraftConfirmModal}
                            validate={props.adminDocumentSelectors.checkErrors}
                            addMode={true}
                            form={'admin-new-document'}
                            handleSubmitModalNewDocument={this.handleSubmitModalNewDocument}
                            setUploadedFiles={this.setUploadedFiles}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowErrorAlert={this.handleShowErrorAlert}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-new-document" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .documentSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowApplicationConfirmModal"
                            }}>公開申請</button>
                            <button type="button" form="admin-new-document" className="btn btn-90 mr-2 btn-outline-primary" onClick={() => {
                                document.querySelector('.modal.show .documentSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowDraftConfirmModal"
                            }}>下書保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseModalNewDocument : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowModalNewDocument = (event) => {
            event.target.blur();
            this.setState({ showModalNewDocument: true });
        }

        handleCloseModalNewDocument = () => {
            this.setState({ showModalNewDocument: false });
            this.removeUploadedFiles()
        }

        handleSubmitModalNewDocument = (values) => {
            let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;
            let formattedValue = {
                files: values.files,
                content: values.content,
                product_id: values.product,
                publish_preferred_date: preferred_date,
                title: values.title,
                publish_type: 1,
                url: values.external_link
            }
    
            let toRemoveFiles = [];
            for (let a = 0; a < values.delete_file_checkboxes.length; a++) {
                toRemoveFiles.push(values.delete_file_checkboxes.item(a).id)
            }
            let formData = new FormData();
            let formKeys = Object.keys(formattedValue);
            formKeys.map((key) => {
                if (key === "files") {
                    let files = formattedValue[key];
                    if (files && files.length) {
                        for (let i = 0; i < files.length; i++) {
                            if (toRemoveFiles.indexOf("delete-file-" + i) === -1) {
                                formData.append("files", files[i]);
                            }
                        }
                    }
                } else {
                    if (key === "publish_preferred_date" && !formattedValue[key]) {
                        formData.append(key, "");
                    } else {
                        formData.append(key, formattedValue[key]);
                    }
                }
                return true
            });
    
            this.setState({
                documentData: {
                    type: "create",
                    payload: formData
                }
            });
        }
    }
}

export {
    withAdminNewDocumentTemplate
}