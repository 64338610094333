import LoginScreen from './LoginScreen'

export const LOGIN_ROUTE = "/login";

export const LOGIN_LABEL = "ログイン";

export const routes = [
    {
        label: LOGIN_LABEL,
        path: LOGIN_ROUTE,
        component: LoginScreen,
        exact: true,
        public: true,
    }
];