import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate'
import * as Commons from '../../components/Commons';

const withNewProductModal = (WrappedComponent) => {
    return class NewProductModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalNewProduct: false
            }
        }

        newProductModal = (props) => {
            let { displayColorPicker, fields, color } = this.state;
            var areAllDefault = props.currentFormValues ? props.currentFormValues.approved_ad_group === "" &&
                "#" + ((1 << 24) + (props.color.r << 16) + (props.color.g << 8) + props.color.b).toString(16).slice(1) === "#000000" && props.currentFormValues.faq_link === "" &&
                props.currentFormValues.inquiry_link === "" && props.currentFormValues.kintone_api_id === "" && props.currentFormValues.kintone_api_link === "" &&
                props.currentFormValues.kintone_api_token === "" && props.currentFormValues.post_ad_group === "" && props.currentFormValues.product_name === "" &&
                props.currentFormValues.kintone_key_field === "" && props.currentFormValues.kintone_key_value === "" &&
                props.currentFormValues.service_icon === "" &&
                props.currentFormValues.start_date === "" &&
                props.currentFormValues.end_date === "" &&
                props.currentFormValues.product_long_name === "" ? true : false : true

            return (
                <Modal keyboard={false} show={this.state.showModalNewProduct} onHide={areAllDefault ? this.handleCloseModalNewProduct : this.handleShowDiscardModal} className="modal-new-product" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品情報登録</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.formInputs}
                            validate={props.adminProductManagementSelectors.productCheckErrors}
                            form={"admin-new-product"}
                            image={props.image}
                            setImageForUpload={this.setImageForUpload}
                            handleShowNewProductConfirmModal={this.handleShowNewProductConfirmModal}
                            handleSubmitModalNewProduct={this.handleSubmitModalNewProduct}
                            addMode={true}
                            handleFormOnchange={this.handleFormOnchange}
                            handleClickColorPicker={this.handleClickColorPicker}
                            handleCloseColorPicker={this.handleCloseColorPicker}
                            handleChangeColorPicker={this.handleChangeColorPicker}
                            displayColorPicker={displayColorPicker}
                            handleDateChange={this.handleDateChange}
                            fields={fields}
                            color={color}
                            styles={props.styles}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-new-product" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowNewProductConfirmModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseModalNewProduct : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowModalNewProduct = () => {
            this.setState({ showModalNewProduct: true })
        }

        handleCloseModalNewProduct = () => {
            this.setState({
                showModalNewProduct: false,
                color: {
                    r: 0,
                    g: 0,
                    b: 0,
                    a: 1,
                },
                fields: {
                    start_date: '',
                    end_date: ''
                }
            })
            this.removeImageForUpload()
        }

        handleShowNewProductConfirmModal = () => {
            this.setState({
                showNewProductConfirmModal: true
            });
        }

        handleSubmitModalNewProduct = (values) => {
            var poster = values.post_ad_group.split(',').map(Function.prototype.call, String.prototype.trim);
            var approver = values.approved_ad_group.split(',').map(Function.prototype.call, String.prototype.trim);
            var start_date = Commons.getServerDatetime(values.start_date);
            var end_date = values.end_date ? Commons.getServerDatetime(values.end_date) : '';
            var kintoneKeyValue = values.kintone_key_value.split(',').map(Function.prototype.call, String.prototype.trim);
            let formattedValue = {
                name: values.product_name,
                long_name: values.product_long_name,
                image: values.image ? values.image : "",
                kintone_url: values.kintone_api_link,
                kintone_app_id: values.kintone_api_id,
                kintone_token: values.kintone_api_token,
                okwave_url: values.faq_link,
                inquire_url: values.inquiry_link,
                kintone_key_field: values.kintone_key_field,
                kintone_key_value: kintoneKeyValue,
                poster_ad_group: poster,
                approver_ad_group: approver,
                bg_color: values.bg_color,
                start_date: start_date,
                end_date: end_date
            }

            let formData = new FormData();
            let formKeys = Object.keys(formattedValue);
            formKeys.map((key) => {
                formData.append(key, formattedValue[key]);
                return true
            });

            this.setState({
                productData: {
                    type: "create",
                    payload: formData
                }
            });
        }
    }
}

export {
    withNewProductModal
}