import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withProductKintoneConfirmEditLabelModal = (WrappedComponent) => {
    return class ProductKintoneConfirmEditLabelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductKintoneConfirmEditLabelModal: false
            }
        }

        productKintoneConfirmEditLabelModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showProductKintoneConfirmEditLabelModal} onHide={this.handleCloseProductKintoneConfirmEditLabelModal} centered>
                    <Modal.Header closeButton>
                        {/* Attribute setting> Edit confirmation */}
                        <Modal.Title>{"属性設定 > 編集確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            属性情報を編集します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitProductKintoneEditLabelConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseProductKintoneConfirmEditLabelModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal >
            )
        }

        handleCloseProductKintoneConfirmEditLabelModal = () => {
            this.setState({
                showProductKintoneConfirmEditLabelModal: false
            });
        }

        handleSubmitProductKintoneEditLabelConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let payload = this.state.kintoneData.payload

            Promise.all([
                this.showLoader(),
                this.props.patchKintoneProductFieldLabel(this.state.productKintoneFieldId,
                    payload).then(
                        () => {
                            this.handleCloseProductKintoneEditLabelModal();
                            if (this.state.submitSubKintone) {
                                this.handleShowKintoneProductSubTableFieldsModal(this.state.kintoneProductId, this.state.parentId, this.state.parentFieldLabel, this.state.parentShowCustomer)
                            } else {
                                this.handleShowProductDynamicFieldsModal(this.state.kintoneProductId);
                            }
                            this.handleShowSuccessAlert(this.props.kintoneMessage);
                            this.handleCloseProductKintoneConfirmEditLabelModal();
                        }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(() => {
                this.setState({
                    showProductKintoneConfirmEditLabelModal: false
                });
            })
        }
    }
}

export {
    withProductKintoneConfirmEditLabelModal
}