import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate';


const withAdminNoticeTemplateEditModal = (WrappedComponent) => {
    return class AdminNoticeTemplateEditModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeTemplateEdit: false,
            }
        }

        adminNoticeTemplateEditModal = (props) => {
            let areAllDefault = props.currentFormValues && this.state.initialFormValue ?
                props.currentFormValues.product.toString() === props.initialFormValue.TemplateForm.product.toString() &&
                props.currentFormValues.name === props.initialFormValue.TemplateForm.name &&
                props.currentFormValues.note === props.initialFormValue.TemplateForm.note &&
                props.currentFormValues.title === props.initialFormValue.TemplateForm.title &&
                props.currentFormValues.content === props.initialFormValue.TemplateForm.content ? true : false : true

            const formInputs = props.formInputs;
            if (this.state.showNoticeTemplateEdit) {
                // eslint-disable-next-line array-callback-return
                formInputs.form.map((item) => {
                    item.disabled = false;
                    if (item.name === "product") {
                        item.type = "select";
                    }
                });
            }

            return (
                <Modal show={this.state.showNoticeTemplateEdit} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseTemplateEditModal : this.handleShowDiscardModal} className="modal-edit-post" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>テンプレート管理 ＞ テンプレート編集</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.formInputs}
                            selectInput={props.selectInput}
                            initialValues={props.initialFormValue}
                            editTemplateMode={true}
                            validate={props.adminNoticeSelectors.checkErrorsTemplateForm}
                            form={"admin-edit-template"}
                            handleShowTemplateConfirmModal={this.handleShowTemplateConfirmModal}
                            handleSubmitTemplateEditModal={this.handleSubmitTemplateEditModal}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-edit-template" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .noticeSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowTemplateConfirmModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseTemplateEditModal : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowTemplateEditModal = (id, event) => {
            Promise.all([
                this.showLoader(),
                this.props.getNoticeTemplateDetail(id).then(
                    () => {
                        this.setState({
                            showNoticeTemplateEdit: true,
                            initialFormValue: {
                                TemplateForm: {
                                    template_id: this.props.noticeTemplateDetail.id,
                                    product: this.props.noticeTemplateDetail.product_id,
                                    name: this.props.noticeTemplateDetail.name,
                                    note: this.props.noticeTemplateDetail.note,
                                    title: this.props.noticeTemplateDetail.title,
                                    content: this.props.noticeTemplateDetail.content
                                }
                            },
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseTemplateEditModal = () => {
            this.setState({
                showNoticeTemplateEdit: false
            });
        }

        handleSubmitTemplateEditModal = (id, values) => {
            let formattedValue = {
                id: values.template_id,
                product_id: Number(values.product),
                title: values.title,
                content: values.content,
                note: values.note,
                name: values.name
            }

            this.setState({
                noticeData: {
                    type: "editTemplate",
                    payload: formattedValue,
                }
            });
        }

        handleShowDeleteModal = (id) => {
            this.setState({ noticeDeleteId: id, showNoticeDeleteModal: true });
        }
    }
}

export {
    withAdminNoticeTemplateEditModal
}
