import PasswordStrengthBar from 'react-password-strength-bar';
import React from 'react'
import { Modal } from 'react-bootstrap';

const AuthPasswordChange = (props) => {
    let isDefault = props.oldPassword === "" && props.newPassword === "" && props.confirmPassword === "";

    return (
        <Modal keyboard={false} size="md" centered 
            className="modal-auth-password-change modal-dialog-md" 
            show={props.showAuthPasswordChangeModal} 
            onHide={ isDefault ? props.handleCloseAuthPasswordChangeModal : props.handleShowDiscardModal } >
            <Modal.Header closeButton>
                <Modal.Title>{"パスワード変更"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0">
                <table className="table table-bordered table-form">
                    <tbody>
                        <tr>
                            <td className="disable-table align-middle" style={{width: '35%'}}>
                                <strong>現在のパスワード</strong>
                            </td>
                            <td>
                                <input type="password" id="oldPassword" onChange={props.handleChange} className="form-control w-100" />
                            </td>
                        </tr>
                        <tr>
                            <td className="disable-table align-middle" style={{width: '35%'}}>
                                <strong>新しいパスワード</strong>
                            </td>
                            <td>
                                <input type="password" id="newPassword" onChange={props.handleChange} className="form-control w-100" />
                                <PasswordStrengthBar 
                                    className={props.newPassword.length ? '':'d-none'} 
                                    minLength={4}
                                    shortScoreWord={'パスワードが短すぎ'} 
                                    scoreWords={['パスワード強度:とても弱い', 'パスワード強度:弱い', 'パスワード強度:平均', 'パスワード強度:強い', 'パスワード強度:とても強い']} 
                                    password={props.newPassword} />
                            </td>
                        </tr>
                        <tr>
                            <td className="disable-table align-middle" style={{width: '35%'}}>
                                <strong>新しいパスワード(確認入力)</strong>
                            </td>
                            <td>
                                <input type="password" id="confirmPassword" onChange={props.handleChange} className="form-control w-100" />
                                <p className={props.newPassword.length && props.newPassword === props.confirmPassword ? "mt-1 mb-0 text-right" : "d-none"} style={{color: "rgb(137, 135, 146)"}}>パスワードが一致しました</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer className="border-top-0 pt-0">
                <div className="w-100 d-inline-flex">
                    <button 
                        id="authPasswordChangeSubmit"
                        type="button" 
                        className="btn btn-90 mr-2 ml-auto btn-primary" 
                        onClick={props.handleSubmit}
                        disabled={!props.validateForm()}>保存</button>
                    <button 
                        className="btn btn-90 btn-secondary" 
                        onClick={isDefault ? props.handleCloseAuthPasswordChangeModal : props.handleShowDiscardModal}>キャンセル</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AuthPasswordChange