// eslint-disable-next-line
import React, { Component } from 'react';
import MailSettingsTemplate from '../../components/AdminEmailManagement/MailSettingsTemplate';
import AdminMailSettingsButton from '../../components/Pagination/AdminMailSettingsButton';
import { withMailSettingsEdit } from '../../components/AdminEmailManagement/MailSettingsEditModal';
import { withMailSettingsTestServer } from '../../components/AdminEmailManagement/MailSettingsTestServerModal';
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { adminEmailManagementOperations } from './state';

class MailSettingsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            totalItemsCount: 0,
            mailSettingsList: []
        };
    }
    
    componentDidMount() {
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getMailSettingsList().then(
                () => {
                    this.setState({
                        mailSettingsList: this.props.mailSettingsList,
                        totalItemsCount: this.props.totalItemsCount,
                    })
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="mail_settings">
                        <h4 className="mr-auto page-heading">{"メール管理 ＞ メールサーバ設定"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-3">
                            <AdminMailSettingsButton 
                                handleShowMailSettingsEditModal={this.handleShowMailSettingsEditModal}
                                handleShowMailSettingsTestServerModal={this.handleShowMailSettingsTestServerModal} />
                        </nav>
                        <MailSettingsTemplate {...this.state} {...this.props} mailSettingsList={this.state.mailSettingsList} />
                    </div>

                    {this.mailSettingsEditModal({ ...this.state, ...this.props })}
                    {this.mailSettingsEditConfirmModal({ ...this.state, ...this.props })}
                    {this.mailSettingsTestServerModal({ ...this.state, ...this.props })}
                    {this.mailSettingsTestServerConfirmModal({ ...this.state, ...this.props })}

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        formInputs: state.adminEmailManagement.mailSettingsForm,
        testMailServerForm: state.adminEmailManagement.testMailServerForm,
        selectOptions: state.adminEmailManagement.selectOptions,
        totalItemsCount: state.adminEmailManagement.totalItemsCount,
        mailSettingsList: state.adminEmailManagement.mailSettingsList,
        mailSettingsMessage: state.adminEmailManagement.mailSettingsMessage,
        mailServerTestMessage: state.adminEmailManagement.mailServerTestMessage,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMailSettingsList: adminEmailManagementOperations.getMailSettingsList,
        patchMailSettings: adminEmailManagementOperations.patchMailSettings,
        testMailServer: adminEmailManagementOperations.testMailServer,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withMailSettingsEdit(
                            withMailSettingsTestServer(
                                MailSettingsScreen
                            )
                        )
                    )
                )
            )
        )
    )
);