import React from 'react'

const AdminKintoneCommonButton = (props) => {
    let { handleShowModalNewKintoneCommon, handleShowCommonKintoneSortingModal } = props
    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value="共通属性設定登録" onClick={handleShowModalNewKintoneCommon}>共通属性設定登録</button>
                <button className="btn btn-secondary" value="表示順変更" onClick={handleShowCommonKintoneSortingModal}>表示順変更</button>
            </div>
        </div>
    );
}

export default AdminKintoneCommonButton