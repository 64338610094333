import React from 'react';
import { Modal, Button, Form, FormCheck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faEdit, faTable } from '@fortawesome/free-solid-svg-icons'
import TooltipTemplate from '../TooltipTemplate';

const withProductDynamicFieldsModal = (WrappedComponent) => {
    return class ProductDynamicFieldsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductDynamicFieldsModal: false
            }
        }

        productDynamicFieldsModal = (props) => {
            let { productDynamicFields } = this.state;
            let db_selected_field = props.selectedProductDynamicField;
            db_selected_field = db_selected_field.length && db_selected_field[0];

            let okButtonAction = this.handleShowProductCtrlPanelUrlConfirmModal;

            if (productDynamicFields.length === 0 && props.controlPanelURLField === null) {
                okButtonAction = this.handleCloseProductDynamicFieldsModal;
            }
            else if (db_selected_field && props.controlPanelURLField === null) {
                okButtonAction = this.handleCloseProductDynamicFieldsModal;
            }
            else if (productDynamicFields.length && productDynamicFields.every((field) => field.is_control_panel === false)) {
                okButtonAction = this.handleShowControlPanelURLFieldError;
            }

            let cancelButtonAction = this.handleCloseProductDynamicFieldsModal;

            if (productDynamicFields.length === 0 && props.controlPanelURLField === null) {
                cancelButtonAction = this.handleCloseProductDynamicFieldsModal;
            }
            else if (!db_selected_field && props.controlPanelURLField === null) {
                cancelButtonAction = this.handleShowNotificationModal;
            }
            else if (!db_selected_field && props.controlPanelURLField && productDynamicFields.length) {
                cancelButtonAction = this.handleShowDiscardModal;
            }
            else if (db_selected_field && props.controlPanelURLField && (parseInt(db_selected_field.id) !== parseInt(props.controlPanelURLField))) {
                cancelButtonAction = this.handleShowDiscardModal;
            }

            return (
                <Modal show={this.state.showProductDynamicFieldsModal} keyboard={false} size="lg" onHide={cancelButtonAction} className="modal-add-product-dynamic-fields" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品管理 ＞製品情報　Kintone属性設定</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="w-100">
                            <div className="font-weight-bold d-flex">
                                <label className="mb-0 mr-1">Kintone属性設定</label>
                                <div>
                                    <TooltipTemplate message="Kintoneからサービスポータルに取り込むフィールドコードを「項目」に、サービスポータル上での表示タイトル名を「表示ラベル」に設定してください。" />
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-info alert-sm mb-2" role="alert">
                            サービスカスコンリンクの場合、ラジオボタンをつけてください。
                        </div>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-2">
                            <div className="form-inline mr-auto">
                                <div className="btn-group navbar-left">
                                    <button className="btn btn-secondary" value="共通属性設定" onClick={this.handleShowModalNewKintone}>属性設定登録</button>
                                    <button className="btn btn-secondary" value="Sorting" onClick={this.handleShowProductKintoneSortingModal}>表示順変更</button>
                                </div>
                            </div>
                        </nav>

                        {
                            props.showControlPanelURLFieldError ?
                                <div className="alert alert-danger alert-sm" role="alert">
                                    サービスカスコンリンクを選択してください。
                                </div>
                                : ""
                        }

                        <Form id={'form-add-product-dynamic-fields'}>
                            <table className="table table-bordered table-striped table-form">
                                <tbody>
                                    <tr className="d-flex w-100">
                                        <th className="custom-border table-column-2 disable-table font-weight-bold d-inline-flex"></th>
                                        <th className="custom-border table-column-3 disable-table font-weight-bold align-middle d-inline-flex justify-content-center">項⽬</th>
                                        <th className="custom-border table-column-4 disable-table font-weight-bold align-middle d-inline-flex justify-content-center">表⽰ラベル</th>
                                        <th className="custom-border table-column-6 disable-table font-weight-bold text-center dynamic-show-customer-header dynamic-header">契約情報に<br />表示</th>
                                        <th className="custom-border table-column-6 disable-table font-weight-bold text-center dynamic-show-customer-header dynamic-header">その他の機能に<br />表示</th>
                                        <th className="custom-border table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                        <th className="custom-border table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                        <th className="custom-border table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                    </tr>
                                    {productDynamicFields.length === 0 ? <tr><td className="font-weight-normal d-inline-block w-100"><center>設定情報はありません。</center></td></tr> : null}
                                    {productDynamicFields.map((field) => {
                                        return (
                                            <tr key={field.id} className={"d-flex"}>
                                                <td className="d-inline-flex justify-content-center table-column-2 custom-border p-0">
                                                    <FormCheck
                                                        id={"dynamic-radio-" + field.id}
                                                        className={'product-dynamic-fields-radio align-self-center'}
                                                        type="radio"
                                                        value={field.id}
                                                        name={"dynamic_field_radio"}
                                                        checked={field.is_control_panel}
                                                        onChange={
                                                            (event) => {
                                                                this.setSelectedField(event.target.value)
                                                            }
                                                        }
                                                    />
                                                </td>
                                                <td className="align-middle table-column-3 table-column-3-column custom-border px-2 py-0"><div className="dynamic-content"><label className="mb-0">{field.field_name}</label></div></td>
                                                <td className="align-middle table-column-4 table-column-4-column custom-border px-2 py-0"><div className="dynamic-content"><label className="mb-0">{field.field_label}</label></div></td>
                                                <td className="d-inline-flex justify-content-center table-column-6 custom-border">
                                                    {field.show_customer === 1 ? "〇" : ""}
                                                </td>
                                                <td className="d-inline-flex justify-content-center table-column-6 custom-border">
                                                    {field.show_customer === 2 ? "〇" : ""}
                                                </td>
                                                <td className="table-column-5 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faEdit} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowProductKintoneEditLabelModal(field.id, field.field_name, field.field_label, field.is_table, field.show_customer) }} />
                                                    </div>
                                                </td>
                                                <td className="table-column-5 custom-border p-0 dynamic-icon-container">
                                                    {field.is_table &&
                                                        <div>
                                                            <FontAwesomeIcon icon={faTable} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowKintoneProductSubTableFieldsModal(field.product_id, field.id, field.field_label, field.show_customer) }} />
                                                        </div>
                                                    }
                                                </td>
                                                <td className="table-column-5 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faWindowClose} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowDeleteKintoneProductConfirmation(field.id) }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                            <div className="form-group">
                                <div className="float-right">
                                    <Button variant="primary" className="btn btn-90 mr-2" onClick={okButtonAction}>Ok</Button>
                                    <Button variant="secondary" className="btn btn-90" onClick={cancelButtonAction}>キャンセル</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            );
        }

        handleShowProductDynamicFieldsModal = (product_id) => {
            Promise.all([
                this.showLoader(),
                this.props.getAdminKintoneProductFieldList(product_id).then(
                    () => {
                        this.setState({
                            productDynamicFields: this.props.kintoneProductFieldList,
                            selectedProductDynamicField: this.props.kintoneProductFieldList.filter((field) => field.is_control_panel === true),
                            kintoneProductId: product_id,
                            showProductDynamicFieldsModal: true,
                            showControlPanelURLFieldError: false
                        });
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseProductDynamicFieldsModal = () => {
            this.setState({
                showProductDynamicFieldsModal: false,
                controlPanelURLField: null,
                productDynamicFieldsDummy: [],
                productDynamicSubTableFields: [],
            });
        }

        handleShowProductCtrlPanelUrlConfirmModal = () => {
            this.setState({
                showProductCtrlPanelUrlConfirmModal: true
            });
        }

        handleShowDeleteKintoneProductConfirmation = (kintone_id) => {
            this.setState({
                showDeleteKintoneProductConfirmation: true,
                kintoneDeleteId: kintone_id
            })
        }

        handleShowModalNewKintone = () => {
            this.setState({
                showModalNewKintone: true
            })
        }

        handleShowNotificationModal = () => {
            this.setState({
                showNotificationModal: true
            });
        }

        handleShowControlPanelURLFieldError = () => {
            this.setState({
                showControlPanelURLFieldError: true
            });
        }

        handleShowProductKintoneEditLabelModal = (id, field_name, field_label, is_table, show_customer) => {
            this.setState({
                initialKintoneFormValue: {
                    KintoneProductForm: {
                        id: id,
                        kintone_attribute_name: field_name,
                        display_label: field_label,
                        format_data: is_table ? "1" : "0",
                        show_customer: show_customer
                    }
                },
                showProductKintoneEditLabel: true,
                productKintoneFieldId: id
            });
        }

        handleShowProductKintoneSortingModal = () => {
            this.setState({
                showProductDynamicSortingModal: true,
                productDynamicFieldsDummy: this.state.submitSubKintone ? this.state.productDynamicSubTableFields : this.state.productDynamicFields
            })
        }
    }
}

export {
    withProductDynamicFieldsModal
}