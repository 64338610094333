import * as types from "./types";

export const readState = (value) => ({
    type: types.READ_STATE,
    payload: value
});

export const getKintoneDetailsState = (value) => ({
    type: types.KINTONE_DETAILS_STATE,
    payload: value
});

export const getOtherFeatureState = (value) => ({
    type: types.OTHER_FEATURE_STATE,
    payload: value
});

export const getDocumentListState = (value) => ({
    type: types.DOCUMENT_LIST_STATE,
    payload: value
});

export const documentDetailsState = (value) => ({
    type: types.DOCUMENT_DETAILS_STATE,
    payload: value
});

export const getKintoneProductOtherFeatureSubTableData = (value) => ({
    type: types.KINTONE_PRODUCT_DATA_OTHER_FEATURE_SUB_TABLE_STATE,
    payload: value
});

export const getKintoneProductContractInforSubTableData = (value) => ({
    type: types.KINTONE_PRODUCT_DATA_CONTRACT_INFOR_SUB_TABLE_STATE,
    payload: value
});

export const getKintoneCommonContractInforSubTableData = (value) => ({
    type: types.KINTONE_COMMON_DATA_CONTRACT_INFOR_SUB_TABLE_STATE,
    payload: value
});

