import React, { Component } from "react";
import { authOperations } from './state'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withLoader } from "../../components/Loader";
import apiService from "../../../utils/apiService";
import { withPasswordRequestModal } from '../../components/PasswordRequestModal';

class LoginScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            email: "",
            password: "",
            logged: false,
        };
    }

    componentDidMount() {
        // Change the redirect to the main page of your app
        if (this.props.isAuthenticated && this.props.isAdmin) {
            apiService.redirectTo(document, '/admin');
        }
        else if (this.props.isAuthenticated) {
            apiService.redirectTo(document, '/');
        }
        else if (!this.props.isAuthenticated) {
            let params = new URLSearchParams(this.props.location.search);
            let redirectParams = {
                'access': params.get('access_token'),
                'refresh': params.get('refresh_token')
            }

            if (redirectParams && redirectParams.access) {
                this.props.setUser(redirectParams, true)
                    .catch((error) => {
                        this.closeLoader();
                        this.handleShowErrorAlert("Error has occured");
                    });
            }
            else {
                this.props.getSSO()
                    .catch((error) => {
                        this.closeLoader();
                        this.handleShowErrorAlert("Error has occured");
                    });
            }
        }
        else {
            this.closeLoader();
        }
    }

    render() {
        return (
            <>
                {this.loader({ ...this.state })}
                {this.errorAlert({ ...this.state })}
                {this.passwordRequestModal({ ...this.state, ...this.props })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isAdmin: state.auth.isAdmin,
        isPoster: state.auth.isPoster,
        isApprover: state.auth.isApprover,
        isPortalAdmin: state.auth.isPortalAdmin,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        setUser: authOperations.setUser,
        getSSO: authOperations.getSSO
    },
    dispatch
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withLoader(
            withErrorAlert(
                withPasswordRequestModal(
                    LoginScreen
                )
            )
        )
    )
);