// eslint-disable-next-line
import React, { Component } from 'react';
import ProductInformationTemplate from '../../components/AdminProductManagement/ProductInformationTemplate';
import AdminProductButton from "../../components/Pagination/AdminProductButton";
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { adminProductManagementSelectors } from './state';
import { adminProductManagementOperations } from './state';
import reactCSS from 'reactcss'
import { withKintoneDeleteConfirmationModal } from '../../components/AdminProductManagement/KintoneDeleteConfirmationModal';
import { withKintoneNotificationModal } from '../../components/AdminProductManagement/KintoneNotificationModal';
import { withNewKintoneConfirmModal } from '../../components/AdminProductManagement/NewKintoneConfirmModal';
import { withNewKintoneModal } from '../../components/AdminProductManagement/NewKintoneModal';
import { withNewProductConfirmationModal } from '../../components/AdminProductManagement/NewProductConfirmationModal';
import { withNewProductModal } from '../../components/AdminProductManagement/NewProductModal';
import { withProductDeleteConfirmationModal } from '../../components/AdminProductManagement/ProductDeleteConfirmationModal';
import { withProductDraftConfirmModal } from '../../components/AdminProductManagement/ProductDraftConfirmModal';
import { withProductDraftModal } from '../../components/AdminProductManagement/ProductDraftModal';
import { withProductDynamicFieldsModal } from '../../components/AdminProductManagement/ProductDynamicFieldsModal';
import { withProductDynamicSortingConfirmModal } from '../../components/AdminProductManagement/ProductDynamicSortingConfirmModal';
import { withProductDynamicSortingModal } from '../../components/AdminProductManagement/ProductDynamicSortingModal';
import { withProductKintoneConfirmEditLabelModal } from '../../components/AdminProductManagement/ProductKintoneConfirmEditLabelModal';
import { withProductCtrlPanelUrlConfirmModal } from '../../components/AdminProductManagement/ProductCtrlPanelUrlConfirmModal';
import { withProductKintoneEditLabelModal } from '../../components/AdminProductManagement/ProductKintoneEditLabelModal';
import { withProductDynamicSubTableFieldsModal } from '../../components/AdminProductManagement/ProductDynamicSubTableFieldsModal';
import * as CONSTANTS from '../../../utils/constants';

class ProductInformationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            displayColorPicker: false,
            color: {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            },
            controlPanelURLField: null,
            productDynamicFields: [],
            productDynamicSubTableFields: [],
            selectedProductDynamicField: [],
            showControlPanelURLFieldError: false,
            productDynamicFieldsDummy: [],
            kintoneOrders: [],
            fields: {
                start_date: '',
                end_date: ''
            }
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getProductInfoList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.product_name, this.state.sort).then(
                    () => {
                        this.setState({
                            rows: this.props.productTableList,
                            totalItemsCount: this.props.totalItemsCount
                        });
                    }
                ),
                this.props.getPortalProductList().then(
                    () => {
                        this.setState({
                            productLookupList: this.props.productLookupList
                        });
                    }
                ),
                this.props.getPortalCategoryList().then(
                    () => {
                        this.setState({
                            categoryLookupList: this.props.categoryLookupList
                        });
                    }
                )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    setImageForUpload = (img) => {
        this.setState({
            image: img
        })
    }

    removeImageForUpload = () => {
        this.setState({
            image: null
        })
    }

    imageOnload = (image) => {
        var request = new XMLHttpRequest();
        request.open('GET', image, true);
        request.responseType = 'blob';
        request.onload = function () {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
                var type = request.response.type.split("/")[0]
                if (type === "image") {
                    document.getElementById('logo').setAttribute('src', e.target.result);
                }
                else {
                    document.getElementById('logo').setAttribute('alt', "Image not found.");
                }
            };
        };
        request.send();
    };

    handleSubmitConfirmModal = (event) => {
        event.target.setAttribute("disabled", "disabled");
        let id = this.state.productData.id;
        let type = this.state.productData.type;
        let payload = this.state.productData.payload

        if (type === "edit") {
            Promise.all([
                this.showLoader(),
                this.props.putProductEditDetails(id, payload).then(
                    () => {
                        this.loadListFromServer()
                        this.handleCloseDraftModal();
                        this.handleShowSuccessAlert(this.props.productMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseDraftConfirmModal)
        } else if (type === "create") {
            Promise.all([
                this.showLoader(),
                this.props.postCreateProduct(payload).then(
                    () => {
                        this.loadListFromServer()
                        this.handleCloseModalNewProduct();
                        this.handleShowSuccessAlert(this.props.productMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseNewProductConfirmModal)
        }
    }

    handleFormOnchange = (values) => {
        values.formValues.PortalProductForm ? this.setState({ currentFormValues: values.formValues.PortalProductForm }) : this.setState({ currentKintoneFormValues: values.formValues.KintoneProductForm })
    }

    handleCloseAllModal = () => {
        let close_states = {
            showModalNewProduct: false,
            showDiscardModal: false,
            showProductDraftModal: false,
            color: {
                r: 0,
                g: 0,
                b: 0,
                a: 1,
            },
            fields: {
                start_date: '',
                end_date: ''
            },
            showModalNewKintone: false,
            showProductKintoneEditLabel: false,
            productDynamicFieldsDummy: [],
            showProductDynamicSortingModal: false,
            kintoneOrders: []
        };
        if (!this.state.showModalNewKintone && !this.state.showProductKintoneEditLabel && !this.state.showProductDynamicSortingModal) {
            close_states.showProductDynamicFieldsModal = false;
            close_states.controlPanelURLField = null;
        }
        this.setState(close_states);
        this.removeImageForUpload()
    }

    handleClickColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChangeColorPicker = (color) => {
        this.setState({ color: color.rgb })
    };

    hexToRgb = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            a: 1
        } : null;
    }

    setSelectedField = (field_id) => {
        let fields = this.state.productDynamicFields;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id === parseInt(field_id)) {
                fields[i].is_control_panel = true;
            } else {
                fields[i].is_control_panel = false;
            }
        }
        this.setState({
            fields: fields,
            controlPanelURLField: field_id
        })
    }

    handleDateChange = (dateName, dateValue) => {
        let { fields } = this.state;
        if (dateName === "start_date") {
            fields["start_date"] = dateValue
        }
        else {
            fields["end_date"] = dateValue
        }
        this.setState({
            fields
        })
    }

    sortKintone = (sortedList) => {
        sortedList.map((itemRow, idx) => {
            let kintoneOrders = [...this.state.kintoneOrders]
            let kintoneItem = { ...kintoneOrders[idx] }
            kintoneItem.id = parseInt(itemRow.content.key)
            kintoneItem.order = itemRow.rank
            kintoneOrders[idx] = kintoneItem

            let productDynamicFields = [...this.state.productDynamicFields]
            let productDynamicFieldsDummy = [...this.state.productDynamicFieldsDummy]
            if (this.state.submitSubKintone) {
                productDynamicFields = [...this.state.productDynamicSubTableFields]
            }
            let currentKintone = productDynamicFields.find(item => item.id === parseInt(itemRow.content.key))
            let kintoneProductDynamic = {}
            kintoneProductDynamic.id = currentKintone.id
            kintoneProductDynamic.field_label = currentKintone.field_label
            kintoneProductDynamic.field_name = currentKintone.field_name
            kintoneProductDynamic.is_control_panel = currentKintone.is_control_panel
            kintoneProductDynamic.product_id = currentKintone.product_id
            kintoneProductDynamic.order = itemRow.rank
            productDynamicFieldsDummy[idx] = kintoneProductDynamic

            this.setState({
                kintoneOrders: kintoneOrders,
                productDynamicFieldsDummy: productDynamicFieldsDummy
            })
            return false;
        })
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '28px',
                    height: '16px',
                    borderRadius: '2px',
                    background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                },
                swatch: {
                    padding: '10px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="product_info_template">
                        <h4 className="mr-auto page-heading">{"製品管理 ＞ 製品情報管理"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            <AdminProductButton handleShowModalNewProduct={this.handleShowModalNewProduct} />
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <ProductInformationTemplate
                            {...this.state}
                            handleShowDraftModal={this.handleShowDraftModal}
                            handleShowProductDeleteConfirmationModal={this.handleShowProductDeleteConfirmationModal}
                            handleShowProductDynamicFieldsModal={this.handleShowProductDynamicFieldsModal} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.kintoneDeleteConfirmationModal({ ...this.state })}
                    {this.kintoneNotificationModal({ ...this.state })}
                    {this.newKintoneConfirmModal({ ...this.state })}
                    {this.newKintoneModal({ ...this.state, ...this.props, adminProductManagementSelectors })}
                    {this.newProductConfirmationModal({ ...this.state })}
                    {this.newProductModal({ ...this.state, ...this.props, adminProductManagementSelectors, styles })}
                    {this.productDeleteConfirmationModal({ ...this.state })}
                    {this.productDraftConfirmModal({ ...this.state })}
                    {this.productDraftModal({ ...this.state, ...this.props, adminProductManagementSelectors, styles })}
                    {this.productDynamicFieldsModal({ ...this.state, ...this.props })}
                    {this.productDynamicSortingConfirmModal({ ...this.state })}
                    {this.productDynamicSortingModal({ ...this.state, ...this.props })}
                    {this.productKintoneConfirmEditLabelModal({ ...this.state })}
                    {this.productCtrlPanelUrlConfirmModal({ ...this.state })}
                    {this.productKintoneEditLabelModal({ ...this.state, ...this.props, adminProductManagementSelectors })}
                    {this.productDynamicSubTableFieldsModal({ ...this.state, ...this.props, adminProductManagementSelectors })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    state.adminProductManagement.kintoneProductForm.form.forEach(kintoneProductField => {
        if (kintoneProductField.name === "show_customer") {
            kintoneProductField.type = CONSTANTS.FORM_TYPE_RADIO;
            kintoneProductField.default = CONSTANTS.ZERO_STRING;
            kintoneProductField.inline = true;
        }
    });

    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        productTableList: state.adminProductManagement.productTableList,
        totalItemsCount: state.adminProductManagement.totalItemsCount,
        formInputs: state.adminProductManagement.portalProductForm,
        servicePortalFormInputs: state.adminProductManagement.servicePortalProductForm,
        productMessage: state.adminProductManagement.productMessage,
        productEditDetails: state.adminProductManagement.productEditDetails,
        categoryLookupList: state.adminProductManagement.categoryLookupList,
        productLookupList: state.adminProductManagement.productFilter,
        listOrdering: state.adminProductManagement.productOrdering,
        defaultOrdering: state.adminProductManagement.defaultProductOrdering,
        kintoneProductFieldList: state.adminProductManagement.kintoneProductFieldList,
        radioInput: state.adminProductManagement.radioOptions,
        kintoneFormInputs: state.adminProductManagement.kintoneProductForm,
        kintoneMessage: state.adminProductManagement.kintoneMessage,
        kintoneProductSubTableFieldList: state.adminProductManagement.kintoneProductSubTableFieldList,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getProductInfoList: adminProductManagementOperations.getProductInfoList,
        deleteProduct: adminProductManagementOperations.deleteProduct,
        postCreateProduct: adminProductManagementOperations.postCreateProduct,
        getProductEditDetails: adminProductManagementOperations.getProductEditDetails,
        getPortalCategoryList: adminProductManagementOperations.getPortalCategoryList,
        getProductImage: adminProductManagementOperations.getProductImage,
        putProductEditDetails: adminProductManagementOperations.putProductEditDetails,
        getPortalProductList: adminProductManagementOperations.getPortalProductList,
        getAdminKintoneProductFieldList: adminProductManagementOperations.getAdminKintoneProductFieldList,
        postCreateKintoneProductField: adminProductManagementOperations.postCreateKintoneProductField,
        deleteKintoneProductField: adminProductManagementOperations.deleteKintoneProductField,
        patchKintoneProductControlPanel: adminProductManagementOperations.patchKintoneProductControlPanel,
        patchKintoneProductFieldLabel: adminProductManagementOperations.patchKintoneProductFieldLabel,
        patchSortingKintoneProductField: adminProductManagementOperations.patchSortingKintoneProductField,
        getKintoneProductSubTableFieldList: adminProductManagementOperations.getKintoneProductSubTableFieldList,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withKintoneDeleteConfirmationModal(
                            withKintoneNotificationModal(
                                withNewKintoneConfirmModal(
                                    withNewKintoneModal(
                                        withNewProductConfirmationModal(
                                            withNewProductModal(
                                                withProductDeleteConfirmationModal(
                                                    withProductDraftConfirmModal(
                                                        withProductDraftModal(
                                                            withProductDynamicFieldsModal(
                                                                withProductDynamicSubTableFieldsModal(
                                                                    withProductDynamicSortingConfirmModal(
                                                                        withProductDynamicSortingModal(
                                                                            withProductKintoneConfirmEditLabelModal(
                                                                                withProductCtrlPanelUrlConfirmModal(
                                                                                    withProductKintoneEditLabelModal(ProductInformationScreen)
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);