import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';
import fileDownload from 'js-file-download';

export const getDocumentList = (
    itemsCountPerPage, activePage, query,
    category_number, product_number, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                return apiService.get(Path.DOCUMENT, {
                    params: {
                        num: itemsCountPerPage,
                        page: activePage,
                        search: query,
                        post_category: category_number,
                        product: product_number,
                        ordering: sort
                    }
                }).then((response) => {
                    dispatch(Actions.readState(response.data));
                })
            })
            .catch(apiService.handleDisplayError);
    }

export const getDocumentDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT + id + '/').then((response) => {
                dispatch(Actions.documentDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentPublishedVersionDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_PUBLISHED_VERSION + id + '/').then((response) => {
                dispatch(Actions.documentPublishedVersionDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const postDocumentCreateDetails = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'post',
                url: Path.DOCUMENT_CREATE,
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then((response) => {
                    dispatch(Actions.postDocumentCreateDetailsState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const putDocumentEditDetails = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'put',
                url: Path.DOCUMENT_UPDATE + id + '/',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then((response) => {
                    dispatch(Actions.putDocumentEditDetailsState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'GET',
                url: Path.PRODUCTLOOKUP,
                responseType: 'json',
                params: {
                    access_type: '1'
                }
            })
                .then((response) => {
                    dispatch(Actions.productLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentEditDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_UPDATE + id + '/').then((response) => {
                dispatch(Actions.getDocumentEditDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchDocumentUnpublish = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.DOCUMENT_UNPUBLISH + id + '/', data).then((response) => {
                dispatch(Actions.patchDocumentUnpublishState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchDocumentDelete = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.DOCUMENT_UPDATE + id + '/', data).then((response) => {
                dispatch(Actions.patchDocumentDeleteState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchDocumentCancel = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.DOCUMENT_CANCEL + id + '/', data).then((response) => {
                dispatch(Actions.patchDocumentCancelState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentHistoryDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_HISTORY_DETAILS + id + '/').then((response) => {
                dispatch(Actions.getDocumentHistoryDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentFile = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            let id = data.id;
            return apiService.get(Path.DOCUMENT_DOWNLOAD + id + '/', {
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                fileDownload(response.data, data.file_name, response.headers['content-type']);
                // if (response.status === 200) {
                //     dispatch(Actions.getDocumentFileState(["File downloaded successfully"]));
                // }
                // else {
                //     return "File Download failed"
                // }
            })
        })
        .catch(apiService.handleDisplayError);
}
