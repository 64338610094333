import { DATETIME_FORMAT, DATETIME_FORMAT_2 } from '../../utils/constants';
import moment from 'moment';
import MomentTimezone from 'moment-timezone';
import ReactHtmlParser from 'react-html-parser';
import JwtDecode from 'jwt-decode';

export const getStatusBackground = (status) => {
    var statusBg;

    switch (status) {
        case "公開中止承認待ち":
            statusBg = "#FFB74D"; //yellow
            break;
        case "公開中止承認却下":
            statusBg = "#E57373"; //red
            break;
        case "公開中止":
            statusBg = "#888282"; //grey
            break;
        case "下書き":
            statusBg = "#FF8A65"; //orange
            break;
        case "承認待ち":
            statusBg = "#FFB74D"; //yellow
            break;
        case "承認却下":
            statusBg = "#E57373"; //red
            break;
        case "公開待ち":
            statusBg = "#4DB6AC"; //green
            break;
        case "公開中":
            statusBg = "#4DB6AC"; //green   
            break;
        default:
            statusBg = "#000000";
            break;
    }

    return statusBg;
}

export const getApprovalStatusBackground = (status) => {
    var statusBg;

    switch (status) {
        case "承認済":
            statusBg = "#4DB6AC"; //green
            break;
        case "承認却下":
            statusBg = "#E57373"; //red
            break;
        case "申請取り消し":
            statusBg = "#FF8A65"; //orange
            break;
        default:
            statusBg = "#000000";
            break;
    }

    return statusBg;
}

export const getProductStatusBackground = (status) => {
    var statusBg;

    switch (status) {
        case "サービス終了予定":
            statusBg = "#F89406"; //orange
            break;
        case "サービス終了":
            statusBg = "#999"; //grey
            break;
        case "サービス中":
            statusBg = "#468847"; //green
            break;
        default:
            statusBg = "#000000";
            break;
    }

    return statusBg;
}

export const getApplicationTypeName = (type) => {
    let name;

    switch (type) {
        case 1:
        case "1":
            name = "おしらせ";
            break;
        case 2:
        case "2":
            name = "ドキュメント";
            break;
        default:
            name = "";
            break;
    }

    return name;
}

export const getDetailsFormattedContent = (content = "") => {
    let value = content.replace(/(\r\n|\n|\r)/gm, "<br>");
    return ReactHtmlParser(value);
}

export const getClientTimezone = () => {
    return MomentTimezone.tz.guess(true);
}

export const getClientDatetime = (datetime) => {
    let date = moment(new Date(datetime)).format(DATETIME_FORMAT);
    return date;
}

export const getServerDatetime = (datetime) => {
    let date = moment(datetime);
    let mtz = MomentTimezone.tz(date, getClientTimezone());
    return mtz.utc().format();
}

export const getClientDatetimeDisplay = (datetime) => {
    let date = '';
    if (datetime) {
        date = moment(new Date(datetime)).format(DATETIME_FORMAT_2);
    }
    return date;
}

export const getMailingStatusBackground = (status) => {
    var statusBg;

    switch (status) {
        case "送信済み":
            statusBg = "#4DB6AC"; //green
            break;
        case "送信失敗":
            statusBg = "#E57373"; //red
            break;
        case "送信予定":
            statusBg = "#FF8A65"; //orange
            break;
        case "再送予定":
            statusBg = "#E57373"; //red
            break;
        default:
            statusBg = "#000000";
            break;
    }

    return statusBg;
}

export const getDecodedAuth = (access) => {
    let decodedAuth = {};
    let decodedAccess = undefined;

    if (typeof access === "string") {
        decodedAccess = JwtDecode(access);

        if (typeof decodedAccess === "object") {
            decodedAuth = {
                username: decodedAccess.username,
                isAdmin: decodedAccess.is_admin,
                isSuperuser: decodedAccess.is_superuser,
                isPoster: decodedAccess.is_poster,
                isApprover: decodedAccess.is_approver,
                isPortalAdmin: decodedAccess.is_portal_admin,
                groups: decodedAccess.groups
            }
        }
    }

    return decodedAuth;
}

const validateIpv46Adress = (ipAddress) => {
    const ipPattern = '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
    const ipRegex = new RegExp(ipPattern);

    return ipRegex.test(ipAddress);
}

const validateDomainPart = (domainRegex, literalRegex, domainPart) => {
    if (domainRegex.test(domainPart)) {
        return true;
    }

    const literalMatch = literalRegex.test(domainPart);
    if (literalMatch) {
        // domain_part value is expected to have brackets ex: [192.xxx.x.x]
        // removing the brackets and pass the value for validation
        const ipAddress = domainPart.replace('[', '').replace(']', '');
        return validateIpv46Adress(ipAddress);
    }

    return false;
}

export const isEmailValid = (emailAddress) => {
    const userPattern = '(^[-!#$%&\'*+/=?^_`{}|~0-9A-Z]+(\\.[-!#$%&\'*+/=?^_`{}|~0-9A-Z]+)*|^"([\\001-\\010\\013\\014\\016-\\037!#-\\[\\]-\\177]|\\\\[\\001-\\011\\013\\014\\016-\\177])*")$';
    const domainPattern = '(?=^.{4,254}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\\.)+(([A-Z0-9-]{1,62}[A-Z0-9]))$)';
    const literalPattern = '\\[([A-f0-9:\\.]+)\\]$';

    const userRegex = new RegExp(userPattern, 'i');
    const domainRegex = new RegExp(domainPattern, 'i');
    const literalRegex = new RegExp(literalPattern, 'i');

    const domainWhitelist = ['localhost'];

    /*
     * Note: If this constant needs to be updated in the future,
     * the back-end needs to be updated accordingly:
     * app/validators.py:9 (EMAIL_LOCAL_PART_MAX_LENGTH)
     */
    const emailLocalPartMaxLength = 64;

    if (!emailAddress || (emailAddress.indexOf('@') === -1)) {
        return false;
    }

    const splitEmail = emailAddress.split("@", 2);
    const userPart = splitEmail[0];
    const domainPart = splitEmail[1];

    if (userPart.length > emailLocalPartMaxLength || !userRegex.test(userPart)) {
        return false;
    }

    return ((domainWhitelist.indexOf(domainPart) === -1) && validateDomainPart(domainRegex, literalRegex, domainPart));
}

export const getStatusCodeText = (status_code) => {
    let status_text;

    if (status_code >= 200 && status_code <= 399) {
        status_text = "成功";
    }
    else if (status_code >= 400 && status_code <= 599) {
        status_text = "失敗";
    }
    else {
        status_text = "";
    }

    return status_text;
}

export const getStatusCodeColor = (status_code) => {
    let customStatusStyle = { color: "#000000" }
    
    if (status_code >= 200 && status_code <= 399) {
        customStatusStyle.color = "#008000";
    }
    else if (status_code >= 400 && status_code <= 599) {
        customStatusStyle.color = "#FF0000";
    }

    return customStatusStyle;
}