import React from 'react'

const Filter = (props) => {
    let { filterList, handleFilterChange } = props;

    return (
        <div className="form-inline ml-auto">
            <div className="form-group mr-1">
                <select className="form-control" onChange={handleFilterChange}>
                    <option value="">すべてのサービス</option>
                    {
                        filterList.map(filter => {
                            return <option key={filter.id} value={filter.id}>{filter.label}</option>
                        })
                    }
                </select>
            </div>
        </div>
    );
}

export default Filter