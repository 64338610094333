import * as types from "./types";

export const readState = (value) => ({
    type: types.READ_STATE,
    payload: value
});

export const approvalHistoryState = (value) => ({
    type: types.APPROVAL_HISTORY_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const categoryLookupListState = (value) => ({
    type: types.CATEGORY_LOOKUP_STATE,
    payload: value
});

export const getNoticeApprovalDetailsState = (value) => ({
    type: types.NOTICE_APPROVAL_RETRIEVE_STATE,
    payload: value
});

export const getNoticeApprovalHistoryDetailsState = (value) => ({
    type: types.NOTICE_APPROVAL_RETRIEVE_HISTORY_STATE,
    payload: value
});

export const getApprovalHistoryNoticeDetailsState = (value) => ({
    type: types.APPROVAL_HISTORY_NOTICE_DETAIL_STATE,
    payload: value
});

export const patchApprovalState = (value) => ({
    type: types.APPROVAL_STATE,
    payload: value
});

export const getDocumentApprovalDetailsState = (value) => ({
    type: types.DOCUMENT_APPROVAL_RETRIEVE_STATE,
    payload: value
});

export const getDocumentApprovalHistoryDetailsState = (value) => ({
    type: types.DOCUMENT_APPROVAL_RETRIEVE_HISTORY_STATE,
    payload: value
});

export const getApprovalHistoryDocumentDetailsState = (value) => ({
    type: types.APPROVAL_HISTORY_DOCUMENT_DETAIL_STATE,
    payload: value
});

export const getDocumentFileState = (value) => ({
    type: types.DOCUMENT_DOWNLOAD_STATE,
    payload: value
});

export const getDocumentEditDetailsState = (value) => ({
    type: types.DOCUMENT_EDIT_RETRIEVE_STATE,
    payload: value
});

export const getNoticeEditDetailsState = (value) => ({
    type: types.NOTICE_EDIT_RETRIEVE_STATE,
    payload: value
});

export const categoryLookupListStateForEditNotice = (value) => ({
    type: types.CATEGORY_LOOKUP_EDIT_NOTICE_STATE,
    payload: value
});
