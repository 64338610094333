import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import * as Commons from '../Commons'
import { MDBTable, MDBTableBody } from 'mdbreact';

const ListOfAdminTemplate = (props) => {
    let {
        rows,
        handleShowAdminDetailsModal,
        handleShowAdminChangeStatusConfirmModal,
    } = props;

    return (
        <div className="mt-2">
            {props.totalItemsCount > 0 ?
                <MDBTable className="w-100">
                    <MDBTableBody>
                        {rows.length ? <tr className="border disable-table">
                            <th>ユーザ名</th>
                            <th>氏名</th>
                            <th>所属</th>
                            <th>メールアドレス</th>
                            <th>最終ログイン日</th>
                            <th>無効</th>
                        </tr> : null }
                        {rows.map(admin => {
                            var lastLogin = Commons.getClientDatetimeDisplay(admin.last_login)
                            return (
                                <tr key={admin.id} className="border">
                                    {/* <td>{admin.email}</td> */}
                                    <td><a href="# " onClick={(event) => { handleShowAdminDetailsModal(admin.id, event) }}><strong>{admin.username}</strong></a></td>
                                    <td>{admin.full_name}</td>
                                    <td>{admin.department}</td>
                                    <td>{admin.email}</td>
                                    <td>{lastLogin}</td>
                                    <td>
                                        {admin.admin_status === 0 ?
                                            <a className={`nav-link py-0 px-0 btn-action`} href="# " onClick={() => { handleShowAdminChangeStatusConfirmModal(admin.id, admin.admin_status) }}>
                                                <FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faWindowClose} /><span className="pl-1">無効化</span>
                                            </a>
                                            :
                                            <a className={`nav-link py-0 px-0 btn-action btn-disable-color`} href="# " onClick={() => { handleShowAdminChangeStatusConfirmModal(admin.id, admin.admin_status) }}>
                                                <FontAwesomeIcon style={{ fontSize: '1rem' }} icon={faEyeSlash} /><span className="pl-1">有効化</span>
                                            </a>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
                : <h4>検索の結果、0件でした。</h4>}
        </div>
    );
}

export default ListOfAdminTemplate;