import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from '@fortawesome/free-solid-svg-icons'


const withLoader = (WrappedContent) => {
    return class Loader extends WrappedContent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showLoader: false,
            };
        }

        loader = (props) => {
            return (
                <Modal show={props.showLoader} size="sm" backdrop="static" className="modal-loader" centered>
                    <Modal.Body>
                        <div className="text-center">
                            <FontAwesomeIcon icon={faCog} spin size="5x" color="#6c757d" />
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }

        showLoader = () => {
            this.setState({ showLoader: true });
        }

        closeLoader = () => {
            this.setState({ showLoader: false })
        }
    }
}

export {
    withLoader
}