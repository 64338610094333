import { DOCUMENT_ERROR_TITLE_1, DOCUMENT_ERROR_TITLE_2, NEWS_ERROR_TYPE, NEWS_ERROR_TITLE_1, NEWS_ERROR_TITLE_2 } from '../../../../utils/constants';

function checkErrors(state) {
    let errors = {};

    let title = state.DocumentForm.title.trim();

    if (title === "") {
        errors['DocumentForm.title'] = DOCUMENT_ERROR_TITLE_1;
    }
    else if (!/^.{1,64}$/i.test(title)) {
        errors['DocumentForm.title'] = DOCUMENT_ERROR_TITLE_2;
    }

    return errors;
}

function checkNoticeErrors(state) {
    let errors = {};

    let type = state.PostForm.type;
    let title = state.PostForm.title.trim();

    if (type === "") {
        errors['PostForm.type'] = NEWS_ERROR_TYPE;
    }
    if (title === "") {
        errors['PostForm.title'] = NEWS_ERROR_TITLE_1;
    }
    else if (!/^.{1,64}$/i.test(title)) {
        errors['PostForm.title'] = NEWS_ERROR_TITLE_2;
    }

    return errors;
}

export {
    checkErrors,
    checkNoticeErrors
};
