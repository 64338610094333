import React from 'react';
import { Modal } from 'react-bootstrap';


const withMailSettingsTestServerConfirm = (WrappedContent) => {

    return class MailSettingsTestServerConfirm extends WrappedContent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showMailSettingsTestServerConfirmModal: false
            };
        }

        mailSettingsTestServerConfirmModal = (props) => {
            return (
                <Modal keyboard={false} size="md" centered 
                    className="modal-test-server-confirm-mail-settings modal-dialog-md" 
                    show={props.showMailSettingsTestServerConfirmModal} 
                    onHide={this.handleCloseMailSettingsTestServerConfirmModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{"テストメールを送信 > 送信確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>
                            テストメールを送信します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <button 
                                type="button"
                                className="btn btn-90 mr-2 ml-auto btn-primary" 
                                onClick={this.handleSubmitMailSettingsTestServerConfirmModal}>Ok</button>
                            <button 
                                className="btn btn-90 btn-secondary" 
                                onClick={this.handleCloseMailSettingsTestServerConfirmModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowMailSettingsTestServerConfirmModal = () => {
            this.setState({
                showMailSettingsTestServerConfirmModal: true,
            })
        }

        handleCloseMailSettingsTestServerConfirmModal = () => {
            this.setState({
                showMailSettingsTestServerConfirmModal: false
            })
        }

        handleSubmitMailSettingsTestServerConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let payload = this.state.testMailServerData.payload;
            
            Promise.all([
                this.showLoader(),
                this.props.testMailServer(payload).then(
                    () => {
                        this.handleCloseMailSettingsTestServerModal();
                        this.handleShowSuccessAlert(this.props.mailServerTestMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseMailSettingsTestServerConfirmModal)
        }
    }

}

export {
    withMailSettingsTestServerConfirm
}
