import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';

export const getCustomerAccessLogList = (itemsCountPerPage, activePage, query, sort) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CUSTOMER_ACCESS_LOG, {
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    ordering: sort
                }
            }).then((response) => {
                dispatch(Actions.readState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}