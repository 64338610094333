// "react-app-polyfill": "^1.0.6"
// import 'react-app-polyfill/ie9';
import { isNullOrUndefined } from 'util';
import { version } from '../package.json';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import "@babel/polyfill";
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './includes/custom/css/index.css';
import { Provider } from 'react-redux';
import configureStore, { rootRoutes } from './app.modules.js';
import {
    BrowserRouter,
    Switch
} from 'react-router-dom';
import CustomRoute from "./views/components/CustomRoute";
import './includes/bootstrap/css/bootstrap.min.css';

const Root = (props) => {
    let appVersion = localStorage.getItem("APP_VERSION");
    if(isNullOrUndefined(appVersion) || (!isNullOrUndefined(appVersion) && appVersion !== version) ){
        localStorage.clear()
        configureStore().dispatch({type: "RESET_STORE", payload: []})
        localStorage.setItem('APP_VERSION', version);
    }

    return (
        <Provider store={configureStore()}>
            <BrowserRouter initialEntries={[props.currentPath]}>
                <Switch>
                    {
                        rootRoutes.map((route, i) => {
                            return <CustomRoute key={i} {...route} />
                        })
                    }
                </Switch>
            </BrowserRouter>
        </Provider>
    )
}

ReactDOM.render(
    <Root />,
    document.getElementById('root')
);
