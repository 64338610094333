// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clearAuth from '../../../utils/clearAuth';
import LoginErrorModal from '../../components/LoginErrorModal';
import apiService from '../../../utils/apiService';
import Error403 from '../Errors/403';

class AdminHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoginError: true
        };
    }

    componentDidMount() {
        apiService.refreshToken();
    }

    handleCloseLoginErrorModal = () => {
        this.setState({
            showLoginError: false
        })
        clearAuth();
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                <LoginErrorModal
                    {...this.state}
                    {...this.props}
                    login_route={this.props.isAdminPage ? "/admin/login" : "/login"}
                    showModal={this.state.showLoginError}
                    handleCloseModal={this.handleCloseLoginErrorModal} />
                :
                <Error403/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomeScreen);