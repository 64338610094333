import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import DragSortableList from 'react-drag-sortable'

const withCommonKintoneSortingModal = (WrappedComponent) => {
    return class CommonKintoneSortingModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCommonKintoneSortingModal: false
            }
        }

        commonKintoneSortingModal = (props) => {
            let { kintoneProductCommonFieldList, kintoneProductCommonFieldListDummy, kintoneOrders } = this.state;
            let okButtonAction = this.handleShowCommonKintoneSortingConfirmModal;

            if (kintoneOrders.length === 0) {
                okButtonAction = this.handleCloseCommonKintoneSortingModal;
            }

            let cancelButtonAction = this.handleCloseCommonKintoneSortingModal;

            if (kintoneOrders.length) {
                cancelButtonAction = this.handleShowDiscardModal;
            }

            var list = [];
            kintoneProductCommonFieldListDummy.map((field) => {
                list.push({
                    "content": (
                        <div key={field.id} className={"d-flex"}>
                            <div className="d-inline-flex justify-content-center custom-border disable-table table-column-1 font-weight-bold">
                                <center className="align-self-center">
                                    <FontAwesomeIcon icon={faEllipsisV} className="pointer" />
                                    <FontAwesomeIcon icon={faEllipsisV} className="pointer" />
                                </center>
                            </div>
                            <div className="align-middle table-column-2 table-column-2-column custom-border px-2"><label className="mb-0">{field.field_name}</label></div>
                            <div className="align-middle table-column-3 table-column-3-column custom-border px-2"><label className="mb-0">{field.field_label}</label></div>
                        </div>
                    )
                })
                return false;
            })

            return (
                <Modal show={this.state.showCommonKintoneSortingModal} keyboard={false} size="lg" onHide={cancelButtonAction} className="modal-kintone-common-sorting-fields" centered>
                    <Modal.Header closeButton>
                        {/* Common attribute setting> Change display order */}
                        <Modal.Title>{"共通属性設定 > 表示順変更"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id={'form-kintone-common-sorting-fields'}>
                            <div className="table table-bordered table-striped table-form">
                                <div className="tbody">
                                    <div className="d-flex w-100">
                                        <div className="custom-border table-column-1 disable-table font-weight-bold"></div>
                                        <div className="custom-border table-column-2 disable-table font-weight-bold align-middle text-center">項⽬</div>
                                        <div className="custom-border table-column-3 disable-table font-weight-bold align-middle text-center">表⽰ラベル</div>
                                    </div>
                                    <div className="w-100 d-flex">
                                        {kintoneProductCommonFieldList.length === 0 ? <div className="font-weight-normal w-100"><center>設定情報はありません。</center></div> :
                                            <div className="w-100">
                                                <DragSortableList items={list} onSort={this.sortKintoneCommon} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="float-right">
                                    <Button variant="primary" className="btn btn-90 mr-2" onClick={okButtonAction}>Ok</Button>
                                    <Button variant="secondary" className="btn btn-90" onClick={cancelButtonAction}>キャンセル</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            );
        }

        handleShowCommonKintoneSortingModal = () => {
            this.setState({
                showCommonKintoneSortingModal: true,
                kintoneProductCommonFieldListDummy: this.state.submitCommonSubKintone ? this.state.commonDynamicSubTableFields : this.state.kintoneProductCommonFieldList
            })
        }

        handleCloseCommonKintoneSortingModal = () => {
            this.setState({
                showCommonKintoneSortingModal: false,
                kintoneOrders: []
            })
        }

        handleShowCommonKintoneSortingConfirmModal = () => {
            this.setState({
                showCommonKintoneSortingConfirmModal: true
            });
        }
    }
}

export {
    withCommonKintoneSortingModal
}