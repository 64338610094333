import reducer from "./reducers";
import { routes } from "../routes";
import * as adminProductManagementOperations from "./operations";
import * as adminProductManagementSelectors from "./selectors"

export {
    adminProductManagementOperations,
    adminProductManagementSelectors
};

const index = {
    routes,
    reducer
}

export default index;