import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminDocumentDeleteModal = (WrappedComponent) => {
    return class AdminDocumentDeleteModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentDeleteModal: false
            }
        }

        adminDocumentDeleteModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDocumentDeleteModal} onHide={this.handleCloseDeleteModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント下書削除確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「ドキュメント」下書を削除します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitDeleteModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDeleteModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleCloseDeleteModal = () => {
            this.setState({ showDocumentDeleteModal: false });
        }

        handleSubmitDeleteModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            Promise.all([
                this.showLoader(),
                this.props.patchDocumentDelete(this.state.documentDeleteId, { is_deleted: 1 }).then(
                    () => {
                        this.loadListFromServer()
                        this.handleCloseDraftModal()
                        this.handleShowSuccessAlert(this.props.documentMessages)
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseDeleteModal)
        }
    }
}

export {
    withAdminDocumentDeleteModal
}