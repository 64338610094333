import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const FilterSearch = (props) => {
    let { productLookupList, handleProductLookupChange, handleSearchChange, handlePageChange, handleSearchClick, hideServiceDropdown } = props;
    let { showChooseTemplateModal, productIdInChooseTemplateModal } = props;

    return (
        <div className="form-inline ml-auto">
            {
                hideServiceDropdown ?
                    ""
                    :
                    productLookupList.length ?
                        (showChooseTemplateModal && productIdInChooseTemplateModal !== undefined) ?
                        <div className="form-group mr-1">
                            <select disabled className="form-control" onChange={handleProductLookupChange}>
                                <option value="">{productLookupList.find(product => product.id === productIdInChooseTemplateModal).label}</option>
                            </select>
                        </div>
                        :
                        <div className="form-group mr-1">
                            <select className="form-control" onChange={handleProductLookupChange}>
                                <option value="">すべてのサービス</option>
                                {
                                    productLookupList.map(product => {
                                        return <option key={product.id} value={product.id}>{product.label || product.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        : ""
            }

            <div className="input-group">
                <input type="text"
                    className="form-control"
                    placeholder="検索キーワードを入力してください"
                    onChange={handleSearchChange}
                    onKeyPress={event => {
                        if (event.key === "Enter") {
                            handlePageChange(1)
                        }
                    }} />
                <div className="input-group-append">
                    <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={handleSearchClick}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FilterSearch