import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';
import fileDownload from 'js-file-download';

export const getCustomerInfoList = (
    itemsCountPerPage, activePage, query, 
    product_number, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                return apiService.get(Path.CUSTOMER_LIST, {
                    params: {
                        num: itemsCountPerPage,
                        page: activePage,
                        search: query,
                        portalcustomer__contract__product__id: product_number,
                        ordering: sort
                    }
                })
                .then((response) => {
                    dispatch(Actions.getCustomerListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getCustomerInformationDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CUSTOMER_INFO + id + '/')
                .then((response) => {
                    dispatch(Actions.getCustomerInformationDetailsState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getPortalProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.PRODUCTLOOKUP)
                .then((response) => {
                    dispatch(Actions.productLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getAdminList = (
    itemsCountPerPage, activePage, query, 
    product_number, sort) => (dispatch) => {
    return apiService.refreshToken()
    .then(()=>{
        return apiService.get(Path.ADMIN_LIST, {
            params: {
                num: itemsCountPerPage,
                page: activePage,
                search: query,
                groups__adgroup__product__id: product_number,
                ordering: sort
            }
        }).then((response) => {
            dispatch(Actions.getAdminListState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const getAdminDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
    .then(()=>{
        return apiService.get(Path.ADMIN_LIST + id + '/')
        .then((response) => {
            dispatch(Actions.getAdminDetailsState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const adminPasswordChangeRequest = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.post(Path.ADMIN_PASSWORD_CHANGE_REQUEST, data)
                .then((response) => {
                    dispatch(Actions.adminPasswordChangeRequestState(response.data));
                })
            })
            .catch(apiService.handleDisplayError);
}

export const getAdminKintoneProductSubTableData = (contractId, kintoneId, isShowIn, itemsCountPerPage, activePage) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.ADMIN_KINTONE_PRODUCT_SUB_TABLE_DATA + contractId + '/', {
            params: {
                kintoneId: kintoneId,
                isShowIn: isShowIn,
                num: itemsCountPerPage,
                page: activePage
            }
        })
        .then((response) => {
            dispatch(Actions.getAdminKintoneProductSubTableData(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const getAdminKintoneCommonSubTableData = (contractId, kintoneId, isShowIn, itemsCountPerPage, activePage) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.ADMIN_KINTONE_COMMON_SUB_TABLE_DATA + contractId + '/', {
            params: {
                kintoneId: kintoneId,
                isShowIn: isShowIn,
                num: itemsCountPerPage,
                page: activePage
            }
        })
        .then((response) => {
            dispatch(Actions.getAdminKintoneCommonSubTableData(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const updateAdminStatus = (data) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.post(Path.ADMIN_STATUS, data)
            .then((response) => {
                dispatch(Actions.adminStatusState(response.data));
            })
    })
    .catch(apiService.handleDisplayError);
}

export const exportAdmin = (encode, current_timezone, product_number, sort) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.ADMIN_EXPORT, {
            params:{
                encode: encode,
                timezone: current_timezone,
                groups__adgroup__product__id: product_number,
                ordering: sort
            },
            responseType: 'blob'
        })
        .then((response) => {
            let file_name = 'portaladmin_utf-8.csv';
            if(encode ==="shift-jis") file_name = 'portaladmin_shift-jis.csv';
            fileDownload(response.data, file_name, response.headers['content-type']);
        })
    })
    .catch(apiService.handleDisplayError);
}
