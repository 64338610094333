import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate';
import * as Commons from '../Commons';

const withAdminDocumentPublishedVersionDetailModal = (WrappedComponent) => {
    return class AdminDocumentPublishedVersionDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalPublishedVersionDetail: false
            }
        }

        adminDocumentPublishedVersionDetailModal = (props) => {
            let template = [];
            const { documentPublishedVersionDetails } = props;
            if (documentPublishedVersionDetails) {
                var customStyle = { color: "#4DB6AC", border: "solid 1px #4DB6AC" };
                var initialFormValue = {
                    DocumentDetailPostForm: {
                        post_id: documentPublishedVersionDetails.id,
                        edit_date: documentPublishedVersionDetails.updated_date ? Commons.getClientDatetimeDisplay(documentPublishedVersionDetails.updated_date) : "",
                        type: documentPublishedVersionDetails.category_name,
                        product: documentPublishedVersionDetails.product_name,
                        title: documentPublishedVersionDetails.title,
                        content: documentPublishedVersionDetails.content,
                        files: documentPublishedVersionDetails.files,
                        applicant: documentPublishedVersionDetails.applied_by ? documentPublishedVersionDetails.applied_by : "",
                        publish_date: documentPublishedVersionDetails.publish_preferred_date == null ? "0" : "1",
                        publish_preferred_date: Commons.getClientDatetimeDisplay(documentPublishedVersionDetails.publish_preferred_date),
                        publish_type: documentPublishedVersionDetails.publish_type
                    }
                };

                template = (
                    <Modal className="modal-dialog-lg admin-document-details" keyboard={false} size="lg" show={this.state.showModalPublishedVersionDetail} onHide={this.handleCloseModalPublishedVersionDetail} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>ドキュメント管理 ＞ ドキュメント詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4 pb-0">
                            <div className="float-right mb-2">
                                <label className="rounded text-center px-2 my-0" style={customStyle}>
                                    公開中
                                </label>
                            </div>
                            <AdminFormTemplate
                                formInputs={props.documentDetailPostForm}
                                radioInput={props.radioInput}
                                initialValues={initialFormValue}
                                publishDate={documentPublishedVersionDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(documentPublishedVersionDetails.publish_preferred_date)}
                                form={"admin-document-form" + documentPublishedVersionDetails.id}
                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="px-0 border-top-0">
                            <div className="w-100 admin-detail-modal-footer">
                                <div className={`w-100 d-inline-flex flex-row-reverse`}>
                                    <Button variant="secondary" className="btn btn-135 mr-4" onClick={this.handleCloseModalPublishedVersionDetail}>
                                        お知らせ一覧に戻る
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }
            return template;
        }

        handleShowModalPublishedVersionDetail = (publishedVersionId) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentPublishedVersionDetails(publishedVersionId).then(
                    () => {
                        this.setState({ showModalPublishedVersionDetail: true })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseModalPublishedVersionDetail = () => {
            this.setState({ showModalPublishedVersionDetail: false })
        }
    }
}

export {
    withAdminDocumentPublishedVersionDetailModal
}