import * as types from "./types";

export const adminAccessLogState = (value) => ({
    type: types.ADMIN_ACCESS_LOG_STATE,
    payload: value
});

export const customerAccessLogState = (value) => ({
    type: types.CUSTOMER_ACCESS_LOG_STATE,
    payload: value
});