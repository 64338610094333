import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const withKintoneCommonDeleteConfirmationModal = (WrappedComponent) => {
    return class KintoneCommonDeleteConfirmationModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDeleteKintoneCommonProductConfirmation: false
            }
        }

        kintoneCommonDeleteConfirmationModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDeleteKintoneCommonProductConfirmation} onHide={this.handleCloseDeleteKintoneCommonProductConfirmation} centered>
                    <Modal.Header closeButton>
                        {/* Common attribute setting> Delete confirmation */}
                        <Modal.Title>{"共通属性設定 > 削除確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            共通属性情報を削除します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.deleteKintoneCommon}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseDeleteKintoneCommonProductConfirmation}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        deleteKintoneCommon = () => {
            Promise.all([
                this.showLoader(),
                this.props.deleteKintoneProductCommonField(this.state.kintoneDeleteId).then(
                    () => {
                        if (this.state.submitCommonSubKintone) {
                            this.handleShowCommonSubTableFieldsModal(this.state.parentId, this.state.parentFieldLabel)
                        } else {
                            this.loadListFromServer();
                        }
                        this.handleShowSuccessAlert(this.props.kintoneCommonMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseDeleteKintoneCommonProductConfirmation)
        }

        handleShowDeleteKintoneCommonProductConfirmation = (kintone_id) => {
            this.setState({
                showDeleteKintoneCommonProductConfirmation: true,
                kintoneDeleteId: kintone_id
            })
        }

        handleCloseDeleteKintoneCommonProductConfirmation = () => {
            this.setState({
                showDeleteKintoneCommonProductConfirmation: false
            })
        }
    }
}

export {
    withKintoneCommonDeleteConfirmationModal
}