import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';
import fileDownload from 'js-file-download';

export const getApprovalList = (
    itemsCountPerPage, activePage, query,
    product_number, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                return apiService.get(Path.APPROVAL, {
                    params: {
                        num: itemsCountPerPage,
                        page: activePage,
                        search: query,
                        product: product_number,
                        ordering: sort
                    }
                }).then((response) => {
                    dispatch(Actions.readState(response.data));
                })
            })
            .catch(apiService.handleDisplayError);
    }

export const getApprovalHistoryList = (
    itemsCountPerPage, activePage, query,
    product_number, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                return apiService.get(Path.APPROVAL_HISTORY, {
                    params: {
                        num: itemsCountPerPage,
                        page: activePage,
                        search: query,
                        product: product_number,
                        ordering: sort
                    }
                }).then((response) => {
                    dispatch(Actions.approvalHistoryState(response.data));
                })
            })
            .catch(apiService.handleDisplayError);
    }

export const getApprovalProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'GET',
                url: Path.PRODUCTLOOKUP,
                responseType: 'json',
                params: {
                    access_type: '2'
                }
            })
                .then((response) => {
                    dispatch(Actions.productLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getApprovalCategoryList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CATEGORYLOOKUP).then((response) => {
                dispatch(Actions.categoryLookupListState(response.data));
                dispatch(Actions.categoryLookupListStateForEditNotice(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeApprovalDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_APPROVAL_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getNoticeApprovalDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getApprovalHistoryNoticeDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_APPROVAL_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getApprovalHistoryNoticeDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeApprovalHistoryDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_APPROVAL_HISTORY_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getNoticeApprovalHistoryDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchApprovalDetails = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.PATCH_APPROVAL + id + '/', data).then((response) => {
                dispatch(Actions.patchApprovalState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentApprovalDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_APPROVAL_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getDocumentApprovalDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getApprovalHistoryDocumentDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_APPROVAL_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getApprovalHistoryDocumentDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentApprovalHistoryDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_APPROVAL_HISTORY_DETAILS + id + "/").then((response) => {
                dispatch(Actions.getDocumentApprovalHistoryDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentFile = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            let id = data.id;
            return apiService.get(Path.DOCUMENT_DOWNLOAD + id + '/', {
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                fileDownload(response.data, data.file_name, response.headers['content-type']);
                // if (response.status === 200) {
                //     dispatch(Actions.getDocumentFileState(["File downloaded successfully"]));
                // }
                // else {
                //     return "File Download failed"
                // }
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentEditDetails = (id, isShowEdit) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENT_UPDATE + id + '/', {
                params: {
                    isShowEdit: isShowEdit
                }
            }).then((response) => {
                    dispatch(Actions.getDocumentEditDetailsState(response.data));
                })
            })
        .catch(apiService.handleDisplayError);
}

export const getNoticeEditDetails = (id, isShowEdit) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_UPDATE + id + '/',{
                params: {
                    isShowEdit: isShowEdit
                }
            }).then((response) => {
                dispatch(Actions.getNoticeEditDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}
