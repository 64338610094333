import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate'

const withNewKintoneCommonModal = (WrappedComponent) => {
    return class NewKintoneCommonModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalNewKintoneCommon: false
            }
        }

        newKintoneCommonModal = (props) => {
            var areAllDefault = props.currentKintoneFormValues ? props.currentKintoneFormValues.kintone_attribute_name === "" &&
                props.currentKintoneFormValues.display_label === "" && props.currentKintoneFormValues.show_customer === false &&
                props.currentKintoneFormValues.format_data === "0" ? true : false : true

            return (
                <Modal keyboard={false} size="lg" show={this.state.showModalNewKintoneCommon} onHide={areAllDefault ? this.handleCloseModalNewKintoneCommon : this.handleShowDiscardModal} className="modal-new-kintone" centered>
                    <Modal.Header closeButton>
                        {/* Common attribute settings> Add */}
                        <Modal.Title>{"共通属性設定 > 追加"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.kintoneFormInputs}
                            radioInput={props.radioInput}
                            validate={props.adminProductManagementSelectors.kintoneCheckErrors}
                            form={"admin-new-kintone-common"}
                            handleShowNewKintoneCommonConfirmModal={this.handleShowNewKintoneCommonConfirmModal}
                            handleSubmitModalNewKintoneCommon={this.handleSubmitModalNewKintoneCommon}
                            addKintoneCommonMode={true}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-new-product" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowNewKintoneCommonConfirmModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseModalNewKintoneCommon : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowModalNewKintoneCommon = () => {
            this.setState({
                showModalNewKintoneCommon: true
            })
        }

        handleCloseModalNewKintoneCommon = () => {
            this.setState({
                showModalNewKintoneCommon: false
            })
        }

        handleShowNewKintoneCommonConfirmModal = () => {
            this.setState({
                showNewKintoneCommonConfirmModal: true
            })
        }

        handleSubmitModalNewKintoneCommon = (values) => {
            let formattedValue = {
                field_name: values.kintone_attribute_name,
                field_label: values.display_label,
                is_table: values.format_data,
                show_customer: values.show_customer
            }

            if (this.state.submitCommonSubKintone) {
                formattedValue.parent_id = this.state.parentId;
            }

            this.setState({
                kintoneData: {
                    payload: formattedValue
                }
            });
        }
    }
}

export {
    withNewKintoneCommonModal
}