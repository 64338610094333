import React from 'react';
import { Modal } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';

const withCustomerInformationDetailsModal = (WrappedComponent) => {
    return class CustomerInformationDetailsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCustomerInfoDetails: false
            }
        }

        customerInformationDetailsModal = (props) => {
            let { customerInfoRows } = this.state;
            let hasCommonfields = customerInfoRows.common_fields && customerInfoRows.common_fields.fields.length > 0;
            let hasSpecificFields = customerInfoRows.specific_fields && customerInfoRows.specific_fields.fields.length > 0;
            let contractId = customerInfoRows.id;

            return (
                <Modal size="lg" className="customer-info-details" keyboard={false} show={this.state.showCustomerInfoDetails} onHide={this.handleCloseCustomerInformationDetails} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>顧客情報 ＞ 案件情報確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="mt-2">
                            {
                                hasCommonfields ?
                                    <>
                                        <h6 className="font-weight-bold">サービス共通情報</h6>
                                        <MDBTable bordered className="mb-3 w-100">
                                            <MDBTableBody>
                                                {
                                                    customerInfoRows.common_fields ? customerInfoRows.common_fields.fields.map((field, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="align-middle disable-table font-weight-bold">{field.field_label}</td>
                                                                <td key={i}>
                                                                    {field.is_table ?
                                                                        <a href="# " onClick={() => {
                                                                            field.is_table_list ? this.handleShowSubListTableDataModal(contractId, field.field_value, field.field_label, field.field_id, "common_admin")
                                                                            : this.handleShowSubTableModal(contractId, field.field_id, field.field_label, "common_admin")
                                                                        }}>詳細</a>
                                                                    : field.field_unique_value ? field.field_unique_value : ""}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                        ""
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </>
                                    :
                                    <>
                                        <h6 className="font-weight-bold">サービス共通情報</h6>
                                        <div className="">情報はありません。</div>
                                    </>
                            }

                            {
                                hasSpecificFields ?
                                    <>
                                        <h6 className="font-weight-bold mt-5">サービス個別情報 ({customerInfoRows.specific_fields.product_name})</h6>

                                        <MDBTable bordered className="w-100">
                                            <MDBTableBody>
                                                {
                                                    customerInfoRows.specific_fields ? customerInfoRows.specific_fields.fields.map((field, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="align-middle disable-table font-weight-bold">{field.field_label}</td>
                                                                <td>
                                                                    {field.is_table ?
                                                                        <a href="# " onClick={() => {
                                                                            field.is_table_list ? this.handleShowSubListTableDataModal(contractId, field.field_value, field.field_label, field.field_id, "product_admin")
                                                                            :this.handleShowSubTableModal(contractId, field.field_id, field.field_label, "product_admin")
                                                                        }}>詳細</a>
                                                                    : field.field_unique_value ? field.field_unique_value : ""}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                        ""
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </>
                                    :
                                    <>
                                        <h6 className="font-weight-bold mt-5">サービス個別情報</h6>
                                        <div className="">情報はありません。</div>
                                    </>
                            }

                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <button className="btn btn-90 btn-secondary" onClick={this.handleCloseCustomerInformationDetails}>
                            閉じる
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowCustomerInformationDetails = (id) => {
            Promise.all([
                this.showLoader(),
                this.props.getCustomerInformationDetails(id).then(
                    () => {
                        this.setState({ showCustomerInfoDetails: true, customerInfoRows: this.props.customerInfoDetails })
                    }
                ),
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }
        
        handleCloseCustomerInformationDetails = () => {
            this.setState({ showCustomerInfoDetails: false })
        }
    }
}

export {
    withCustomerInformationDetailsModal
}