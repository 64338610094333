import reducer from "./reducers";
import { routes } from "../routes";
import * as controlPanelOperations from "./operations";
import * as controlPanelSelectors from "./selectors"

export {
    controlPanelOperations,
    controlPanelSelectors
};

const index = {
    routes,
    reducer
}

export default index;