import React from 'react';
import * as Commons from '../Commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faWindowClose, faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AdminDocumentTemplate = (props) => {
    let {
        rows,
        handleShowDraftModal,
        handleShowModalDetail,
        handleShowStopPublishModal,
        handleShowCancelModal,
        handleShowModalPublishedVersionDetail
    } = props;

    return (
        <section className="list mt-3 mb-3">
            {props.totalItemsCount > 0 ?
                rows.map((docs) => {
                    var createdDate = Commons.getClientDatetimeDisplay(docs.created_date);
                    var editDate = Commons.getClientDatetimeDisplay(docs.edit_date);
                    var statusBg = Commons.getStatusBackground(docs.info.status_main);
                    var customStyle = { color: statusBg, border: "solid 1px" };
                    var customButtonStyle = { marginRight: '8px' };
                    if (docs.info.access_edit && docs.info.access_unpublish) {
                        customButtonStyle.marginLeft = '8px';
                    }
                    return (
                        <div className="px-3 py-3 list-group-item table-layout" key={docs.post_id}>
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute">
                                        <span className="custom-badge mb-1 mr-2 text-center" style={{ backgroundColor: docs.product_bg_color }}>
                                            {docs.product_name}
                                        </span>
                                        {docs.info.status_main ?
                                            <span className="rounded text-center px-2 mb-1 d-inline-block" style={customStyle}>
                                                {docs.info.status_main}
                                            </span>
                                            : null}
                                        <ul className="list-item-actions nav float-right">
                                            {
                                                docs.info.published ?
                                                    <a href="# " className={"nav-link py-0 px-0 btn-action"} onClick={() => { handleShowModalPublishedVersionDetail(docs.published_version) }}>
                                                        <FontAwesomeIcon icon={faGlobeAmericas} size="lg" /><span className="pl-1">公開中</span>
                                                    </a>
                                                    : <a href="# " className={"nav-link py-0 px-0 btn-action disabled"}>
                                                        <FontAwesomeIcon icon={faEyeSlash} size="lg" /><span className="pl-1">非公開</span>
                                                    </a>
                                            }
                                        </ul>
                                    </div>
                                    <div className="text-break">
                                        <a href="# " style={{ fontSize: '15px' }} className="font-weight-bold" value={docs.id} onClick={() => { handleShowModalDetail(docs.post_detail_id, docs.post_id) }}>
                                            {docs.title}
                                        </a>
                                    </div>
                                    <div className="list-item-attribute pt-1 row">
                                        <div className="col-md-3 col-sm-12 pr-0">
                                            <strong>作成日: </strong>
                                            {createdDate}
                                        </div>
                                        <div className="col-md-3 col-sm-12 p-0">
                                            <strong className="pl-3">更新日: </strong>
                                            {editDate}
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row">
                                        <div className="col-md-3 col-sm-12 pr-0">
                                            <strong>作成者: </strong>
                                            {docs.created_by}
                                        </div>
                                        <div className="col-md-3 col-sm-12 p-0">
                                            <strong className="pl-3">更新者: </strong>
                                            {docs.edited_by}
                                        </div>
                                    </div>
                                    <ul className="list-item-actions nav justify-content-end">
                                        {docs.info.access_edit ?
                                            <li className="nav-item">
                                                <a className={"nav-link py-0 px-3 btn-action"} href="# " onClick={(event) => { handleShowDraftModal(docs.post_id, event) }}>
                                                    <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1">修正</span>
                                                </a>
                                            </li> : ""}
                                        {docs.info.access_unpublish ?
                                            <li className="nav-item">
                                                <a className={`nav-link py-0 pl-3 pr-0 btn-action`} href="# " onClick={(event) => { handleShowStopPublishModal(docs.post_id, event) }} >
                                                    <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">公開中止</span>
                                                </a>
                                            </li> : ""}
                                        {docs.info.access_request_cancel ?
                                            <li className="nav-item">
                                                <a className={`nav-link py-0 pl-3 pr-0 btn-action`} href="# " onClick={(event) => { handleShowCancelModal(docs.post_id, event) }} >
                                                    <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">申請取り消し</span>
                                                </a>
                                            </li> : ""}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })
                : <h4>検索の結果、0件でした。</h4>
            }

        </section>
    );
}

export default AdminDocumentTemplate;