import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';

export const getMailTemplateList = (itemsCountPerPage, activePage, query, sort) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.MAIL_TEMPLATE_LIST, {
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    ordering: sort
                }
            }).then((response) => {
                dispatch(Actions.getMailTemplateListState(response.data))
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getMailTemplate = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.MAIL_TEMPLATE_EDIT + id + '/')
                .then((response) => {
                    dispatch(Actions.getMailTemplateEditState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const putMailTemplate = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.put(Path.MAIL_TEMPLATE_EDIT + id + '/', data)
                .then((response) => {
                    dispatch(Actions.putMailTemplateEditState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getMailSettingsList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(()=>{
            return apiService.get(Path.MAIL_SETTINGS_LIST).then((response) => {
                dispatch(Actions.getMailSettingsListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchMailSettings = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.MAIL_SETTINGS_EDIT, data)
                .then((response) => {
                    dispatch(Actions.patchMailSettingsEditState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const testMailServer = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(()=>{
            return apiService.post(Path.MAIL_SETTINGS_TEST_SERVER, data).then((response) => {
                dispatch(Actions.testMailServerState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getMailPendingList = (
    itemsCountPerPage, activePage, filter_value, sort) => (dispatch) => {
        return apiService.refreshToken()
        .then(()=>{
            return apiService.get(Path.MAIL_PENDING_LIST, {
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    priority: filter_value,
                    ordering: sort
                }
            }).then((response) => {
                dispatch(Actions.getMailPendingListState(response.data));
            })
    })
    .catch(apiService.handleDisplayError);
}

export const getMailDeliveryResultList = (
    itemsCountPerPage, activePage, filter_value, sort) => (dispatch) => {
        return apiService.refreshToken()
        .then(()=>{
            return apiService.get(Path.MAIL_DELIVERY_RESULT_LIST, {
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    result: filter_value,
                    ordering: sort
                }
            }).then((response) => {
                dispatch(Actions.getMailDeliveryResultListState(response.data));
            })
    })
    .catch(apiService.handleDisplayError);
}