import reducer from "./reducers";
import { routes } from "../routes";
import * as adminAccessLoggingOperations from "./operations";
import * as adminAccessLoggingSelectors from "./selectors"

export {
    adminAccessLoggingOperations,
    adminAccessLoggingSelectors
};

const index = {
    routes,
    reducer
}

export default index;