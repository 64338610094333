import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';

export const getNoticeList = (itemsCountPerPage, activePage, query, category_number, product_number) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE, {
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    post_category: category_number,
                    product: product_number
                }
            }).then((response) => {
                dispatch(Actions.readState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.PRODUCTLOOKUP).then((response) => {
                dispatch(Actions.productLookupListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE + id + '/').then((response) => {
                dispatch(Actions.noticeDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeCategoryList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CATEGORYLOOKUP).then((response) => {
                dispatch(Actions.categoryLookupListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}