import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    documentForm: {
        model: "DocumentForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.FORM_PRODUCT_MESSAGE,
                name: "product",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_TITLE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                name: "title",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_CONTENT,
                message: CONSTANTS.FORM_CONTENT_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                name: "content",
                default: '',
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILE_CHECKBOX,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                message: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.DOCUMENT_FORM_EXTERNAL_LINK_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_EXTERNAL_LINK,
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                message: CONSTANTS.DOCUMENT_FORM_EXTERNAL_LINK_MESSAGE,
                name: "external_link",
                default: '',
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                message: CONSTANTS.FORM_PUBLISH_DATE_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                placeholder: "",
                message: "",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "remarks",
                default: '',
                required: false,
                onSetEmphasize: true,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    radioOptions: [
        { name: "publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING},
        { name: "publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING},
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING},
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING}
    ],
    documentDetailPostForm: {
        model: "DocumentDetailPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILES,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    documentHistoryPostForm: {
        model: "DocumentHistoryPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILES,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "history_publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    documentOrdering: [
        {
            id: 1,
            label: "サービス名(昇順)",
            value: "product__name,-updated_date"
        },
        {
            id: 2,
            label: "サービス名(降順)",
            value: "-product__name,-updated_date"
        },
        {
            id: 3,
            label: "日時（昇順）",
            value: "updated_date"
        },
        {
            id: 4,
            label: "日時（降順）",
            value: "-updated_date"
        },
        {
            id: 5,
            label: "ステータス（昇順）",
            value: "post_status,-updated_date"
        },
        {
            id: 6,
            label: "ステータス（降順）",
            value: "-post_status,-updated_date"
        }
    ],
    defaultDocumentOrdering: "-updated_date",
    tableList: [],
    totalItemsCount: CONSTANTS.ZERO,
    productLookupList: [],
    documentDetails: ({}),
    documentEditDetails: ({}),
    documentMessages: [],
    documentHistoryDetails: ({})
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, tableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.DOCUMENT_DETAILS_STATE:
            return { ...state, documentDetails: action.payload };
        case types.DOCUMENT_PUBLISHED_VERSION_DETAILS_STATE:
            return { ...state, documentPublishedVersionDetails: action.payload };
        case types.PRODUCT_LOOKUP_STATE:
            return {
                ...state,
                productLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product", label: item.name }
                })
            };
        case types.DOCUMENT_CREATE_STATE:
            return { ...state, documentCreateDetails: action.payload };
        case types.DOCUMENT_EDIT_RETRIEVE_STATE:
            return { ...state, documentEditDetails: action.payload };
        case types.DOCUMENT_EDIT_UPDATE_STATE:
            return { ...state, documentMessages: action.payload };
        case types.DOCUMENT_UNPUBLISH_STATE:
            return { ...state, documentMessages: action.payload }
        case types.DOCUMENT_DELETE_STATE:
            return { ...state, documentMessages: action.payload }
        case types.DOCUMENT_CANCEL_STATE:
            return { ...state, documentMessages: action.payload }
        case types.DOCUMENT_RETRIEVE_HISTORY_STATE:
            return { ...state, documentHistoryDetails: action.payload };
        case types.DOCUMENT_DOWNLOAD_STATE:
            return { ...state, documentMessages: action.payload }
        default: return state;
    }
};