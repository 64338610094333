import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { rootRoutes } from '../../app.modules';
import Logo from '../../includes/img/SolitonCloudServicePortal_logo_C.svg'
import { LOGIN_ROUTE, LOGIN_LABEL } from '../containers/Login/routes';

const AdminMainHeader = (props) => {
    var productManagementScreen = props.path === "/admin/product" || props.path === "/admin/systemsettings" ? true : false;
    var accessLogScreen = props.path === "/admin/adminaccesslog" || props.path === "/admin/customeraccesslog" ? true : false;
    var approvalScreen = props.path === "/admin/approvalpending" || props.path === "/admin/approvalhistory" ? true : false;
    var accountManagementScreen = props.path === "/admin/portalusers" || props.path === "/admin/portaladmin" ? true : false;
    var noticeManagementScreen = props.path === "/admin/noticepost" || props.path === "admin/noticetemplate" ? true : false;
    var emailManagementScreen = props.path === "/admin/mailtemplates" || props.path === "/admin/mailsettings" || props.path === "/admin/mailpending" || props.path === "/admin/maildeliveryresult" ? true : false;
    var adminLink = null;
    if (props.isSuperuser || props.isPoster) {
        adminLink = "/admin/noticepost";
    }
    else if (props.isApprover) {
        adminLink = "/admin/approvalpending";
    }
    else if (props.isPortalAdmin) {
        adminLink = "/admin/portalusers";
    }
    else {
        adminLink = "/admin";
    }

    return (
        <>
            <header className="desktop-header py-3">
                <Nav.Link as={Link} to={adminLink} className="py-0 px-0 soliton_logo">
                    <img src={Logo}
                        className="header-logo"
                        alt="Soliton サービスポータル" />
                </Nav.Link>
            </header>
            <nav id="menu" className="desktop-header navbar navbar-expand-sm navbar-light bg-menu py-0">
                <div className="w-100 d-inline-flex admin-nav-bar">
                    <ul className="navbar-nav mr-auto">
                        {
                            rootRoutes.map((route, i) => {
                                let link = undefined;

                                if (route.path && route.isAdminPage && route.showAdminNav && props.isAuthenticated) {
                                    if ((route.isAdminProductManagement && props.isPortalAdmin) || (route.isAdminProductManagement && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={productManagementScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="product-management-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="product-management-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/product"}>製品情報管理</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/systemsettings"}>共通属性設定</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else if ((route.isAdminAccountManagement && props.isPortalAdmin) || (route.isAdminAccountManagement && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={accountManagementScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="account-management-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="account-management-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/portalusers"}>顧客情報一覧</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/portaladmin"}>管理者一覧</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else if ((route.isAdminEmailManagement && props.isPortalAdmin) || (route.isAdminEmailManagement && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={emailManagementScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="email-management-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="email-management-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/mailtemplates"}>メールテンプレート</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/mailsettings"}>メールサーバ設定</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/mailpending"}>メール送信予定一覧</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/maildeliveryresult"}>メール送信結果一覧</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else if ((route.isAdminAccessLogging && props.isPortalAdmin) || (route.isAdminAccessLogging && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={accessLogScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="accesslog-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="accesslog-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/adminaccesslog"}>管理者ログ</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/customeraccesslog"}>顧客アクセスログ</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else if ((route.isPosterNotice && props.isPoster) || (route.isPosterNotice && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={noticeManagementScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="notice-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="notice-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/noticepost"}>お知らせ管理</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/noticetemplate"}>テンプレート管理</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else if ((route.isPoster && props.isPoster) || (route.isPoster && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item" key={i} >
                                            <NavLink className="nav-link" href={route.path} to={route.path}>{route.label}</NavLink>
                                        </li>;
                                    }
                                    else if ((route.isApprover && props.isApprover) || (route.isApprover && props.isSuperuser)) {
                                        link = 
                                        <li className="nav-item dropdown" key={i}>
                                            <a className={approvalScreen ? "nav-link dropdown-toggle dropdown active" : "nav-link dropdown-toggle dropdown"} href="# " id="approval-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {route.label}
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="approval-dropdown">
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/approvalpending"}>承認待ち</NavLink>
                                                <NavLink className="nav-link p-2 px-3 dropdown-item" to={"/admin/approvalhistory"}>承認履歴</NavLink>
                                            </div>
                                        </li>;
                                    }
                                    else {
                                        link = "";
                                    }
                                }

                                return link;
                            })
                        }
                    </ul>
                    <ul className="navbar-nav">
                        {
                            props.isAuthenticated ?
                                <li className="nav-item dropdown">
                                    <a className={"nav-link dropdown-toggle dropdown"} href="# " id="auth-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {props.username}&nbsp;
                                    </a>
                                    <div className="dropdown-menu auth-dropdown" aria-labelledby="auth-dropdown">
                                        <NavLink exact className="nav-link dropdown-item p-2 px-3" to={"/"}>顧客画面に切り替え</NavLink>
                                        <a className="nav-link dropdown-item p-2 px-3" href="# " onClick={props.handleShowAuthPasswordChangeModal}>パスワード変更</a>
                                        <a className="nav-link dropdown-item p-2 px-3" href="# " onClick={props.handleShowLogoutModal}>ログアウト</a>
                                    </div>
                                </li>
                                :
                                <li className="nav-item">
                                    <Nav.Link to={LOGIN_ROUTE}>{LOGIN_LABEL}</Nav.Link>
                                </li>
                        }
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default AdminMainHeader;