import reducer from "./reducers";
import { routes } from "../routes";
import * as adminHomeOperations from "./operations";
import * as adminHomeSelectors from "./selectors"

export {
    adminHomeOperations,
    adminHomeSelectors
};

const index = {
    routes,
    reducer
}

export default index;