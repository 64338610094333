import NoticeScreen from "./NoticeScreen";

export const NOTICE_ROUTE = '/';

export const routes = [
    {
        label: "お知らせ", //define label to be displayed
        path: NOTICE_ROUTE,
        component: NoticeScreen,
        exact: true,
        showNavPrivate: true, //if true, displays the label in the mainHeader.js component if authenticated
        showNavPublic: true, //if true, displays the label in the mainHeader.js component if not authenticated
        public: true
    },
]