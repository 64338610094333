import reducer from "./reducers";
import { routes } from "../routes";
import * as customerAccessLogOperations from "./operations";
import * as customerAccessLogSelectors from "./selectors"

export {
    customerAccessLogOperations,
    customerAccessLogSelectors
};

const index = {
    routes,
    reducer
}

export default index;