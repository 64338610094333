import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate'

const withNewKintoneModal = (WrappedComponent) => {
    return class NewKintoneModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalNewKintone: false
            }
        }

        newKintoneModal = (props) => {
            var areAllDefault = props.currentKintoneFormValues ? props.currentKintoneFormValues.kintone_attribute_name === "" &&
                props.currentKintoneFormValues.display_label === "" && props.currentKintoneFormValues.show_customer === "0" &&
                props.currentKintoneFormValues.format_data === "0" ? true : false : true

            let {submitSubKintone, parentShowCustomer, radioInput } = props;
            
            if (submitSubKintone) {
                switch(parentShowCustomer) {
                    case 0:
                        radioInput.forEach(item => {
                            if (item.name === 'show_customer' && (item.value === "1" || item.value === "2")) {
                                item.disabled = true;
                            }
                        });
                        break;
                    case 1:
                        radioInput.forEach(item => {
                            if (item.name === 'show_customer' && item.value === "2") {
                                item.disabled = true;
                            }
                        });
                        break;
                    case 2:
                        radioInput.forEach(item => {
                            if (item.name === 'show_customer' && item.value === "1") {
                                item.disabled = true;
                            }
                        });
                        break;
                    default:
                        break;
                }
            } else {
                radioInput.forEach(item => {
                    if (item.name === 'show_customer') {
                        item.disabled = false;
                    }
                });
            }

            return (
                <Modal keyboard={false} size="lg" show={this.state.showModalNewKintone} onHide={areAllDefault ? this.handleCloseModalNewKintone : this.handleShowDiscardModal} className="modal-new-kintone" centered>
                    <Modal.Header closeButton>
                        {/* Attribute setting> Add */}
                        <Modal.Title>{"属性設定 > 追加"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.kintoneFormInputs}
                            radioInput={radioInput}
                            validate={props.adminProductManagementSelectors.kintoneCheckErrors}
                            form={"admin-new-kintone"}
                            handleShowNewKintoneConfirmModal={this.handleShowNewKintoneConfirmModal}
                            handleSubmitModalNewKintone={this.handleSubmitModalNewKintone}
                            addKintoneMode={true}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-new-product" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowNewKintoneConfirmModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseModalNewKintone : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleCloseModalNewKintone = () => {
            this.setState({
                showModalNewKintone: false
            })
        }

        handleShowNewKintoneConfirmModal = () => {
            this.setState({
                showNewKintoneConfirmModal: true
            })
        }

        handleSubmitModalNewKintone = (values) => {
            let formattedValue = {
                product_id: this.state.kintoneProductId,
                field_name: values.kintone_attribute_name,
                field_label: values.display_label,
                is_table: values.format_data,
                show_customer: values.show_customer
            }

            if (this.state.submitSubKintone) {
                formattedValue.parent_id = this.state.parentId;
            }

            this.setState({
                kintoneData: {
                    payload: formattedValue
                }
            });
        }
    }
}

export {
    withNewKintoneModal
}