import AdminPasswordUpdateScreen from './AdminPasswordUpdateScreen';
import AdminPasswordRequestUpdate from './AdminPasswordRequestUpdate';

export const ADMIN_PASSWORD_UPDATE_ROUTE = "/admin/password/update";

export const ADMIN_PASSWORD_REQUEST_UPDATE_ROUTE = "/admin/password/request/update";

export const ADMIN_PASSWORD_AUTH_UPDATE_ROUTE = "/admin/password/auth/update";

export const ADMIN_PASSWORD_UPDATE_LABEL = "パスワードの更新";

export const routes = [
    {
        label: ADMIN_PASSWORD_UPDATE_LABEL,
        path: ADMIN_PASSWORD_REQUEST_UPDATE_ROUTE,
        component: AdminPasswordRequestUpdate,
        exact: true,
        public: true,
    },
    {
        label: ADMIN_PASSWORD_UPDATE_LABEL,
        path: ADMIN_PASSWORD_UPDATE_ROUTE,
        component: AdminPasswordUpdateScreen,
        exact: true,
        public: true,
    },
    {
        label: ADMIN_PASSWORD_UPDATE_LABEL,
        path: ADMIN_PASSWORD_AUTH_UPDATE_ROUTE,
        component: AdminPasswordUpdateScreen,
        exact: true,
        isAdminPage: true,
    }
];