import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate';
import * as Commons from '../Commons';

const withAdminDocumentApprovalModal = (WrappedComponent) => {
    return class AdminDocumentApprovalModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentApprovalModal: false,
                showEditDocumentModalFromApprovalModal: false,
            }
        }

        adminDocumentApprovalModal = (props) => {
            let template = "";
            if (props.documentApprovalDetails && props.initialDocumentFormValue) {
                let applicationType = props.documentApprovalDetails.application_type ? props.documentApprovalDetails.application_type : "";
                let hasHistoryDetails = props.documentApprovalHistoryDetails.length > 0;

                template = (
                    <Modal show={this.state.showDocumentApprovalModal} keyboard={false} size="lg" onHide={this.handleCloseDocumentApprovalModal} className="modal-edit-post" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>承認管理 ＞ 承認（ドキュメント{applicationType}）</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <label>
                                <strong>申請番号: </strong>
                                {props.documentApprovalDetails.application_number}
                            </label>
                            <AdminFormTemplate
                                formInputs={props.approvalDocumentForm}
                                radioInput={props.radioInput}
                                initialValues={props.initialDocumentFormValue}
                                publishDate={props.documentApprovalDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(props.documentApprovalDetails.publish_preferred_date)}
                                confirmApprovalMode={true}
                                form={"admin-approval-document"}
                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                handleShowApprovalConfirmModal={this.handleShowApprovalConfirmModal}
                                handleShowApprovalRejectModal={this.handleShowApprovalRejectModal}
                                handleSubmitApprovalModal={this.handleSubmitApprovalModal}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="pt-0 border-top-0">
                            <div className="w-100 approval-modal-footer">
                                <div className={`w-100 d-inline-flex ${hasHistoryDetails ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                    <input type="hidden" name="submit_action" />
                                    <button type="button" form="admin-approval-document" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                        this.handleShowApprovalEditDocumentModal(props.initialDocumentFormValue.postId)
                                    }}>編集</button>
                                    <button type="button" form="admin-approval-document" className="btn btn-90 mr-2 btn-success" onClick={() => {
                                        this.handleSubmitApprovalModal(props.initialDocumentFormValue.ApprovalDocumentForm.post_id, props.initialDocumentFormValue.ApprovalDocumentForm)
                                        this.handleShowApprovalConfirmModal()
                                    }} disabled={props.documentApprovalDetails.application_status === "承認待ち" ? false : true}>承認</button>
                                    <button type="button" form="admin-approval-document" className="btn btn-90 mr-2 btn-outline-danger" onClick={() => {
                                        this.handleShowApprovalRejectModal(props.initialDocumentFormValue.ApprovalDocumentForm.post_id)
                                    }} disabled={props.documentApprovalDetails.application_status === "承認待ち" ? false : true}>却下</button>
                                    <button className="btn btn-90 btn-secondary" onClick={this.handleCloseDocumentApprovalModal}>キャンセル</button>
                                </div>
                                {
                                    hasHistoryDetails ?
                                        <>
                                            <h5>修正履歴</h5>
                                            {
                                                props.documentApprovalHistoryDetails.map((document, idx) => {
                                                    var initialDocumentFormHistoryValue = {
                                                        ApprovalHistoryDocumentForm: {
                                                            post_id: document.id,
                                                            edit_date: document.updated_date ? Commons.getClientDatetimeDisplay(document.updated_date) : "",
                                                            product: document.product_name,
                                                            title: document.title,
                                                            content: document.content,
                                                            files: document.files,
                                                            applicant: document.applied_by ? document.applied_by : "",
                                                            history_publish_date: document.publish_preferred_date == null ? "0" : "1",
                                                            publish_preferred_date: Commons.getClientDatetimeDisplay(document.publish_preferred_date),
                                                            history_publish_type: document.publish_type,
                                                            remarks: document.remarks ? document.remarks : ""
                                                        }
                                                    };

                                                    return (
                                                        <div className="bg-light" key={document.id + "-" + idx}>
                                                            <AdminFormTemplate
                                                                formInputs={props.approvalHistoryDocumentForm}
                                                                radioInput={props.radioInput}
                                                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                                                initialValues={initialDocumentFormHistoryValue}
                                                                publishDate={document.publish_preferred_date === null ? "0" : "1"}
                                                                publishPreferredDate={Commons.getClientDatetimeDisplay(document.publish_preferred_date)}
                                                                form={"admin-history-form" + document.id}
                                                                handleFormOnchange={this.handleFormOnchange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </Modal.Footer>
                    </Modal>
                );
            }
            return template;
        }

        handleShowDocumentApprovalModal = (application_id, post_id) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentApprovalDetails(application_id).then(
                    () => {
                        this.setState({
                            initialDocumentFormValue: {
                                ApprovalDocumentForm: {
                                    post_id: this.props.documentApprovalDetails.application_id,
                                    product: this.props.documentApprovalDetails.product_name,
                                    title: this.props.documentApprovalDetails.title,
                                    content: this.props.documentApprovalDetails.content,
                                    files: this.props.documentApprovalDetails.post_files,
                                    applicant: this.props.documentApprovalDetails.created_by,
                                    publish_date: this.props.documentApprovalDetails.publish_preferred_date == null ? 0 : 1,
                                    publish_preferred_date: this.props.documentApprovalDetails.publish_preferred_date,
                                    publish_type: this.props.documentApprovalDetails.publish_type
                                },
                                postId: post_id
                            }
                        })
                        this.props.getDocumentApprovalHistoryDetails(post_id).then(
                            () => {
                                this.setState({
                                    showDocumentApprovalModal: true,
                                    showEditDocumentModalFromApprovalModal: true,
                                })
                            }
                        )
                    }
                ),
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseDocumentApprovalModal = () => {
            this.setState({
                showDocumentApprovalModal: false,
                showEditDocumentModalFromApprovalModal: false
            })
        }

        handleDocumentFileDownload = (data) => {
            this.props.getDocumentFile(data)
            .catch(this.handleShowErrorAlert)
        }
    }
}

export {
    withAdminDocumentApprovalModal
}