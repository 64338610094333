import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    portalProductForm: {
        model: "PortalProductForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "id"
            },
            {
                type: "text",
                label: CONSTANTS.PRODUCT_FORM_PRODUCT_LONG_NAME_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_LONG_NAME,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_LONG_NAME,
                name: "product_long_name",
                minLength: 1,
                maxLength: 200,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_PRODUCT_NAME_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME_MESSAGE,
                name: "product_name",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_COLOR,
                label: CONSTANTS.PRODUCT_FORM_BG_COLOR_LABEL,
                placeholder: "",
                message: CONSTANTS.PRODUCT_FORM_BG_COLOR_MESSAGE,
                name: "bg_color",
                default: '#000000',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_IMAGE,
                label: CONSTANTS.PRODUCT_FORM_SERVICE_ICON_LABEL,
                message: CONSTANTS.PRODUCT_FORM_SERVICE_ICON_MESSAGE,
                name: "service_icon",
                default: ''
            },
            {
                type: CONSTANTS.FORM_TYPE_DATE,
                label: CONSTANTS.PRODUCT_FORM_START_DATE_LABEL,
                message: CONSTANTS.PRODUCT_FORM_START_DATE_MESSAGE,
                name: "start_date",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_DATE,
                label: CONSTANTS.PRODUCT_FORM_END_DATE_LABEL,
                message: CONSTANTS.PRODUCT_FORM_END_DATE_MESSAGE,
                name: "end_date",
                default: ''
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_POST_ADGROUP_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_POST_ADGROUP,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_POST_ADGROUP,
                name: "post_ad_group",
                minLength: CONSTANTS.ONE,
                maxLength: 45,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_APPROVED_ADGROUP_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_APPROVED_ADGROUP,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_APPROVED_ADGROUP,
                name: "approved_ad_group",
                minLength: CONSTANTS.ONE,
                maxLength: 45,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_KINTONE_API_LINK_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_LINK,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_LINK,
                name: "kintone_api_link",
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_KINTONE_API_ID_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_ID,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_ID,
                name: "kintone_api_id",
                minLength: CONSTANTS.ONE,
                maxLength: 5,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_KINTONE_API_TOKEN_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_TOKEN,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_API_TOKEN,
                name: "kintone_api_token",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_KINTONE_KEY_FIELD_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_FIELD,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_FIELD,
                name: "kintone_key_field",
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_KINTONE_KEY_VALUE_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_VALUE,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_VALUE,
                name: "kintone_key_value",
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_FAQ_LINK_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_FAQ_LINK,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_FAQ_LINK,
                name: "faq_link",
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                default: '',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_INQUIRY_LINK_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_INQUIRY_LINK,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_INQUIRY_LINK,
                name: "inquiry_link",
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                default: '',
                required: true,
            },
        ]
    },
    servicePortalProductForm: {
        model: "PortalProductForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "id"
            },
            {
                type: "text",
                label: CONSTANTS.PRODUCT_FORM_PRODUCT_LONG_NAME_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_LONG_NAME,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_LONG_NAME,
                name: "product_long_name",
                minLength: 1,
                maxLength: 200,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_PRODUCT_NAME_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME_MESSAGE,
                name: "product_name",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_COLOR,
                label: CONSTANTS.PRODUCT_FORM_BG_COLOR_LABEL,
                placeholder: "",
                message: CONSTANTS.PRODUCT_FORM_BG_COLOR_MESSAGE,
                name: "bg_color",
                default: '#000000',
                required: true,
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_POST_ADGROUP_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_POST_ADGROUP,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_POST_ADGROUP,
                name: "post_ad_group",
                minLength: CONSTANTS.ONE,
                maxLength: 45,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.PRODUCT_FORM_APPROVED_ADGROUP_LABEL,
                placeholder: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_APPROVED_ADGROUP,
                message: CONSTANTS.PRODUCT_FORM_PLACEHOLDER_APPROVED_ADGROUP,
                name: "approved_ad_group",
                minLength: CONSTANTS.ONE,
                maxLength: 45,
                default: '',
                required: true,
            }
        ]
    },
    radioOptions: [
        { name: "show_customer", label: CONSTANTS.DO_NOT_SHOW_IN_CUSTOMER, value: CONSTANTS.ZERO_STRING, disabled: false },
        { name: "show_customer", label: CONSTANTS.SHOW_IN_CONTRACT_DETAIL, value: CONSTANTS.ONE_STRING, disabled: false },
        { name: "show_customer", label: CONSTANTS.SHOW_IN_OTHER_FEATURE, value: CONSTANTS.TWO_STRING, disabled: false },
        { name: "format_data", label: CONSTANTS.FORMAT_DATA_UNIQUE, value: CONSTANTS.ZERO_STRING },
        { name: "format_data", label: CONSTANTS.FORMAT_DATA_TABLE, value: CONSTANTS.ONE_STRING }
    ],
    kintoneProductForm: {
        model: "KintoneProductForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.KINTONE_FORM_ATTRIBUTE_NAME_LABEL,
                placeholder: CONSTANTS.KINTONE_FORM_PLACEHOLDER_TEXT,
                message: CONSTANTS.KINTONE_FORM_ATTRIBUTE_NAME_MESSAGE,
                name: "kintone_attribute_name",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.KINTONE_FORM_DISPLAY_LABEL,
                placeholder: CONSTANTS.KINTONE_FORM_PLACEHOLDER_TEXT,
                message: CONSTANTS.KINTONE_FORM_DISPLAY_LABEL_MESSAGE,
                name: "display_label",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.KINTONE_FORM_FORMAT_DATA_LABEL,
                message: CONSTANTS.KINTONE_FORM_FORMAT_DATA_MESSAGE,
                name: "format_data",
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_CHECKBOX,
                label: CONSTANTS.KINTONE_FORM_SHOW_PUBLIC_LABEL,
                message: CONSTANTS.KINTONE_FORM_SHOW_PUBLIC_MESSAGE,
                name: "show_customer",
                inline: true,
                default: false
            }
        ]
    },
    productOrdering: [
        {
            id: 1,
            label: "サービス・製品名(昇順)",
            value: "name"
        },
        {
            id: 2,
            label: "サービス・製品名(降順)",
            value: "-name"
        }
    ],
    defaultProductOrdering: "name",
    productFilter: [
        {
            id: 1,
            label: "サービス中"
        },
        {
            id: 2,
            label: "サービス終了予定"
        },
        {
            id: 3,
            label: "サービス終了"
        }
    ],
    productTableList: [],
    totalItemsCount: CONSTANTS.ZERO,
    productMessage: [],
    productEditDetails: ({}),
    categoryLookupList: [],
    productLookupList: [],
    kintoneProductFieldList: [],
    kintoneProductCommonFieldList: [],
    kintoneMessage: [],
    kintoneCommonMessage: [],
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.PRODUCT_LIST_STATE:
            return { ...state, productTableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.PRODUCT_DELETE_STATE:
            return { ...state, productMessage: action.payload }
        case types.CREATE_PRODUCT_STATE:
            return { ...state, productMessage: action.payload };
        case types.PRODUCT_EDIT_RETRIEVE_STATE:
            return { ...state, productEditDetails: action.payload };
        case types.CATEGORY_LOOKUP_STATE:
            return {
                ...state,
                categoryLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product_name", label: item.name }
                })
            };
        case types.PRODUCT_LOOKUP_STATE:
            return {
                ...state,
                productLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product", label: item.name }
                })
            };
        case types.PRODUCT_EDIT_UPDATE_STATE:
            return { ...state, productMessage: action.payload };
        case types.KINTONE_PRODUCT_FIELD_LIST_STATE:
            return { ...state, kintoneProductFieldList: action.payload }
        case types.KINTONE_PRODUCT_COMMON_FIELD_LIST_STATE:
            return { ...state, kintoneProductCommonFieldList: action.payload, totalItemsCount: action.payload.length }
        case types.KINTONE_PRODUCT_FIELD_CREATE_STATE:
            return { ...state, kintoneMessage: action.payload }
        case types.KINTONE_PRODUCT_FIELD_DELETE_STATE:
            return { ...state, kintoneMessage: action.payload }
        case types.KINTONE_PRODUCT_FIELD_PATCH_STATE:
            return { ...state, kintoneMessage: action.payload }
        case types.KINTONE_PRODUCT_COMMON_FIELD_PATCH_STATE:
            return { ...state, kintoneCommonMessage: action.payload }
        case types.KINTONE_PRODUCT_COMMON_FIELD_CREATE_STATE:
            return { ...state, kintoneCommonMessage: action.payload }
        case types.KINTONE_PRODUCT_COMMON_FIELD_DELETE_STATE:
            return { ...state, kintoneCommonMessage: action.payload }
        case types.KINTONE_PRODUCT_SUBTABLE_FIELD_LIST_STATE:
            return { ...state, kintoneProductSubTableFieldList: action.payload }
        case types.KINTONE_PRODUCT_COMMON_SUB_TABLE_FIELD_LIST_STATE:
            return { ...state, kintoneCommonSubTableFieldList: action.payload, subTotalItemCount: action.payload.length }
        default: return state;
    }
};