import reducer from "./reducers";
import { routes } from "../routes";
import * as adminEmailManagementOperations from "./operations";
import * as adminEmailManagementSelectors from "./selectors"

export {
    adminEmailManagementOperations,
    adminEmailManagementSelectors
};

const index = {
    routes,
    reducer
}

export default index;