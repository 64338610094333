import Error404 from "../Errors/404";
import ProductInformationScreen from "./ProductInformationScreen";
import SystemSettingsScreen from "./SystemSettingsScreen";

export const PRODUCT_MANAGEMENT_ROUTE = '/admin/productmanagement';
export const PRODUCT_INFO_ROUTE = '/admin/product';
export const SYSTEM_SETTINGS_SCREEN_ROUTE = '/admin/systemsettings';

export const routes = [
    {
        label: "製品管理",
        path: PRODUCT_MANAGEMENT_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        dropdown: true,
        isAdminProductManagement: true,
    },
    {
        label: "製品情報管理",
        path: PRODUCT_INFO_ROUTE,
        component: ProductInformationScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "共通属性設定",
        path: SYSTEM_SETTINGS_SCREEN_ROUTE,
        component: SystemSettingsScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
]