import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import * as Commons from '../Commons';


const MailTemplate = (props) => {

    return (
        <section className="list mt-3 mb-3">
        {
            props.totalItemsCount > 0 ?
                props.rows.map((mail_template) => {
                    return (
                        <div className="px-3 py-3 list-group-item table-layout" key={mail_template.id}>
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute">
                                        <span>
                                            <a className="list-item-title btn-action font-weight-bold" href="# " 
                                                onClick={() => { props.handleShowMailTemplateEditModal(mail_template.id) }}>
                                                {mail_template.name}
                                            </a>
                                        </span>
                                    </div>
                                    <div className="list-item-attribute pt-1">
                                        <strong>備考: </strong>
                                        {mail_template.remarks}
                                    </div>
                                    <div className="list-item-attribute pt-1">
                                        <strong>更新者: </strong>
                                        {mail_template.updated_by}
                                        <strong className="pl-3">更新日: </strong>
                                        {Commons.getClientDatetimeDisplay(mail_template.updated_date)}
                                    </div>
                                    <ul className="list-item-actions nav justify-content-end">
                                        <li className="nav-item">
                                            <a className={"nav-link py-0 px-3 pr-0 btn-action"} href="# " 
                                                onClick={() => { props.handleShowMailTemplateEditModal(mail_template.id) }}>
                                                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1">修正</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })
                : <h4>検索の結果、0件でした。</h4>
        }
        </section>
    );
}

export default MailTemplate;