import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminNoticeCancelModal = (WrappedComponent) => {
    return class AdminNoticeCancelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeCancelModal: false,
            }
        }

        adminNoticeCancelModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNoticeCancelModal} onHide={this.handleCloseCancelModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ申請取り消し</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「お知らせ」の申請を取り消します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitCancelModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseCancelModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowCancelModal = (value, event) => {
            event.target.blur();
            this.setState({ noticeCancelId: value, showNoticeCancelModal: true })
        }

        handleCloseCancelModal = () => {
            this.setState({ showNoticeCancelModal: false });
        }

        handleSubmitCancelModal = (event) => {
            event.target.setAttribute("disabled", "disabled")
            Promise.all([
                this.showLoader(),
                this.props.patchNoticeCancel(
                    this.state.noticeCancelId,
                    {cancel_application: 1 }
                ).then(
                    () => {
                        this.loadListFromServer()
                        this.handleShowSuccessAlert(this.props.noticeMessages)
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseCancelModal)
        }
    }
}

export {
    withAdminNoticeCancelModal
}