// eslint-disable-next-line
import React, { Component } from 'react';
import MailTemplate from '../../components/AdminEmailManagement/MailTemplate';
import { withMailTemplateEdit } from '../../components/AdminEmailManagement/MailTemplateEditModal';
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { adminEmailManagementOperations } from './state';

class MailTemplateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
        };
    }
    
    componentDidMount() {
        this.setState({
            hideServiceDropdown: true,
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getMailTemplateList(
                this.state.itemsCountPerPage, this.state.activePage, 
                this.state.query, this.state.sort
            ).then(() => {
                this.setState({
                    rows: this.props.mailTemplateList,
                    totalItemsCount: this.props.totalItemsCount,
                })
            })
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="mail_template">
                        <h4 className="mr-auto page-heading">{"メール管理 ＞ メールテンプレート一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light navbar-mail-template bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <MailTemplate 
                            {...this.state} 
                            {...this.props} 
                            handleShowMailTemplateEditModal={this.handleShowMailTemplateEditModal} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>

                    {this.mailTemplateEditModal({ ...this.state, ...this.props })}
                    {this.mailTemplateEditConfirmModal({ ...this.state })}

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        formInputs: state.adminEmailManagement.mailTemplateForm,
        listOrdering: state.adminEmailManagement.mailTemplateOrdering,
        defaultOrdering: state.adminEmailManagement.defaultMailTemplateOrdering,
        totalItemsCount: state.adminEmailManagement.totalItemsCount,
        mailTemplateList: state.adminEmailManagement.mailTemplateList,
        mailTemplateDetails: state.adminEmailManagement.mailTemplateDetails,
        mailTemplateMessage: state.adminEmailManagement.mailTemplateMessage,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMailTemplateList: adminEmailManagementOperations.getMailTemplateList,
        getMailTemplate: adminEmailManagementOperations.getMailTemplate,
        putMailTemplate: adminEmailManagementOperations.putMailTemplate
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withMailTemplateEdit(
                            MailTemplateScreen
                        )
                    )
                )
            )
        )
    )
);