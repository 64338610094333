import React, { Component } from "react";
import { authOperations } from '../Login/state';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { UNABLE_TO_PROCESS_RESET_PASSWORD } from '../../../utils/constants';


class AdminPasswordRequestUpdate extends Component {

    componentDidMount() {
        this.props.logoutUser();
        let params = new URLSearchParams(this.props.location.search);
        let uidb64 = params.get('uidb64');
        let token = params.get('token');

        if (uidb64 && token) {
            this.props.adminPasswordReset(uidb64, token)
            .catch((error) => {
                this.props.redirect(`/admin/login?error=${error}`)
            })
        }
        else {
            this.props.redirect(`/admin/login?error=${UNABLE_TO_PROCESS_RESET_PASSWORD}`)
        }
    }

    render() {
        return <></>
    }
}

const mapStateToProps = (state) => {
    return {
        access: (state.auth.credentials && state.auth.credentials.access) || false,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        adminPasswordReset: authOperations.adminPasswordReset,
        redirect: authOperations.redirect,
        logoutUser: authOperations.logoutUser
    },
    dispatch
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        AdminPasswordRequestUpdate
    )
);