import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminNoticeStopPublishModal = (WrappedComponent) => {
    return class AdminNoticeStopPublishModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeStopPublishModal: false,
            }
        }

        adminNoticeStopPublishModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNoticeStopPublishModal} onHide={this.handleCloseStopPublishModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ公開中止確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「お知らせ」を公開中止にします。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitStopPublishModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseStopPublishModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowStopPublishModal = (id, event) => {
            event.target.blur();
            this.setState({
                showNoticeStopPublishModal: true,
                noticeUnpublishId: id
            });
        }

        handleCloseStopPublishModal = () => {
            this.setState({
                showNoticeStopPublishModal: false
            });
        }

        handleSubmitStopPublishModal = (event) => {
            event.target.setAttribute("disabled", "disabled")
            Promise.all([
                this.showLoader(),
                this.props.patchNoticeUnpublish(
                    this.state.noticeUnpublishId,
                    { "is_unpublish": 1 }
                ).then(
                    () => {
                        this.loadListFromServer()
                        this.handleShowSuccessAlert(this.props.noticeMessages);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseStopPublishModal)
        }
    }
}

export {
    withAdminNoticeStopPublishModal
}