import React from 'react'

const UserNoticeButtons = (props) => {
    let { handleSearchFilter, getIdByCategoryName } = props;

    return (
        <div className="col-sm-6 pl-0">
            <div className="input-group">
                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <input type="radio" className="d-none highlight" name="noticeOptions" id="category1" value={getIdByCategoryName("お知らせ")} autoComplete="off" onClick={handleSearchFilter} />
                    <label className="btn btn-secondary firstbtn" htmlFor="category1">すべてのお知らせ</label>
                    <input type="radio" className="d-none" name="noticeOptions" id="category2" value={getIdByCategoryName("重要")} autoComplete="off" onClick={handleSearchFilter} />
                    <label className="btn btn-secondary" htmlFor="category2">重要</label>
                    <input type="radio" className="d-none" name="noticeOptions" id="category3" value={getIdByCategoryName("障害")} autoComplete="off" onClick={handleSearchFilter} />
                    <label className="btn btn-secondary" htmlFor="category3">障害</label>
                    <input type="radio" className="d-none" name="noticeOptions" id="category4" value={getIdByCategoryName("メンテナンス")} autoComplete="off" onClick={handleSearchFilter} />
                    <label className="btn btn-secondary" htmlFor="category4">メンテナンス</label>
                </div>
            </div>
        </div>
    );
}

export default UserNoticeButtons