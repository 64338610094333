import * as types from "./types";

const defaultState = {
    tableList: [],
    totalItemsCount: 0,
    productLookupList: [],
    noticeDetails: ({}),
    categoryLookupList: []
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, tableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.PRODUCT_LOOKUP_STATE:
            return { ...state, productLookupList: action.payload };
        case types.NOTICE_DETAILS_STATE:
            return { ...state, noticeDetails: action.payload };
        case types.CATEGORY_LOOKUP_STATE:
            return { ...state, categoryLookupList: action.payload };
        default: return state;
    }
};