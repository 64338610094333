import React from 'react'

const SortingTemplate = (props) => {
    let { handleSortChange, defaultTemplateOrdering, sortTemplate, listTemplateOrdering } = props;

    return (
        <div className="col-sm-3 sort-container">
            <div className="row sort-row">
                <div className="col-sm-3 pr-1 sort-label">表示順序</div>
                <div className="col-sm-8 sort-dropdown">
                    <div className="input-group input-group-sm ml-auto">
                        <select id="sort" className="form-control" value={sortTemplate || defaultTemplateOrdering} onChange={handleSortChange}>
                            <option value=""></option>
                            {
                                (listTemplateOrdering && listTemplateOrdering.length) ?
                                    listTemplateOrdering.map(option => {
                                        return (<option key={option.id} value={option.value}>{option.label}</option>)
                                    })
                                    :
                                    ""
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SortingTemplate