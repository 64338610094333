// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { adminAccountManagementOperations } from './state';
import ListOfAdminTemplate from '../../components/AdminAccountManagement/ListOfAdminTemplate';
import { withAdminDetailsModal } from '../../components/AdminAccountManagement/AdminDetailsModal';
import { withAdminResetPasswordRequestConfirmModal } from '../../components/AdminAccountManagement/AdminResetPasswordRequestConfirmModal';
import AdminExportButton from "../../components/Pagination/AdminExportButton";
import { withAdminExportModal} from '../../components/AdminAccountManagement/AdminExportModal';
import { withAdminChangeStatusConfirmModal } from '../../components/AdminAccountManagement/AdminChangeStatusConfirmModal';

class ListOfAdminScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            adminDetails: null,
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getAdminList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.product_number, this.state.sort
            ).then(
                () => {
                    this.setState({
                        rows: this.props.adminTableList,
                        totalItemsCount: this.props.totalItemsCount
                    });
                }
            ),
            this.props.getPortalProductList().then(
                () => {
                    this.setState({
                        productLookupList: this.props.productLookupList
                    });
                }
            ),
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="list_of_admin_template">
                        <h4 className="mr-auto page-heading">{"アカウント管理 ＞ 管理者一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.state.rows.length > 0 && <AdminExportButton
                                {...this.state}
                                {...this.props}
                                handleShowModalExportAdmin={this.handleShowModalExportAdmin}
                            />}
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <ListOfAdminTemplate
                            {...this.state}
                            handleShowAdminDetailsModal={this.handleShowAdminDetailsModal}
                            handleShowAdminChangeStatusConfirmModal={this.handleShowAdminChangeStatusConfirmModal}
                        />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.adminDetailsModal({ ...this.state })}
                    {this.adminResetPasswordRequestConfirmModal({ ...this.state })}
                    {this.adminExportModal({ ...this.state, ...this.props })}
                    {this.adminChangeStatusConfirmModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        adminTableList: state.adminAccountManagement.adminTableList,
        totalItemsCount: state.adminAccountManagement.totalItemsCount,
        productLookupList: state.adminAccountManagement.productLookupList,
        listOrdering: state.adminAccountManagement.adminOrdering,
        defaultOrdering: state.adminAccountManagement.defaultAdminOrdering,
        adminDetails: state.adminAccountManagement.adminDetails,
        passwordChangeRequestMessage: state.adminAccountManagement.passwordChangeRequestMessage,
        selectOptionFormat: state.adminAccountManagement.selectOptionFormat,
        exportAdminForm: state.adminAccountManagement.exportAdminForm,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getAdminList: adminAccountManagementOperations.getAdminList,
        getPortalProductList: adminAccountManagementOperations.getPortalProductList,
        getAdminDetails: adminAccountManagementOperations.getAdminDetails,
        adminPasswordChangeRequest: adminAccountManagementOperations.adminPasswordChangeRequest,
        updateAdminStatus: adminAccountManagementOperations.updateAdminStatus,
        exportAdmin: adminAccountManagementOperations.exportAdmin,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withSuccessAlert(
                withErrorAlert(
                    withAdminDetailsModal(
                        withAdminResetPasswordRequestConfirmModal(
                            withAdminExportModal(
                                withAdminChangeStatusConfirmModal(
                                    ListOfAdminScreen
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);