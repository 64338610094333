import React from 'react'
import { Modal } from 'react-bootstrap';
import { isNullOrUndefined } from 'util';

const withAdminTemplateDetailFromNoticeModal = (WrappedComponent) => {
    return class AdminTemplateDetailFromNoticeModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showTemplateDetailFromNotice: false,
            }
        }

        adminTemplateDetailFromNoticeModal = (props) => {
            let templateDetailData = this.state.templateDetailData;
            return (
                    isNullOrUndefined(templateDetailData) ?
                <>
                </>
                :
                <div className="w-100">
                    <Modal className="w-100 modal-dialog-lg admin-notice-details" keyboard={false} size="lg" show={this.state.showTemplateDetailFromNotice} onHide={this.handleCloseDetailFromNoticeModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>テンプレート管理 ＞ テンプレート詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="w-100 px-4 pb-0">
                            <table className="table table-bordered table-form detail-template-choose-modal">
                                <tbody>
                                    <tr>
                                        <td className="align-middle disable-table font-weight-bold"><strong>サービス・製品</strong>
                                            <span className="text-danger font-weight-bold"> * </span>
                                        </td>
                                        <td className="align-middle px-2 py-2">
                                            <textarea rows="1" className="form-control text-left" defaultValue={templateDetailData.product_name}></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle disable-table font-weight-bold"><strong>テンプレート名</strong>
                                            <span className="text-danger font-weight-bold"> * </span>
                                        </td>
                                        <td className="align-middle px-2 py-2">
                                            <textarea className="form-control text-left" defaultValue={templateDetailData.name}></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle disable-table font-weight-bold"><strong>備考</strong></td>
                                        <td className="align-middle px-2 py-2">
                                            <textarea className="form-control text-left" defaultValue={templateDetailData.note}></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle disable-table font-weight-bold"><strong>タイトルテンプレート</strong>
                                            <span className="text-danger font-weight-bold"> * </span>
                                        </td>
                                        <td className="align-middle px-2 py-2">
                                            <textarea className="form-control text-left" defaultValue={templateDetailData.title}></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle disable-table font-weight-bold"><strong>本文テンプレート</strong></td>
                                        <td className="align-middle px-2 py-2">
                                            <textarea rows="5" className="form-control text-left" defaultValue={templateDetailData.content}></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer className="px-0 pt-0 border-top-0">
                            <div className="d-inline-flex">
                                <button className="btn btn-200 btn-secondary mr-4" onClick={this.handleCloseDetailFromNoticeModal}>テンプレート選択に戻る</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        }

        handShowDetailFromNoticeModal = (data) => {
            this.setState({
                showTemplateDetailFromNotice: true,
                templateDetailData: data
            })
        }

        handleCloseDetailFromNoticeModal = () => {
            this.setState({
                showTemplateDetailFromNotice: false
            })
        }
    }
}

export {
    withAdminTemplateDetailFromNoticeModal
}
