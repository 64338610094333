// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLoader } from '../../components/Loader';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { adminProductManagementOperations } from './state';
import ProductCommonFieldsTemplate from '../../components/AdminProductManagement/ProductCommonFieldsTemplate';
import AdminKintoneCommonButton from "../../components/Pagination/AdminKintoneCommonButton";
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { adminProductManagementSelectors } from './state';
import { withCommonKintoneConfirmEditLabelModal } from '../../components/AdminProductManagement/CommonKintoneConfirmEditLabelModal';
import { withCommonKintoneEditLabelModal } from '../../components/AdminProductManagement/CommonKintoneEditLabelModal';
import { withCommonKintoneSortingConfirmModal } from '../../components/AdminProductManagement/CommonKintoneSortingConfirmModal';
import { withCommonKintoneSortingModal } from '../../components/AdminProductManagement/CommonKintoneSortingModal';
import { withKintoneCommonDeleteConfirmationModal } from '../../components/AdminProductManagement/KintoneCommonDeleteConfirmationModal';
import { withNewKintoneCommonConfirmModal } from '../../components/AdminProductManagement/NewKintoneCommonConfirmModal';
import { withNewKintoneCommonModal } from '../../components/AdminProductManagement/NewKintoneCommonModal';
import { withCommonDynamicSubTableFieldsModal } from '../../components/AdminProductManagement/CommonDynamicSubTablFieldsModal';
import * as CONSTANTS from '../../../utils/constants';

class SystemSettingsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            kintoneProductCommonFieldList: [],
            kintoneProductCommonFieldListDummy: [],
            kintoneOrders: [],
            commonDynamicSubTableFields: []
        };
    }

    componentDidMount() {
        this.setState({ totalItemsCount: this.props.totalItemsCount });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getAdminKintoneProductCommonFieldList().then(
                () => {
                    this.setState({
                        kintoneProductCommonFieldList: this.props.kintoneProductCommonFieldList
                    });
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    handleFormOnchange = (values) => {
        this.setState({ currentKintoneFormValues: values.formValues.KintoneProductForm })
    }

    handleCloseAllModal = () => {
        this.setState({
            showDiscardModal: false,
            showModalNewKintoneCommon: false,
            showCommonKintoneSortingModal: false,
            showCommonKintoneEditLabel: false,
            kintoneOrders: []
        });
    }

    sortKintoneCommon = (sortedList) => {
        sortedList.map((itemRow, idx) => {
            let kintoneOrders = [...this.state.kintoneOrders]
            let kintoneItem = { ...kintoneOrders[idx] }
            kintoneItem.id = parseInt(itemRow.content.key)
            kintoneItem.order = itemRow.rank
            kintoneOrders[idx] = kintoneItem

            let kintoneProductCommonFieldList = [...this.state.kintoneProductCommonFieldList]
            let kintoneProductCommonFieldListDummy = [...this.state.kintoneProductCommonFieldListDummy]
            if (this.state.submitCommonSubKintone) {
                kintoneProductCommonFieldList = [...this.state.commonDynamicSubTableFields];
            }
            let currentKintone = kintoneProductCommonFieldList.find(item => item.id === parseInt(itemRow.content.key))
            let kintoneProductCommon = {}
            kintoneProductCommon.id = currentKintone.id
            kintoneProductCommon.field_label = currentKintone.field_label
            kintoneProductCommon.show_customer = currentKintone.show_customer
            kintoneProductCommon.field_name = currentKintone.field_name
            kintoneProductCommon.order = itemRow.rank
            kintoneProductCommonFieldListDummy[idx] = kintoneProductCommon

            this.setState({
                kintoneOrders: kintoneOrders,
                kintoneProductCommonFieldListDummy: kintoneProductCommonFieldListDummy
            })
            return false;
        })
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="system_settings_template">
                        <h4 className="mr-auto page-heading">{"製品管理 ＞共通属性設定"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-3">
                            <AdminKintoneCommonButton
                                handleShowModalNewKintoneCommon={this.handleShowModalNewKintoneCommon}
                                handleShowCommonKintoneSortingModal={this.handleShowCommonKintoneSortingModal}
                            />
                        </nav>
                    </div>
                    <ProductCommonFieldsTemplate
                        {...this.state}
                        handleSubmitProductCommonField={this.handleSubmitProductCommonField}
                        handleShowDeleteKintoneCommonProductConfirmation={this.handleShowDeleteKintoneCommonProductConfirmation}
                        handleShowCommonKintoneEditLabelModal={this.handleShowCommonKintoneEditLabelModal}
                        handleShowCommonSubTableFieldsModal={this.handleShowCommonSubTableFieldsModal}
                    />
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.commonKintoneConfirmEditLabelModal({ ...this.state })}
                    {this.commonKintoneEditLabelModal({ ...this.state, ...this.props, adminProductManagementSelectors })}
                    {this.commonKintoneSortingConfirmModal({ ...this.state })}
                    {this.commonKintoneSortingModal({ ...this.state, ...this.props })}
                    {this.kintoneCommonDeleteConfirmationModal({ ...this.state })}
                    {this.newKintoneCommonConfirmModal({ ...this.state })}
                    {this.newKintoneCommonModal({ ...this.state, ...this.props, adminProductManagementSelectors })}
                    {this.commonDynamicSubTableFieldsModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    state.adminProductManagement.kintoneProductForm.form.forEach(kintoneProductField => {
        if (kintoneProductField.name === "show_customer") {
            kintoneProductField.type = CONSTANTS.FORM_TYPE_CHECKBOX;
            kintoneProductField.default = false;
            kintoneProductField.inline = false;
        }
    });

    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        kintoneProductCommonFieldList: state.adminProductManagement.kintoneProductCommonFieldList,
        radioInput: state.adminProductManagement.radioOptions,
        kintoneFormInputs: state.adminProductManagement.kintoneProductForm,
        kintoneCommonMessage: state.adminProductManagement.kintoneCommonMessage,
        totalItemsCount: state.adminProductManagement.totalItemsCount,
        commonDynamicSubTableFields: state.adminProductManagement.kintoneCommonSubTableFieldList,
        subTotalItemCount: state.adminProductManagement.subTotalItemCount
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getAdminKintoneProductCommonFieldList: adminProductManagementOperations.getAdminKintoneProductCommonFieldList,
        postCreateKintoneProductCommonField: adminProductManagementOperations.postCreateKintoneProductCommonField,
        deleteKintoneProductCommonField: adminProductManagementOperations.deleteKintoneProductCommonField,
        patchKintoneCommonField: adminProductManagementOperations.patchKintoneCommonField,
        patchSortingKintoneCommonField: adminProductManagementOperations.patchSortingKintoneCommonField,
        getKintoneCommonSubTableFieldList: adminProductManagementOperations.getKintoneCommonSubTableFieldList
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withLoginCheck(
        withLoader(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withCommonDynamicSubTableFieldsModal(
                            withCommonKintoneConfirmEditLabelModal(
                                withCommonKintoneEditLabelModal(
                                    withCommonKintoneSortingConfirmModal(
                                        withCommonKintoneSortingModal(
                                            withKintoneCommonDeleteConfirmationModal(
                                                withNewKintoneCommonConfirmModal(
                                                    withNewKintoneCommonModal(SystemSettingsScreen)
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);