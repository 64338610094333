import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    adminOrdering: [
        {
            id: 1,
            label: "ユーザー名（昇順）",
            value: "username"
        },
        {
            id: 2,
            label: "ユーザー名（降順）",
            value: "-username"
        },
        {
            id: 3,
            label: "氏名（昇順）",
            value: "last_name,first_name"
        },
        {
            id: 4,
            label: "氏名（降順）",
            value: "-last_name,-first_name"
        },
        {
            id: 5,
            label: "所属（昇順）",
            value: "portaladmin__department"
        },
        {
            id: 6,
            label: "所属（降順）",
            value: "-portaladmin__department"
        },
        {
            id: 7,
            label: "最終ログイン日時（昇順）",
            value: "last_login"
        },
        {
            id: 8,
            label: "最終ログイン日時（降順）",
            value: "-last_login"
        }
    ],
    defaultAdminOrdering: "-last_login",
    customerOrdering: [
        {
            id: 1,
            label: "最終ログイン日時（昇順）",
            value: "last_login"
        },
        {
            id: 2,
            label: "最終ログイン日時（降順）",
            value: "-last_login"
        },
        {
            id: 3,
            label: "ポータルログインID（昇順）",
            value: "username,-last_login"
        },
        {
            id: 4,
            label: "ポータルログインID（降順）",
            value: "-username,-last_login"
        },
        // {
        //     id: 5,
        //     label: "契約プラン(昇順)",
        //     value: "portalcustomer__contract__product__name"
        // },
        // {
        //     id: 6,
        //     label: "契約プラン(降順)",
        //     value: "-portalcustomer__contract__product__name"
        // },
    ],
    defaultCustomerOrdering: "-last_login",
    customerTableList: [],
    adminTableList: [],
    totalItemsCount: CONSTANTS.ZERO,
    customerInfoDetails: [],
    productLookupList: [],
    adminDetails: [],
    passwordChangeRequestMessage: [],
    selectOptionFormat: [
        { id: 1, value: "shift-jis", name: "FILE_FORMAT", label: "Shift_JIS" },
        { id: 2, value: "utf8", name: "FILE_FORMAT", label: "UTF-8" },
    ],
    exportAdminForm: {
        model: "ExportAdminPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_EXPORT_ADMIN_LABEL,
                message: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT_EXPORT,
                name: "FILE_FORMAT",
                default: 'shift-jis',
                required: true,
            }
        ]
    },
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.CUSTOMER_LIST_STATE:
            return { ...state, customerTableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.CUSTOMER_INFO_DETAILS_RETRIEVE_STATE:
            return { ...state, customerInfoDetails: action.payload };
        case types.ADMIN_LIST_STATE:
            return { ...state, adminTableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.ADMIN_DETAILS_STATE:
            return { ...state, adminDetails: action.payload }
        case types.PRODUCT_LOOKUP_STATE:
            return {
                ...state,
                productLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product", label: item.name }
                })
            };
        case types.ADMIN_PASSWORD_CHANGE_REQUEST_STATE:
            return { ...state, passwordChangeRequestMessage: action.payload }
        case types.ADMIN_KINTONE_PRODUCT_DATA_SUB_TABLE_STATE:
            return { ...state, adminKintoneProductSubTableData: action.payload };
        case types.ADMIN_KINTONE_COMMON_DATA_SUB_TABLE_STATE:
            return { ...state, adminKintoneCommonSubTableData: action.payload };
        default: return state;
    }
};