import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withProductCtrlPanelUrlConfirmModal = (WrappedComponent) => {
    return class ProductCtrlPanelUrlConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductCtrlPanelUrlConfirmModal: false
            }
        }

        productCtrlPanelUrlConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showProductCtrlPanelUrlConfirmModal} onHide={this.handleCloseProductCtrlPanelUrlConfirmModal} centered>
                    <Modal.Header closeButton>
                        {/* Attribute setting> Edit confirmation */}
                        <Modal.Title>{"属性設定 > 編集確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            サービスカスコンリンクを更新します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitProductCtrlPanelUrlField}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseProductCtrlPanelUrlConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal >
            )
        }

        handleCloseProductCtrlPanelUrlConfirmModal = () => {
            this.setState({
                showProductCtrlPanelUrlConfirmModal: false
            });
        }

        handleSubmitProductCtrlPanelUrlField = () => {
            Promise.all([
                this.showLoader(),
                this.props.patchKintoneProductControlPanel(
                    this.state.controlPanelURLField,
                    { 'is_control_panel': 1, 'product_id': this.state.kintoneProductId }
                ).then(
                    () => {
                        this.handleCloseProductDynamicFieldsModal();
                        this.handleShowSuccessAlert(this.props.kintoneMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseProductCtrlPanelUrlConfirmModal)
            .then(() => {
                this.setState({
                    controlPanelURLField: null
                });
            })
        }
    }
}

export {
    withProductCtrlPanelUrlConfirmModal
}