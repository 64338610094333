import reducer from "./reducers";
import { routes } from "../routes";
import * as adminDocumentOperations from "./operations";
import * as adminDocumentSelectors from "./selectors"

export {
    adminDocumentOperations,
    adminDocumentSelectors
};

const index = {
    routes,
    reducer
}

export default index;