import React from 'react'

const PaginationPerPage = (props) => {
    let { itemsOffset, itemsCountPerPage, totalItemsCount, handlePerPageClick, handlePageChange } = props;

    if (props.isDocumentList) {
        totalItemsCount = props.totalDocumentsListItemsCount;
    } else if (props.showChooseTemplateModal) {
        totalItemsCount = props.totalItemsTemplateCount;
    }

    return (
        <div className={props.hideServiceDropdown ? "offset-sm-1 col-sm-8 per-page-pagination-container" : "offset-sm-1 col-sm-8 per-page-pagination-container"}>
            <div className="mr-auto">
                <ul className="per-page-pagination pagination pagination-sm justify-content-end mb-1">
                    <li className="page-item">
                        <a href="# " className="page-link previous-button disabled" onClick={() => {handlePageChange(props.activePage-1)}}>前へ</a>
                    </li>
                    <ul className="pagination per-page-pagination-ul-child">
                        <li className="page-item text-secondary disabled">|</li>
                        <li className="page-item disabled">
                            <a href="/#" className="page-link">
                                {totalItemsCount === 0 ? '0 - ' : itemsOffset + 1 + ' - '}
                                {(itemsOffset + itemsCountPerPage > totalItemsCount ? totalItemsCount : itemsOffset + itemsCountPerPage) + ' / '}
                                {totalItemsCount}</a>
                        </li>
                        <li className="page-item text-secondary disabled">|</li>
                        <li className="page-item active">
                            <a href="/#" className="page-link option active" onClick={handlePerPageClick}>10</a>
                        </li>
                        <li className="page-item">
                            <a href="/#" className="page-link option" onClick={handlePerPageClick}>25</a>
                        </li>
                        <li className="page-item">
                            <a href="/#" className="page-link option" onClick={handlePerPageClick}>50</a>
                        </li>
                        <li className="page-item">
                            <a href="/#" className="page-link option" onClick={handlePerPageClick}>100</a>
                        </li>
                        <li className="page-item text-secondary disabled">|</li>
                    </ul>
                    <li className="page-item">
                        <a href="# " className={((props.totalItemsCount > props.itemsCountPerPage && props.totalDocumentsListItemsCount === undefined)
                            || (props.totalDocumentsListItemsCount > props.itemsCountPerPage)
                            || (props.totalItemsTemplateCount > props.itemsCountPerPage && props.totalDocumentsListItemsCount === undefined)) ? "page-link next-button" : "page-link next-button disabled"} onClick={() => {handlePageChange(props.activePage+1)}}>次へ</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default PaginationPerPage