import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Commons from '../Commons';
import AdminFormTemplate from '../AdminPortalFormTemplate'

const withProductDraftModal = (WrappedComponent) => {
    return class ProductDraftModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductDraftModal: false
            }
        }

        productDraftModal = (props) => {
            let { displayColorPicker, fields, color } = this.state;
            var areAllDefault = props.currentFormValues && props.initialFormValue ?
                props.currentFormValues.approved_ad_group === props.initialFormValue.PortalProductForm.approved_ad_group &&
                    "#" + ((1 << 24) + (props.color.r << 16) + (props.color.g << 8) + props.color.b).toString(16).slice(1) === props.initialFormValue.PortalProductForm.bg_color &&
                    props.currentFormValues.faq_link === props.initialFormValue.PortalProductForm.faq_link &&
                    props.currentFormValues.inquiry_link === props.initialFormValue.PortalProductForm.inquiry_link &&
                    props.currentFormValues.kintone_api_id.toString() === props.initialFormValue.PortalProductForm.kintone_api_id.toString() &&
                    props.currentFormValues.kintone_api_link === props.initialFormValue.PortalProductForm.kintone_api_link &&
                    props.currentFormValues.kintone_api_token === props.initialFormValue.PortalProductForm.kintone_api_token &&
                    props.currentFormValues.kintone_key_field === props.initialFormValue.PortalProductForm.kintone_key_field &&
                    props.currentFormValues.kintone_key_value === props.initialFormValue.PortalProductForm.kintone_key_value &&
                    props.currentFormValues.post_ad_group === props.initialFormValue.PortalProductForm.post_ad_group &&
                    props.currentFormValues.product_name === props.initialFormValue.PortalProductForm.product_name &&
                    props.currentFormValues.product_long_name === props.initialFormValue.PortalProductForm.product_long_name &&
                    props.currentFormValues.service_link === props.initialFormValue.PortalProductForm.service_link &&
                    (props.currentFormValues.image === props.initialFormValue.PortalProductForm.image || props.currentFormValues.image === "delete") &&
                    Commons.getClientDatetimeDisplay(props.currentFormValues.start_date) === Commons.getClientDatetimeDisplay(props.initialFormValue.PortalProductForm.start_date) &&
                    Commons.getClientDatetimeDisplay(props.currentFormValues.end_date) === Commons.getClientDatetimeDisplay(props.initialFormValue.PortalProductForm.end_date) ? true : false : true

            return (
                <Modal show={this.state.showProductDraftModal} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal} className="modal-edit-product" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品情報編集</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            isServicePortal={props.isServicePortal}
                            formInputs={props.formInputs}
                            servicePortalFormInputs={props.servicePortalFormInputs}
                            initialValues={props.initialFormValue}
                            editMode={true}
                            productDraft={true}
                            image={props.image}
                            validate={props.isServicePortal ? props.adminProductManagementSelectors.servicePortalProductCheckErrors : props.adminProductManagementSelectors.productCheckErrors}
                            handleSubmitModalProductDraft={this.handleSubmitModalProductDraft}
                            form={"product-draft"}
                            handleShowDraftConfirmModal={this.handleShowDraftConfirmModal}
                            setImageForUpload={this.setImageForUpload}
                            handleFormOnchange={this.handleFormOnchange}
                            handleClickColorPicker={this.handleClickColorPicker}
                            handleCloseColorPicker={this.handleCloseColorPicker}
                            handleChangeColorPicker={this.handleChangeColorPicker}
                            displayColorPicker={displayColorPicker}
                            handleDateChange={this.handleDateChange}
                            fields={fields}
                            color={color}
                            styles={props.styles}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="product-draft" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowDraftConfirmModal"
                            }}>保存</button>
                            <button className={"btn btn-90 btn-secondary"} onClick={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowDraftModal = (value, event) => {
            event.target.blur();
            Promise.all([
                this.showLoader(),
                this.props.getProductEditDetails(value).then(
                    () => {
                        var poster = this.props.productEditDetails.poster_ad_group.join(', ');
                        var approved = this.props.productEditDetails.approver_ad_group.join(', ');
                        var kintoneKeyValue = this.props.productEditDetails.kintone_key_value.join(', ');
                        this.setState({
                            initialFormValue: {
                                PortalProductForm: {
                                    id: this.props.productEditDetails.id,
                                    product_name: this.props.productEditDetails.name,
                                    product_long_name: this.props.productEditDetails.long_name,
                                    post_ad_group: poster,
                                    approved_ad_group: approved,
                                    kintone_api_link: this.props.productEditDetails.kintone_url ? this.props.productEditDetails.kintone_url : "",
                                    kintone_api_id: this.props.productEditDetails.kintone_app_id ? this.props.productEditDetails.kintone_app_id : "",
                                    kintone_api_token: this.props.productEditDetails.kintone_token ? this.props.productEditDetails.kintone_token : "",
                                    image: "",
                                    kintone_key_field: this.props.productEditDetails.kintone_key_field ? this.props.productEditDetails.kintone_key_field : "",
                                    kintone_key_value: kintoneKeyValue,
                                    faq_link: this.props.productEditDetails.okwave_url ? this.props.productEditDetails.okwave_url : "",
                                    inquiry_link: this.props.productEditDetails.inquire_url ? this.props.productEditDetails.inquire_url : "",
                                    bg_color: this.props.productEditDetails.bg_color,
                                    start_date: new Date(Commons.getClientDatetimeDisplay(this.props.productEditDetails.start_date)),
                                    end_date: this.props.productEditDetails.end_date ? new Date(Commons.getClientDatetimeDisplay(this.props.productEditDetails.end_date)) : ''
                                }
                            },
                            showProductDraftModal: true,
                            image: this.props.productEditDetails.image,
                            color: this.hexToRgb(this.props.productEditDetails.bg_color),
                            fields: {
                                start_date: new Date(Commons.getClientDatetimeDisplay(this.props.productEditDetails.start_date)),
                                end_date: this.props.productEditDetails.end_date ? new Date(Commons.getClientDatetimeDisplay(this.props.productEditDetails.end_date)) : ''
                            },
                            isServicePortal: this.props.productEditDetails.is_service_portal
                        })
                        if (!this.props.productEditDetails.is_service_portal && this.props.productEditDetails.image) {
                            this.imageOnload(this.props.productEditDetails.image)
                        }
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseDraftModal = () => {
            this.setState({
                showProductDraftModal: false,
                color: {
                    r: 0,
                    g: 0,
                    b: 0,
                    a: 1,
                },
                fields: {
                    start_date: '',
                    end_date: ''
                }
            })
            this.removeImageForUpload()
        }

        handleSubmitModalProductDraft = (id, values) => {
            var poster = values.post_ad_group.split(',').map(Function.prototype.call, String.prototype.trim);
            var approver = values.approved_ad_group.split(',').map(Function.prototype.call, String.prototype.trim);
            var start_date = Commons.getServerDatetime(values.start_date);
            var end_date = values.end_date ? Commons.getServerDatetime(values.end_date) : '';
            var kintoneKeyValue = values.kintone_key_value.split(',').map(Function.prototype.call, String.prototype.trim);
            let formattedValue = {
                name: values.product_name,
                long_name: values.product_long_name,
                kintone_url: values.kintone_api_link,
                kintone_app_id: values.kintone_api_id,
                kintone_token: values.kintone_api_token,
                okwave_url: values.faq_link,
                inquire_url: values.inquiry_link,
                poster_ad_group: poster,
                approver_ad_group: approver,
                bg_color: values.bg_color,
                start_date: start_date,
                end_date: end_date,
                kintone_key_field: values.kintone_key_field,
                kintone_key_value: kintoneKeyValue
            }

            if (values.image === "delete") {
                formattedValue['image'] = ""
            }
            else if (values.image) {
                formattedValue['image'] = values.image
            }

            let formData = new FormData();
            let formKeys = Object.keys(formattedValue);
            formKeys.map((key) => {
                formData.append(key, formattedValue[key]);
                return true
            });

            this.setState({
                productData: {
                    id: id,
                    type: "edit",
                    payload: formData
                }
            });
        }

        handleShowDraftConfirmModal = () => {
            this.setState({
                showProductDraftConfirmModal: true
            });
        }
    }
}

export {
    withProductDraftModal
}