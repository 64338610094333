import React, { Component } from 'react';
import AdminApprovalTemplate from "../../components/AdminApproval/AdminApprovalTemplate";
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withPaging } from '../../components/Pagination/Paging';
import { connect } from 'react-redux';
import { adminApprovalSelectors } from './state';
import { adminApprovalOperations } from './state';
import { adminNoticeOperations } from '../AdminNotice/state';
import { bindActionCreators } from 'redux';
import { withAdminNoticeApprovalModal } from '../../components/AdminApproval/AdminNoticeApprovalModal';
import { withAdminDocumentApprovalModal } from '../../components/AdminApproval/AdminDocumentApprovalModal';
import { withAdminApprovalConfirmModal } from '../../components/AdminApproval/AdminApprovalConfirmModal';
import { withAdminApprovalRejectModal } from '../../components/AdminApproval/AdminApprovalRejectModal';
import { withAdminApprovalEditDocumentModal } from '../../components/AdminApproval/AdminApprovalEditDocumentModal';
import { withAdminApprovalEditNoticeModal } from '../../components/AdminApproval/AdminApprovalEditNoticeModal';
import { withAdminChooseTemplateModal } from '../../components/AdminNotice/AdminChooseTemplateModal';
import { withAdminTemplateDetailFromNoticeModal } from '../../components/AdminNotice/AdminTemplateDetailModalFromTemplateChooseModal';

class AdminApprovalScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            rejectRemarks: "",
            initialDocumentFormValue: null,
            initialDocumentFormValue1: null,
            initialNoticeFormValue: null,
            initialFormRejectValue: null,
            uploaded_files: [],
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount,
            defaultTemplateOrdering: this.props.defaultTemplateOrdering,
            listTemplateOrdering: this.props.listTemplateOrdering,
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getApprovalList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.product_number, this.state.sort)
            .then(
                () => {
                    this.setState({
                        rows: this.props.tableList,
                        totalItemsCount: this.props.totalItemsCount
                    })
                }
            ),
            this.props.getApprovalProductList().then(
                () => {
                    this.setState({
                        productLookupList: this.props.productLookupList
                    })
                }
            ),
            this.props.getApprovalCategoryList().then(
                () => {
                    this.setState({
                        categoryLookupList: this.props.categoryLookupList,
                        categoryLookupListForEditNotice: this.props.categoryLookupListForEditNotice
                    })
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    setUploadedFiles = (files) => {
        this.setState({
            upload_files: files
        })
    }

    removeUploadedFiles = () => {
        this.setState({
            uploaded_files: []
        })
    }

    handleFormOnchange = (values) => {
        if (values.formValues.DocumentForm) {
            this.setState({
                currentFormValues: values.formValues.DocumentForm,
                currentDocumentFormValues: values.formValues.DocumentForm,
            })
        }
        else if (values.formValues.PostForm) {
            this.setState({
                currentFormValues: values.formValues.PostForm,
                currentNoticeFormValues: values.formValues.PostForm,
            })
        }
        else if (values.formValues.ApprovalRejectForm) {
            this.setState({ currentFormValues: values.formValues.ApprovalRejectForm })
        }
    }

    handleCloseAllModal = () => {
        this.handleCloseApprovalRejectModal();
        this.handleCloseDiscardModal();
        this.handleCloseApprovalEditDocumentModal();
        this.handleCloseApprovalEditNoticeModal();
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="admin_approval_template">
                        <h4 className="mr-auto page-heading">{"承認管理 ＞ 承認待ち一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <AdminApprovalTemplate {...this.state}
                            handleShowNoticeApprovalModal={this.handleShowNoticeApprovalModal}
                            handleShowDocumentApprovalModal={this.handleShowDocumentApprovalModal}
                            handleShowApprovalConfirmModal={this.handleShowApprovalConfirmModal}
                            handleShowApprovalEditDocumentModal={this.handleShowApprovalEditDocumentModal}
                            handleShowApprovalEditNoticeModal={this.handleShowApprovalEditNoticeModal}
                            handShowDetailFromNoticeModal={this.handShowDetailFromNoticeModal}
                        />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.adminNoticeApprovalModal({ ...this.state, ...this.props })}
                    {this.adminDocumentApprovalModal({ ...this.state, ...this.props })}
                    {this.adminApprovalConfirmModal({ ...this.state })}
                    {this.adminApprovalRejectModal({ ...this.state, ...this.props })}
                    {this.adminApprovalEditDocumentModal({ ...this.state, ...this.props, adminApprovalSelectors })}
                    {this.adminApprovalEditNoticeModal({ ...this.state, ...this.props, adminApprovalSelectors })}
                    {this.adminChooseTemplateModal({ ...this.state, ...this.props })}
                    {this.adminTemplateDetailFromNoticeModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        tableList: state.adminApproval.tableList,
        totalItemsCount: state.adminApproval.totalItemsCount,
        productLookupList: state.adminApproval.productLookupList,
        categoryLookupList: state.adminApproval.categoryLookupList,
        approvalPostForm: state.adminApproval.approvalPostForm,
        approvalHistoryPostForm: state.adminApproval.approvalHistoryPostForm,
        radioInput: state.adminApproval.radioOptions,
        noticeApprovalDetails: state.adminApproval.noticeApprovalDetails,
        noticeApprovalHistoryDetails: state.adminApproval.noticeApprovalHistoryDetails,
        approvalRejectForm: state.adminApproval.approvalRejectForm,
        approvalDocumentForm: state.adminApproval.approvalDocumentForm,
        approvalHistoryDocumentForm: state.adminApproval.approvalHistoryDocumentForm,
        documentApprovalDetails: state.adminApproval.documentApprovalDetails,
        documentApprovalHistoryDetails: state.adminApproval.documentApprovalHistoryDetails,
        approvalMessages: state.adminApproval.approvalMessages,
        listOrdering: state.adminApproval.approvalOrdering,
        defaultOrdering: state.adminApproval.defaultApprovalOrdering,
        documentEditDetails: state.adminApproval.documentEditDetails,
        formInputs: state.adminApproval.documentForm,
        noticeEditDetails: state.adminApproval.noticeEditDetails,
        formNoticeInput: state.adminApproval.postForm,
        categoryLookupListForEditNotice: state.adminApproval.categoryLookupListForEditNotice,
        selectInput: state.adminApproval.categoryLookupListForEditNotice,
        noticeTemplateDetail: state.adminNotice.noticeTemplateDetail,
        noticeTemplateLookup: state.adminNotice.noticeTemplateLookup,
        totalItemsTemplateCount: state.adminNotice.totalItemsTemplateCount,
        defaultTemplateOrdering: state.adminNotice.defaultTemplateOrdering,
        listTemplateOrdering: state.adminNotice.noticeChooseTemplateOrdering,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getApprovalList: adminApprovalOperations.getApprovalList,
        getApprovalProductList: adminApprovalOperations.getApprovalProductList,
        getApprovalCategoryList: adminApprovalOperations.getApprovalCategoryList,
        getNoticeApprovalDetails: adminApprovalOperations.getNoticeApprovalDetails,
        getNoticeApprovalHistoryDetails: adminApprovalOperations.getNoticeApprovalHistoryDetails,
        patchApprovalDetails: adminApprovalOperations.patchApprovalDetails,
        getDocumentApprovalDetails: adminApprovalOperations.getDocumentApprovalDetails,
        getDocumentApprovalHistoryDetails: adminApprovalOperations.getDocumentApprovalHistoryDetails,
        getDocumentFile: adminApprovalOperations.getDocumentFile,
        getDocumentEditDetails: adminApprovalOperations.getDocumentEditDetails,
        getNoticeEditDetails: adminApprovalOperations.getNoticeEditDetails,
        getNoticeTemplateLookup: adminNoticeOperations.getNoticeTemplateLookup,
        getNoticeTemplateDetail: adminNoticeOperations.getNoticeTemplateDetail,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withAdminNoticeApprovalModal(
                            withAdminDocumentApprovalModal(
                                withAdminApprovalEditDocumentModal(
                                    withAdminApprovalEditNoticeModal(
                                        withAdminApprovalConfirmModal(
                                            withAdminChooseTemplateModal(
                                                withAdminTemplateDetailFromNoticeModal(
                                                    withAdminApprovalRejectModal(AdminApprovalScreen)
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);