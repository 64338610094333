import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withKintoneNotificationModal = (WrappedComponent) => {
    return class KintoneNotificationModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNotificationModal: false
            }
        }

        kintoneNotificationModal = (props) => {
            return (
                <Modal className="modal-dialog-sm" keyboard={false} size="sm" show={this.state.showNotificationModal} onHide={this.handleCloseNotificationModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>警告</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-sm">
                        <div>
                            サービスカスコンリンクは選択していません。
                        </div>
                        <div>
                            選択しないまま設定変更終了しますか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.handleSubmitKintoneNotificationModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseNotificationModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleCloseNotificationModal = () => {
            this.setState({
                showNotificationModal: false
            });
        }

        handleSubmitKintoneNotificationModal = () => {
            this.handleCloseNotificationModal();
            this.handleCloseProductDynamicFieldsModal();
        }
    }
}

export {
    withKintoneNotificationModal
}