import Error404 from "../Errors/404";
import AdminNoticeScreen from "./AdminNoticeScreen";
import AdminNoticeTemplateScreen from "./AdminNoticeTemplateScreen";

export const NOTICE_MANAGEMENT_ROUTE = '/admin/notice';
export const NOTICE_POST_ROUTE = '/admin/noticepost';
export const NOTICE_TEMPLATE_ROUTE = '/admin/noticetemplate';

export const routes = [
    {
        label: "お知らせ",
        path: NOTICE_MANAGEMENT_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        dropdown: true,
        isPosterNotice: true
    },
    {
        label: "お知らせ管理",
        path: NOTICE_POST_ROUTE,
        component: AdminNoticeScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
    },
    {
        label: "テンプレート管理",
        path: NOTICE_TEMPLATE_ROUTE,
        component: AdminNoticeTemplateScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
    },
]
