import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminNoticeApplicationConfirmModal = (WrappedComponent) => {
    return class AdminNoticeApplicationConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeApplicationConfirmModal: false,
            }
        }

        noticeApplicationConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNoticeApplicationConfirmModal} onHide={this.handleCloseApplicationConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ公開申請確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「お知らせ」公開を申請します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitApplicationConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseApplicationConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowApplicationConfirmModal = () => {
            this.setState({
                showNoticeApplicationConfirmModal: true
            });
        }

        handleCloseApplicationConfirmModal = () => {
            this.setState({
                showNoticeApplicationConfirmModal: false
            });
        }

        handleSubmitApplicationConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let id = this.state.noticeData.id;
            let type = this.state.noticeData.type;
            let payload = this.state.noticeData.payload

            payload.is_draft = 0;
            if (type === "draft") {
                Promise.all([
                    this.showLoader(),
                    this.props.putNoticeEditDetails(id, payload).then(
                        () => {
                            this.loadListFromServer();
                            this.handleCloseDraftModal();
                            this.handleShowSuccessAlert(this.props.noticeMessages);
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseApplicationConfirmModal)
            } else if (type === "create") {
                Promise.all([
                    this.showLoader(),
                    this.props.postNoticeCreateDetails(payload).then(
                        () => {
                            this.loadListFromServer();
                            this.handleCloseModalNewPost();
                            if (this.props.noticeCreateDetails.message) {
                                this.handleShowSuccessAlert(this.props.noticeCreateDetails.message);
                            } else {
                                this.handleShowSuccessAlert(this.props.noticeCreateDetails);
                            }
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseApplicationConfirmModal)
            }
        }
    }
}

export {
    withAdminNoticeApplicationConfirmModal
}