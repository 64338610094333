import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const withKintoneDeleteConfirmationModal = (WrappedComponent) => {
    return class KintoneDeleteConfirmationModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDeleteKintoneProductConfirmation: false
            }
        }

        kintoneDeleteConfirmationModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDeleteKintoneProductConfirmation} onHide={this.handleCloseDeleteKintoneProductConfirmation} centered>
                    <Modal.Header closeButton>
                        {/* Attribute setting> Delete confirmation */}
                        <Modal.Title>{"属性設定 > 削除確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            属性情報を削除します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.deleteKintoneProduct}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseDeleteKintoneProductConfirmation}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        deleteKintoneProduct = () => {
            Promise.all([
                this.showLoader(),
                this.props.deleteKintoneProductField(
                    this.state.kintoneDeleteId,
                    { 'product_id': this.state.kintoneProductId }).then(
                        () => {
                            if (this.state.submitSubKintone) {
                                this.handleShowKintoneProductSubTableFieldsModal(this.state.kintoneProductId, this.state.parentId, this.state.parentFieldLabel, this.state.parentShowCustomer);
                            } else {
                                this.handleShowProductDynamicFieldsModal(this.state.kintoneProductId);
                            }
                            this.handleShowSuccessAlert(this.props.kintoneMessage);
                            this.setState({ controlPanelURLField: null });
                        }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseDeleteKintoneProductConfirmation)
        }

        handleCloseDeleteKintoneProductConfirmation = () => {
            this.setState({
                showDeleteKintoneProductConfirmation: false
            })
        }
    }
}

export {
    withKintoneDeleteConfirmationModal
}