import React from 'react';
import { Modal } from 'react-bootstrap';


const withMailTemplateEditConfirm = (WrappedContent) => {

    return class MailTemplateEditConfirm extends WrappedContent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showMailTemplateEditConfirmModal: false
            };
        }

        mailTemplateEditConfirmModal = (props) => {
            return (
                <Modal keyboard={false} size="md" className="modal-edit-confirm-mail-template modal-dialog-md" centered
                    show={props.showMailTemplateEditConfirmModal}
                    onHide={this.handleCloseMailTemplateEditConfirmModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{"メールテンプレートの更新 > 更新確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            メールテンプレート情報を更新します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button
                                type="button" form="mail-template"
                                className="btn btn-90 mr-2 ml-auto btn-primary"
                                onClick={this.handleSubmitMailTemplateEditConfirmModal}>Ok</button>
                            <button
                                className="btn btn-90 btn-secondary"
                                onClick={this.handleCloseMailTemplateEditConfirmModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowMailTemplateEditConfirmModal = () => {
            this.setState({
                showMailTemplateEditConfirmModal: true
            })
        }

        handleCloseMailTemplateEditConfirmModal = () => {
            this.setState({
                showMailTemplateEditConfirmModal: false
            })
        }

        handleSubmitMailTemplateEditConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let id = this.state.mailTemplateData.id;
            let payload = this.state.mailTemplateData.payload;

            Promise.all([
                this.showLoader(),
                this.props.putMailTemplate(id, payload).then(
                    () => {
                        this.handleCloseMailTemplateEditModal();
                        this.loadListFromServer();
                        this.handleShowSuccessAlert(this.props.mailTemplateMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseMailTemplateEditConfirmModal)
        }

    }

}

export {
    withMailTemplateEditConfirm
}
