/*Define all constants*/
/*Numbers*/
export const ZERO = 0;
export const ONE = 1;
export const ZERO_STRING = "0";
export const ONE_STRING = "1";
export const TWO_STRING = "2";

/*Date*/
export const DATE_FORMAT = "YYYY/MM/DD";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATETIME_FORMAT_2 = "YYYY/MM/DD HH:mm";
export const DATETIME_FORMAT_3 = "yyyy/MM/dd";

/*Form Errors*/
export const DOCUMENT_ERROR_PRODUCT = "サービス・製品名を入力してください";
export const DOCUMENT_ERROR_TITLE_1 = "ドキュメント名を入力してください";
export const DOCUMENT_ERROR_TITLE_2 = "最大文字数64文字。使用可能文字はUTF-8表現可能文字。";
export const NEWS_ERROR_TYPE = "種別を入力してください";
export const NEWS_ERROR_PRODUCT = "サービス・製品名を入力してください";
export const NEWS_ERROR_TITLE_1 = "タイトルを入力してください";
export const NEWS_ERROR_TITLE_2 = "最大文字数64文字。使用可能文字はUTF-8表現可能文字。";
export const PRODUCT_ERROR_PRODUCT_NAME = "サービス・製品の略称を入力してください";
export const PRODUCT_ERROR_PRODUCT_LONG_NAME = "サービス・製品名を入力してください";
export const PRODUCT_ERROR_POST_AD_GROUP_1 = "投稿ADグループを入力してください";
export const PRODUCT_ERROR_POST_AD_GROUP_2 = "投稿ADグループ名毎に45文字以内で入力してください";
export const PRODUCT_ERROR_APPROVED_AD_GROUP_1 = "承認ADグループを入力してください";
export const PRODUCT_ERROR_APPROVED_AD_GROUP_2 = "承認ADグループ名毎に45文字以内で入力してください";
export const PRODUCT_ERROR_KINTONE_API_LINK = "Kintone API URLを入力してください";
export const PRODUCT_ERROR_KINTONE_API_ID_1 = "Kintone アプリIDを入力してください";
export const PRODUCT_ERROR_KINTONE_API_ID_2 = "Kintone アプリIDは数字で入力してください";
export const PRODUCT_ERROR_KINTONE_API_TOKEN = "Kintone APIトークンを入力してください";
export const PRODUCT_ERROR_FAQ_LINK = "FAQリンクを入力してください";
export const PRODUCT_ERROR_INQUIRY_LINK = "問合せリンクを入力してください";
export const PRODUCT_ERROR_START_DATE = "サービス開始日を入力してください";
export const PRODUCT_ERROR_END_DATE = "サービス終了日にはサービス開始日以降の日付を設定してください";
export const PRODUCT_ERROR_KINTONE_KEY_FIELD = "取得条件kintoneキーを入力してください";
export const PRODUCT_ERROR_KINTONE_KEY_VALUE_1 = "取得条件値を入力してください";
export const PRODUCT_ERROR_KINTONE_KEY_VALUE_2 = "取得条件値名毎に45文字以内で入力してください";
export const KINTONE_PRODUCT_ERROR_ATTRIBUTE_NAME = "Kintone 属性名を入力してください";
export const KINTONE_PRODUCT_ERROR_DISPLAY_LABEL = "表示ラベルを入力してください";
export const MAIL_SETTINGS_ERROR_EMAIL_HOST_PASSWORD_CONFIRM = "EMAIL_HOST_PASSWORDとEMAIL_HOST_PASSWORD_CONFIRMが同じではありません。もう一度やり直してください";
export const ERROR_INVALID_EMAIL = "有効なメールアドレスを入力してください。";
export const UPDATE_ADMIN_PASSWORD_ERROR_PASSWORD_CONFIRM = "新しいパスワードと再入力パスワードが一致しません。再入力してください。";
export const TEMPLATE_FORM_NAME_BLANK_ERROR = "テンプレート名を入力してください";
export const TEMPLATE_FORM_TITLE_BLANK_ERROR = "タイトルテンプレートを入力してください";
export const TEMPLATE_FORM_INPUT_MAX_64_CHARACTERS = "最大文字数64文字。使用可能文字はUTF-8表現可能文字。";
export const NEW_ERROR_PRODUCT_TEMPLATE = "サービス・製品を選択してください";

/*Form types*/
export const FORM_TYPE_TEXT = "text";
export const FORM_TYPE_PASSWORD = "password";
export const FORM_TYPE_PASSWORD_VIEW = "password-view";
export const FORM_TYPE_SELECT = "select";
export const FORM_TYPE_HIDDEN = "hidden";
export const FORM_TYPE_TEXTAREA = "textarea";
export const FORM_TYPE_RADIO_DATE = "radio-date";
export const FORM_TYPE_DATE = "date";
export const FORM_TYPE_RADIO = "radio";
export const FORM_TYPE_FILES = "files";
export const FORM_TYPE_FILE_CHECKBOX = "file-checkbox";
export const FORM_TYPE_TEXT_REMARKS = "text-remarks";
export const FORM_TYPE_COLOR = "color";
export const FORM_TYPE_IMAGE = "image";
export const FORM_TYPE_CHECKBOX = "checkbox";
export const FORM_TYPE_CHOOSE_TEMPLATE = "choosetemplate";

/*Form labels*/
export const FORM_APPLICANT_LABEL = "申請者";
export const FORM_PUBLISH_DATE_LABEL = "公開日時";
export const FORM_REMARKS_LABEL = "承認者コメント";
export const FORM_PRODUCT_LABEL = "サービス・製品";
export const FORM_EDIT_DATE_LABEL = "編集日時";
export const NEWS_FORM_TYPE_LABEL = "種別";
export const NEWS_FORM_TITLE_LABEL = "タイトル";
export const NEWS_FORM_CONTENT_LABEL = "本文";
export const NEWS_FORM_PUBLISH_TYPE_LABEL = "未ログイン時表示";
export const DOCUMENT_FORM_TITLE_LABEL = "ドキュメント名";
export const DOCUMENT_FORM_CONTENT_LABEL = "説明文";
export const DOCUMENT_FORM_ATTACHMENT_LABEL = "添付ファイル";
export const DOCUMENT_FORM_EXTERNAL_LINK_LABEL = "外部リンク";
export const PRODUCT_FORM_PRODUCT_NAME_LABEL = "略称";
export const PRODUCT_FORM_PRODUCT_LONG_NAME_LABEL = "サービス・製品名";
export const PRODUCT_FORM_BG_COLOR_LABEL = "背景色";
export const PRODUCT_FORM_SERVICE_ICON_LABEL = "サービスアイコン";
export const PRODUCT_FORM_START_DATE_LABEL = "サービス開始日";
export const PRODUCT_FORM_END_DATE_LABEL = "サービス終了日";
export const PRODUCT_FORM_POST_ADGROUP_LABEL = "投稿ADグループ";
export const PRODUCT_FORM_APPROVED_ADGROUP_LABEL = "承認ADグループ";
export const PRODUCT_FORM_KINTONE_API_LINK_LABEL = "Kintone API URL";
export const PRODUCT_FORM_KINTONE_API_ID_LABEL = "Kintone アプリID";
export const PRODUCT_FORM_KINTONE_API_TOKEN_LABEL = "Kintone APIトークン";
export const PRODUCT_FORM_KINTONE_KEY_FIELD_LABEL = "取得条件Kintoneキー";
export const PRODUCT_FORM_KINTONE_KEY_VALUE_LABEL = "取得条件値";
export const PRODUCT_FORM_FAQ_LINK_LABEL = "FAQ URL";
export const PRODUCT_FORM_INQUIRY_LINK_LABEL = "問い合わせURL";
export const KINTONE_FORM_ATTRIBUTE_NAME_LABEL = "Kintone 属性名";
export const KINTONE_FORM_DISPLAY_LABEL = "表⽰ラベル";
export const KINTONE_FORM_SHOW_PUBLIC_LABEL = "顧客画面に表示";
export const KINTONE_FORM_FORMAT_DATA_LABEL="値の形式";
export const MAIL_TEMPLATE_FORM_SUBJECT_LABEL = "件名";
export const MAIL_TEMPLATE_FORM_MESSAGE_LABEL = "メッセージ";
export const MAIL_SETTINGS_FORM_EMAIL_HOST_LABEL = "EMAIL_HOST";
export const MAIL_SETTINGS_FORM_EMAIL_PORT_LABEL = "EMAIL_PORT";
export const MAIL_SETTINGS_FORM_EMAIL_HOST_USER_LABEL = "EMAIL_HOST_USER";
export const MAIL_SETTINGS_FORM_EMAIL_HOST_PASSWORD_LABEL = "EMAIL_HOST_PASSWORD";
export const MAIL_SETTINGS_FORM_EMAIL_HOST_PASSWORD_CONFIRM_LABEL = "EMAIL_HOST_PASSWORD_CONFIRM";
export const MAIL_SETTINGS_FORM_EMAIL_PROTOCOL_LABEL = "EMAIL_PROTOCOL";
export const MAIL_SETTINGS_FORM_EMAIL_TIMEOUT_LABEL = "EMAIL_TIMEOUT";
export const MAIL_SETTINGS_FORM_EMAIL_FROM_LABEL = "EMAIL_FROM";
export const TEST_MAIL_SERVER_FORM_EMAIL_TO_LABEL = "宛先";
export const FORM_EXPORT_ADMIN_LABEL = "文字エンコード";
export const TEMPLATE_FORM_NAME_LABEL = "テンプレート名";
export const TEMPLATE_FORM_NOTE_LABEL = "備考";
export const TEMPLATE_FORM_TITLE_LABEL = "タイトルテンプレート";
export const TEMPLATE_FORM_BODY_LABEL = "本文テンプレート";
export const POST_TEMPLATE = "投稿テンプレート";

/*Form placeholders*/
export const FORM_PLACEHOLDER_REJECT_REMARKS = "申請者へのコメントを1024文字以内で入力してください";
export const FORM_PLACEHOLDER_DROPDOWN_SELECT = "プルダウンリストから選択して下さい";
export const NEWS_FORM_PLACEHOLDER_TITLE = "お知らせタイトルを64文字以内で入力して下さい";
export const NEWS_FORM_PLACEHOLDER_CONTENT = "お知らせ本文を4096文字以内で入力して下さい";
export const DOCUMENT_FORM_PLACEHOLDER_TITLE = "ドキュメント名を64文字以内で入力して下さい";
export const DOCUMENT_FORM_PLACEHOLDER_CONTENT = "説明文を4096文字以内で入力して下さい";
export const DOCUMENT_FORM_PLACEHOLDER_EXTERNAL_LINK = "外部参照リンク（HiQZenなど）を設定してください";
export const PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME = "サービス・製品の略称を入力してください";
export const PRODUCT_FORM_PLACEHOLDER_PRODUCT_LONG_NAME = "サービス・製品名を入力してください";
export const PRODUCT_FORM_PLACEHOLDER_POST_ADGROUP = "投稿ADグループ名を設定して下さい（複数指定時は「,」区切り）";
export const PRODUCT_FORM_PLACEHOLDER_APPROVED_ADGROUP = "承認ADグループ名を設定して下さい（複数指定時は「,」区切り）";
export const PRODUCT_FORM_PLACEHOLDER_KINTONE_API_LINK = "Kintone API URLを設定して下さい";
export const PRODUCT_FORM_PLACEHOLDER_KINTONE_API_ID = "Kintone アプリIDを設定して下さい";
export const PRODUCT_FORM_PLACEHOLDER_KINTONE_API_TOKEN = "Kintone APIトークンを設定して下さい";
export const PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_FIELD = "サービスポータルに連携する条件を判定するkitoneのキーを入力してください";
export const PRODUCT_FORM_PLACEHOLDER_KINTONE_KEY_VALUE = "サービスポータルに連携するkitoneキーの値をカンマ区切りで列挙してください";
export const PRODUCT_FORM_PLACEHOLDER_FAQ_LINK = "FAQ URLを設定して下さい";
export const PRODUCT_FORM_PLACEHOLDER_INQUIRY_LINK = "問い合わせURLを設定して下さい";
export const KINTONE_FORM_PLACEHOLDER_TEXT = "値を⼊⼒してください";
export const MAIL_TEMPLATE_FORM_PLACEHOLDER_SUBJECT = "件名を⼊⼒してください";
export const MAIL_TEMPLATE_FORM_PLACEHOLDER_MESSAGE = "メッセージを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST = "EMAIL_HOSTを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PORT = "EMAIL_PORTを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_USER = "EMAIL_HOST_USERを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD = "EMAIL_HOST_PASSWORDを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD_CONFIRM = "EMAIL_HOST_PASSWORD_CONFIRMを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PROTOCOL = "EMAIL_PROTOCOLを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_TIMEOUT = "EMAIL_TIMEOUTを⼊⼒してください";
export const MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_FROM = "EMAIL_FROMを⼊⼒してください";
export const TEST_MAIL_SERVER_FORM_PLACEHOLDER_EMAIL_TO = "宛先を⼊⼒してください";
export const FORM_PLACEHOLDER_DROPDOWN_SELECT_EXPORT = "文字エンコードを選択してください。";
export const NEW_TEMPLATE_FORM_PLACEHOLDER_NAME = "テンプレート名を64文字以内で入力して下さい";
export const NEW_TEMPLATE_FORM_PLACEHOLDER_NOTE = "備考を64文字以内で入力して下さい";
export const NEW_TEMPLATE_FORM_PLACEHOLDER_TITLE = "タイトルテンプレートを64文字以内で入力して下さい";

/*Form tooltip message*/
export const FORM_PRODUCT_MESSAGE = "投稿の対象となるサービス・製品を選択してください";
export const FORM_TITLE_MESSAGE = "投稿のタイトルを入力してください（最大文字数64文字。超過分の文字は削除されます。使用可能文字はUTF-8表現可能文字）";
export const FORM_CONTENT_MESSAGE = "投稿の本文を入力してください（最大文字数4096文字。超過分の文字は削除されます。使用可能文字はUTF-8表現可能文字）";
export const FORM_PUBLISH_DATE_MESSAGE = "ご希望の投稿日時を入力してください。承認タイミングにより、差異が生じる場合があります";
export const NEWS_FORM_TYPE_MESSAGE = "お知らせ、重要、障害、メンテナンスとプルダウンから選択する";
export const NEWS_FORM_PUBLISH_TYPE_MESSAGE = "未ログイン状態での表示有無を選択してください。「表示しない」の場合、ログイン前の状態では表示されません";
export const DOCUMENT_FORM_ATTACHMENT_MESSAGE = "「添付ファイルを選択」リンクから、アップロードするファイルを選択してください";
export const DOCUMENT_FORM_EXTERNAL_LINK_MESSAGE = "外部参照リンクを設定してください";
export const PRODUCT_FORM_PLACEHOLDER_PRODUCT_NAME_MESSAGE = "<div class='text-left'>サービス・製品の略称を入力してください。<br>略称は以下の表示に使用します。<br>・「おしらせ」の製品名アイコン</div>";
export const PRODUCT_FORM_BG_COLOR_MESSAGE = "背景色を設定してください";
export const PRODUCT_FORM_SERVICE_ICON_MESSAGE = "寸法「120x120」の画像を入力してください";
export const PRODUCT_FORM_START_DATE_MESSAGE = "サービス開始日を設定してください";
export const PRODUCT_FORM_END_DATE_MESSAGE = "<div class='text-left'>サービス終了日を設定してください。<br>サービス終了日をすぎるとユーザーの<br>サービス管理メニューに表示されなくなります。</div>";
export const KINTONE_FORM_ATTRIBUTE_NAME_MESSAGE = "Kintoneからサービスポータルに取り込むフィールドコードを設定してください";
export const KINTONE_FORM_DISPLAY_LABEL_MESSAGE = "サービスポータル上での表示タイトル名を設定してください";
export const KINTONE_FORM_SHOW_PUBLIC_MESSAGE = "顧客画面に表示する場合、チェックを入れてください。";
export const KINTONE_FORM_FORMAT_DATA_MESSAGE = "表形式で表示する場合、「テーブル」にチェックを入れてください。";
export const POST_PUBLISH_ICON_MESSAGE = "公開中";
export const POST_LOGIN_ONLY_ICON_MESSAGE = "ログインしているユーザーのみに表示";
export const UNABLE_TO_PROCESS_RESET_PASSWORD = "正常に処理できませんでした。";
export const POST_TEMPLATE_MESSAGE = "投稿の対象となる投稿テンプレートを選択してください";

/*Form radio options label*/
export const PUBLISH_DATE_IMMEDIATE_LABEL = "承認後即時";
export const PUBLISH_DESIRED_DATE_LABEL = "公開希望日時";
export const PUBLISH_TYPE_INDICATE_LABEL = "表示する";
export const PUBLISH_TYPE_DO_NOT_SHOW_LABEL = "表示しない";
export const DO_NOT_SHOW_IN_CUSTOMER = "しない ";
export const SHOW_IN_CONTRACT_DETAIL = "契約情報に表示";
export const SHOW_IN_OTHER_FEATURE = "その他の機能に表示";
export const FORMAT_DATA_UNIQUE = "単一値";
export const FORMAT_DATA_TABLE = "テーブル";