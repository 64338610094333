import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';


class Error404 extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        if(this.props.isAdmin){
            if(this.props.location.pathname.split("/",2)[1] === "admin")
                if(this.props.isSuperuser || this.props.isPoster){
                    return <Redirect to="/admin/noticepost" />;
                }
                else if(this.props.isApprover){
                    return <Redirect to="/admin/approvalpending" />;
                }
                else if(this.props.isPortalAdmin){
                    return <Redirect to="/admin/portalusers" />;
                }
                else{
                    return <Redirect to="/admin" />;
                }
            else {
                return <Redirect to="/" />;
            }
        }
        else {
            return <Redirect to="/" />;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isSuperuser: state.auth.isSuperuser,
        isPoster: state.auth.isPoster,
        isApprover: state.auth.isApprover,
        isPortalAdmin: state.auth.isPortalAdmin,
    }
};

export default connect(mapStateToProps)(Error404);