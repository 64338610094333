import AdminHomeScreen from "./AdminHomeScreen";

export const ADMIN_HOME_ROUTE = '/admin';

export const routes = [
    {
        label: "管理ホームページ",
        path: ADMIN_HOME_ROUTE,
        component: AdminHomeScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
]