//Dummy json data
export const REST_SERVICE_URL_ROOT = process.env.REACT_APP_REST_SERVICE_URL_ROOT;
//Root Client
export const URL_ROOT = process.env.REACT_APP_URL_ROOT;
//Persisted key 
export const PERSISTED_STATE_KEY = '@_Persistent_Key';
//Client Id
export const CLIENT_ID = 'client';
//Client Secret
export const CLIENT_SECRET = 'secret';
//Root
export const ROOT = '/';
//Mode 'development' or 'production'
export const MODE = 'production';
