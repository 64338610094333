export const READ_STATE = "Portal/READ_STATE";
export const CUSTOMER_INFO_RETRIEVE_STATE = "Portal/CUSTOMER_INFO_RETRIEVE_STATE";
export const PRODUCT_LOOKUP_STATE = "Portal/PRODUCT_LOOKUP_STATE";
export const CATEGORY_LOOKUP_STATE = "Portal/CATEGORY_LOOKUP_STATE";
export const PRODUCT_LIST_STATE = "Portal/PRODUCT_LIST_STATE";
export const CREATE_PRODUCT_STATE = "Portal/CREATE_PRODUCT_STATE"
export const PRODUCT_EDIT_RETRIEVE_STATE = "Portal/PRODUCT_EDIT_RETRIEVE_STATE"
export const PRODUCT_EDIT_UPDATE_STATE = "Portal/PRODUCT_EDIT_UPDATE_STATE"
export const PRODUCT_DELETE_STATE = "Portal/PRODUCT_DELETE_STATE";
export const KINTONE_PRODUCT_FIELD_LIST_STATE = "Portal/KINTONE_PRODUCT_FIELD_LIST_STATE";
export const KINTONE_PRODUCT_FIELD_CREATE_STATE = "Portal/KINTONE_PRODUCT_FIELD_CREATE_STATE";
export const KINTONE_PRODUCT_FIELD_DELETE_STATE = "Portal/KINTONE_PRODUCT_FIELD_DELETE_STATE";
export const KINTONE_PRODUCT_FIELD_PATCH_STATE = "Portal/KINTONE_PRODUCT_FIELD_PATCH_STATE";
export const KINTONE_PRODUCT_SUBTABLE_FIELD_LIST_STATE = "Portal/KINTONE_PRODUCT_SUBTABLE_FIELD_LIST_STATE";
export const KINTONE_PRODUCT_COMMON_FIELD_LIST_STATE = "Portal/KINTONE_PRODUCT_COMMON_FIELD_LIST_STATE";
export const KINTONE_PRODUCT_COMMON_FIELD_CREATE_STATE = "Portal/KINTONE_PRODUCT_COMMON_FIELD_CREATE_STATE";
export const KINTONE_PRODUCT_COMMON_FIELD_DELETE_STATE = "Portal/KINTONE_PRODUCT_COMMON_FIELD_DELETE_STATE";
export const KINTONE_PRODUCT_COMMON_FIELD_PATCH_STATE = "Portal/KINTONE_PRODUCT_COMMON_FIELD_PATCH_STATE";
export const KINTONE_PRODUCT_COMMON_SUB_TABLE_FIELD_LIST_STATE = "Portal/KINTONE_PRODUCT_COMMON_SUB_TABLE_FIELD_LIST_STATE";