import React, { Component } from 'react';
import AdminDocumentTemplate from "../../components/AdminDocument/AdminDocumentTemplate";
import AdminDocumentButtons from "../../components/Pagination/AdminDocumentButtons";
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { connect } from 'react-redux';
import { adminDocumentSelectors } from './state';
import { adminDocumentOperations } from './state';
import { bindActionCreators } from 'redux';
import { withAdminDocumentApplicationConfirmModal } from '../../components/AdminDocument/AdminDocumentApplicationConfirmModal';
import { withAdminDocumentCancelModal } from '../../components/AdminDocument/AdminDocumentCancelModal';
import { withAdminDocumentDeleteModal } from '../../components/AdminDocument/AdminDocumentDeleteModal';
import { withAdminDocumentDetailModal } from '../../components/AdminDocument/AdminDocumentDetailModal';
import { withAdminDocumentDraftConfirmModal } from '../../components/AdminDocument/AdminDocumentDraftConfirmModal';
import { withAdminDocumentDraftModal } from '../../components/AdminDocument/AdminDocumentDraftModal';
import { withAdminDocumentPublishedVersionDetailModal } from '../../components/AdminDocument/AdminDocumentPublishedVersionDetailModal';
import { withAdminDocumentStopPublishModal } from '../../components/AdminDocument/AdminDocumentStopPublishModal';
import { withAdminNewDocumentTemplate } from '../../components/AdminDocument/AdminNewDocumentTemplate';

class AdminDocumentScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            uploaded_files: [],
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getDocumentList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.category_number, this.state.product_number, this.state.sort
            ).then(
                () => {
                    this.setState({
                        rows: this.props.tableList,
                        totalItemsCount: this.props.totalItemsCount
                    });
                }
            ),
            this.props.getDocumentProductList().then(
                () => {
                    this.setState({
                        productLookupList: this.props.productLookupList
                    });
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    handleFormOnchange = (values) => {
        this.setState({ currentFormValues: values.formValues.DocumentForm })
    }

    handleCloseAllModal = () => {
        this.setState({
            showModalNewDocument: false,
            showDiscardModal: false,
            showDocumentDraft: false
        });
        this.removeUploadedFiles()
    }

    handleDocumentFileDownload = (data) => {
        this.props.getDocumentFile(data)
            .catch(this.handleShowErrorAlert)
    }

    setUploadedFiles = (files) => {
        this.setState({
            upload_files: files
        })
    }

    removeUploadedFiles = () => {
        this.setState({
            uploaded_files: []
        })
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="admin_document_template">
                        <h4 className="mr-auto page-heading">{"ドキュメント管理  ＞ドキュメント一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            <AdminDocumentButtons handleShowModalNewDocument={this.handleShowModalNewDocument} />
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <AdminDocumentTemplate {...this.state}
                            handleShowModalDetail={this.handleShowModalDetail}
                            handleShowModalPublishedVersionDetail={this.handleShowModalPublishedVersionDetail}
                            handleShowDraftModal={this.handleShowDraftModal}
                            handleShowStopPublishModal={this.handleShowStopPublishModal}
                            handleShowCancelModal={this.handleShowCancelModal} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.adminDocumentApplicationConfirmModal({ ...this.state })}
                    {this.adminDocumentCancelModal({ ...this.state })}
                    {this.adminDocumentDeleteModal({ ...this.state })}
                    {this.adminDocumentDetailModal({ ...this.state, ...this.props })}
                    {this.adminDocumentDraftConfirmModal({ ...this.state })}
                    {this.adminDocumentDraftModal({ ...this.state, ...this.props, adminDocumentSelectors })}
                    {this.adminDocumentPublishedVersionDetailModal({ ...this.state, ...this.props})}
                    {this.adminDocumentStopPublishModal({ ...this.state })}
                    {this.adminNewDocumentTemplate({ ...this.state, ...this.props, adminDocumentSelectors })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        tableList: state.adminDocument.tableList,
        totalItemsCount: state.adminDocument.totalItemsCount,
        productLookupList: state.adminDocument.productLookupList,
        documentDetails: state.adminDocument.documentDetails,
        formInputs: state.adminDocument.documentForm,
        radioInput: state.adminDocument.radioOptions,
        documentEditDetails: state.adminDocument.documentEditDetails,
        documentPublishedVersionDetails: state.adminDocument.documentPublishedVersionDetails,
        documentCreateDetails: state.adminDocument.documentCreateDetails,
        documentHistoryDetails: state.adminDocument.documentHistoryDetails,
        documentHistoryPostForm: state.adminDocument.documentHistoryPostForm,
        documentDetailPostForm: state.adminDocument.documentDetailPostForm,
        documentMessages: state.adminDocument.documentMessages,
        listOrdering: state.adminDocument.documentOrdering,
        defaultOrdering: state.adminDocument.defaultDocumentOrdering
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getDocumentList: adminDocumentOperations.getDocumentList,
        getDocumentProductList: adminDocumentOperations.getDocumentProductList,
        getDocumentDetails: adminDocumentOperations.getDocumentDetails,
        getDocumentPublishedVersionDetails: adminDocumentOperations.getDocumentPublishedVersionDetails,
        getDocumentEditDetails: adminDocumentOperations.getDocumentEditDetails,
        postDocumentCreateDetails: adminDocumentOperations.postDocumentCreateDetails,
        putDocumentEditDetails: adminDocumentOperations.putDocumentEditDetails,
        patchDocumentUnpublish: adminDocumentOperations.patchDocumentUnpublish,
        patchDocumentDelete: adminDocumentOperations.patchDocumentDelete,
        patchDocumentCancel: adminDocumentOperations.patchDocumentCancel,
        getDocumentHistoryDetails: adminDocumentOperations.getDocumentHistoryDetails,
        getDocumentFile: adminDocumentOperations.getDocumentFile
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withAdminDocumentApplicationConfirmModal(
                            withAdminDocumentCancelModal(
                                withAdminDocumentDeleteModal(
                                    withAdminDocumentDetailModal(
                                        withAdminDocumentDraftConfirmModal(
                                            withAdminDocumentDraftModal(
                                                withAdminDocumentPublishedVersionDetailModal(
                                                    withAdminDocumentStopPublishModal(
                                                        withAdminNewDocumentTemplate(AdminDocumentScreen)
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);