import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withNewProductConfirmationModal = (WrappedComponent) => {
    return class NewProductConfirmationModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNewProductConfirmModal: false
            }
        }

        newProductConfirmationModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNewProductConfirmModal} onHide={this.handleCloseNewProductConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品情報登録確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            製品情報を登録します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseNewProductConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleCloseNewProductConfirmModal = () => {
            this.setState({
                showNewProductConfirmModal: false
            });
        }
    }
}

export {
    withNewProductConfirmationModal
}