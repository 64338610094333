export const APPROVAL = "api/admin/application/list/"
export const APPROVAL_HISTORY = "api/admin/applicationhistory/list/"
export const PRODUCTLOOKUP = "api/productlookup/"
export const CATEGORYLOOKUP = "api/postcategorylookup/"
export const NOTICE_APPROVAL_DETAILS = "api/admin/application/news/"
export const NOTICE_APPROVAL_HISTORY_DETAILS = "api/admin/news/history/"
export const PATCH_APPROVAL = "api/admin/application/"
export const DOCUMENT_APPROVAL_DETAILS = "api/admin/application/document/"
export const DOCUMENT_APPROVAL_HISTORY_DETAILS = "api/admin/document/history/"
export const DOCUMENT_DOWNLOAD = "/api/document/file/"
export const DOCUMENT_UPDATE = "/api/admin/document/edit/";
export const NOTICE_UPDATE = "/api/admin/news/edit/";
