// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPaging } from '../../components/Pagination/Paging';
import { withErrorAlert } from '../../components/ErrorAlert';
import { adminAccountManagementOperations } from './state';
import CustomerInformationTemplate from '../../components/AdminAccountManagement/CustomerInformationTemplate';
import { withLoginCheck } from '../../components/LoginCheck';
import { withCustomerInformationDetailsModal } from '../../components/AdminAccountManagement/CustomerInformationDetailsModal';
import { withSubTableDataModal } from '../../components/SubTableDataModal';
import { withSubListTableDataModal } from '../../components/SubListTableDataModal';

class CustomerInformationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            customerInfoRows: []
        };
    }

    componentDidMount() {
        this.setState({ 
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getCustomerInfoList(
                this.state.itemsCountPerPage, this.state.activePage,
                this.state.query, this.state.product_number, this.state.sort
            ).then(
                () => {
                    this.setState({
                        rows: this.props.customerTableList,
                        totalItemsCount: this.props.totalItemsCount
                    })
                }
            ),
            this.props.getPortalProductList().then(
                () => {
                    this.setState({
                        productLookupList: this.props.productLookupList
                    })
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="customer_info_template">
                        <h4 className="mr-auto page-heading">{"アカウント管理 ＞  顧客情報一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <CustomerInformationTemplate {...this.state}
                            handleShowCustomerInformationDetails={this.handleShowCustomerInformationDetails} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.customerInformationDetailsModal({ ...this.state })}
                    {this.subTableDataModal({ ...this.state })}
                    {this.subListTableDataModal({ ...this.state })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customerTableList: state.adminAccountManagement.customerTableList,
        totalItemsCount: state.adminAccountManagement.totalItemsCount,
        customerInfoDetails: state.adminAccountManagement.customerInfoDetails,
        productLookupList: state.adminAccountManagement.productLookupList,
        listOrdering: state.adminAccountManagement.customerOrdering,
        defaultOrdering: state.adminAccountManagement.defaultCustomerOrdering,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        isAdmin: state.auth.isAdmin,
        adminKintoneProductSubTableData: state.adminAccountManagement.adminKintoneProductSubTableData,
        adminKintoneCommonSubTableData: state.adminAccountManagement.adminKintoneCommonSubTableData
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCustomerInfoList: adminAccountManagementOperations.getCustomerInfoList,
        getCustomerInformationDetails: adminAccountManagementOperations.getCustomerInformationDetails,
        getPortalProductList: adminAccountManagementOperations.getPortalProductList,
        getAdminKintoneProductSubTableData: adminAccountManagementOperations.getAdminKintoneProductSubTableData,
        getAdminKintoneCommonSubTableData: adminAccountManagementOperations.getAdminKintoneCommonSubTableData
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withErrorAlert(
            withLoginCheck(
                withSubTableDataModal(
                    withSubListTableDataModal(
                        withCustomerInformationDetailsModal(
                            CustomerInformationScreen
                        )
                    )
                )
            )
        )
    )
);