import UserAccessLoggingScreen from "./UserAccessLoggingScreen";

export const CUSTOMER_ACCESS_LOG_ROUTE = '/customeraccesslog';

export const routes = [
    {
        label: "アクセスログ", //define label to be displayed
        path: CUSTOMER_ACCESS_LOG_ROUTE,
        component: UserAccessLoggingScreen,
        exact: true,
        showNavPrivate: true, //if true, displays the label in the mainHeader.js component if authenticated
    },
]