import React from 'react'

const AdminMailSettingsButton = (props) => {

    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value="メール設定更新" onClick={props.handleShowMailSettingsEditModal}>メール設定更新</button>
                <button className="btn btn-secondary" value="テストメール送信" onClick={props.handleShowMailSettingsTestServerModal}>テストメール送信</button>
            </div>
        </div>
    );
}

export default AdminMailSettingsButton