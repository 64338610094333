import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate';
import * as Commons from '../Commons';

const withAdminApprovalEditDocumentModal = (WrappedComponent) => {
    return class AdminApprovalEditDocumentModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showApprovalEditDocument: false
            }
        }

        adminApprovalEditDocumentModal = (props) => {
            let areAllDefault = props.currentDocumentFormValues && props.initialDocumentFormValue1 ?
                props.currentDocumentFormValues.attachment === props.initialDocumentFormValue1.DocumentForm.attachment &&
                props.currentDocumentFormValues.title === props.initialDocumentFormValue1.DocumentForm.title &&
                props.currentDocumentFormValues.content === props.initialDocumentFormValue1.DocumentForm.content &&
                props.currentDocumentFormValues.external_link === props.initialDocumentFormValue1.DocumentForm.external_link &&
                props.currentDocumentFormValues.publish_date.toString() === props.initialDocumentFormValue1.DocumentForm.publish_date.toString() &&
                props.currentDocumentFormValues.publish_type.toString() === props.initialDocumentFormValue1.DocumentForm.publish_type.toString() ? true : false : true

            let { formInputs } = props;

            // eslint-disable-next-line array-callback-return
            formInputs.form.map((item) => {
                if (item.name === "product" && item.type === "select") {
                    item.type = "textarea";
                    item.disabled = true;
                }
            });

            return (
                <Modal show={this.state.showApprovalEditDocument} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseApprovalEditDocumentModal : this.handleShowDiscardModal} className="modal-edit-document" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント修正</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={formInputs}
                            radioInput={props.radioInput}
                            selectInput={props.productLookupList}
                            initialValues={props.initialDocumentFormValue1}
                            files={props.documentEditDetails.files}
                            uploaded_files={props.uploaded_files}
                            publishDate={props.documentEditDetails.publish_preferred_date === null ? "0" : "1"}
                            publishPreferredDate={Commons.getClientDatetimeDisplay(props.documentEditDetails.publish_preferred_date)}
                            editMode={true}
                            form={"admin-edit-document-from-approval"}
                            validate={props.adminApprovalSelectors.checkErrors}
                            setUploadedFiles={this.setUploadedFiles}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowErrorAlert={this.handleShowErrorAlert}
                            showApprovalEditDocument={this.state.showApprovalEditDocument}
                            handleSubmitApprovalEditDocumentModal={this.handleSubmitApprovalEditDocumentModal}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 admin-draft-modal-footer">
                            <div className="w-100 d-inline-flex">
                                <input type="hidden" name="submit_action" />
                                <button type="button" form="admin-edit-document-from-approval" className="btn btn-90 mr-2 ml-auto btn-success" onClick={() => {
                                    document.querySelector('.modal.show .submitApprovalEditDocument').click()
                                }}>承認</button>
                                <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseApprovalEditDocumentModal : this.handleShowDiscardModal}>キャンセル</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowApprovalEditDocumentModal = (value) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentEditDetails(value, this.state.showEditDocumentModalFromApprovalModal).then(
                    () => {
                        var url = null;
                        for (var i = 0; i < this.props.documentEditDetails.files.length; i++) {
                            if (this.props.documentEditDetails.files[i].file_name === null) {
                                url = this.props.documentEditDetails.files[i].url;
                                break;
                            }
                        }
                        this.setState({
                            initialDocumentFormValue1: {
                                DocumentForm: {
                                    post_id: this.props.documentEditDetails.post_id,
                                    product: this.props.documentEditDetails.product_name,
                                    title: this.props.documentEditDetails.title,
                                    content: this.props.documentEditDetails.content,
                                    attachment: this.props.documentEditDetails.files,
                                    external_link: url ? url : "",
                                    publish_date: this.props.documentEditDetails.publish_preferred_date === null ? "0" : "1",
                                    publish_preferred_date: this.props.documentEditDetails.publish_preferred_date,
                                    publish_type: 1,
                                    remarks: this.props.documentEditDetails.remarks ? this.props.documentEditDetails.remarks : "",
                                }
                            },
                            showApprovalEditDocument: true
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseApprovalEditDocumentModal = () => {
            this.setState({
                showApprovalEditDocument: false
            });
            this.removeUploadedFiles()
        }

        handleSubmitApprovalEditDocumentModal = (values) => {
            let id = this.props.documentApprovalDetails.application_id;
            let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;

            let formattedValue = {
                files: values.files,
                content: values.content,
                publish_preferred_date: preferred_date,
                title: values.title,
                publish_type: 1,
                url: values.external_link
            }

            let toRemoveFiles = [];
            let formData = new FormData();
            let formKeys = Object.keys(formattedValue);

            for (let a = 0; a < values.delete_file_checkboxes.length; a++) {
                var fileString = values.delete_file_checkboxes.item(a).id.split(/[\s-]+/);
                var fileId = fileString[fileString.length - 1];
                for (let i = 0; i < this.props.documentEditDetails.files.length; i++) {
                    if ("delete-file-" + this.props.documentEditDetails.files[i].id === values.delete_file_checkboxes.item(a).id) {
                        formData.append("deleted_files", Number(fileId))
                    }
                }
                toRemoveFiles.push(values.delete_file_checkboxes.item(a).id)
            }

            formKeys.map((key) => {
                if (key === "files") {
                    let files = formattedValue[key];
                    if (files && files.length) {
                        for (let i = 0; i < files.length; i++) {
                            if (toRemoveFiles.indexOf("delete-file-" + i) === -1) {
                                formData.append("files", files[i]);
                            }
                        }
                    }
                } else {
                    if (key === "publish_preferred_date" && !formattedValue[key]) {
                        formData.append(key, "");
                    } else {
                        formData.append(key, formattedValue[key]);
                    }
                }
                return true
            });

            formData.append("application_status", 1);
            formData.append("isEditApproval", true);

            Promise.all([
                this.showLoader(),
                this.props.patchApprovalDetails(id, formData).then(
                    () => {
                        this.loadListFromServer();
                        this.handleCloseNoticeApprovalModal();
                        this.handleCloseDocumentApprovalModal();
                        this.handleShowSuccessAlert(this.props.approvalMessages);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.handleCloseApprovalEditDocumentModal)
            .then(this.closeLoader)
        }
    }
}

export {
    withAdminApprovalEditDocumentModal
}