import AdminLoginScreen from './AdminLoginScreen'

export const ADMIN_LOGIN_ROUTE = "/admin/login";

export const LOGIN_LABEL = "ログイン";

export const routes = [
    {
        label: LOGIN_LABEL,
        path: ADMIN_LOGIN_ROUTE,
        component: AdminLoginScreen,
        exact: true,
        public: true,
    }
];