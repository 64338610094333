import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import checkUtil from '../../../utils/checkUtil'

const ControlPanelTemplate = (props) => {
    let { data, isAdmin, isSuperuser, handleShowOtherFeature, all_contracts_expired, handleShowKintoneDetails, handleShowDocumentListModal } = props

    return (
        <div className="services_container row">
            {isAdmin || isSuperuser ? <h4 className="ml-3">管理者として、顧客側のサービス管理を表示することはできません。</h4>
                    : props.totalItemsCount > 0 ?
                    data.map((service, idx) => {
                        var default_image = require(`../../../includes/img/default_image.PNG`).default;
                        return (
                            <div className="cp-card mb-3 col-md-6 col-sm-12" key={`${idx}-${service.support_id}`}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    service.product.control_panel_url && checkUtil.checkUrl(service.product.control_panel_url) ?
                                                        <a href={service.product.control_panel_url} target="_blank" rel="noopener noreferrer">
                                                            <img id={"service_image_" + service.support_id + idx}
                                                                src={service.product.image ? service.product.image : default_image}
                                                                className="cp-img"
                                                                alt={service.product.image ? service.product.name : "Image not found"} />
                                                        </a>
                                                        :
                                                        <img id={"service_image_" + service.support_id + idx}
                                                            src={service.product.image ? service.product.image : default_image}
                                                            className="cp-img"
                                                            alt={service.product.image ? service.product.name : "Image not found"} />
                                                }

                                            </td>
                                            <td className="px-3 w-100">
                                                {
                                                    service.product.control_panel_url && checkUtil.checkUrl(service.product.control_panel_url) ?
                                                        <>
                                                            <h5 className="d-block">
                                                                <a className="text-dark"
                                                                    href={service.product.control_panel_url}
                                                                    target="_blank" rel="noopener noreferrer"
                                                                    title={`${service.product.name} (${service.support_id})`}>
                                                                    <b>
                                                                        {service.product.long_name}
                                                                        <br />
                                                                        {`(${service.support_id})`}
                                                                    </b>
                                                                </a>
                                                            </h5>
                                                            <a href={service.product.control_panel_url}
                                                                target="_blank" rel="noopener noreferrer">
                                                                <FontAwesomeIcon icon={faCaretRight} /> 管理ページ
                                                            </a>
                                                            <br />
                                                        </>
                                                        :
                                                        <>
                                                            <h5 className="d-block">
                                                                <label className="text-dark" title={`${service.product.name} (${service.support_id})`}>
                                                                    <b>
                                                                        {service.product.long_name}
                                                                        <br />
                                                                        {`(${service.support_id})`}
                                                                    </b>
                                                                </label>
                                                            </h5>
                                                            <label className="mb-0">
                                                                <FontAwesomeIcon icon={faCaretRight} /> 管理ページ
                                                            </label>
                                                            <br />
                                                        </>
                                                }
                                                <a href={service.product.okwave_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faCaretRight} /> FAQ
                                                </a>
                                                <br />
                                                <a href="# " onClick={() => { handleShowDocumentListModal(service.product.id, service.product.name) }}>
                                                    <FontAwesomeIcon icon={faCaretRight} /> ドキュメント/モジュール
                                                </a>
                                                <br />
                                                <a href="# " onClick={event=>{handleShowOtherFeature(service.contract_id)}}>
                                                    <FontAwesomeIcon icon={faCaretRight} /> その他の機能
                                                </a>
                                                <br/>
                                                <a href="# " onClick={event=>{handleShowKintoneDetails(service.contract_id)}}>
                                                    <FontAwesomeIcon icon={faCaretRight} /> 契約情報
                                                </a>
                                                <br />
                                                <a href={service.product.inquire_url}
                                                    target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faCaretRight} /> 問い合わせ
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                    : !all_contracts_expired && <h4 className="ml-3">契約しているサービスはありません。</h4>
            }
        </div>
    );
}

export default ControlPanelTemplate;