import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminAccessLogResponseModal = (WrappedComponent) => {
    return class AdminAccessLogResponseModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showResponseModal: false
            }
        }

        adminAccessLogResponseModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showResponseModal} onHide={this.handleCloseResponseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>レスポンス情報</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            {props.responseDetails ? <pre>{JSON.stringify(JSON.parse(props.responseDetails), null, 2)}</pre> : <center>空の応答</center>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseResponseModal}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowResponseModal = (response) => {
            this.setState({
                showResponseModal: true,
                responseDetails: response
            })
        }

        handleCloseResponseModal = () => {
            this.setState({
                showResponseModal: false,
                responseDetails: null
            })
        }
    }
}

export {
    withAdminAccessLogResponseModal
}