import * as CONSTANTS from '../../../../utils/constants';

function productCheckErrors(state) {
    let errors = {};

    let product_name = state.PortalProductForm.product_name.trim();
    let product_long_name = state.PortalProductForm.product_long_name.trim();
    let post_ad_group = state.PortalProductForm.post_ad_group.trim();
    let approved_ad_group = state.PortalProductForm.approved_ad_group.trim();
    let kintone_api_link = state.PortalProductForm.kintone_api_link.trim();
    let kintone_api_id = state.PortalProductForm.kintone_api_id;
    let kintone_api_token = state.PortalProductForm.kintone_api_token.trim();
    let faq_link = state.PortalProductForm.faq_link.trim();
    let inquiry_link = state.PortalProductForm.inquiry_link.trim();
    let start_date = state.PortalProductForm.start_date;
    let end_date = state.PortalProductForm.end_date;
    let post_ad_group_exceed = false;
    let approved_ad_group_exceed = false;
    let kintone_key_field = state.PortalProductForm.kintone_key_field.trim();
    let kintone_key_value = state.PortalProductForm.kintone_key_value.trim();
    let kintone_key_value_exceed = false;

    post_ad_group.split(',').map(post => {
        if (post.length > 45) {
            post_ad_group_exceed = true;
        }
        return true;
    });

    approved_ad_group.split(',').map(approved => {
        if (approved.length > 45) {
            approved_ad_group_exceed = true;
        }
        return true;
    });

    kintone_key_value.split(',').map(kintoneKeyValue => {
        if (kintoneKeyValue.length > 45) {
            kintone_key_value_exceed = true;
        }
        return true;
    });

    if (product_name === "") {
        errors['PortalProductForm.product_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_NAME;
    }
    if (product_long_name === "") {
        errors['PortalProductForm.product_long_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_LONG_NAME;
    }
    if (post_ad_group === "") {
        errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_1;
    }
    if (post_ad_group_exceed) {
        errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_2;
    }
    if (approved_ad_group === "") {
        errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_1;
    }
    if (approved_ad_group_exceed) {
        errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_2;
    }
    if (kintone_api_link === "") {
        errors['PortalProductForm.kintone_api_link'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_LINK;
    }
    if (kintone_api_id === "") {
        errors['PortalProductForm.kintone_api_id'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_ID_1;
    }
    if (!/^[0-9]*$/gm.test(kintone_api_id)) {
        errors['PortalProductForm.kintone_api_id'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_ID_2;
    }
    if (kintone_api_token === "") {
        errors['PortalProductForm.kintone_api_token'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_TOKEN;
    }
    if (kintone_key_field === "") {
        errors['PortalProductForm.kintone_key_field'] = CONSTANTS.PRODUCT_ERROR_KINTONE_KEY_FIELD;
    }
    if (kintone_key_value === "") {
        errors['PortalProductForm.kintone_key_value'] = CONSTANTS.PRODUCT_ERROR_KINTONE_KEY_VALUE_1;
    }
    if (kintone_key_value_exceed) {
        errors['PortalProductForm.kintone_key_value'] = CONSTANTS.PRODUCT_ERROR_KINTONE_KEY_VALUE_2;
    }
    if (faq_link === "") {
        errors['PortalProductForm.faq_link'] = CONSTANTS.PRODUCT_ERROR_FAQ_LINK;
    }
    if (inquiry_link === "") {
        errors['PortalProductForm.inquiry_link'] = CONSTANTS.PRODUCT_ERROR_INQUIRY_LINK;
    }
    if (start_date === "") {
        errors['PortalProductForm.start_date'] = CONSTANTS.PRODUCT_ERROR_START_DATE;
    }
    if (end_date) {
        if (start_date > end_date) {
            errors['PortalProductForm.end_date'] = CONSTANTS.PRODUCT_ERROR_END_DATE;
        }
    }

    return errors;
}

function servicePortalProductCheckErrors(state) {
    let errors = {};

    let product_name = state.PortalProductForm.product_name.trim();
    let product_long_name = state.PortalProductForm.product_long_name.trim();
    let post_ad_group = state.PortalProductForm.post_ad_group.trim();
    let approved_ad_group = state.PortalProductForm.approved_ad_group.trim();
    let post_ad_group_exceed = false;
    let approved_ad_group_exceed = false;

    post_ad_group.split(',').map(post => {
        if (post.length > 45) {
            post_ad_group_exceed = true;
        }
        return true;
    });

    approved_ad_group.split(',').map(approved => {
        if (approved.length > 45) {
            approved_ad_group_exceed = true;
        }
        return true;
    });

    if (product_name === "") {
        errors['PortalProductForm.product_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_NAME;
    }
    if (product_long_name === "") {
        errors['PortalProductForm.product_long_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_LONG_NAME;
    }
    if (post_ad_group === "") {
        errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_1;
    }
    if (post_ad_group_exceed) {
        errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_2;
    }
    if (approved_ad_group === "") {
        errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_1;
    }
    if (approved_ad_group_exceed) {
        errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_2;
    }

    return errors;
}

function kintoneCheckErrors(state) {
    let errors = {};

    let kintone_attribute_name = state.KintoneProductForm.kintone_attribute_name.trim();
    let display_label = state.KintoneProductForm.display_label.trim();

    if (kintone_attribute_name === "") {
        errors['KintoneProductForm.kintone_attribute_name'] = CONSTANTS.KINTONE_PRODUCT_ERROR_ATTRIBUTE_NAME;
    }
    if (display_label === "") {
        errors['KintoneProductForm.display_label'] = CONSTANTS.KINTONE_PRODUCT_ERROR_DISPLAY_LABEL;
    }

    return errors;
}

export {
    productCheckErrors,
    servicePortalProductCheckErrors,
    kintoneCheckErrors,
};
