// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { adminEmailManagementOperations } from './state';
import MailPendingListTemplate from '../../components/AdminEmailManagement/MailPendingListTemplate';
import { withMailPendingDetailsModal } from '../../components/AdminEmailManagement/MailPendingDetailsModal';

class MailPendingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            mailPendingDetails: null,
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getMailPendingList(
                this.state.itemsCountPerPage, this.state.activePage,
                this.state.filter_value, this.state.sort
            ).then(() => {
                this.setState({
                    rows: this.props.mailPendingList,
                    totalItemsCount: this.props.totalItemsCount,
                    filterList: this.props.filterPendingList
                })
            })
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="list_of_admin_template">
                        <h4 className="mr-auto page-heading">{"メール管理 ＞ メール送信予定一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filter()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <MailPendingListTemplate
                            {...this.state}
                            handleShowMailPendingDetailsModal={this.handleShowMailPendingDetailsModal} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.mailPendingDetailsModal({ ...this.state })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        mailPendingList: state.adminEmailManagement.mailPendingList,
        totalItemsCount: state.adminEmailManagement.totalItemsCount,
        listOrdering: state.adminEmailManagement.mailPendingOrdering,
        defaultOrdering: state.adminEmailManagement.defaultMailPendingOrdering,
        filterPendingList: state.adminEmailManagement.filterPendingList
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMailPendingList: adminEmailManagementOperations.getMailPendingList,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withSuccessAlert(
                withErrorAlert(
                    withMailPendingDetailsModal(
                        MailPendingScreen
                    )
                )
            )
        )
    )
);