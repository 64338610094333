import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate';
import * as Commons from '../Commons';

const withAdminExportModal = (WrappedComponent) => {
    return class AdminExportModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalExportAdmin: false,
                currentFormValues: {},
            }
        }

        adminExportModal = (props) => {
            return (
                <Modal size="md" className="modal-test-server-mail-settings modal-dialog-md" keyboard={false} show={this.state.showModalExportAdmin}
                    onHide={ this.handleCloseExportAdminModal } centered>
                    <Modal.Header closeButton>
                        <Modal.Title>エクスポート</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            form={"export-admin"}
                            isExportAdminForm={this.state.showModalExportAdmin}
                            validate={() => {return []}}
                            formInputs={props.exportAdminForm}
                            selectInput={props.selectOptionFormat}
                            handleSubmitModalExportAdmin={this.handleSubmitModalExportAdmin}
                            handleExportAdminDownload={this.handleExportAdminDownload}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <input type="hidden" name="submit_action" />
                        <button type="button" form="export-admin" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                            document.querySelector('.modal.show .portalSubmitButton').click()
                            document.querySelector('.modal.show input[name=submit_action]').value = "handleExportAdminDownload"
                        }}>エクスポート</button>
                        <button className="btn btn-90 btn-secondary" onClick={ this.handleCloseExportAdminModal }>キャンセル</button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowModalExportAdmin = (event) => {
            event.target.blur();
            this.setState({ showModalExportAdmin: true });
        }

        handleCloseExportAdminModal = () => {
            this.setState({
                showModalExportAdmin: false
            });
        }

        handleSubmitModalExportAdmin = (values) => {
            this.setState({
                encodeData: values
            })
        }

        handleExportAdminDownload = () => {
            let encodeData = this.state.encodeData.FILE_FORMAT;
            let timezone = Commons.getClientTimezone();
            Promise.all([
                this.props.exportAdmin(encodeData, timezone, this.state.product_number ,this.state.sort).then(
                    () => {
                        this.setState({
                            showModalExportAdmin: false
                        })
                    }
                ),
            ]).catch(this.handleShowErrorAlert);
        }

        handleFormOnchange = (values) => {
            if (values.formValues.ExportAdminPostForm) {
                this.setState({
                    currentFormValues: values.formValues.ExportAdminPostForm
                });
            }
        }
    }
}

export {
    withAdminExportModal
}
