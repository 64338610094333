import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate'

const withProductKintoneEditLabelModal = (WrappedComponent) => {
    return class ProductKintoneEditLabelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductKintoneEditLabel: false,
            }
        }

        productKintoneEditLabelModal = (props) => {
            var areAllDefault = props.currentKintoneFormValues && props.initialKintoneFormValue ? props.currentKintoneFormValues.display_label === props.initialKintoneFormValue.KintoneProductForm.display_label &&
                props.currentKintoneFormValues.show_customer === props.initialKintoneFormValue.KintoneProductForm.show_customer &&
                props.currentKintoneFormValues.format_data === props.initialKintoneFormValue.KintoneProductForm.format_data ? true : false : true

                let {submitSubKintone, parentShowCustomer, radioInput} = props;
                if (submitSubKintone) {
                    switch(parentShowCustomer) {
                        case 0:
                            radioInput.forEach(item => {
                                if (item.name === 'show_customer' && (item.value === "1" || item.value === "2")) {
                                    item.disabled = true;
                                }
                            });
                            break;
                        case 1:
                            radioInput.forEach(item => {
                                if (item.name === 'show_customer' && item.value === "2") {
                                    item.disabled = true;
                                }
                            });
                            break;
                        case 2:
                            radioInput.forEach(item => {
                                if (item.name === 'show_customer' && item.value === "1") {
                                    item.disabled = true;
                                }
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    radioInput.forEach(item => {
                        if (item.name === 'show_customer') {
                            item.disabled = false;
                        }
                    });
                }

            return (
                <Modal keyboard={false} size="lg" show={this.state.showProductKintoneEditLabel} onHide={areAllDefault ? this.handleCloseProductKintoneEditLabelModal : this.handleShowDiscardModal} className="modal-new-kintone" centered>
                    <Modal.Header closeButton>
                        {/* Attribute settings> Edit */}
                        <Modal.Title>{"属性設定 > 編集"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.kintoneFormInputs}
                            radioInput={radioInput}
                            initialValues={props.initialKintoneFormValue}
                            validate={props.adminProductManagementSelectors.kintoneCheckErrors}
                            form={"admin-edit-kintone"}
                            handleShowProductKintoneConfirmEditLabelModal={this.handleShowProductKintoneConfirmEditLabelModal}
                            handleSubmitModalProductKintoneEdit={this.handleSubmitModalProductKintoneEdit}
                            editKintoneMode={true}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-edit-kintone" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowProductKintoneConfirmEditLabelModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseProductKintoneEditLabelModal : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleCloseProductKintoneEditLabelModal = () => {
            this.setState({
                showProductKintoneEditLabel: false
            });
        }

        handleShowProductKintoneConfirmEditLabelModal = () => {
            this.setState({
                showProductKintoneConfirmEditLabelModal: true
            });
        }

        handleSubmitModalProductKintoneEdit = (values) => {
            let formattedValue = {
                product_id: this.state.kintoneProductId,
                field_label: values.display_label,
                is_table: values.format_data,
                show_customer: values.show_customer
            }

            if (this.state.submitSubKintone) {
                formattedValue.parent_id = this.state.parentId;
            }

            this.setState({
                kintoneData: {
                    payload: formattedValue
                }
            });
        }
    }
}

export {
    withProductKintoneEditLabelModal
}