import { connect } from 'formik';
import noop from 'lodash/noop';
import { useEffect } from 'react';

const FormikEffect = ({ onChange = noop, formik }) => {
    const { values } = formik;
    
    useEffect(() => {
        onChange({ formValues: values, formik });
        // eslint-disable-next-line
    }, [values]);

    return null;
};

export default connect(FormikEffect);