import AdminApprovalScreen from "./AdminApprovalScreen";
import Error404 from "../Errors/404";
import AdminApprovalHistoryScreen from "./AdminApprovalHistoryScreen";
export const APPROVAL_ROUTE = '/admin/approval';
export const APPROVAL_PENDING_ROUTE = '/admin/approvalpending';
export const APPROVAL_HISTORY_ROUTE = '/admin/approvalhistory';

export const routes = [
    {
        label: "承認管理",
        path: APPROVAL_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        isApprover: true
    },
    {
        label: "承認待ち",
        path: APPROVAL_PENDING_ROUTE,
        component: AdminApprovalScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false,
        isApprover: true
    },
    {
        label: "承認履歴",
        path: APPROVAL_HISTORY_ROUTE,
        component: AdminApprovalHistoryScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false,
        isApprover: true
    },
]