import {NEWS_ERROR_TYPE, NEWS_ERROR_PRODUCT, NEWS_ERROR_TITLE_1, NEWS_ERROR_TITLE_2,
    TEMPLATE_FORM_NAME_BLANK_ERROR, TEMPLATE_FORM_TITLE_BLANK_ERROR, TEMPLATE_FORM_INPUT_MAX_64_CHARACTERS} from '../../../../utils/constants';

function checkErrors(state) {
    let errors = {};

    let type = state.PostForm.type;
    let product = state.PostForm.product;
    let title = state.PostForm.title.trim();

    if (type === "") {
        errors['PostForm.type'] = NEWS_ERROR_TYPE;
    }
    if (product === "") {
        errors['PostForm.product'] = NEWS_ERROR_PRODUCT;
    }
    if (title === "") {
        errors['PostForm.title'] = NEWS_ERROR_TITLE_1;
    }
    else if (!/^.{1,64}$/i.test(title)) {
        errors['PostForm.title'] = NEWS_ERROR_TITLE_2;
    }

    return errors;
}

function checkErrorsTemplateForm(state) {
    let errors = {};

    let product = state.TemplateForm.product;
    let name = state.TemplateForm.name.trim();
    let title = state.TemplateForm.title.trim();

    if (product === "") {
        errors['TemplateForm.product'] = NEWS_ERROR_PRODUCT;
    }
    if (name === "") {
        errors['TemplateForm.name'] = TEMPLATE_FORM_NAME_BLANK_ERROR;
    }  else if (!/^.{1,64}$/i.test(name)) {
        errors['TemplateForm.name'] = TEMPLATE_FORM_INPUT_MAX_64_CHARACTERS;
    }
    if (title === "") {
        errors['TemplateForm.title'] = TEMPLATE_FORM_TITLE_BLANK_ERROR;
    } else if (!/^.{1,64}$/i.test(title)) {
        errors['TemplateForm.title'] = TEMPLATE_FORM_INPUT_MAX_64_CHARACTERS;
    }

    return errors;
}

export {
    checkErrors,
    checkErrorsTemplateForm
};
