import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    mailTemplateForm: {
        model: "MailTemplateForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_TEMPLATE_FORM_SUBJECT_LABEL,
                placeholder: CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_SUBJECT,
                message: CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_SUBJECT,
                name: "subject",
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.MAIL_TEMPLATE_FORM_MESSAGE_LABEL,
                placeholder: CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_MESSAGE,
                message: CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_MESSAGE,
                name: "message",
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                rows: 6,
                default: '',
                required: true
            }
        ]
    },
    mailSettingsForm: {
        model: "MailSettingsForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_HOST_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST,
                name: "EMAIL_HOST",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_PORT_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PORT,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PORT,
                name: "EMAIL_PORT",
                minLength: CONSTANTS.ONE,
                maxLength: 10,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_HOST_USER_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_USER,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_USER,
                name: "EMAIL_HOST_USER",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: false
            },
            {
                type: CONSTANTS.FORM_TYPE_PASSWORD_VIEW,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_HOST_PASSWORD_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD,
                name: "EMAIL_HOST_PASSWORD_VIEW",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: false
            },
            {
                type: CONSTANTS.FORM_TYPE_PASSWORD,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_HOST_PASSWORD_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD,
                name: "EMAIL_HOST_PASSWORD",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                hide: true,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_PASSWORD,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_HOST_PASSWORD_CONFIRM_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD_CONFIRM,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST_PASSWORD_CONFIRM,
                name: "EMAIL_HOST_PASSWORD_CONFIRM",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                hide: true,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_PROTOCOL_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PROTOCOL,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PROTOCOL,
                name: "EMAIL_PROTOCOL",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_TIMEOUT_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_TIMEOUT,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_TIMEOUT,
                name: "EMAIL_TIMEOUT",
                minLength: CONSTANTS.ONE,
                maxLength: 10,
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.MAIL_SETTINGS_FORM_EMAIL_FROM_LABEL,
                placeholder: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_FROM,
                message: CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_FROM,
                name: "EMAIL_FROM",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: true
            },
        ]
    },
    selectOptions: [
        { id: 1, value: "EMAIL_USE_TLS", name: "EMAIL_PROTOCOL", label: "TLS" },
        { id: 2, value: "EMAIL_USE_SSL", name: "EMAIL_PROTOCOL", label: "SSL" },
    ],
    testMailServerForm: {
        model: "TestMailServerForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.TEST_MAIL_SERVER_FORM_EMAIL_TO_LABEL,
                placeholder: CONSTANTS.TEST_MAIL_SERVER_FORM_PLACEHOLDER_EMAIL_TO,
                message: CONSTANTS.TEST_MAIL_SERVER_FORM_PLACEHOLDER_EMAIL_TO,
                name: "email_to",
                minLength: CONSTANTS.ONE,
                maxLength: 254,
                default: '',
                required: true
            }
        ]
    },
    mailTemplateOrdering: [
        {
            id: 1,
            label: "名前(昇順)",
            value: "name"
        },
        {
            id: 2,
            label: "名前(降順)",
            value: "-name"
        },
        {
            id: 3,
            label: "更新日(昇順)",
            value: "updated_date"
        },
        {
            id: 4,
            label: "更新日(降順)",
            value: "-updated_date"
        }
    ],
    defaultMailTemplateOrdering: "name",
    mailPendingOrdering: [
        {
            id: 1,
            label: "作成日時(昇順)",
            value: "when_added"
        },
        {
            id: 2,
            label: "作成日時(降順)",
            value: "-when_added"
        },
    ],
    defaultMailPendingOrdering: "-when_added",
    mailDeliveryResultOrdering: [
        {
            id: 1,
            label: "送信日時(昇順)",
            value: "when_attempted"
        },
        {
            id: 2,
            label: "送信日時(降順)",
            value: "-when_attempted"
        },
        {
            id: 3,
            label: "作成日時(昇順)",
            value: "when_added"
        },
        {
            id: 4,
            label: "作成日時(降順)",
            value: "-when_added"
        },
    ],
    defaultMailDeliveryResultOrdering: "-when_attempted",
    filterPendingList: [
        {
            id: 2,
            label: "送信予定"
        },
        {
            id: 4,
            label: "再送予定"
        }
    ],
    filterDeliveryResultList: [
        {
            id: 1,
            label: "送信済み"
        },
        {
            id: 3,
            label: "送信失敗"
        }
    ],
    totalItemsCount: CONSTANTS.ZERO,
    mailTemplateList: [],
    mailTemplateDetails: ({}),
    mailTemplateMessage: [],
    mailSettingsList: [],
    mailSettingsMessage: [],
    mailServerTestMessage: [],
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.MAIL_TEMPLATE_LIST_STATE:
            return { ...state, mailTemplateList: action.payload.results, totalItemsCount: action.payload.count }
        case types.MAIL_TEMPLATE_RETRIEVE_STATE:
            return { ...state, mailTemplateDetails: action.payload }
        case types.MAIL_TEMPLATE_EDIT_STATE:
            return { ...state, mailTemplateMessage: action.payload }
        case types.MAIL_SETTINGS_LIST_STATE:
            return { ...state, mailSettingsList: action.payload, totalItemsCount: action.payload.length }
        case types.MAIL_SETTINGS_EDIT_STATE:
            return { ...state, mailSettingsMessage: action.payload }
        case types.MAIL_SETTINGS_TEST_SERVER_STATE:
            return { ...state, mailServerTestMessage: action.payload }
        case types.MAIL_PENDING_LIST_STATE:
            return { ...state, mailPendingList: action.payload.results, totalItemsCount: action.payload.count }
        case types.MAIL_DELIVERY_RESULT_LIST_STATE:
            return { ...state, mailDeliveryResultList: action.payload.results, totalItemsCount: action.payload.count }
        default: return state;
    }
};