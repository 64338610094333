import * as types from "./types";

const defaultState = {
    tableList: [],
    totalItemsCount: 0,
    accessLogOrdering: [
        {
            id: 1,
            label: "日時（昇順）",
            value: "access_timestamp"
        },
        {
            id: 2,
            label: "日時（降順）",
            value: "-access_timestamp"
        },
    ],
    defaultAccessLogOrdering: "-access_timestamp",
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, tableList: action.payload.results, totalItemsCount: action.payload.count };
        default: return state;
    }
};