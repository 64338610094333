import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';
import throttle from 'lodash/throttle';
import createSagaMiddleware from 'redux-saga';
import { PERSISTED_STATE_KEY, MODE } from './config/settings';
import { all } from 'redux-saga/effects';

//Modules imports
import Login from './views/containers/Login/state';
import AdminLogin from './views/containers/AdminLogin/state';
import Notice from './views/containers/Notice/state';
import ControlPanel from './views/containers/ControlPanel/state';
import UserAccessLogging from './views/containers/UserAccessLogging/state';
import AdminApproval from './views/containers/AdminApproval/state';
import AdminNotice from './views/containers/AdminNotice/state';
import AdminDocument from './views/containers/AdminDocument/state';
import AdminAccountManagement from './views/containers/AdminAccountManagement/state';
import AdminProductManagement from './views/containers/AdminProductManagement/state';
import AdminEmailManagement from './views/containers/AdminEmailManagement/state';
import AdminAccessLogging from './views/containers/AdminAccessLogging/state';
import AdminHome from './views/containers/AdminHome/state';
import AdminPasswordUpdate from './views/containers/AdminPasswordUpdate/state';
import Error404 from './views/containers/Errors/404';

//Store methods
const loadState = () => {
    try {
        const serializedState = localStorage.getItem(PERSISTED_STATE_KEY);
        return null === serializedState ? undefined : JSON.parse(serializedState);
    } catch (e) { return undefined; }
};

const saveState = (state) => {
    try {
        localStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(state));
    } catch (e) { }
};

//Module Reducers
const appReducer = combineReducers({
    auth: Login.reducer,
    notice: Notice.reducer,
    controlPanel: ControlPanel.reducer,
    customerAccessLog: UserAccessLogging.reducer,
    adminNotice: AdminNotice.reducer,
    adminDocument: AdminDocument.reducer,
    adminApproval: AdminApproval.reducer,
    adminAccountManagement: AdminAccountManagement.reducer,
    adminProductManagement: AdminProductManagement.reducer,
    adminEmailManagement: AdminEmailManagement.reducer,
    adminAccessLogging: AdminAccessLogging.reducer,
    adminHome: AdminHome.reducer,
});

const rootReducer = (state, action) => {
    if ("RESET_STORE" === action.type) {
        state = undefined;
    }
    return appReducer(state, action);
}

//Module Routes
export const rootRoutes = [
    ...Login.routes,
    ...AdminLogin.routes,
    ...Notice.routes,
    ...ControlPanel.routes,
    ...UserAccessLogging.routes,
    ...AdminNotice.routes,
    ...AdminDocument.routes,
    ...AdminApproval.routes,
    ...AdminAccountManagement.routes,
    ...AdminProductManagement.routes,
    ...AdminEmailManagement.routes,
    ...AdminAccessLogging.routes,
    ...AdminHome.routes,
    ...AdminPasswordUpdate.routes,
    { component: Error404 }
]

//Module sagas
function* rootSaga() {
    yield all([
        //Insert module sagas
    ])
}

const persistedState = loadState();
const sagaMiddleware = createSagaMiddleware()
let middleware;

if (MODE === 'development') {
    middleware = applyMiddleware(
        thunkMiddleware,
        sagaMiddleware,
        logger
    );
} else {
    middleware = applyMiddleware(
        thunkMiddleware,
        sagaMiddleware
    );
}

const store = createStore(
    rootReducer,
    persistedState,
    middleware
);


export default function configureStore() {
    store.subscribe(throttle(() => {
        saveState(store.getState());
    }), 1000);

    sagaMiddleware.run(rootSaga)

    return store;
};
