import React from 'react';
import { Modal } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';
import { withMailTemplateEditConfirm } from './MailTemplateEditConfirmModal';
import AdminFormTemplate from '../AdminPortalFormTemplate'
import { adminEmailManagementSelectors } from '../../containers/AdminEmailManagement/state';
import * as Commons from '../Commons';


const withMailTemplateEdit = (WrappedContent) => {

    return withMailTemplateEditConfirm(
        class MailTemplateEdit extends WrappedContent {
            constructor(props) {
                super(props);
                this.state = {
                    ...this.state,
                    showMailTemplateEditModal: false,
                    currentFormValues: {},
                    initialFormValue: {},
                };
            }

            mailTemplateEditModal = (props) => {

                let isDefault = 
                    (props.currentFormValues && props.initialFormValue) ? 
                    (props.currentFormValues.subject === props.initialFormValue.MailTemplateForm.subject) &&
                    (props.currentFormValues.message === props.initialFormValue.MailTemplateForm.message)
                    : true;

                return (
                    <Modal keyboard={false} size="lg" centered 
                        className="modal-edit-mail-template modal-dialog-lg" 
                        show={props.showMailTemplateEditModal} 
                        onHide={ isDefault ? this.handleCloseMailTemplateEditModal : this.handleShowDiscardModal } >
                        <Modal.Header closeButton>
                            <Modal.Title>{"メールテンプレート編集"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <MDBTable bordered className="mb-3 w-100">
                                <MDBTableBody>
                                    <tr >
                                        <td className="disable-table">
                                            <span className="font-weight-bold">テンプレート名</span>
                                        </td>
                                        <td>{props.mailTemplateDetails.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="disable-table">
                                            <span className="font-weight-bold">備考</span>
                                        </td>
                                        <td>
                                            {Commons.getDetailsFormattedContent(props.mailTemplateDetails.remarks)}
                                        </td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                            <h6 className="font-weight-bold mt-2">メールテンプレート</h6>
                            <AdminFormTemplate
                                form={"mail-template-edit"}
                                editMailTemplate={true}
                                formInputs={props.formInputs}
                                initialValues={props.initialFormValue}
                                handleFormOnchange={this.handleFormOnchange}
                                handleSubmitMailTemplateEdit={this.handleSubmitMailTemplateEdit}
                                handleShowMailTemplateEditConfirmModal={this.handleShowMailTemplateEditConfirmModal}
                                validate={adminEmailManagementSelectors.mailTemplateCheckErrors}
                            />
                        </Modal.Body>
                        <Modal.Footer className="border-top-0 pt-0">
                            <div className="w-100 d-inline-flex">
                                <input type="hidden" name="submit_action" />
                                <button 
                                    type="button" form="mail-template" 
                                    className="btn btn-90 mr-2 ml-auto btn-primary" 
                                    onClick={this.handleMailTemplateEditAction}>保存</button>
                                <button 
                                    className="btn btn-90 btn-secondary" 
                                    onClick={ isDefault ? this.handleCloseMailTemplateEditModal : this.handleShowDiscardModal }>キャンセル</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }

            handleFormOnchange = (values) => {
                if (values.formValues.MailTemplateForm) {
                    this.setState({ 
                        currentFormValues: values.formValues.MailTemplateForm
                    });
                }
            }

            handleCloseAllModal = () => {
                this.handleCloseMailTemplateEditModal();
                this.handleCloseDiscardModal();
            }

            handleMailTemplateEditAction = () => {
                document.querySelector('.modal.show .portalSubmitButton').click();
                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowMailTemplateEditConfirmModal";
            }

            handleShowMailTemplateEditModal = (id) => {
                Promise.all([
                    this.showLoader(),
                    this.props.getMailTemplate(id).then(
                        () => {
                            this.setState({
                                showMailTemplateEditModal: true,
                                initialFormValue: {
                                    MailTemplateForm: {
                                        id: this.props.mailTemplateDetails.id,
                                        subject: this.props.mailTemplateDetails.subject,
                                        message: this.props.mailTemplateDetails.message,
                                    }
                                },
                            })
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
            }
        
            handleCloseMailTemplateEditModal = () => {
                this.setState({
                    showMailTemplateEditModal: false
                })
            }

            handleSubmitMailTemplateEdit = (values) => {
                this.setState({
                    mailTemplateData: {
                        id: values.id,
                        payload: values
                    }
                });
            }
        }
    )
}

export {
    withMailTemplateEdit
}
