import React from 'react'
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate'
import * as Commons from '../../components/Commons';

const withNewNoticePost = (WrappedComponent) => {
    return class AdminNewPostTemplate extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalNewPost: false,
                showNoticeApplicationConfirmModal: false,
                showNoticeDraftConfirmModal: false,
                templateDataChoosen: null,
            }
        }

        newNoticePostModal = (props) => {
            let { isNoticeTemplate } = props;
            let { formInputs } = props;

            let areAllNoticeDefault = props.currentFormValues ?
                props.currentFormValues.type === "" &&
                props.currentFormValues.product === "" &&
                props.currentFormValues.title === "" &&
                props.currentFormValues.content === "" &&
                props.currentFormValues.publish_date === "0" &&
                props.currentFormValues.publish_type === "0" ? true : false : true

            let areAllTemplateDefault = props.currentFormValues ?
                props.currentFormValues.product === "" &&
                props.currentFormValues.name === "" &&
                props.currentFormValues.note === "" &&
                props.currentFormValues.title === "" &&
                props.currentFormValues.content === "" ? true : false : true

            let areAllDefault = isNoticeTemplate ? areAllTemplateDefault : areAllNoticeDefault;

            if (isNoticeTemplate && this.state.showModalNewPost) {
                // eslint-disable-next-line array-callback-return
                formInputs.form.map((item) => {
                    item.disabled = false;
                    if (item.name === "product") {
                        item.type = "select";
                    }
                });
            }

            return (
                <Modal show={this.state.showModalNewPost} keyboard={false} onHide={areAllDefault ? this.handleCloseModalNewPost : this.handleShowDiscardModal} className="modal-new-post" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{ isNoticeTemplate ? "テンプレート管理 ＞ テンプレート新規作成" : "お知らせ管理 ＞ お知らせ投稿" }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            isNoticeTemplate={isNoticeTemplate}
                            formInputs={props.formInputs}
                            radioInput={props.radioInput}
                            selectInput={props.selectInput}
                            validate={isNoticeTemplate ? props.adminNoticeSelectors.checkErrorsTemplateForm : props.adminNoticeSelectors.checkErrors}
                            form={isNoticeTemplate ? "admin-new-template" : "admin-new-post"}
                            addMode={true}
                            handleSubmitModalNewPost={this.handleSubmitModalNewPost}
                            handleShowApplicationConfirmModal={this.handleShowApplicationConfirmModal}
                            handleShowTemplateConfirmModal={this.handleShowTemplateConfirmModal}
                            handleShowDraftConfirmModal={this.handleShowDraftConfirmModal}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowChooseTemplateModal={this.handleShowChooseTemplateModal}
                            templateDataChoosen={this.state.templateDataChoosen}
                            showModalNewPost={this.state.showModalNewPost}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            { isNoticeTemplate ?
                                <>
                                    <button type="button" form="admin-new-template" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                        document.querySelector('.modal.show .noticeSubmitButton').click()
                                        document.querySelector('.modal.show input[name=submit_action]').value = "handleShowTemplateConfirmModal"
                                    }}>新規作成</button>
                                </>
                                :
                                <>
                                    <button type="button" form="admin-new-post" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                        document.querySelector('.modal.show .noticeSubmitButton').click()
                                        document.querySelector('.modal.show input[name=submit_action]').value = "handleShowApplicationConfirmModal"
                                    }}>公開申請</button>
                                    <button type="button" form="admin-new-post" className="btn btn-90 mr-2 btn-outline-primary" onClick={() => {
                                        document.querySelector('.modal.show .noticeSubmitButton').click()
                                        document.querySelector('.modal.show input[name=submit_action]').value = "handleShowDraftConfirmModal"
                                    }}>下書保存</button>
                                </>
                            }
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseModalNewPost : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowModalNewPost = (event) => {
            event.target.blur();
            this.setState({
                showModalNewPost: true,
                templateDataChoosen: null
            });
        }

        handleCloseModalNewPost = () => {
            this.setState({
                showModalNewPost: false,
                templateDataChoosen: null
            });
        }

        handleSetValueTitleContent = (title, content) => {
            this.setState({
                templateDataChoosen: {
                    'title': title,
                    'content': content
                },
            })
        }

        handleSubmitModalNewPost = (values, isNoticeTemplate) => {
            let formattedValue = {};
            if (isNoticeTemplate) {
                formattedValue = {
                    content: values.content,
                    name: values.name,
                    note: values.note,
                    product_id: Number(values.product),
                    title: values.title
                }
            } else {
                let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;
                formattedValue = {
                    category_id: values.type,
                    product_id: values.product,
                    publish_preferred_date: preferred_date,
                    publish_type: values.publish_type,
                    title: values.title,
                    content: values.content
                }
            }

            this.setState({
                noticeData: {
                    type: "create",
                    payload: formattedValue
                },
                templateDataChoosen: null
            });
        }
    }
}

export {
    withNewNoticePost
}