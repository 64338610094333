import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';

export const getPortalProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.PRODUCTLOOKUP)
                .then((response) => {
                    dispatch(Actions.productLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getPortalCategoryList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CATEGORYLOOKUP)
                .then((response) => {
                    dispatch(Actions.categoryLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getProductInfoList = (
    itemsCountPerPage, activePage, query,
    product_filter, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                let filter = {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    ordering: sort
                };
                if (product_filter === "サービス中") {
                    filter = {...filter, end_date__isnull: 'True'}
                } else if (product_filter === "サービス終了") {
                    filter = {...filter, end_date__lt: new Date()}
                } else if (product_filter === "サービス終了予定") {
                    filter = {...filter, end_date__gt: new Date()}
                }

                return apiService.get(Path.PRODUCT_INFO, {
                    params: filter
                })
                    .then((response) => {
                        dispatch(Actions.getProductListState(response.data));
                    })
            })
            .catch(apiService.handleDisplayError);
    }

export const postCreateProduct = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.post(Path.PRODUCT_CREATE, data).then((response) => {
                dispatch(Actions.postCreateProductState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getProductEditDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.PRODUCT_UPDATE + id + '/')
                .then((response) => {
                    dispatch(Actions.getProductEditDetailsState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const putProductEditDetails = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'put',
                url: Path.PRODUCT_UPDATE + id + '/',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
                .then((response) => {
                    dispatch(Actions.putProductEditDetailsState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const deleteProduct = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.delete(Path.PRODUCT_DELETE + id + '/')
                .then((response) => {
                    dispatch(Actions.getProductDeleteState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getAdminKintoneProductFieldList = (product_id) => (dispatch) => {
    return apiService.refreshToken()
    .then(()=>{
        return apiService.get(Path.KINTONE_PRODUCT_FIELD_LIST, {
            params: {
                product_id: product_id
            }
        }).then((response) => {
            dispatch(Actions.getKintoneProductFieldListState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const postCreateKintoneProductField = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.post(Path.KINTONE_PRODUCT_FIELD_CREATE, data).then((response) => {
                dispatch(Actions.postCreateKintoneProductFieldState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const deleteKintoneProductField = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.delete(Path.KINTONE_PRODUCT_FIELD_DELETE + id + '/', data)
                .then((response) => {
                    dispatch(Actions.deleteKintoneProductFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const patchKintoneProductControlPanel = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.KINTONE_PRODUCT_CONTROL_PANEL_PATCH + id + '/', data)
                .then((response) => {
                    dispatch(Actions.patchKintoneProductFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const patchKintoneProductFieldLabel = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.KINTONE_PRODUCT_FIELD_LABEL_PATCH + id + '/', data)
                .then((response) => {
                    dispatch(Actions.patchKintoneProductFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const patchSortingKintoneProductField = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.KINTONE_PRODUCT_FIELD_SORTING_PATCH, data)
                .then((response) => {
                    dispatch(Actions.patchKintoneProductFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const patchKintoneCommonField = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.KINTONE_PRODUCT_COMMON_FIELD_EDIT + id + '/', data)
                .then((response) => {
                    dispatch(Actions.patchKintoneCommonFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const patchSortingKintoneCommonField = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.KINTONE_COMMON_FIELD_SORTING_PATCH, data)
                .then((response) => {
                    dispatch(Actions.patchKintoneCommonFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getAdminKintoneProductCommonFieldList = () => (dispatch) => {
    return apiService.refreshToken()
    .then(()=>{
        return apiService.get(Path.KINTONE_PRODUCT_COMMON_FIELD_LIST).then((response) => {
            dispatch(Actions.getKintoneProductCommonFieldListState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
}

export const postCreateKintoneProductCommonField = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.post(Path.KINTONE_PRODUCT_COMMON_FIELD_CREATE, data).then((response) => {
                dispatch(Actions.postCreateKintoneProductCommonFieldState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const deleteKintoneProductCommonField = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.delete(Path.KINTONE_PRODUCT_COMMON_FIELD_DELETE + id + '/')
                .then((response) => {
                    dispatch(Actions.deleteKintoneProductCommonFieldState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getKintoneProductSubTableFieldList = (product_id, kintone_id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.KINTONE_PRODUCT_FIELD_SUB_TABLE_LIST + kintone_id + '/', {
                params: {
                    product_id: product_id
                }
            })
                .then((response) => {
                    dispatch(Actions.getKintoneProductSubTableFieldListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getKintoneCommonSubTableFieldList = (kintone_id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.KINTONE_COMMON_FIELD_SUB_TABLE_LIST + kintone_id + '/')
                .then((response) => {
                    dispatch(Actions.getKintoneCommonSubTableFieldListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}