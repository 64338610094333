import React from 'react';
import { Modal } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';

const withSubListTableDataModal = (WrappedComponent) => {
    return class SubListTableDataModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showSubListTableDataModal: false,
                subListData: [],
                subLabelList: [],
                showSubTableListWhenCloseSubTable: false
            };
            this.showSubTableIndex = 0;
        }

        subListTableDataModal = (props) => {
            let { parentLabel, subListData, subLabelList, contractId, isShowIn } = this.state;
            if (isShowIn === "product_admin" || isShowIn === "product_other" || isShowIn === "product_contract") {
                subLabelList = subLabelList && subLabelList.specific_fields && subLabelList.specific_fields.fields;
            }
            if (isShowIn === "common_admin" || isShowIn === "common_contract") {
                subLabelList = subLabelList && subLabelList.common_fields && subLabelList.common_fields.fields;
            }

            return (
                <Modal size="lg" keyboard={false} show={this.state.showSubListTableDataModal} onHide={this.handleCloseSubListTableDataModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {parentLabel}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="mt-2">
                            {subListData.length > 0 &&
                                <>
                                    <h6 className="font-weight-bold">{subListData.field_label}</h6>
                                    <MDBTable bordered className="mb-3 w-100">
                                        <MDBTableBody>
                                            {subListData.map((data, i) => {
                                                return(
                                                    <tr key={i}>
                                                        <td className="align-middle font-weight-bold disable-table sub-table-th-td height-sub-table-td">{data.name}</td>
                                                        <td className="sub-table-th-td height-sub-table-td">{data.code}</td>
                                                    </tr>
                                                )
                                            })}
                                        </MDBTableBody>
                                    </MDBTable>
                                </>
                            }
                        </div>
                        <div className="sub-table-scroll">
                            <MDBTable className="mb-3 w-100">
                                <MDBTableBody>
                                    {subLabelList && subLabelList.length > 0 &&
                                        <>
                                            <tr className="border disable-table">
                                                {subLabelList.map((field, i) => {
                                                    return(
                                                        <th key={i} className="sub-table-th-td">{field.field_label}</th>
                                                    )
                                                })}
                                            </tr>
                                            <tr className="height-sub-table-td">
                                                {subLabelList.map((field, i) => {
                                                    return(
                                                        <td key={i} className="sub-table-th-td">
                                                            {field.is_table ?
                                                                <a href="# " onClick={() => {
                                                                    this.handleShowSubTableModal(contractId, field.field_id, field.field_label, isShowIn)
                                                                }}>詳細</a>
                                                            : ""}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        </>}
                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <button className="btn btn-90 btn-secondary" onClick={this.handleCloseSubListTableDataModal}>
                            閉じる
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowSubListTableDataModal = (contractId, data, parentLabel, parentId, isShowIn) => {
            let promises = [];
            if (isShowIn === "product_admin") {
                promises.push(this.props.getAdminKintoneProductSubTableData(contractId, parentId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "common_admin") {
                promises.push(this.props.getAdminKintoneCommonSubTableData(contractId, parentId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "product_other") {
                promises.push(this.props.getKintoneProductOtherFeatureSubTableData(contractId, parentId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "product_contract") {
                promises.push(this.props.getKintoneProductContractInforSubTableData(contractId, parentId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "common_contract") {
                promises.push(this.props.getKintoneCommonContractInforSubTableData(contractId, parentId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            Promise.all([
                this.showLoader(),
                promises[0].then(
                    () => {
                        this.setState({
                            subLabelList: isShowIn === "product_admin" ? this.props.adminKintoneProductSubTableData
                            : isShowIn === "common_admin" ? this.props.adminKintoneCommonSubTableData
                            : isShowIn === "product_other" ? this.props.subTableKintoneProductOtherFeatureData
                            : isShowIn === "product_contract" ? this.props.subTableKintoneProductContractInforData
                            : isShowIn === "common_contract" && this.props.subTableKintoneCommonContractInforData
                        });
                        this.setState({
                            showSubListTableDataModal: true,
                            parentLabel: parentLabel,
                            subListData: data,
                            contractId: contractId,
                            parentId: parentId,
                            isShowIn: isShowIn
                        });
                        if (this.parentObjectList.length >= 0 && !this.state.showSubTableListWhenCloseSubTable) {
                            this.state.showSubTableListWhenCloseSubTable = true;
                            this.showSubTableIndex = this.parentObjectList.length;
                        }
                    })
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseSubListTableDataModal = () => {
            this.setState({
                showSubListTableDataModal: false
            });
            this.state.showSubTableListWhenCloseSubTable = false;
            if (this.parentObjectList.length > 0) {
                if (this.showSubTableIndex === this.parentObjectList.length) {
                    this.parentObject = this.parentObjectList[this.parentObjectList.length - 1];
                }
                this.handleShowSubTableModal(this.parentObject.contractId, this.parentObject.parentId, this.parentObject.parentFieldLabel, this.parentObject.isShowIn);
            }
            this.showSubTableIndex = 0;
        }
    }
}

export {
    withSubListTableDataModal
}