import React from 'react'
import * as Commons from '../Commons'
import { Modal, Button } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';

const withDocumentListModal = (WrappedComponent) => {
    return class DocumentListModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentListModal: false
            }
        }

        documentListModal = (props) => {
            let { documentList } = props;
            return (
                <Modal className="modal-dialog-lg document_template" keyboard={false} size="lg" show={this.state.showDocumentListModal} onHide={this.handleCloseDocumentListModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント/モジュール一覧（{props.product_name}）</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-lg">
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                <div className="col-sm-2"></div>
                                {props.totalDocumentsListItemsCount > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        {props.totalDocumentsListItemsCount > 0 ?
                            <MDBTable borderless>
                                <MDBTableBody>
                                    {documentList.map(docs => {
                                        var publishDate = Commons.getClientDatetimeDisplay(docs.publish_date);
                                        return (
                                            <tr className="border" key={docs.id}>
                                                <td className="py-3 pr-3 pl-3 align-middle text-right">{publishDate}</td>
                                                <td className="py-3 pr-5 pl-2 align-middle text-break">
                                                    <a href="# " className="font-weight-bold document-list-title" onClick={() => { this.handleShowDocumentDetailsModal(docs.id) }}>{docs.title}</a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                            : <h4>検索の結果、0件でした。</h4>}
                        {props.totalDocumentsListItemsCount > 0 ? this.pager() : null}
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDocumentListModal}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowDocumentListModal = (product_id, product_name) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentList(
                    this.state.itemsCountPerPage, this.state.activePage,
                    this.state.query, product_id
                ).then(
                    () => {
                        this.setState({
                            showDocumentListModal: true,
                            documentList: this.props.documentList,
                            totalDocumentsListItemsCount: this.props.totalDocumentsListItemsCount,
                            product_number: product_id,
                            product_name: product_name,
                            hideServiceDropdown: true,
                            isDocumentList: true,
                        });
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseDocumentListModal = () => {
            this.setState({
                showDocumentListModal: false,
                documentList: [],
                totalDocumentsListItemsCount: 0,
                product_number: null,
                product_name: null,
                query: null,
                activePage: 1
            })
        }

        handleShowDocumentDetailsModal = (id) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentDetails(id).then(
                    () => {
                        this.setState({
                            showDocumentDetailsModal: true
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }
    }
}

export {
    withDocumentListModal
}