import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminNoticeTemplateConfirmModal = (WrappedComponent) => {
    return class AdminNoticeTemplateConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeTemplateConfirmModal: false,
                isDeleteAction: false,
                isEditAction: false
            }
        }

        noticeTemplateConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNoticeTemplateConfirmModal} onHide={this.handleCloseTemplateConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.isDeleteAction ?
                                        "テンプレート管理 ＞ テンプレート削除確認"
                                        :
                                        this.state.isEditAction ?
                                            "テンプレート管理 ＞ テンプレート修正"
                                            :
                                            "テンプレート管理 ＞ テンプレートを登録します"
                                    }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            {this.state.isDeleteAction ?
                                "テンプレートを削除します。"
                                :
                                this.state.isEditAction ?
                                    "「テンプレート」を修正。後ほど一覧画面にて状態確認をお願いします。"
                                    :
                                    "「テンプレート」を登録します。後ほど一覧画面にて状態確認をお願いします。"
                            }
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.state.isDeleteAction ? this.handleDeleteTemplateConfirmModal : this.handleSubmitTemplateConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseTemplateConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowTemplateConfirmModal = (id=undefined, isEdit=false) => {
            this.setState({
                showNoticeTemplateConfirmModal: true,
                isDeleteAction: id === undefined ? false : true,
                isEditAction: isEdit,
                templateId: id
            });
        }

        handleCloseTemplateConfirmModal = () => {
            this.setState({
                showNoticeTemplateConfirmModal: false,
                isDeleteAction: false,
                isEditAction: false,
                templateId: undefined
            });
        }

        handleDeleteTemplateConfirmModal = () => {
            Promise.all([
                this.showLoader(),
                this.props.deleteNoticeTemplate(this.state.templateId)
                    .then(
                        () => {
                            this.loadListFromServer();
                            if (this.props.noticeTemplateDelete) {
                                this.handleShowSuccessAlert(this.props.noticeTemplateDelete.message);
                            }
                        }
                    )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseTemplateConfirmModal)
        }

        handleSubmitTemplateConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let type = this.state.noticeData.type;
            let payload = this.state.noticeData.payload

            if (type === "create") {
                Promise.all([
                    this.showLoader(),
                    this.props.createNoticeTemplate(payload)
                        .then(
                            () => {
                                this.loadListFromServer();
                                this.handleCloseModalNewPost();
                                if (this.props.noticeTemplateCreate) {
                                    this.handleShowSuccessAlert(this.props.noticeTemplateCreate.message);
                                }
                            }
                        )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseTemplateConfirmModal)
            } else if (type === "editTemplate") {
                Promise.all([
                    this.showLoader(),
                    this.props.updateNoticeTemplate(payload)
                        .then(
                            () => {
                                this.loadListFromServer();
                                this.handleCloseTemplateEditModal();
                                if (this.props.noticeTemplateUpdate) {
                                    this.handleShowSuccessAlert(this.props.noticeTemplateUpdate.message);
                                }
                            }
                        )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseTemplateConfirmModal)
            }
        }
    }
}

export {
    withAdminNoticeTemplateConfirmModal
}
