import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const withProductDeleteConfirmationModal = (WrappedComponent) => {
    return class ProductDeleteConfirmationModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductDeleteConfirmationModal: false
            }
        }

        productDeleteConfirmationModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showProductDeleteConfirmationModal} onHide={this.handleCloseProductDeleteConfirmationModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品情報削除確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「製品情報」を削除します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitProductDeleteConfirmationModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseProductDeleteConfirmationModal}>
                            閉じる
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleSubmitProductDeleteConfirmationModal = (event) => {
            event.target.setAttribute("disabled", "disabled")
            Promise.all([
                this.showLoader(),
                this.props.deleteProduct(this.state.productDeleteId).then(
                    () => {
                        this.loadListFromServer();
                        this.handleShowSuccessAlert(this.props.productMessage)
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseProductDeleteConfirmationModal)
        }

        handleShowProductDeleteConfirmationModal = (id) => {
            this.setState({ showProductDeleteConfirmationModal: true, productDeleteId: id });
        }

        handleCloseProductDeleteConfirmationModal = () => {
            this.setState({ showProductDeleteConfirmationModal: false });
        }
    }
}

export {
    withProductDeleteConfirmationModal
}