import React from 'react';

const MailSettingsTemplate = (props) => {
    let protocolKeys = ['EMAIL_USE_TLS', 'EMAIL_USE_SSL'];
    let protocolSelected = null;
    if (props.mailSettingsList) {
        protocolSelected = props.mailSettingsList.find((o) => { 
            return ['EMAIL_USE_TLS', 'EMAIL_USE_SSL'].includes(o.key) && 
            o.value.toLowerCase() === "true";
        });
    }
    
    let protocolLabel = "";
    if (protocolSelected && props.selectOptions) {
        for (let option of props.selectOptions) {
            if (option.value === protocolSelected.key) {
                protocolLabel = option.label;
            }
        }
    }
    
    let formLabels = {};
    if (props.formInputs) {
        formLabels = props.formInputs.form.reduce((acc, cur)=>({...acc, [cur.name]:cur.label}), {});
    }

    return (
        <table className="table table-bordered table-striped table-form">
            <tbody>
                {
                    props.mailSettingsList.length && protocolSelected ?
                        props.mailSettingsList.map((setting) => {
                            return protocolSelected.key === setting.key ? 
                                <tr key={setting.id} className="">
                                    <td className="disable-table align-middle" style={{width: '20%'}}>
                                        <strong>{"EMAIL_PROTOCOL"}</strong>
                                    </td>
                                    <td className="align-middle">{protocolLabel}</td>
                                </tr>
                                : protocolKeys.includes(setting.key) && protocolSelected.key !== setting.key ? null : 
                                <tr key={setting.id} className="">
                                    <td className="disable-table align-middle" style={{width: '20%'}}>
                                        <strong>{formLabels[setting.key]}</strong>
                                    </td>
                                    <td className="align-middle">{setting.value}</td>
                                </tr>
                            
                        })
                        :
                        <tr className="disable-table font-weight-bold">
                            <td colSpan={2} className="font-weight-normal">
                                <center>設定情報はありません。</center>
                            </td>
                        </tr>
                }
            </tbody>
        </table>
    );
}

export default MailSettingsTemplate;