import React from 'react';
import * as Commons from '../Commons'

const AdminApprovalTemplate = (props) => {
    let {
        rows,
        handleShowNoticeApprovalModal,
        handleShowDocumentApprovalModal
    } = props;

    return (
        <section className="list mt-3 mb-3">
            <div className="mt-2">
                {props.totalItemsCount > 0 ?
                    <>
                        {rows.map(application => {
                            var applicationDate = Commons.getClientDatetimeDisplay(application.application_date)

                            return (
                                <div className="px-3 py-3 list-group-item table-layout" key={application.application_id}>
                                    <div className="list-group">
                                        <div className="px-0 py-0 table-layout-body">
                                            <div>
                                                <label className="float-right mb-0">
                                                    <strong>申請日時: </strong>
                                                    {applicationDate}
                                                </label>
                                                <label className="mb-0">
                                                    <strong>申請番号: </strong>
                                                    {application.application_number}
                                                </label>
                                            </div>
                                            <div className="my-2">
                                                <span className="custom-badge mr-2" style={{ background: application.category_bg_color }}>
                                                    {application.category_name}
                                                </span>
                                                <span className="custom-badge mr-2" style={{ background: application.product_bg_color }}>
                                                    {application.product_name}
                                                </span>
                                                <a href="# " className="font-weight-bold approval-list-title" value={application.post_id} onClick={() => {
                                                    application.category_type === "1" ?
                                                        handleShowNoticeApprovalModal(application.application_id, application.post_id) :
                                                        handleShowDocumentApprovalModal(application.application_id, application.post_id)
                                                    }}>
                                                    {application.title}
                                                </a>
                                            </div>
                                            <div className="list-item-attribute row">
                                                <div className="col-lg-3 col-sm-12">
                                                    <strong>申請種別: </strong>
                                                    <label>{application.application_type}</label>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <strong>申請者: </strong>
                                                    <label>{application.created_by}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    : <h4>検索の結果、0件でした。</h4>}
            </div>
        </section>
    );
}

export default AdminApprovalTemplate;