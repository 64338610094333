import React from 'react'
import { Modal, Button } from 'react-bootstrap';


const withDiscardChangesNotification = (WrappedComponent) => {
    return class DiscardChanges extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDiscardModal: false,
            }
        }

        discardChanges = (props) => {

            return (
                <Modal className="discard-changes-modal" keyboard={false} size="md" show={props.showDiscardModal} onHide={this.handleCloseDiscardModal} centered>
                    <Modal.Header className="border-bottom-0">
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-12">
                                <p>ダイアログボックスを閉じます。よろしいですか？</p>
                                <p>入力したデータは保存されません。</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleCloseAllModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDiscardModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowDiscardModal = (event) => {
            if (event) {
                event.target.blur();
            }
            else {
                document.getElementsByClassName("close")[0].blur();
            }
            this.setState({
                showDiscardModal: true,
                templateDataChoosen: null
            })
        }

        handleCloseDiscardModal = () => {
            this.setState({ showDiscardModal: false })
        }
    }
}

export {
    withDiscardChangesNotification
}