import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminApprovalRejectModal = (WrappedComponent) => {
    return class AdminApprovalRejectModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showApprovalRejectModal: false
            }
        }

        adminApprovalRejectModal = (props) => {
            var areAllDefault = props.rejectRemarks === "" ? true : false

            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showApprovalRejectModal} onHide={areAllDefault ? this.handleCloseApprovalRejectModal : this.handleShowDiscardModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>承認管理 ＞ 却下確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md pb-0">
                        <div>
                            申請を却下します。必要に応じてコメントを入力してください。
                        </div>
                        <div>
                            <textarea type="textarea" rows="4" name="ApprovalRejectForm.remarks" id="remarks" className="form-control text-left"
                                placeholder="申請者へのコメントを1024文字以内で入力してください" maxLength="1024" minLength="1"
                                onChange={() => {
                                    this.setRejectRemarks(document.getElementById("remarks").value)
                                }}>
                            </textarea>
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button type="submit" id="reject-approval" form="approval-reject-form" variant="primary" className="btn-90" onClick={() => {
                            this.handleSubmitApprovalRejectModal(props.initialFormRejectValue.ApprovalRejectForm.post_id, document.getElementById('remarks').value)
                        }}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={areAllDefault ? this.handleCloseApprovalRejectModal : this.handleShowDiscardModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowApprovalRejectModal = (id) => {
            this.setState({
                initialFormRejectValue: {
                    ApprovalRejectForm: {
                        post_id: id,
                        remarks: ""
                    }
                },
                showApprovalRejectModal: true
            })
        }

        handleCloseApprovalRejectModal = () => {
            this.setState({
                showApprovalRejectModal: false
            })
        }

        handleSubmitApprovalRejectModal = (id, remarks) => {
            let formattedValue = {
                "application_status": 2,
                "remarks": remarks
            }

            this.setState({
                approvalData: {
                    id: id,
                    type: "reject",
                    payload: formattedValue
                }
            }, this.handleSubmitApprovalConfirmModal);
        }

        setRejectRemarks = (remarks) => {
            this.setState({
                rejectRemarks: remarks
            })
        }
    }
}

export {
    withAdminApprovalRejectModal
}