// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLoginCheck } from '../../components/LoginCheck';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withPaging } from '../../components/Pagination/Paging';
import AdminApprovalHistoryTemplate from "../../components/AdminApproval/AdminApprovalHistoryTemplate";
import { adminApprovalOperations } from './state';
import { withAdminDocumentApprovalHistoryModal } from '../../components/AdminApproval/AdminDocumentApprovalHistoryModal';
import { withAdminNoticeApprovalHistoryModal } from '../../components/AdminApproval/AdminNoticeApprovalHistoryModal';


class AdminApprovalHistoryScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getApprovalHistoryList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.product_number, this.state.sort
            ).then(
                () => {
                    this.setState({
                        rows: this.props.tableHistoryList,
                        totalItemsCount: this.props.totalItemsCount,
                        productLookupList: this.props.productLookupList,
                        categoryLookupList: this.props.categoryLookupList
                    });
                }
            ),
            this.props.getApprovalProductList().then(
                () => {
                    this.setState({
                        productLookupList: this.props.productLookupList
                    });
                }
            ),
            this.props.getApprovalCategoryList().then(
                () => {
                    this.setState({
                        categoryLookupList: this.props.categoryLookupList
                    });
                }
            ),
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    handleFormOnchange = (values) => {
        if (values.formValues.DocumentForm) {
            this.setState({ currentFormValues: values.formValues.DocumentForm })
        }
        else if (values.formValues.PostForm) {
            this.setState({ currentFormValues: values.formValues.PostForm })
        }
        else if (values.formValues.ApprovalRejectForm) {
            this.setState({ currentFormValues: values.formValues.ApprovalRejectForm })
        }
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="admin_approval_history_template">
                        <h4 className="mr-auto page-heading">{"承認管理 ＞ 承認履歴一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <AdminApprovalHistoryTemplate {...this.state}
                            handleNoticeShowApprovalHistoryDetail={this.handleNoticeShowApprovalHistoryDetail}
                            handleDocumentShowApprovalHistoryDetail={this.handleDocumentShowApprovalHistoryDetail} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.adminDocumentApprovalHistoryModal({ ...this.state, ...this.props })}
                    {this.adminNoticeApprovalHistoryModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        tableHistoryList: state.adminApproval.tableHistoryList,
        totalItemsCount: state.adminApproval.totalItemsCount,
        productLookupList: state.adminApproval.productLookupList,
        categoryLookupList: state.adminApproval.categoryLookupList,
        approvalHistoryPostForm: state.adminApproval.approvalHistoryPostForm,
        radioInput: state.adminApproval.radioOptions,
        approvalHistoryNoticeDetails: state.adminApproval.approvalHistoryNoticeDetails,
        approvalHistoryDocumentForm: state.adminApproval.approvalHistoryDocumentForm,
        approvalHistoryDocumentDetails: state.adminApproval.approvalHistoryDocumentDetails,
        listOrdering: state.adminApproval.approvalHistoryOrdering,
        defaultOrdering: state.adminApproval.defaultApprovalHistoryOrdering
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getApprovalHistoryList: adminApprovalOperations.getApprovalHistoryList,
        getApprovalProductList: adminApprovalOperations.getApprovalProductList,
        getApprovalCategoryList: adminApprovalOperations.getApprovalCategoryList,
        getApprovalHistoryNoticeDetails: adminApprovalOperations.getApprovalHistoryNoticeDetails,
        getApprovalHistoryDocumentDetails: adminApprovalOperations.getApprovalHistoryDocumentDetails,
        getDocumentFile: adminApprovalOperations.getDocumentFile
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withAdminDocumentApprovalHistoryModal(
                            withAdminNoticeApprovalHistoryModal(AdminApprovalHistoryScreen)
                        )
                    )
                )
            )
        )
    )
);