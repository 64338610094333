import * as CONSTANTS from '../../../../utils/constants';
import * as Commons from '../../../components/Commons';

// function productCheckErrors(state) {
//     let errors = {};

//     let product_name = state.PortalProductForm.product_name.trim();
//     let product_long_name = state.PortalProductForm.product_long_name.trim();
//     let post_ad_group = state.PortalProductForm.post_ad_group.trim();
//     let approved_ad_group = state.PortalProductForm.approved_ad_group.trim();
//     let kintone_api_link = state.PortalProductForm.kintone_api_link.trim();
//     let kintone_api_id = state.PortalProductForm.kintone_api_id;
//     let kintone_api_token = state.PortalProductForm.kintone_api_token.trim();
//     let faq_link = state.PortalProductForm.faq_link.trim();
//     let inquiry_link = state.PortalProductForm.inquiry_link.trim();
//     let start_date = state.PortalProductForm.start_date;
//     let end_date = state.PortalProductForm.end_date;
//     let post_ad_group_exceed = false;
//     let approved_ad_group_exceed = false;

//     post_ad_group.split(',').map(post => {
//         if (post.length > 45) {
//             post_ad_group_exceed = true;
//         }
//         return true;
//     });

//     approved_ad_group.split(',').map(approved => {
//         if (approved.length > 45) {
//             approved_ad_group_exceed = true;
//         }
//         return true;
//     });

//     if (product_name === "") {
//         errors['PortalProductForm.product_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_NAME;
//     }
//     if (product_long_name === "") {
//         errors['PortalProductForm.product_long_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_LONG_NAME;
//     }
//     if (post_ad_group === "") {
//         errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_1;
//     }
//     if (post_ad_group_exceed) {
//         errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_2;
//     }
//     if (approved_ad_group === "") {
//         errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_1;
//     }
//     if (approved_ad_group_exceed) {
//         errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_2;
//     }
//     if (kintone_api_link === "") {
//         errors['PortalProductForm.kintone_api_link'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_LINK;
//     }
//     if (kintone_api_id === "") {
//         errors['PortalProductForm.kintone_api_id'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_ID_1;
//     }
//     if (!/^[0-9]*$/gm.test(kintone_api_id)) {
//         errors['PortalProductForm.kintone_api_id'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_ID_2;
//     }
//     if (kintone_api_token === "") {
//         errors['PortalProductForm.kintone_api_token'] = CONSTANTS.PRODUCT_ERROR_KINTONE_API_TOKEN;
//     }
//     if (faq_link === "") {
//         errors['PortalProductForm.faq_link'] = CONSTANTS.PRODUCT_ERROR_FAQ_LINK;
//     }
//     if (inquiry_link === "") {
//         errors['PortalProductForm.inquiry_link'] = CONSTANTS.PRODUCT_ERROR_INQUIRY_LINK;
//     }
//     if (start_date === "") {
//         errors['PortalProductForm.start_date'] = CONSTANTS.PRODUCT_ERROR_START_DATE;
//     }
//     if (end_date) {
//         if(start_date > end_date) {
//             errors['PortalProductForm.end_date'] = CONSTANTS.PRODUCT_ERROR_END_DATE;
//         }
//     }

//     return errors;
// }

// function servicePortalProductCheckErrors(state) {
//     let errors = {};

//     let product_name = state.PortalProductForm.product_name.trim();
//     let product_long_name = state.PortalProductForm.product_long_name.trim();
//     let post_ad_group = state.PortalProductForm.post_ad_group.trim();
//     let approved_ad_group = state.PortalProductForm.approved_ad_group.trim();
//     let post_ad_group_exceed = false;
//     let approved_ad_group_exceed = false;

//     post_ad_group.split(',').map(post => {
//         if (post.length > 45) {
//             post_ad_group_exceed = true;
//         }
//         return true;
//     });

//     approved_ad_group.split(',').map(approved => {
//         if (approved.length > 45) {
//             approved_ad_group_exceed = true;
//         }
//         return true;
//     });

//     if (product_name === "") {
//         errors['PortalProductForm.product_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_NAME;
//     }
//     if (product_long_name === "") {
//         errors['PortalProductForm.product_long_name'] = CONSTANTS.PRODUCT_ERROR_PRODUCT_LONG_NAME;
//     }
//     if (post_ad_group === "") {
//         errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_1;
//     }
//     if (post_ad_group_exceed) {
//         errors['PortalProductForm.post_ad_group'] = CONSTANTS.PRODUCT_ERROR_POST_AD_GROUP_2;
//     }
//     if (approved_ad_group === "") {
//         errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_1;
//     }
//     if (approved_ad_group_exceed) {
//         errors['PortalProductForm.approved_ad_group'] = CONSTANTS.PRODUCT_ERROR_APPROVED_AD_GROUP_2;
//     }

//     return errors;
// }

// function kintoneCheckErrors(state) {
//     let errors = {};

//     let kintone_attribute_name = state.KintoneProductForm.kintone_attribute_name.trim();
//     let display_label = state.KintoneProductForm.display_label.trim();

//     if (kintone_attribute_name === "") {
//         errors['KintoneProductForm.kintone_attribute_name'] = CONSTANTS.KINTONE_PRODUCT_ERROR_ATTRIBUTE_NAME;
//     }
//     if (display_label === "") {
//         errors['KintoneProductForm.display_label'] = CONSTANTS.KINTONE_PRODUCT_ERROR_DISPLAY_LABEL;
//     }

//     return errors;
// }

function mailTemplateCheckErrors(state) {
    let errors = {};

    let subject = state.MailTemplateForm.subject.trim();
    let message = state.MailTemplateForm.message.trim();

    if (subject === "") {
        errors['MailTemplateForm.subject'] = CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_SUBJECT;
    }
    if (message === "") {
        errors['MailTemplateForm.message'] = CONSTANTS.MAIL_TEMPLATE_FORM_PLACEHOLDER_MESSAGE;
    }

    return errors;
}

function mailSettingsCheckErrors(state) {
    let errors = {};

    let email_host = state.MailSettingsForm.EMAIL_HOST.trim();
    let email_port = state.MailSettingsForm.EMAIL_PORT.trim();
    let email_host_password = state.MailSettingsForm.EMAIL_HOST_PASSWORD.trim();
    let email_timeout = state.MailSettingsForm.EMAIL_TIMEOUT.trim();
    let email_from = state.MailSettingsForm.EMAIL_FROM.trim();

    let email_host_password_view_change = state.MailSettingsForm.EMAIL_HOST_PASSWORD_VIEW_CHANGE;
    let email_host_password_confirm = state.MailSettingsForm.EMAIL_HOST_PASSWORD_CONFIRM;

    if (email_host_password_view_change) {
        if (email_host_password !== email_host_password_confirm) {
            errors['MailSettingsForm.EMAIL_HOST_PASSWORD_CONFIRM'] = CONSTANTS.MAIL_SETTINGS_ERROR_EMAIL_HOST_PASSWORD_CONFIRM;
        }
    }

    if (email_host === "") {
        errors['MailSettingsForm.EMAIL_HOST'] = CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_HOST;
    }
    if (email_port === "") {
        errors['MailSettingsForm.EMAIL_PORT'] = CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_PORT;
    }
    if (email_timeout === "") {
        errors['MailSettingsForm.EMAIL_TIMEOUT'] = CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_TIMEOUT;
    }
    if (email_from === "") {
        errors['MailSettingsForm.EMAIL_FROM'] = CONSTANTS.MAIL_SETTINGS_FORM_PLACEHOLDER_EMAIL_FROM;
    }
    return errors
}

function testMailServerCheckErrors(state) {
    let errors = {};

    let email_to = state.TestMailServerForm.email_to.trim();

    if (email_to === "") {
        errors['TestMailServerForm.email_to'] = CONSTANTS.TEST_MAIL_SERVER_FORM_PLACEHOLDER_EMAIL_TO;
    }
    else if (!Commons.isEmailValid(email_to)) {
        errors['TestMailServerForm.email_to'] = CONSTANTS.ERROR_INVALID_EMAIL;
    }

    return errors
}

export {
//     productCheckErrors,
//     servicePortalProductCheckErrors,
//     kintoneCheckErrors,
    mailTemplateCheckErrors,
    mailSettingsCheckErrors,
    testMailServerCheckErrors,
};
