import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate';
import * as Commons from '../Commons';

const withAdminDocumentDraftModal = (WrappedComponent) => {
    return class AdminDocumentDraftModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentDraft: false
            }
        }

        adminDocumentDraftModal = (props) => {
            let hasHistoryDetails = props.documentHistoryDetails && props.documentHistoryDetails.length > 0;

            let areAllDefault = props.currentFormValues && props.initialFormValue ?
                props.currentFormValues.attachment === props.initialFormValue.DocumentForm.attachment &&
                    props.currentFormValues.product.toString() === props.initialFormValue.DocumentForm.product.toString() &&
                    props.currentFormValues.title === props.initialFormValue.DocumentForm.title &&
                    props.currentFormValues.content === props.initialFormValue.DocumentForm.content &&
                    props.currentFormValues.external_link === props.initialFormValue.DocumentForm.external_link &&
                    props.currentFormValues.publish_date.toString() === props.initialFormValue.DocumentForm.publish_date.toString() &&
                    props.currentFormValues.publish_type.toString() === props.initialFormValue.DocumentForm.publish_type.toString() ? true : false : true

            return (
                <Modal show={this.state.showDocumentDraft} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal} className="modal-edit-document" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント修正{props.documentEditDetails.status}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.formInputs}
                            radioInput={props.radioInput}
                            selectInput={props.productLookupList}
                            initialValues={props.initialFormValue}
                            files={props.documentEditDetails.files}
                            uploaded_files={props.uploaded_files}
                            publishDate={props.documentEditDetails.publish_preferred_date === null ? "0" : "1"}
                            publishPreferredDate={Commons.getClientDatetimeDisplay(props.documentEditDetails.publish_preferred_date)}
                            editMode={true}
                            form={"admin-draft-document"}
                            validate={props.adminDocumentSelectors.checkErrors}
                            handleSubmitDraftModal={this.handleSubmitDraftModal}
                            handleShowApplicationConfirmModal={this.handleShowApplicationConfirmModal}
                            handleShowDraftConfirmModal={this.handleShowDraftConfirmModal}
                            setUploadedFiles={this.setUploadedFiles}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowErrorAlert={this.handleShowErrorAlert}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 admin-draft-modal-footer">
                            <div className={`w-100 d-inline-flex ${hasHistoryDetails ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                <input type="hidden" name="submit_action" />
                                <button type="button" form="admin-draft-document" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                    document.querySelector('.modal.show .documentSubmitButton').click()
                                    document.querySelector('.modal.show input[name=submit_action]').value = "handleShowApplicationConfirmModal"
                                }}>承認申請</button>
                                <button type="button" form="admin-draft-document" className="btn btn-90 mr-2 btn-outline-primary" onClick={() => {
                                    document.querySelector('.modal.show .documentSubmitButton').click()
                                    document.querySelector('.modal.show input[name=submit_action]').value = "handleShowDraftConfirmModal"
                                }}>下書保存</button>
                                {
                                    props.documentEditDetails.status === "（下書き）" ?
                                        <button className="btn btn-90 mr-2 btn-outline-danger" onClick={() => { this.handleShowDeleteModal(props.documentEditDetails.post_id) }}>下書削除</button>
                                        : ""
                                }
                                <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseDraftModal : this.handleShowDiscardModal}>キャンセル</button>
                            </div>
                            {
                                hasHistoryDetails ?
                                    <>
                                        <h5>修正履歴</h5>
                                        {
                                            props.documentHistoryDetails.map((docs, idx) => {
                                                var initialFormHistoryValue = {
                                                    DocumentHistoryPostForm: {
                                                        post_id: docs.id,
                                                        edit_date: docs.updated_date ? Commons.getClientDatetimeDisplay(docs.updated_date) : "",
                                                        product: docs.product_name,
                                                        title: docs.title,
                                                        content: docs.content,
                                                        files: docs.files,
                                                        applicant: docs.applied_by ? docs.applied_by : "",
                                                        history_publish_date: docs.publish_preferred_date === null ? "0" : "1",
                                                        publish_preferred_date: Commons.getClientDatetimeDisplay(docs.publish_preferred_date),
                                                        history_publish_type: docs.publish_type,
                                                        remarks: docs.remarks ? docs.remarks : ""
                                                    }
                                                };

                                                return (
                                                    <div className="bg-light" key={docs.id + "-" + idx}>
                                                        <AdminFormTemplate
                                                            formInputs={props.documentHistoryPostForm}
                                                            radioInput={props.radioInput}
                                                            initialValues={initialFormHistoryValue}
                                                            handleDocumentFileDownload={this.handleDocumentFileDownload}
                                                            publishDate={docs.publish_preferred_date === null ? "0" : "1"}
                                                            publishPreferredDate={Commons.getClientDatetimeDisplay(docs.publish_preferred_date)}
                                                            form={"admin-history-form" + docs.id}
                                                            handleFormOnchange={this.handleFormOnchange}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    : null
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowDraftModal = (value, event) => {
            event.target.blur();
            Promise.all([
                this.showLoader(),
                this.props.getDocumentEditDetails(value).then(
                    () => {
                        var url = null;
                        for (var i = 0; i < this.props.documentEditDetails.files.length; i++) {
                            if (this.props.documentEditDetails.files[i].file_name === null) {
                                url = this.props.documentEditDetails.files[i].url;
                                break;
                            }
                        }
                        this.setState({
                            initialFormValue: {
                                DocumentForm: {
                                    post_id: this.props.documentEditDetails.post_id,
                                    product: this.props.documentEditDetails.product_id,
                                    title: this.props.documentEditDetails.title,
                                    content: this.props.documentEditDetails.content,
                                    attachment: this.props.documentEditDetails.files,
                                    external_link: url ? url : "",
                                    publish_date: this.props.documentEditDetails.publish_preferred_date === null ? "0" : "1",
                                    publish_preferred_date: this.props.documentEditDetails.publish_preferred_date,
                                    publish_type: 1,
                                    remarks: this.props.documentEditDetails.remarks ? this.props.documentEditDetails.remarks : ""
                                }
                            }
                        })
                        this.props.getDocumentHistoryDetails(value).then(
                            () => {
                                this.setState({ showDocumentDraft: true })
                            }
                        )
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseDraftModal = () => {
            this.setState({
                showDocumentDraft: false
            });
            this.removeUploadedFiles()
        }

        handleSubmitDraftModal = (id, values) => {
            let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;
            let formattedValue = {
                files: values.files,
                content: values.content,
                product_id: values.product,
                publish_preferred_date: preferred_date,
                title: values.title,
                publish_type: 1,
                url: values.external_link
            }

            let toRemoveFiles = [];
            let formData = new FormData();
            let formKeys = Object.keys(formattedValue);

            for (let a = 0; a < values.delete_file_checkboxes.length; a++) {
                var fileString = values.delete_file_checkboxes.item(a).id.split(/[\s-]+/);
                var fileId = fileString[fileString.length - 1];
                for (let i = 0; i < this.props.documentEditDetails.files.length; i++) {
                    if ("delete-file-" + this.props.documentEditDetails.files[i].id === values.delete_file_checkboxes.item(a).id) {
                        formData.append("deleted_files", Number(fileId))
                    }
                }
                toRemoveFiles.push(values.delete_file_checkboxes.item(a).id)
            }

            formKeys.map((key) => {
                if (key === "files") {
                    let files = formattedValue[key];
                    if (files && files.length) {
                        for (let i = 0; i < files.length; i++) {
                            if (toRemoveFiles.indexOf("delete-file-" + i) === -1) {
                                formData.append("files", files[i]);
                            }
                        }
                    }
                } else {
                    if (key === "publish_preferred_date" && !formattedValue[key]) {
                        formData.append(key, "");
                    } else {
                        formData.append(key, formattedValue[key]);
                    }
                }
                return true
            });

            this.setState({
                documentData: {
                    id: id,
                    type: "draft",
                    payload: formData
                }
            });
        }

        handleShowDeleteModal = (id) => {
            this.setState({ documentDeleteId: id, showDocumentDeleteModal: true });
        }
    }
}

export {
    withAdminDocumentDraftModal
}