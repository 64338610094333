import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    approvalPostForm: {
        model: "ApprovalPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                name: "type",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                name: "publish_type",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            }
        ]
    },
    approvalHistoryPostForm: {
        model: "ApprovalHistoryPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                name: "type",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "history_publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                name: "history_publish_type",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    approvalRejectForm: {
        model: "ApprovalRejectForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT_REMARKS,
                name: "remarks",
                placeholder: CONSTANTS.FORM_PLACEHOLDER_REJECT_REMARKS,
                default: '',
                minLength: CONSTANTS.ONE,
                maxLength: 1024,
                required: true
            }
        ]
    },
    approvalDocumentForm: {
        model: "ApprovalDocumentForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILES,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            }
        ]
    },
    approvalHistoryDocumentForm: {
        model: "ApprovalHistoryDocumentForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILES,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "history_publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    documentForm: {
        model: "DocumentForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.FORM_PRODUCT_MESSAGE,
                name: "product",
                default: '',
                disabled: true,
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_TITLE_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_TITLE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                name: "title",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.DOCUMENT_FORM_CONTENT_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_CONTENT,
                message: CONSTANTS.FORM_CONTENT_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                name: "content",
                default: '',
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_FILE_CHECKBOX,
                label: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_LABEL,
                name: "attachment",
                message: CONSTANTS.DOCUMENT_FORM_ATTACHMENT_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.DOCUMENT_FORM_EXTERNAL_LINK_LABEL,
                placeholder: CONSTANTS.DOCUMENT_FORM_PLACEHOLDER_EXTERNAL_LINK,
                minLength: CONSTANTS.ONE,
                maxLength: 200,
                message: CONSTANTS.DOCUMENT_FORM_EXTERNAL_LINK_MESSAGE,
                name: "external_link",
                default: '',
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                message: CONSTANTS.FORM_PUBLISH_DATE_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                placeholder: "",
                message: "",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "remarks",
                default: '',
                required: false,
                onSetEmphasize: true,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    postForm: {
        model: "PostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.NEWS_FORM_TYPE_MESSAGE,
                name: "type",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.FORM_PRODUCT_MESSAGE,
                name: "product",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_CHOOSE_TEMPLATE,
                label: CONSTANTS.POST_TEMPLATE,
                message: CONSTANTS.POST_TEMPLATE_MESSAGE,
                name: "template_choose",
                phase: false,
                inline: true,
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                placeholder: CONSTANTS.NEWS_FORM_PLACEHOLDER_TITLE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                name: "title",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                placeholder: CONSTANTS.NEWS_FORM_PLACEHOLDER_CONTENT,
                message: CONSTANTS.FORM_CONTENT_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                name: "content",
                default: '',
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                message: CONSTANTS.FORM_PUBLISH_DATE_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                message: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_MESSAGE,
                name: "publish_type",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                placeholder: "",
                message: "",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "remarks",
                default: '',
                required: false,
                onSetEmphasize: true,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    radioOptions: [
        { name: "publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "publish_type", label: CONSTANTS.PUBLISH_TYPE_INDICATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "publish_type", label: CONSTANTS.PUBLISH_TYPE_DO_NOT_SHOW_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "history_publish_type", label: CONSTANTS.PUBLISH_TYPE_INDICATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "history_publish_type", label: CONSTANTS.PUBLISH_TYPE_DO_NOT_SHOW_LABEL, value: CONSTANTS.ONE_STRING }
    ],
    approvalOrdering: [
        {
            id: 1,
            label: "申請番号(昇順)",
            value: "id"
        },
        {
            id: 2,
            label: "申請番号(降順)",
            value: "-id"
        },
        {
            id: 3,
            label: "サービス名(昇順)",
            value: "product__name,-created_date"
        },
        {
            id: 4,
            label: "サービス名(降順)",
            value: "-product__name,-created_date"
        },
        {
            id: 5,
            label: "種別(昇順)",
            value: "post_category,-created_date"
        },
        {
            id: 6,
            label: "種別(降順)",
            value: "-post_category,-created_date"
        },
        {
            id: 7,
            label: "日時（昇順）",
            value: "created_date"
        },
        {
            id: 8,
            label: "日時（降順）",
            value: "-created_date"
        }
    ],
    approvalHistoryOrdering: [
        {
            id: 1,
            label: "申請番号(昇順)",
            value: "id"
        },
        {
            id: 2,
            label: "申請番号(降順)",
            value: "-id"
        },
        {
            id: 3,
            label: "サービス名(昇順)",
            value: "product__name,-updated_date"
        },
        {
            id: 4,
            label: "サービス名(降順)",
            value: "-product__name,-updated_date"
        },
        {
            id: 5,
            label: "種別(昇順)",
            value: "post_category,-updated_date"
        },
        {
            id: 6,
            label: "種別(降順)",
            value: "-post_category,-updated_date"
        },
        {
            id: 7,
            label: "日時（昇順）",
            value: "updated_date"
        },
        {
            id: 8,
            label: "日時（降順）",
            value: "-updated_date"
        }
    ],
    defaultApprovalOrdering: "-created_date",
    defaultApprovalHistoryOrdering: "-updated_date",
    tableList: [],
    totalItemsCount: CONSTANTS.ZERO,
    productLookupList: [],
    categoryLookupList: [],
    noticeApprovalDetails: ({}),
    noticeApprovalHistoryDetails: ({}),
    documentApprovalDetails: ({}),
    documentApprovalHistoryDetails: ({}),
    approvalMessages: ({}),
    tableHistoryList: [],
    documentEditDetails: ({}),
    noticeEditDetails: ({}),
    categoryLookupListForEditNotice: [],
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, tableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.APPROVAL_HISTORY_STATE:
            return { ...state, tableHistoryList: action.payload.results, totalItemsCount: action.payload.count };
        case types.PRODUCT_LOOKUP_STATE:
            return {
                ...state,
                productLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product", label: item.name }
                })
            };
        case types.CATEGORY_LOOKUP_STATE:
            return { ...state, categoryLookupList: action.payload };
        case types.NOTICE_APPROVAL_RETRIEVE_STATE:
            return { ...state, noticeApprovalDetails: action.payload };
        case types.NOTICE_APPROVAL_RETRIEVE_HISTORY_STATE:
            return { ...state, noticeApprovalHistoryDetails: action.payload };
        case types.APPROVAL_HISTORY_NOTICE_DETAIL_STATE:
            return { ...state, approvalHistoryNoticeDetails: action.payload };
        case types.DOCUMENT_APPROVAL_RETRIEVE_STATE:
            return { ...state, documentApprovalDetails: action.payload };
        case types.DOCUMENT_APPROVAL_RETRIEVE_HISTORY_STATE:
            return { ...state, documentApprovalHistoryDetails: action.payload };
        case types.APPROVAL_HISTORY_DOCUMENT_DETAIL_STATE:
            return { ...state, approvalHistoryDocumentDetails: action.payload };
        case types.APPROVAL_STATE:
            return { ...state, approvalMessages: action.payload }
        case types.DOCUMENT_DOWNLOAD_STATE:
            return { ...state, approvalMessages: action.payload }
        case types.DOCUMENT_EDIT_RETRIEVE_STATE:
            return { ...state, documentEditDetails: action.payload };
        case types.NOTICE_EDIT_RETRIEVE_STATE:
            return { ...state, noticeEditDetails: action.payload };
        case types.CATEGORY_LOOKUP_EDIT_NOTICE_STATE:
            return {
                ...state,
                categoryLookupListForEditNotice: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "type", label: item.name }
                })
            };
        default: return state;
    }
};