import axios from 'axios';
//import { REST_SERVICE_URL_ROOT, CLIENT_ID, CLIENT_SECRET } from '../config/settings';
import { REST_SERVICE_URL_ROOT } from '../config/settings';
import configureStore from '../app.modules';
import * as Actions from "../views/containers/Login/state/actions";

class ApiService {
    constructor() {

        axios.defaults.baseURL = REST_SERVICE_URL_ROOT;

        let service = axios.create();

        //Request Interceptor
        service.interceptors.request.use(function (config) {
            try {
                let token = configureStore().getState().auth.credentials.access;
                config.headers.common['Authorization'] = 'Bearer ' + token;
                config.headers.common['Content-Type'] = 'application/json';
                config.headers.common['Pragma'] = 'no-cache';
            } catch{
                console.log("Unauthorized");
            }

            // Do something before request is sent
            return config;
        }, function (error) {
            // Do something with request error
            this.handleError(error)
            return Promise.reject(error);
        });

        //Response Interceptor
        service.interceptors.response.use(this.handleSuccess, this.handleError);

        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    //Update actions for error handling
    handleError = (error) => {

        const status = error.response ? error.response.status : null;

        switch (status) {
            case 401:
                console.error(error);
                break;
            case 404:
                console.error(error);
                break;
            default:
                console.error(error);
                break;
        }
        return Promise.reject(error);
    }

    handleDisplayError = (error, data=null) => {
        var errorResponse = "エラーが発生しました。後ほどもう一度お試しください。";
        var files = [];
        
        if (error.response) {
            if(error.response.status === 400){
                if(error.response.data.length === 1) {
                    errorResponse = error.response.data
                }
                else if (error.response.data.non_field_errors) {
                    errorResponse = error.response.data.non_field_errors
                }
                else if (error.response.data.message) {
                    errorResponse = error.response.data.message;
                }
                else if (error.response.data.files){
                    for(var item of data.entries()){
                        if(item[0]==="files")
                        files.push(item[1].name)
                    }
                    errorResponse = "";
                    Object.entries(error.response.data.files).map((err) => {
                        errorResponse += files[err[0]] + ": " + err[1] + "<br/>"
                        return false;
                    })
                }
                else {
                    errorResponse = "";
                    Object.entries(error.response.data).map((err) => {
                        errorResponse += err[0] + ": " + err[1] + "<br/>"
                        return false;
                    })
                }
            }
            else if (error.response.status !== 500) {
                if (error.response.data && error.response.data.detail) {
                    errorResponse = error.response.data.detail;
                }
                else if (error.response.statusText) {
                    errorResponse = error.response.statusText;
                }
            }
        }
        return Promise.reject(errorResponse);
    }

    redirectTo = (document, path) => {
        document.location = path;
    }

    get(path, settings = {}) {
        return this.service.get(path, settings);
    }

    token(payload) {
        return this.service.request({
            method: 'POST',
            url: REST_SERVICE_URL_ROOT + "/api/token/",
            responseType: 'json',
            data: { username: payload.username, password: payload.password },
            headers: { "Authorization": "" }
        }).then((response) => { return response });
    }

    refreshToken() {
        let store = configureStore();
        let refresh = store.getState().auth &&
            store.getState().auth.credentials &&
            store.getState().auth.credentials.refresh;

        if (refresh) {
            let credentials = store.getState().auth.credentials;
            return this.service.request({
                method: 'POST',
                url: REST_SERVICE_URL_ROOT + "/api/token/refresh/",
                responseType: 'json',
                data: { refresh: refresh },
                headers: { "Authorization": "" }
            })
                .then((response) => {
                    store.dispatch(Actions.setUserAuth({
                        ...credentials,
                        access: response.data.access,
                        refresh: response.data.refresh || refresh
                    }, true));
                    return response
                })
                .catch((error) => {
                    if (error && error.response && error.response.status === 401) {
                        store.dispatch(Actions.setUserAuth(credentials, true, true));
                        throw error
                    }
                })
        } else {
            return Promise.resolve({})
        }
    }

    requests(settings) {
        return this.service.request(settings)
    }

    put(path, payload) {
        return this.service.request({
            method: 'PUT',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => { return response });
    }

    patch(path, payload) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => { return response });
    }

    post(path, payload) {
        return this.service.request({
            method: 'POST',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => { return response });
    }

    delete(path, payload) {
        return this.service.request({
            method: 'DELETE',
            url: path,
            responseType: 'json',
            data: payload
        }).then((response) => { return response });
    }
}

export default new ApiService();