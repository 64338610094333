import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withProductDraftConfirmModal = (WrappedComponent) => {
    return class ProductDraftConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductDraftConfirmModal: false
            }
        }

        productDraftConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showProductDraftConfirmModal} onHide={this.handleCloseDraftConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>商品情報の編集を確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            製品情報を編集します。 後ほど一覧画面で状態をご確認ください。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDraftConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal >
            )
        }

        handleCloseDraftConfirmModal = () => {
            this.setState({
                showProductDraftConfirmModal: false
            });
        }
    }
}

export {
    withProductDraftConfirmModal
}