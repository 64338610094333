import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withCommonKintoneSortingConfirmModal = (WrappedComponent) => {
    return class CommonKintoneSortingConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCommonKintoneSortingConfirmModal: false
            }
        }

        commonKintoneSortingConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showCommonKintoneSortingConfirmModal} onHide={this.handleCloseCommonKintoneSortingConfirmModal} centered>
                    <Modal.Header closeButton>
                        {/* Common attribute setting> Confirmation of change in display order */}
                        <Modal.Title>{"共通属性設定 > 表示順変更確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            共通属性設定の表示順を変更します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitCommonKintoneSorting}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseCommonKintoneSortingConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal >
            )
        }

        handleCloseCommonKintoneSortingConfirmModal = () => {
            this.setState({
                showCommonKintoneSortingConfirmModal: false
            });
        }

        handleSubmitCommonKintoneSorting = () => {
            Promise.all([
                this.showLoader(),
                this.props.patchSortingKintoneCommonField({ 'data': this.state.kintoneOrders }).then(
                    () => {
                        this.handleCloseCommonKintoneSortingModal();
                        if (this.state.submitCommonSubKintone) {
                            this.handleShowCommonSubTableFieldsModal(this.state.parentId, this.state.parentFieldLabel)
                        } else {
                            this.loadListFromServer();
                        }
                        this.loadListFromServer();
                        this.handleShowSuccessAlert(this.props.kintoneCommonMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseCommonKintoneSortingConfirmModal)
        }
    }
}

export {
    withCommonKintoneSortingConfirmModal
}