import reducer from "./reducers";
import { routes } from "../routes";
import * as adminNoticeOperations from "./operations";
import * as adminNoticeSelectors from "./selectors"

export {
    adminNoticeOperations,
    adminNoticeSelectors
};

const index = {
    routes,
    reducer
}

export default index;