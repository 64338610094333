import React from 'react'
import { Alert } from 'reactstrap';


const withSuccessAlert = (WrappedComponent) => {
    return class SuccessAlert extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showSuccessAlert: false,
                display: "d-none",
            }
        }

        dismissAlert = (props) => {
            return (
                <div className={"overlay " + this.state.display} onClick={this.handleCloseAlerts}></div>
            )
        }

        handleCloseAlerts = () => {
            this.setState({
                showSuccessAlert: false,
                showErrorAlert: false,
                display: "d-none"
            });
        }

        successAlert = (props) => {
            return (
                <Alert color="success" className="message position-fixed w-50 align-self-center" isOpen={props.showSuccessAlert} toggle={this.handleCloseSuccessAlert}>
                    <p className="m-0" dangerouslySetInnerHTML={{ __html:props.successMessage}}/>
                </Alert>
            )
        }

        handleShowSuccessAlert = (successMessage) => {
            this.setState({
                successMessage: successMessage,
                showSuccessAlert: true,
                display: "block"
            });
        }

        handleCloseSuccessAlert = () => {
            this.setState({
                showSuccessAlert: false,
                display: "d-none"
            });
        }
    }
}

export {
    withSuccessAlert
}