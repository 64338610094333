// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPaging } from '../../components/Pagination/Paging';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withErrorAlert } from '../../components/ErrorAlert';
import { adminEmailManagementOperations } from './state';
import MailDeliveryResultListTemplate from '../../components/AdminEmailManagement/MailDeliveryResultListTemplate';
import { withMailDeliveryResultDetailsModal } from '../../components/AdminEmailManagement/MailDeliveryResultDetailsModal';

class MailDeliveryResultScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            mailDeliveryResultDetails: null,
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getMailDeliveryResultList(
                this.state.itemsCountPerPage, this.state.activePage,
                this.state.filter_value, this.state.sort
            ).then(
                () => {
                    this.setState({
                        rows: this.props.mailDeliveryResultList,
                        totalItemsCount: this.props.totalItemsCount,
                        filterList: this.props.filterDeliveryResultList
                    })
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="list_of_admin_template">
                        <h4 className="mr-auto page-heading">{"メール管理 ＞ メール送信結果一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filter()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <MailDeliveryResultListTemplate
                            {...this.state}
                            handleShowMailDeliveryDetailsModal={this.handleShowMailDeliveryDetailsModal} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.mailDeliveryResultDetailsModal({ ...this.state })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        mailDeliveryResultList: state.adminEmailManagement.mailDeliveryResultList,
        totalItemsCount: state.adminEmailManagement.totalItemsCount,
        listOrdering: state.adminEmailManagement.mailDeliveryResultOrdering,
        defaultOrdering: state.adminEmailManagement.defaultMailDeliveryResultOrdering,
        filterDeliveryResultList: state.adminEmailManagement.filterDeliveryResultList
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getMailDeliveryResultList: adminEmailManagementOperations.getMailDeliveryResultList,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withSuccessAlert(
                withErrorAlert(
                    withMailDeliveryResultDetailsModal(
                        MailDeliveryResultScreen
                    )
                )
            )
        )
    )
);