import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipTemplate = (props) => {
    let { message } = props;

    return (
        <OverlayTrigger
            placement={props.placement ? props.placement : "right"}
            trigger={["hover", "focus"]}
            delay={{ show: 250, hide: 400 }}
            overlay={
                <Tooltip id="button-tooltip">
                    <div dangerouslySetInnerHTML={{ __html: message }}></div>
                </Tooltip>
            }>
            <FontAwesomeIcon icon={faQuestionCircle} />
        </OverlayTrigger>
    )
}

export default TooltipTemplate