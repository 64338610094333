import React from 'react'

const withMobileNavCollapser = (WrappedComponent) => {
    return class MobileNavCollapser extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                displayMobileOverlay: "d-none",
            }
        }

        dismissNav = (props) => {
            return (
                <div className={"overlay " + this.state.displayMobileOverlay} style={{backgroundColor: "#dee2e6", zIndex: 1020, opacity: 0.5}} onClick={this.handleCloseBackGround}></div>
            )
        }

        handleCloseBackGround = () => {
            this.setState({
                displayMobileOverlay: "d-none"
            });
        }

        handleShowBackGround = () => {
            this.setState({
                displayMobileOverlay: "block"
            })
        }
    }
}

export {
    withMobileNavCollapser
}