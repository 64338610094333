import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-regular-svg-icons'
import * as Commons from '../Commons';


const ProductInformationTemplate = (props) => {
    let {
        rows,
        handleShowProductDeleteConfirmationModal,
        handleShowDraftModal,
        handleShowProductDynamicFieldsModal
    } = props;

    return (
        <section className="list mt-3 mb-3">
            {props.totalItemsCount > 0 ?
                rows.map((product) => {
                    var statusBg = Commons.getProductStatusBackground(product.status);
                    var customStyle = { backgroundColor: statusBg, border: "solid 1px" + statusBg };
                    var disabled_link = product.is_service_portal ? "disabled" : "";
                    return (
                        <div className="px-3 py-3 list-group-item table-layout" key={product.id}>
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute">
                                        <span className="custom-badge mr-2" style={{ background: product.bg_color }}>
                                            {product.name}
                                        </span>
                                        <span>
                                            <a className="list-item-title btn-action font-weight-bold" href="# " onClick={(event) => { handleShowDraftModal(product.id, event) }}>
                                                {product.long_name}
                                            </a>
                                        </span>
                                        <span className="product-status rounded text-center px-2 mb-0 float-right text-white" style={customStyle}>
                                            {product.status}
                                        </span>
                                    </div>
                                    {
                                        !product.is_service_portal ?
                                        <div className="list-item-attribute pt-1 row">
                                            <div className="col-lg-3 col-sm-12 pr-0">
                                                <strong>サービス開始: </strong>
                                                <label className="mb-0">{Commons.getClientDatetimeDisplay(product.start_date)}</label>
                                            </div>
                                            <div className="col-lg-3 col-sm-12 pr-0">
                                                <strong>サービス終了: </strong>
                                                <label className="mb-0">{product.end_date ? Commons.getClientDatetimeDisplay(product.end_date) : "----/--/-- --:--"}</label>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="list-item-attribute pt-1">
                                        <strong>投稿ADグループ: </strong>
                                        {
                                            product.poster_ad_group.join(', ')
                                        }
                                    </div>
                                    <div className="list-item-attribute">
                                        <strong>承認ADグループ: </strong>
                                        {
                                            product.approver_ad_group.join(', ')
                                        }
                                    </div>
                                    <ul className="list-item-actions nav justify-content-end">
                                        <li className="nav-item">
                                            <a className={`nav-link py-0 px-3 btn-action ${disabled_link}`} href="# " onClick={() => { handleShowProductDynamicFieldsModal(product.id) }}>
                                                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWrench} /><span className="pl-1">属性設定</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={"nav-link py-0 px-3 btn-action"} href="# " onClick={(event) => { handleShowDraftModal(product.id, event) }}>
                                                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1">修正</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link py-0 pl-3 pr-0 btn-action ${disabled_link}`} href="# " onClick={() => { handleShowProductDeleteConfirmationModal(product.id) }} >
                                                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">削除</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })
                : <h4>検索の結果、0件でした。</h4>
            }

        </section>
    );
}

export default ProductInformationTemplate;
