import reducer from "./reducers";
import { routes } from "../routes";
import * as adminAccountManagementOperations from "./operations";
import * as adminAccountManagementSelectors from "./selectors"

export {
    adminAccountManagementOperations,
    adminAccountManagementSelectors
};

const index = {
    routes,
    reducer
}

export default index;