import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faEdit, faTable } from '@fortawesome/free-solid-svg-icons'
import TooltipTemplate from '../TooltipTemplate';

const withCommonDynamicSubTableFieldsModal = (WrappedComponent) => {
    return class CommonDynamicSubTableFieldsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCommonDynamicSubTableFieldsModal: false
            };
            this.parentObject = {};
            this.parentObjectList = [];
        }

        commonDynamicSubTableFieldsModal = (props) => {
            let { commonDynamicSubTableFields } = this.state;
            let okButtonAction = this.handleCloseCommonSubTableDynamicFieldsModal;

            let cancelButtonAction = this.handleCloseCommonSubTableDynamicFieldsModal;

            let parentFieldLabelList = this.parentObjectList.map(parentObject => parentObject.parentFieldLabel);
            let fieldLabel = parentFieldLabelList.join(' ＞ ');

            return (
                <Modal show={this.state.showCommonDynamicSubTableFieldsModal} keyboard={false} size="lg" onHide={cancelButtonAction} className="modal-add-common-dynamic-fields" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>共通属性設定 ＞ {fieldLabel}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="w-100">
                            <div className="font-weight-bold d-flex">
                                <label className="mb-0 mr-1">共通属性設定</label>
                                <div>
                                    <TooltipTemplate message="Kintoneからサービスポータルに取り込むフィールドコードを「項目」に、サービスポータル上での表示タイトル名を「表示ラベル」に設定してください。" />
                                </div>
                            </div>
                        </div>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-2">
                            <div className="form-inline mr-auto">
                                <div className="btn-group navbar-left">
                                    <button className="btn btn-secondary" value="共通属性設定" onClick={this.handleShowModalNewSubKintone}>属性設定登録</button>
                                    <button className="btn btn-secondary" value="Sorting" onClick={this.handleShowCommonKintoneSortingModal}>表示順変更</button>
                                </div>
                            </div>
                        </nav>

                        <Form id={'form-add-product-sub-table-dynamic-fields'}>
                            <table className="table table-bordered table-striped table-form">
                                <tbody>
                                    <tr className="d-flex w-100">
                                        <th className="sub-table-column-1 disable-table font-weight-bold align-middle text-center">項⽬</th>
                                        <th className="sub-table-column-2 disable-table font-weight-bold align-middle text-center">表⽰ラベル</th>
                                        <th className="sub-table-column-3 disable-table font-weight-bold text-center">顧客画面<br />表示</th>
                                        <th className="sub-table-column-4 disable-table font-weight-bold"></th>
                                        <th className="sub-table-column-4 disable-table font-weight-bold"></th>
                                        <th className="sub-table-column-4 disable-table font-weight-bold"></th>
                                    </tr>
                                    {commonDynamicSubTableFields.length === 0 && props.subTotalItemCount === 0 ? <tr><td className="font-weight-normal d-inline-block w-100"><center>設定情報はありません。</center></td></tr> : null}
                                    {commonDynamicSubTableFields.length !== 0 && commonDynamicSubTableFields.map((field) => {
                                        return (
                                            <tr key={field.id} className={"d-flex w-100"}>
                                                <td className="align-middle sub-table-column-1 table-column-1-column custom-border"><div className="dynamic-content"><label className="mb-0">{field.field_name}</label></div></td>
                                                <td className="align-middle sub-table-column-2 table-column-2-column custom-border"><div className="dynamic-content"><label className="mb-0">{field.field_label}</label></div></td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-3 custom-border">
                                                    {field.show_customer && "〇"}
                                                </td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-4 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faEdit} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowCommonKintoneEditLabelSubTableModal(field.id, field.field_name, field.field_label, field.is_table, field.show_customer) }} />
                                                    </div>
                                                </td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-4 custom-border p-0 dynamic-icon-container">
                                                    {field.is_table &&
                                                        <div>
                                                            <FontAwesomeIcon icon={faTable} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowCommonSubTableFieldsModal(field.id, field.field_label) }} />
                                                        </div>
                                                    }
                                                </td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-4 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faWindowClose} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowDeleteKintoneCommonSubTableConfirmation(field.id) }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                            <div className="form-group">
                                <div className="float-right">
                                    <Button variant="primary" className="btn btn-90 mr-2" onClick={okButtonAction}>Ok</Button>
                                    <Button variant="secondary" className="btn btn-90" onClick={cancelButtonAction}>キャンセル</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            );
        }

        handleSaveParentKintoneCommonData = (parentId, parentFieldLabel) => {
            let object = {
                parentId: parentId,
                parentFieldLabel: parentFieldLabel
            }
            const isParentIdExsited = this.parentObjectList.some(object => object.parentId === parentId);
            if (!isParentIdExsited) {
                this.parentObjectList.push(object);
            }
        }

        handleDeleteParentKintoneCommonData = () => {
            this.parentObjectList.pop();
            if (this.parentObjectList.length > 0) {
                this.parentObject = this.parentObjectList[this.parentObjectList.length - 1];
            }
        };

        handleShowCommonSubTableFieldsModal = (kintone_id, field_label) => {
            Promise.all([
                this.showLoader(),
                this.props.getKintoneCommonSubTableFieldList(kintone_id).then(
                    () => {
                        this.setState({
                            parentId: kintone_id,
                            parentFieldLabel: field_label,
                            commonDynamicSubTableFields: this.props.commonDynamicSubTableFields,
                            showCommonDynamicSubTableFieldsModal: true,
                            submitCommonSubKintone: true
                        }, () => this.handleSaveParentKintoneCommonData(kintone_id, field_label));
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseCommonSubTableDynamicFieldsModal = () => {
            this.handleDeleteParentKintoneCommonData();
            if (this.parentObjectList.length === 0) {
                this.setState({
                    showCommonDynamicSubTableFieldsModal: false,
                    commonDynamicSubTableFields: [],
                    submitCommonSubKintone: false,
                });
            } else {
                this.handleShowCommonSubTableFieldsModal(this.parentObject.parentId, this.parentObject.parentFieldLabel);
            }
        }

        handleShowDeleteKintoneCommonSubTableConfirmation = (kintone_id) => {
            this.setState({
                showDeleteKintoneCommonProductConfirmation: true,
                kintoneDeleteId: kintone_id
            })
        }

        handleShowModalNewSubKintone = () => {
            this.setState({
                showModalNewKintoneCommon: true
            })
        }

        handleShowCommonKintoneEditLabelSubTableModal = (id, field_name, field_label, is_table, show_customer) => {
            this.setState({
                initialKintoneFormValue: {
                    KintoneProductForm: {
                        id: id,
                        kintone_attribute_name: field_name,
                        display_label: field_label,
                        format_data: is_table ? "1" : "0",
                        show_customer: show_customer
                    }
                },
                showCommonKintoneEditLabel: true,
                commonKintoneFieldId: id,
            });
        }
    }
}

export {
    withCommonDynamicSubTableFieldsModal
}