import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { rootRoutes } from '../../app.modules';
import Logo from '../../includes/img/SolitonCloudServicePortal_icon.svg';
import UserLogo from '../../includes/img/user-solid.svg';
import { LOGIN_ROUTE, LOGIN_LABEL } from '../containers/Login/routes';
import { ADMIN_LOGIN_ROUTE } from '../containers/AdminLogin/routes';
import { NOTICE_ROUTE } from '../containers/Notice/routes';
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBContainer
} from 'mdbreact';

const UserMenu = (props) => {
    let menu = undefined;

    if (props.isAuthenticated) {
        menu =
        <li className="nav-item dropdown">
            <a className={"nav-link px-2 dropdown-toggle dropdown mobile-dropdown"} href="# " id="auth-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.username}&nbsp;
            </a>
            <div className="dropdown-menu auth-dropdown" aria-labelledby="auth-dropdown">
                {
                    props.isAdminPage || props.isSuperuser ?
                        <>
                        <NavLink exact className="nav-link mobile-link dropdown-item p-2 px-3" to={props.adminLink}>管理画面に切り替え</NavLink>
                        <a className="nav-link dropdown-item p-2 px-3" href="# " onClick={props.handleShowAuthPasswordChangeModal}>パスワード変更</a>
                        </>
                        : ""
                }

                <a className="nav-link dropdown-item p-2 px-3" href="# " onClick={props.handleShowLogoutModal}>ログアウト</a>
            </div>
        </li>;
    }
    else if (props.location.pathname === "/admin/login" || props.location.pathname === "/admin/password/update") {
        menu =
        <li className="nav-item">
            <NavLink className="nav-link mobile-link" href={ADMIN_LOGIN_ROUTE} to={ADMIN_LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
        </li>;
    }
    else {
        menu =
        <li className="nav-item">
            <NavLink className="nav-link mobile-link" href={LOGIN_ROUTE} to={LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
        </li>
    }

    return menu;
}

const MobileMainHeader = (props) => {
    var adminLink = null;
    if (props.isSuperuser || props.isPoster) {
        adminLink = "/admin/noticepost";
    }
    else if (props.isApprover) {
        adminLink = "/admin/approvalpending";
    }
    else if (props.isPortalAdmin) {
        adminLink = "/admin/portalusers";
    }
    else {
        adminLink = "/admin";
    }

    return (
        <>
            <header className="mobile-header py-3">
                <MDBContainer className="p-0 mw-100">
                    <MDBNavbar
                        color='light-blue lighten-4'
                        style={{ margin: '0' }}
                        light
                    >
                        <MDBContainer className="p-0 d-flex">
                            <MDBNavbarToggler
                                onClick={props.toggleCollapse('navbarCollapse1')}
                            />
                            <Nav.Link as={Link} href={NOTICE_ROUTE} to={NOTICE_ROUTE} className="py-0 px-0 soliton_logo">
                                <img src={Logo}
                                    className="header-logo"
                                    alt="Soliton サービスポータル" />
                            </Nav.Link>
                            <MDBNavbarToggler image={UserLogo}
                                onClick={props.toggleCollapse('navbarCollapse2')}
                            />
                            <MDBCollapse
                                id='navbarCollapse1'
                                isOpen={props.collapseID}
                                navbar
                            >
                                <MDBNavbarNav left>
                                    {
                                        rootRoutes.map((route, i) => {
                                            let link = undefined;

                                            if (route.path && (route.showNavPublic || (route.showNavPrivate && props.isAuthenticated))) {
                                                link =
                                                <li className="nav-item dropdown" key={i} >
                                                    <NavLink exact className="nav-link mobile-link px-2" href={route.path} to={route.path}>{route.label}</NavLink>
                                                </li>;
                                            }
                                            else {
                                                link = "";
                                            }
                                            return link;
                                        })
                                    }
                                </MDBNavbarNav>
                            </MDBCollapse>
                            <MDBCollapse
                                id='navbarCollapse2'
                                isOpen={props.collapseID}
                                navbar
                            >
                                <MDBNavbarNav left>
                                    <UserMenu {...props} adminLink={adminLink} />
                                </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBContainer>
                    </MDBNavbar>
                </MDBContainer>
            </header>
        </>
    );
};

export default MobileMainHeader;