import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withNewKintoneConfirmModal = (WrappedComponent) => {
    return class NewKintoneConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNewKintoneConfirmModal: false
            }
        }

        newKintoneConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNewKintoneConfirmModal} onHide={this.handleCloseNewKintoneConfirmModal} centered>
                    <Modal.Header closeButton>
                        {/* Attribute setting> Additional confirmation */}
                        <Modal.Title>{"属性設定 > 追加確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            属性情報を登録します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.handleSubmitKintoneConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseNewKintoneConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleCloseNewKintoneConfirmModal = () => {
            this.setState({
                showNewKintoneConfirmModal: false
            })
        }

        handleSubmitKintoneConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let payload = this.state.kintoneData.payload

            Promise.all([
                this.showLoader(),
                this.props.postCreateKintoneProductField(payload).then(
                    () => {
                        this.handleCloseModalNewKintone();
                        if (this.state.submitSubKintone) {
                            this.handleShowKintoneProductSubTableFieldsModal(this.state.kintoneProductId, this.state.parentId, this.state.parentFieldLabel, this.state.parentShowCustomer);
                        } else {
                            this.handleShowProductDynamicFieldsModal(this.state.kintoneProductId);
                        }
                        this.handleShowSuccessAlert(this.props.kintoneMessage);
                        this.setState({ controlPanelURLField: null });
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseNewKintoneConfirmModal)
        }
    }
}

export {
    withNewKintoneConfirmModal
}