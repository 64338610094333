import React from 'react';
import LoginErrorModal from './LoginErrorModal';
import clearAuth from '../../utils/clearAuth';


const withLoginCheck = (WrappedContent) => {
    return class ExpiredTokenCheck extends WrappedContent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showLoginError: true,
            }
        }

        loginError = (props) => {
            return (
                <LoginErrorModal
                    login_route={props.isAdminPage ? "/admin/login" : "/login"}
                    showModal={props.showLoginError}
                    handleCloseModal={this.handleCloseLoginErrorModal} />
            )
        }

        handleCloseLoginErrorModal = () => {
            this.setState({
                showLoginError: false
            })
            clearAuth();
        }
    }
}

export {
    withLoginCheck
}