import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate'
import * as Commons from '../Commons';

const withAdminApprovalEditNoticeModal = (WrappedComponent) => {
    return class AdminApprovalEditNoticeModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showApprovalEditNotice: false,
                templateDataChoosen: null,
            }
        }

        adminApprovalEditNoticeModal = (props) => {
            let areAllDefault = props.currentNoticeFormValues && props.initialNoticeFormValue ?
                props.currentNoticeFormValues.type.toString() === props.initialNoticeFormValue.PostForm.type.toString() &&
                props.currentNoticeFormValues.title === props.initialNoticeFormValue.PostForm.title &&
                props.currentNoticeFormValues.content === props.initialNoticeFormValue.PostForm.content &&
                props.currentNoticeFormValues.publish_date.toString() === props.initialNoticeFormValue.PostForm.publish_date.toString() &&
                props.currentNoticeFormValues.publish_type.toString() === props.initialNoticeFormValue.PostForm.publish_type.toString() ? true : false : true

            let { formNoticeInput } = props;

            // eslint-disable-next-line array-callback-return
            formNoticeInput.form.map((item) => {
                if (item.name === "product" && item.type === "select") {
                    item.type = "textarea";
                    item.disabled = true;
                }
            });

            return (
                <Modal show={this.state.showApprovalEditNotice} keyboard={false} size="lg" onHide={areAllDefault ? this.handleCloseApprovalEditNoticeModal : this.handleShowDiscardModal} className="modal-edit-post" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ修正</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={formNoticeInput}
                            radioInput={props.radioInput}
                            selectInput={props.selectInput}
                            initialValues={props.initialNoticeFormValue}
                            publishDate={props.noticeEditDetails.publish_preferred_date === null ? "0" : "1"}
                            publishPreferredDate={Commons.getClientDatetimeDisplay(props.noticeEditDetails.publish_preferred_date)}
                            editMode={true}
                            validate={props.adminApprovalSelectors.checkNoticeErrors}
                            handleSubmitDraftModal={this.handleSubmitDraftModal}
                            form={"admin-edit-notice-from-approval"}
                            handleFormOnchange={this.handleFormOnchange}
                            handleShowChooseTemplateModal={this.handleShowChooseTemplateModal}
                            templateDataChoosen={this.state.templateDataChoosen}
                            showApprovalEditNotice={this.state.showApprovalEditNotice}
                            handleSubmitApprovalEditNoticeModal={this.handleSubmitApprovalEditNoticeModal}
                            productId={this.state.productId}
                        />
                    </Modal.Body>
                    <Modal.Footer className="pt-0 border-top-0">
                        <div className="w-100 admin-draft-modal-footer">
                            <div className="w-100 d-inline-flex">
                                <input type="hidden" name="submit_action" />
                                <button type="button" form="admin-edit-notice-from-approval" className="btn btn-90 mr-2 ml-auto btn-success" onClick={() => {
                                    document.querySelector('.modal.show .submitApprovalNoticeDocument').click()
                                }}>承認</button>
                                <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseApprovalEditNoticeModal : this.handleShowDiscardModal}>キャンセル</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowApprovalEditNoticeModal = (value) => {
            Promise.all([
                this.showLoader(),
                this.props.getNoticeEditDetails(value, this.state.showEditNoticeModalFromApprovalModal).then(
                    () => {
                        this.setState({
                            initialNoticeFormValue: {
                                PostForm: {
                                    post_id: this.props.noticeEditDetails.post_id,
                                    type: this.props.noticeEditDetails.post_category_id,
                                    product: this.props.noticeEditDetails.product_name,
                                    title: this.props.noticeEditDetails.title,
                                    content: this.props.noticeEditDetails.content,
                                    publish_date: this.props.noticeEditDetails.publish_preferred_date === null ? "0" : "1",
                                    publish_preferred_date: this.props.noticeEditDetails.publish_preferred_date,
                                    publish_type: this.props.noticeEditDetails.publish_type,
                                    remarks: this.props.noticeEditDetails.remarks ? this.props.noticeEditDetails.remarks : ""
                                }
                            },
                            productId: this.props.noticeEditDetails.product_id,
                            showApprovalEditNotice: true
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseApprovalEditNoticeModal = () => {
            this.setState({
                showApprovalEditNotice: false,
                templateDataChoosen: null
            });
        }

        handleSetValueTitleContent = (title, content) => {
            this.setState({
                templateDataChoosen: {
                    'title': title,
                    'content': content
                },
            })
        }

        handleSubmitApprovalEditNoticeModal = (values) => {
            let id = this.props.noticeApprovalDetails.application_id;
            let preferred_date = values.publish_date === "1" ?
                Commons.getServerDatetime(values.publish_preferred_date) : null;
            let formattedValue = {
                category_id: values.type,
                publish_preferred_date: preferred_date,
                publish_type: values.publish_type,
                title: values.title,
                content: values.content
            }

            this.setState({
                templateDataChoosen: null
            })

            formattedValue.application_status = 1;
            formattedValue.isEditApproval = true;

            Promise.all([
                this.showLoader(),
                this.props.patchApprovalDetails(id, formattedValue).then(
                    () => {
                        this.loadListFromServer();
                        this.handleCloseNoticeApprovalModal();
                        this.handleCloseDocumentApprovalModal();
                        this.handleShowSuccessAlert(this.props.approvalMessages);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.handleCloseApprovalEditNoticeModal)
            .then(this.closeLoader)
        }
    }
}

export {
    withAdminApprovalEditNoticeModal
}