import React from 'react'

const AdminNoticeButtons = (props) => {
    let { handleShowModalNewPost, isNoticeTemplate } = props
    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value={isNoticeTemplate ? "新規作成" : "新規投稿"}
                    onClick={handleShowModalNewPost}>{isNoticeTemplate ? "新規作成" : "新規投稿"}</button>
            </div>
        </div>
    );
}

export default AdminNoticeButtons