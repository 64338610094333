import * as Path from './apiRoutes';
import * as Actions from "./actions";
import apiService from '../../../../utils/apiService';

export const getNoticeList = (
    itemsCountPerPage, activePage, query,
    category_number, product_number, sort) => (dispatch) => {
        return apiService.refreshToken()
            .then(() => {
                return apiService.requests({
                    method: 'GET',
                    url: Path.NOTICE,
                    responseType: 'json',
                    params: {
                        num: itemsCountPerPage,
                        page: activePage,
                        search: query,
                        post_category: category_number,
                        product: product_number,
                        ordering: sort
                    }
                })
                    .then((response) => {
                        dispatch(Actions.readState(response.data));
                    })
            })
            .catch(apiService.handleDisplayError);
    }

export const getNoticeDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE + id + '/').then((response) => {
                dispatch(Actions.noticeDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticePublishedVersionDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_PUBLISHED_VERSION + id + '/').then((response) => {
                dispatch(Actions.noticePublishedVersionDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const postNoticeCreateDetails = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.post(Path.NOTICE_CREATE, data).then((response) => {
                dispatch(Actions.postNoticeCreateDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeEditDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_UPDATE + id + '/').then((response) => {
                dispatch(Actions.getNoticeEditDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const putNoticeEditDetails = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.put(Path.NOTICE_UPDATE + id + '/', data).then((response) => {
                dispatch(Actions.putNoticeEditDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchNoticeUnpublish = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.NOTICE_UNPUBLISH + id + '/', data).then((response) => {
                dispatch(Actions.patchNoticeUnpublishState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchNoticeCancel = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.NOTICE_CANCEL + id + '/', data).then((response) => {
                dispatch(Actions.patchNoticeCancelState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const patchNoticeDelete = (id, data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.patch(Path.NOTICE_UPDATE + id + '/', data).then((response) => {
                dispatch(Actions.patchNoticeDeleteState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeProductList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'GET',
                url: Path.PRODUCTLOOKUP,
                responseType: 'json',
                params: {
                    access_type: '1',
                }
            })
                .then((response) => {
                    dispatch(Actions.productLookupListState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeCategoryList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CATEGORYLOOKUP).then((response) => {
                dispatch(Actions.categoryLookupListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeHistoryDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.NOTICE_HISTORY_DETAILS + id + '/').then((response) => {
                dispatch(Actions.getNoticeHistoryDetailsState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getNoticeTemplateList = (itemsCountPerPage, activePage, query,
    product_number, sort) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.requests({
                method: 'GET',
                url: Path.NOTICE_TEMPLATE_LIST,
                responseType: 'json',
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    product: product_number,
                    ordering: sort
                }
            })
            .then((response) => {
                dispatch(Actions.getNoticeTemplateListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
};

export const getNoticeTemplateDetail = (id) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.NOTICE_TEMPLATE_CRUD + id + '/')
        .then((response) => {
            dispatch(Actions.getNoticeTemplateDetailState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
};

export const createNoticeTemplate = (data) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.post(Path.NOTICE_TEMPLATE_CRUD, data)
        .then((response) => {
            dispatch(Actions.createNoticeTemplateState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
};

export const updateNoticeTemplate = (data) => (dispatch) => {
    let id = data.id;
    delete data.id;
    return apiService.refreshToken()
    .then(() => {
        return apiService.put(Path.NOTICE_TEMPLATE_CRUD + id + '/' , data)
        .then((response) => {
            dispatch(Actions.updateNoticeTemplateState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
};

export const deleteNoticeTemplate = (id) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.delete(Path.NOTICE_TEMPLATE_CRUD + id + '/')
        .then((response) => {
            dispatch(Actions.deleteNoticeTemplateState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
};

export const getNoticeTemplateLookup = (id, itemsCountPerPage, activePage, query, sort) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.requests({
            method: 'GET',
            url: Path.NOTICE_TEMPLATE_LOOKUP + id +'/',
            responseType: 'json',
            params: {
                num: itemsCountPerPage,
                page: activePage,
                search: query,
                ordering: sort
            }
        }).then((response) => {
            dispatch(Actions.getNoticeTemplateLookupState(response.data));
        })
    })
    .catch(apiService.handleDisplayError);
};
