import React from 'react'
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate';


const withAdminNoticeTemplateDetailModal = (WrappedComponent) => {
    return class AdminNoticeTemplateDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalTemplateDetail: false,
            }
        }

        adminNoticeTemplateDetailModal = (props) => {
            let template = [];

            if (props.isNoticeTemplate && this.state.showModalTemplateDetail) {
                const { noticeTemplateDetail } = props;
                const formInputs = props.formInputs;
                // eslint-disable-next-line array-callback-return
                formInputs.form.map((item) => {
                    item.disabled = true;
                    if (item.name === "product" && item.type === "select") {
                        item.type = "textarea";
                    }
                });

                var initialTemplateFormValue = {
                    TemplateForm: {
                        template_id: noticeTemplateDetail.id,
                        note: noticeTemplateDetail.note,
                        product: noticeTemplateDetail.product_name,
                        title: noticeTemplateDetail.title,
                        content: noticeTemplateDetail.content,
                        name: noticeTemplateDetail.name
                    }
                };

                template =
                    <Modal className="modal-dialog-lg admin-notice-details" keyboard={false} size="lg" show={this.state.showModalTemplateDetail} onHide={this.handleCloseModalTemplateFormDetail} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>テンプレート管理 ＞ テンプレート詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4 pb-0">
                            <AdminFormTemplate
                                formInputs={formInputs}
                                initialValues={initialTemplateFormValue}
                                form={"admin-template-form" + noticeTemplateDetail.id}
                                handleFormOnchange={this.handleFormOnchange}
                                showModalTemplateDetail={this.state.showModalTemplateDetail}
                            />
                        </Modal.Body>
                        <Modal.Footer className="px-0 pt-0 border-top-0">
                            <div className="d-inline-flex">
                                <button className="btn btn-200 btn-secondary mr-4" onClick={this.handleCloseModalTemplateFormDetail}>テンプレート一覧に戻る</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
            }
            return template;
        }

        handleShowModalTemplateFormDetail = (id, event) => {
            event.target.blur();
            Promise.all([
                this.showLoader(),
                this.props.getNoticeTemplateDetail(id)
                .then(() => {
                    this.setState({
                        showModalTemplateDetail: true,
                    });
                }),
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseModalTemplateFormDetail = () => {
            this.setState({
                showModalTemplateDetail: false,
            });
        }
    }
}

export {
    withAdminNoticeTemplateDetailModal
}