import React from 'react';
import { Button, ButtonToolbar, FormCheck, Alert, FormLabel, FormGroup } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { isNullOrUndefined } from 'util';
import TooltipTemplate from './TooltipTemplate';
import DateTime from "@nateradebaugh/react-datetime";
import "@nateradebaugh/react-datetime/scss/styles.scss";
import { DATETIME_FORMAT_3 } from '../../utils/constants';
import FormikEffect from './FormikEffect';
import { Field } from 'formik';

let AdminFormTemplate = (props) => {
    //Initial values
    let initialValues = props.initialValues;
    let initialPublishPreferredDate = null;
    (props.publishDate === "1") ?
        initialPublishPreferredDate = new Date(props.publishPreferredDate) :
        initialPublishPreferredDate = new Date();
    const [dateValue, setValue] = React.useState(new Date(initialPublishPreferredDate))

    if (isNullOrUndefined(props.initialValues)) {
        initialValues = {};
        //Initial value if tabbed or single
        if (isNullOrUndefined(props.initialValues)) {
            if (props.tabbed || props.nested) {
                props.multiFormInputs.map(tab => {
                    let modelValues = {};
                    tab.form.map(input => (
                        input.type === "datepicker" ? modelValues[input.name] = moment()
                            : modelValues[input.name] = input.default
                    ));
                    initialValues[tab.model] = modelValues;
                    return initialValues[tab.model];
                });
            } else {
                let modelValues = {};
                props.formInputs.form.map(input => (
                    input.type === "datepicker" ? modelValues[input.name] = moment()
                        : modelValues[input.name] = input.default)
                );
                initialValues[props.formInputs.model] = modelValues;
            }
        }
    }

    const CustomError = (error, displayError) => {
        return displayError ? <p className="formik-error">{error}</p> : null;
    }

    function RenderInput(model, input, fieldValue, setFieldValue, errors, touched) {
        let modelField = `${model}.${input.name}`;

        switch (input.type) {

            //renders file upload
            case "file": {
                let modelField = `${model}.${input.name}`;
                let defaultClass = "form-input";
            
                return (
                    <FormGroup key={input.name} className={defaultClass}>
                        <FormLabel >{input.label}</FormLabel>
                        <br />
                        <input name={modelField} type="file" onChange={(event) => {
                            setFieldValue(modelField, event.target.files[0]);
                        }} />
                    </FormGroup>
                );
            }

            //renders single checkbox field
            case "file-checkbox": {
                let defaultClass = "form-input";
                const { setUploadedFiles } = props
                var upload_files = props.uploaded_files;
            
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2 file">
                            <div className="d-flex">
                                <div id="delete-label" className={props.files ? props.files.filter((item) => { return Boolean(item.file_name) }).length !== 0 ? "col-sm-7" : "col-sm-7 text-transparent" : "col-sm-7 text-transparent"}><label className="mb-0">削除</label></div>
                                <div className="col-sm-5">
                                    <FormGroup className={defaultClass + " mb-0"}>
                                        <FormLabel className="mb-0"><a href="/#" onClick={(event) => {
                                            event.preventDefault();
                                            let link = document.querySelector('input.input-file');
                                            link.click();
                                        }}>添付ファイルを選択</a></FormLabel>
                                        <br />
                                        <input className="input-file" name="DocumentForm.files" type="file" multiple onChange={(event) => {
                                            let deleteListTemplate = "";
                                            let errorFiles = "";
                                            for (let i = 0; i < event.target.files.length; i++) {
                                                if (event.target.files[i].name.length > 100) {
                                                    errorFiles += event.target.files[i].name + "\n";
                                                }
                                                else {
                                                    upload_files.push(event.target.files[i])
                                                }
                                            }
                                            let toDeleteCheckedItems = document.querySelectorAll('.delete-file-list input:checked');
                                            let checkedItems = [];
                                            for (let i = 0; i < toDeleteCheckedItems.length; i++) {
                                                checkedItems.push(toDeleteCheckedItems.item(i).id);
                                            }
                                            for (let i = 0; i < upload_files.length; i++) {
                                                let isChecked = checkedItems.indexOf(`delete-file-${i}`) !== -1;
                                                let template =
            
                                                    '<div class="col-sm-12">' +
                                                    '<div class="form-check file">' +
                                                    `<input id="delete-file-${i}" name="DocumentForm.delete_files" type="checkbox" class="form-check-input" value="${upload_files[i].name}" ${isChecked ? "checked" : ""}>` +
                                                    `<label for="delete-file-${i}" title="${upload_files[i].name}" type="checkbox" class="d-inline form-check-label">${upload_files[i].name}</label>` +
                                                    '</div>' +
                                                    '</div>';
            
                                                deleteListTemplate += template;
                                            }
                                            if (errorFiles) {
                                                props.handleShowErrorAlert("Filename of following files exceeds 100 characters: \n" + errorFiles)
                                                let input_file_field = document.querySelector('.input-file');
                                                input_file_field.value = "";
                                                if (!/safari/i.test(navigator.userAgent)) {
                                                    input_file_field.type = "";
                                                    input_file_field.type = "file";
                                                }
                                            }
                                            if (upload_files.length !== 0) {
                                                document.getElementById("delete-label").setAttribute("class", "col-sm-7")
                                                setFieldValue("DocumentForm.files", upload_files);
                                                setFieldValue("DocumentForm.attachment", upload_files);
                                            }
                                            setUploadedFiles(upload_files)
                                            document.querySelector('.delete-file-list').innerHTML = deleteListTemplate
                                        }} />
                                    </FormGroup>
                                </div>
                            </div>
                            {props.files ?
                                props.files.map((file, index) => {
                                    if (file.file_name) {
                                        return (
                                            <div className="col-sm-12" key={index}>
                                                <div className="file form-check">
                                                    <input id={"delete-file-" + file.id} name="DocumentForm.delete_files" type="checkbox" className="form-check-input" value={file.file_name} />
                                                    <label htmlFor={"delete-file-" + file.id} title={file.file_name} type="checkbox" className="d-inline form-check-label">{file.file_name}</label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return ""
                                    }
                                }) : ""
                            }
                            <div className="delete-file-list"></div>
                        </td>
                    </tr>
                )
            }

            case "files": {
                const { handleDocumentFileDownload } = props
                var docFiles = null;
                var hasFiles = null;
                var hasExtFiles = null;
            
                switch (props.formInputs.model) {
                    case "DocumentDetailPostForm":
                        if (props.initialValues.DocumentDetailPostForm.files) {
                            docFiles = props.initialValues.DocumentDetailPostForm.files;
                            hasFiles = props.initialValues.DocumentDetailPostForm.files ? props.initialValues.DocumentDetailPostForm.files.some(file => Boolean(file.file_name) === true) : false;
                            hasExtFiles = props.initialValues.DocumentDetailPostForm.files ? props.initialValues.DocumentDetailPostForm.files.some(file => Boolean(file.url) === true) : false;
                        }
                        break;
                    case "DocumentHistoryPostForm":
                        if (props.initialValues.DocumentHistoryPostForm.files) {
                            docFiles = props.initialValues.DocumentHistoryPostForm.files;
                            hasFiles = props.initialValues.DocumentHistoryPostForm.files ? props.initialValues.DocumentHistoryPostForm.files.some(file => Boolean(file.file_name) === true) : false;
                            hasExtFiles = props.initialValues.DocumentHistoryPostForm.files ? props.initialValues.DocumentHistoryPostForm.files.some(file => Boolean(file.url) === true) : false;
                        }
                        break;
                    case "ApprovalDocumentForm":
                        if (props.initialValues.ApprovalDocumentForm.files) {
                            docFiles = props.initialValues.ApprovalDocumentForm.files;
                            hasFiles = props.initialValues.ApprovalDocumentForm.files ? props.initialValues.ApprovalDocumentForm.files.some(file => Boolean(file.file_name) === true) : false;
                            hasExtFiles = props.initialValues.ApprovalDocumentForm.files ? props.initialValues.ApprovalDocumentForm.files.some(file => Boolean(file.url) === true) : false;
                        }
                        break;
                    case "ApprovalHistoryDocumentForm":
                        if (props.initialValues.ApprovalHistoryDocumentForm.files) {
                            docFiles = props.initialValues.ApprovalHistoryDocumentForm.files;
                            hasFiles = props.initialValues.ApprovalHistoryDocumentForm.files ? props.initialValues.ApprovalHistoryDocumentForm.files.some(file => Boolean(file.file_name) === true) : false;
                            hasExtFiles = props.initialValues.ApprovalHistoryDocumentForm.files ? props.initialValues.ApprovalHistoryDocumentForm.files.some(file => Boolean(file.url) === true) : false;
                        }
                        break;
                    default: break;
                }
                return (
                    <React.Fragment key={input.name}>
                        <tr>
                            <td className="align-middle disable-table font-weight-bold">
                                <div>添付ファイル</div>
                            </td>
                            <td className="align-middle px-2 py-2 file">
                                {
                                    hasFiles ? docFiles.map((file, idx) => {
                                        if (!Boolean(file.url)) {
                                            return (<p className="file mb-0 pl-0" key={idx}>
                                                <a href="# " title={file.file_name} onClick={() => {
                                                    handleDocumentFileDownload(file)
                                                }}>{file.file_name}</a>
                                            </p>)
                                        } else { return true }
                                    })
                                        :
                                        "添付ファイルなし"
                                }
                            </td>
                        </tr >
                        <tr>
                            <td className="align-middle disable-table font-weight-bold">
                                <div>外部リンク</div>
                            </td>
                            <td className="align-middle px-2 py-2 url">
                                {
                                    hasExtFiles ? docFiles.map((file, idx) => {
                                        if (Boolean(file.url)) {
                                            return (<p className="url mb-0 pl-0" key={idx}>
                                                <a href={file.url} title={file.url} rel="noopener noreferrer" target="_blank">{file.url}</a>
                                            </p>)
                                        } else { return true }
                                    })
                                        :
                                        "外部リンクなし"
                                }
                            </td>
                        </tr >
                    </React.Fragment>
                );
            }

            case "radio-date": {
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div className="d-flex row">
                                {props.radioInput.map((radio, index) => {
                                    if (radio.name === input.name) {
                                        return (
                                            <div className="form-radio col-md-4 col-6" key={input.name + index}>
                                                <FormCheck
                                                    id={input.name + index + props.form}
                                                    className={props.form + "-publish-preferred-date-radio"}
                                                    type="radio"
                                                    inline={input.inline}
                                                    value={radio.value}
                                                    name={modelField}
                                                    onChange={event => {
                                                        setFieldValue(modelField, event.target.value);
                                                        let radioOptions = document.querySelectorAll(`input[name="${modelField}"]`)
                                                        if (radioOptions.item(1).checked) {
                                                            document.getElementById(props.form + "dateTime").setAttribute("class", "form-datetime form-control-sm align-middle")
                                                        }
                                                        else {
                                                            document.getElementById(props.form + "dateTime").setAttribute("class", "d-none")
                                                        }
                                                    }}
                                                    checked={fieldValue.toString() === radio.value.toString()}
                                                    label={radio.label}
                                                    disabled={input.disabled}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                                <div className="form-datetime-container col-md-4 col-12">
                                    <DateTime id={props.form + "dateTime"} dateFormat={DATETIME_FORMAT_3} timeFormat={true} className={props.publishDate === "1" ? "form-datetime form-control-sm align-middle" : "d-none"} value={dateValue} onFocus={() => {
                                        let radioOptions = document.querySelectorAll("." + props.form + "-publish-preferred-date-radio label");
                                        radioOptions && radioOptions.length === 2 && radioOptions.item(1).click();
                                    }} onChange={setValue} disabled={input.disabled} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }

            case "radio": {
                let modelField = `${model}.${input.name}`;

                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div className="d-flex row">
                                {props.radioInput.map((radio, index) => {
                                    if (radio.name === input.name) {
                                        return (
                                            <div className="form-radio col-md-4 col-6" key={input.name + index}>
                                                <FormCheck
                                                    id={input.name + index + props.form}
                                                    type="radio"
                                                    inline={input.inline}
                                                    value={radio.value}
                                                    name={modelField}
                                                    onChange={event => setFieldValue(modelField, event.target.value)}
                                                    checked={fieldValue.toString() === radio.value.toString()}
                                                    label={radio.label}
                                                    disabled={input.disabled}
                                                />
                                            </div>
                                        );
                                    }
            
                                    return null;
                                })}
                            </div>
                        </td>
                    </tr>
                )
            }

            // renders select field
            case "select": {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control form-error" : "form-control"}
                                component="select"
                                name={modelField}
                            >
                                <option disabled value="">{input.placeholder}</option>
                                {props.selectInput && props.selectInput.filter((option) => option.name === input.name).map((item, i) => {
                                    return (
                                        <option
                                            key={input.name + i} value={item.value}> {item.label} </option>
                                    );
                                })}
                            </Field>
                        </td>
                    </tr>
                );
            }

            case "hidden": {
                return null;
            }

            case "text-remarks": {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <div key={input.name}>
                        {CustomError(errors[modelField], displayError)}
                        <Field
                            className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                            placeholder={input.placeholder}
                            component={input.component}
                            type={input.type}
                            name={modelField}
                            maxLength={input.maxLength}
                            minLength={input.minLength}
                            min={input.min}
                            max={input.max}
                            onChange={event => setFieldValue(modelField, event.target.value)}
                            disabled={input.disabled}
                        />
                    </div>
                );
            }

            //renders text, textarea, email, password, numbers, default input
            default: {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : input.hide ? "d-none hidden-password" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row d-block">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}
                                        {
                                            input.required && !props.editKintoneMode && !props.editKintoneCommonMode ?
                                                <span className="text-danger font-weight-bold"> * </span>
                                                : input.required && input.name === "display_label" ?
                                                    <span className="text-danger font-weight-bold"> * </span>
                                                    : null
                                        }
                                    </label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={input.type}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onClick={event => {
                                    if (input.type === "password") {
                                        setFieldValue(modelField, "");
                                    }
                                }}
                                onChange={event => {
                                    if (input.type === "textarea" && modelField.split(".").includes("title")) {
                                        setFieldValue(modelField, event.target.value.replace(/(\r\n|\n|\r)/gm, ""));
                                    } else {
                                        setFieldValue(modelField, event.target.value);
                                    }
                                }}
                                style={{ backgroundColor: (input.onSetEmphasize && fieldValue) ? '#f9f972' : '' }}
                                disabled={(props.editKintoneMode || props.editKintoneCommonMode) && input.name === "kintone_attribute_name" ? true : input.disabled}
                                rows={input.rows}
                            />
                        </td>
                    </tr>
                );
            }
        }

    }

    //Renders form buttons
    // const FormButtons = props.formButtons && props.formButtons.map((button, i) => {
    //     return (
    //         <Button type={button.submit ? "submit" : "button"} key={i} className={button.className} variant={button.variant} size={button.size} onClick={button.onClick}>
    //             {button.label}
    //         </Button>
    //     );
    // });

    //Maps form inputs
    const FormInputs = (values, setFieldValue, errors, touched) => props.formInputs.form && props.formInputs.form.map((input) => {
        return RenderInput(props.formInputs.model, input, values[props.formInputs.model][input.name], setFieldValue, errors, touched)
    });

    var DisplayAlert = (errors, form) => {
        // eslint-disable-next-line
        let errorList = Object.entries(errors).map((error, idx) => {
            if (form[error[0].split(".").pop(-1)]) {
                return <p key={idx} className="mb-0">{error[1]}</p>
            }
        })
        let displayTemplate = <></>

        if (errorList.length){
            displayTemplate = <Alert variant="danger">{errorList}</Alert>
        }

        return displayTemplate;
    }

    if ((!isNullOrUndefined(props.initialValues) && props.editMode) || !props.editMode) {
        return (
            <Formik
                initialValues={initialValues}
                className={props.className}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    return props.validate(values);
                }}
                onSubmit={(values) => {
                    if (values.DocumentForm) {
                        values.DocumentForm.publish_date === "1" ?
                            values.DocumentForm.publish_preferred_date = new Date(dateValue) :
                            values.DocumentForm.publish_preferred_date = undefined;
                    }

                    // console.log("VALUES: ", values)
                    if (props.addMode) {
                        // console.log('add mode');
                        let toDeleteFileCheckboxes = document.querySelectorAll('[name="DocumentForm.delete_files"]:checked')
                        values.DocumentForm.delete_file_checkboxes = toDeleteFileCheckboxes;
                        props.handleSubmitModalNewDocument(values.DocumentForm);
                    } else if (props.editMode) {
                        // console.log('edit mode');
                        let toDeleteFileCheckboxes = document.querySelectorAll('[name="DocumentForm.delete_files"]:checked')
                        values.DocumentForm.delete_file_checkboxes = toDeleteFileCheckboxes;
                        if (props.showApprovalEditDocument) {
                            props.handleSubmitApprovalEditDocumentModal(values.DocumentForm);
                        } else {
                            props.handleSubmitDraftModal(values.DocumentForm.post_id, values.DocumentForm);
                        }
                    } else if (props.rejectMode) {
                        // console.log('reject mode');
                        props.handleSubmitRejectModal(values.RejectForm.post_id, values.RejectForm);
                    }

                    if (document.querySelector('.modal.show input[name=submit_action]')) {
                        switch (document.querySelector('.modal.show input[name=submit_action]').value) {
                            case "handleShowApplicationConfirmModal": {
                                props.handleShowApplicationConfirmModal()
                                break;
                            }
                            case "handleShowDraftConfirmModal": {
                                props.handleShowDraftConfirmModal()
                                break;
                            }
                            case "handleShowApprovalConfirmModal": {
                                props.handleShowApprovalConfirmModal()
                                break;
                            }
                            case "handleShowApprovalRejectModal": {
                                props.handleShowApprovalRejectModal(values.ApprovalDocumentForm.post_id)
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form id={props.form}>
                        <FormikEffect delay={500} onChange={values => props.handleFormOnchange(values)} />
                        {DisplayAlert(errors, touched[Object.keys(touched)[0]])}
                        <table className="configuration-table table table-bordered table-form">
                            <colgroup>
                                <col className="colwidth4" />
                                <col />
                            </colgroup>
                            <tbody>
                                {FormInputs(values, setFieldValue, errors, touched)}
                            </tbody>
                        </table>
                        <ButtonToolbar className="d-none">
                            <Button type="submit" value="Submit" className={props.showApprovalEditDocument ? "submitApprovalEditDocument" : "documentSubmitButton"} />
                        </ButtonToolbar>
                    </Form>
                )}
            </Formik>
        );
    } else {
        return <p>Loading . . .</p>;
    }
}

export default AdminFormTemplate;