import React from 'react'
import { Modal, Button } from 'react-bootstrap';


const withAdminApprovalConfirmModal = (WrappedComponent) => {
    return class AdminApprovalConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showApprovalConfirmModal: false
            }
        }

        adminApprovalConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-sm" keyboard={false} size="sm" show={this.state.showApprovalConfirmModal} onHide={this.handleCloseApprovalConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>承認管理 ＞ 承認確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-sm">
                        <div>
                            申請を承認します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitApprovalConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseApprovalConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowApprovalConfirmModal = () => {
            this.setState({
                showApprovalConfirmModal: true
            })
        }

        handleCloseApprovalConfirmModal = () => {
            this.setState({
                showApprovalConfirmModal: false
            })
        }

        handleSubmitApprovalModal = (id, values) => {
            let formattedValue = {
                "application_status": 1
            }
    
            this.setState({
                approvalData: {
                    id: id,
                    type: "approved",
                    payload: formattedValue
                }
            });
        }

        handleSubmitApprovalConfirmModal = (event) => {
            let id = this.state.approvalData.id;
            let type = this.state.approvalData.type;
            let payload = this.state.approvalData.payload

            if (type === "approved") {
                event.target.setAttribute("disabled", "disabled");
                Promise.all([
                    this.showLoader(),
                    this.props.patchApprovalDetails(id, payload).then(
                        () => {
                            this.loadListFromServer();
                            this.handleCloseNoticeApprovalModal();
                            this.handleCloseDocumentApprovalModal();
                            this.handleShowSuccessAlert(this.props.approvalMessages);
                            
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseApprovalConfirmModal)
            } else if (type === "reject") {
                document.getElementById("reject-approval").setAttribute("disabled", "disabled")
                Promise.all([
                    this.showLoader(),
                    this.props.patchApprovalDetails(id, payload).then(
                        () => {
                            this.loadListFromServer()
                            this.handleCloseApprovalRejectModal();
                            this.handleCloseNoticeApprovalModal();
                            this.handleCloseDocumentApprovalModal();
                            this.handleShowSuccessAlert(this.props.approvalMessages)
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
            }
        }
    }
}

export {
    withAdminApprovalConfirmModal
}