import React from 'react';
import { Modal } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';
import Pagination from 'react-js-pagination';

const withSubTableDataModal = (WrappedComponent) => {
    return class SubTableDataModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showSubTableModal: false,
                subTableData: [],
                activeSubTablePage: 1,
                totalSubTableItemCount: 0,
                pageSubTableRangeDisplayed: 3,
                itemsCountPerSubTablePage: 5
            };
            this.parentObject = {};
            this.parentObjectList = [];
        }

        subTableDataModal = (props) => {
            let { subTableData, isShowIn, showSubTableModal, parentFieldLabel } = this.state;

            let kintoneData = [];
            if (isShowIn === "product_admin" || isShowIn === "product_other" || isShowIn === "product_contract") {
                kintoneData = subTableData && subTableData.specific_fields && subTableData.specific_fields.fields;
            }
            if (isShowIn === "common_admin" || isShowIn === "common_contract") {
                kintoneData = subTableData && subTableData.common_fields && subTableData.common_fields.fields;
            }

            let rowCountNowList = kintoneData && kintoneData.map(field => {
                return field.field_value.length
            });

            let rowCountNow = Math.max.apply(Math, rowCountNowList);
            let rowCountRange = []

            for (let i = 0; i < rowCountNow; i++) {
                rowCountRange.push(i);
            }

            return (
                <Modal size="lg" keyboard={false} show={showSubTableModal} onHide={this.handleCloseSubTableDataModal} centered>
                    <Modal.Header closeButton className="sub-table-modal-header">
                        <Modal.Title className="font-weight-bold">
                            {parentFieldLabel}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0 sub-table-modal-body">
                        <div className="mt-2 sub-table-scroll">
                            {kintoneData && kintoneData.length > 0 ?
                                <MDBTable className="w-100">
                                    <MDBTableBody>
                                        <tr className="border disable-table">
                                            {kintoneData.map((field, i) => {
                                                return(
                                                    <th key={i} className="sub-table-th-td">{field.field_label}</th>
                                                )
                                            })}
                                        </tr>
                                        {this.state.rowCount > 0 ? rowCountRange.map(index => {
                                            return(
                                                <tr className="border" key={index}>
                                                    {kintoneData.map((field, i) => {
                                                        return(
                                                            <td className="sub-table-th-td height-sub-table-td" key={i}>
                                                                {field.is_table ?
                                                                    <a href="# " onClick={() => {
                                                                        field.is_table_list ? this.handleShowSubListTableDataModal(subTableData.id, field.field_value[index], field.field_label, field.field_id, isShowIn)
                                                                        : this.handleShowSubTableModal(subTableData.id, field.field_id, field.field_label, isShowIn)
                                                                    }}>詳細</a>
                                                                : field.field_unique_value.length > 0 ? field.field_unique_value[index] : ""}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            {kintoneData.map((field, i) => {
                                                return(
                                                    <td className="sub-table-th-td height-sub-table-td" key={i}>
                                                        {field.is_table ?
                                                            <a href="# " onClick={() => {
                                                                this.handleShowSubTableModal(subTableData.id, field.field_id, field.field_label, isShowIn)
                                                            }}>詳細</a>
                                                        : ""}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                                : <MDBTable>
                                    <MDBTableBody>
                                        <tr className="height-sub-table-td"></tr><tr className="height-sub-table-td"></tr>
                                    </MDBTableBody>
                                </MDBTable>
                            }
                        </div>
                    </Modal.Body>
                    {this.state.rowCount > 0 ? this.pagerSubTable() : null}
                </Modal>
            );
        }

        handleSaveParentSubTableData = (contractId, parentId, parentFieldLabel, isShowIn) => {
            let object = {
                contractId: contractId,
                parentId: parentId,
                parentFieldLabel: parentFieldLabel,
                isShowIn: isShowIn
            }
            const isParentIdExsited = this.parentObjectList.some(object => object.parentId === parentId);
            if (!isParentIdExsited) {
                this.parentObjectList.push(object);
            }
        }

        handleDeleteParentSubTableData = () => {
            this.parentObjectList.pop();
            if (this.parentObjectList.length > 0) {
                this.parentObject = this.parentObjectList[this.parentObjectList.length - 1];
            } else {
                this.parentObject = {};
            }
        };

        handleShowSubTableModal = (contractId, kintoneId, fieldLabel, isShowIn) => {
            let promises = [];
            if (isShowIn === "product_admin") {
                promises.push(this.props.getAdminKintoneProductSubTableData(contractId, kintoneId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "common_admin") {
                promises.push(this.props.getAdminKintoneCommonSubTableData(contractId, kintoneId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "product_other") {
                promises.push(this.props.getKintoneProductOtherFeatureSubTableData(contractId, kintoneId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "product_contract") {
                promises.push(this.props.getKintoneProductContractInforSubTableData(contractId, kintoneId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            if (isShowIn === "common_contract") {
                promises.push(this.props.getKintoneCommonContractInforSubTableData(contractId, kintoneId, isShowIn, this.state.itemsCountPerSubTablePage, this.state.activeSubTablePage));
            }
            Promise.all([
                this.showLoader(),
                promises[0].then(
                    () => {
                        this.setState({
                            subTableData: isShowIn === "product_admin" ? this.props.adminKintoneProductSubTableData
                            : isShowIn === "common_admin" ? this.props.adminKintoneCommonSubTableData
                            : isShowIn === "product_other" ? this.props.subTableKintoneProductOtherFeatureData
                            : isShowIn === "product_contract" ? this.props.subTableKintoneProductContractInforData
                            : isShowIn === "common_contract" && this.props.subTableKintoneCommonContractInforData
                        });
                        this.setState({
                            showSubTableModal: true,
                            showSubListTableDataModal: false,
                            parentFieldLabel: fieldLabel,
                            isShowIn: isShowIn,
                            contractId: contractId,
                            kintoneId: kintoneId,
                            rowCount: this.state.subTableData.row_count,
                        }, () => this.handleSaveParentSubTableData(contractId, kintoneId, fieldLabel, isShowIn));
                    })
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        pagerSubTable = () => {
            return <Pagination
            firstPageText={'最初'}
            prevPageText={'前へ'}
            nextPageText={'次へ'}
            lastPageText={'最後'}

            innerClass={'pagination pagination-sm justify-content-center'}
            activeClass={'active'}
            activeLinkClass={'active'}
            itemClass={'page-item'}
            linkClass={'page-link'}

            activePage={this.state.activeSubTablePage}
            itemsCountPerPage={this.state.itemsCountPerSubTablePage}
            totalItemsCount={this.state.showSubTableModal ? this.state.rowCount : this.state.totalSubTableItemCount}
            pageRangeDisplayed={this.state.pageSubTableRangeDisplayed}
            onChange={this.handleSubTablePageChange}
        />
        }

        handleSubTablePageChange = page => {
            if (this.state.totalSubTableItemCount) {
                document.getElementsByClassName('previous-button')[0].classList.remove('disabled')
                document.getElementsByClassName('next-button')[0].classList.remove('disabled')

                if (page === 1 && (this.state.itemsCountPerSubTablePage >= this.state.rowCount)) {
                    document.getElementsByClassName('previous-button')[0].classList.add('disabled')
                    document.getElementsByClassName('next-button')[0].classList.add('disabled')
                } else if (page === 1) {
                    document.getElementsByClassName('previous-button')[0].classList.add('disabled')
                } else if (page === Math.ceil(this.state.rowCount / this.state.itemsCountPerSubTablePage)) {
                    document.getElementsByClassName('next-button')[0].classList.add('disabled')
                }
            }

            this.setState(
                { activeSubTablePage: page },
                () => this.handleShowSubTableModal(this.state.contractId, this.state.kintoneId, this.state.parentFieldLabel, this.state.isShowIn))
        }

        handleCloseSubTableDataModal = () => {
            this.handleDeleteParentSubTableData();
            this.setState({
                showSubTableModal: false,
                activeSubTablePage: 1,
                subTableData: [],
                totalSubTableItemCount: 0
            })
            if (this.state.showSubTableListWhenCloseSubTable && this.showSubTableIndex === this.parentObjectList.length) {
                this.handleShowSubListTableDataModal(this.state.contractId, this.state.subListData, this.state.parentLabel, this.state.parentId, this.state.isShowIn);
            } else if (this.parentObjectList.length > 0) {
                this.handleShowSubTableModal(this.parentObject.contractId, this.parentObject.parentId, this.parentObject.parentFieldLabel, this.parentObject.isShowIn);
            }
        }
    }
}

export {
    withSubTableDataModal
}