import React from 'react'
import { Modal, Button } from 'react-bootstrap';


const withAdminNoticeDraftConfirmModal = (WrappedComponent) => {
    return class AdminNoticeDraftConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeDraftConfirmModal: false,
            }
        }

        adminNoticeDraftConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showNoticeDraftConfirmModal} onHide={this.handleCloseDraftConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>お知らせ管理 ＞ お知らせ下書保存確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「お知らせ」を下書・保存します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitDraftConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDraftConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowDraftConfirmModal = () => {
            this.setState({
                showNoticeDraftConfirmModal: true
            });
        }

        handleCloseDraftConfirmModal = () => {
            this.setState({
                showNoticeDraftConfirmModal: false
            });
        }

        handleSubmitDraftConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled")
            let id = this.state.noticeData.id;
            let type = this.state.noticeData.type;
            let payload = this.state.noticeData.payload

            payload.is_draft = 1;
            if (type === "draft") {
                Promise.all([
                    this.showLoader(),
                    this.props.putNoticeEditDetails(id, payload).then(
                        () => {
                            this.loadListFromServer()
                            this.handleCloseDraftModal();
                            this.handleShowSuccessAlert(this.props.noticeMessages);
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseDraftConfirmModal)
            } else if (type === "create") {
                Promise.all([
                    this.showLoader(),
                    this.props.postNoticeCreateDetails(payload).then(
                        () => {
                            this.loadListFromServer()
                            this.handleCloseModalNewPost();
                            this.handleShowSuccessAlert(this.props.noticeCreateDetails);
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseDraftConfirmModal)
            }
        }
    }
}

export {
    withAdminNoticeDraftConfirmModal
}