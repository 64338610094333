import * as types from "./types";

const defaultState = {
    serviceList: [],
    kintoneDetails: [],
    totalItemsCount: 0,
    documentList: [],
    totalDocumentsListItemsCount: 0,
    documentDetails: ({}),
    otherFeature: []
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, serviceList: action.payload, totalItemsCount: action.payload.length };
        case types.KINTONE_DETAILS_STATE:
            return { ...state, kintoneDetails: action.payload };
        case types.DOCUMENT_LIST_STATE:
            return { ...state, documentList: action.payload.results, totalDocumentsListItemsCount: action.payload.count };
        case types.DOCUMENT_DETAILS_STATE:
            return { ...state, documentDetails: action.payload };
        case types.OTHER_FEATURE_STATE:
            return { ...state, otherFeature: action.payload};
        case types.KINTONE_PRODUCT_DATA_OTHER_FEATURE_SUB_TABLE_STATE:
            return { ...state, subTableKintoneProductOtherFeatureData: action.payload };
        case types.KINTONE_PRODUCT_DATA_CONTRACT_INFOR_SUB_TABLE_STATE:
            return { ...state, subTableKintoneProductContractInforData: action.payload };
        case types.KINTONE_COMMON_DATA_CONTRACT_INFOR_SUB_TABLE_STATE:
            return { ...state, subTableKintoneCommonContractInforData: action.payload };
        default: return state;
    }
};