import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { authOperations } from '../containers/Login/state';
import AdminMainHeader from './AdminMainHeader';
import MobileAdminMainHeader from './MobileAdminMainHeader';
import AuthPasswordChange from './AuthPasswordChange';
import LogoutModal from './LogoutModal';
import { ADMIN_LOGIN_ROUTE } from '../containers/AdminLogin/routes';
import { withMobileNavCollapser } from '../components/MobileNavCollapser';
import { withErrorAlert } from './ErrorAlert';
import { withSuccessAlert } from './SuccessAlert';
import { withLoader } from "./Loader";
import { withDiscardChangesNotification } from './DiscardChanges';
import * as CONSTANTS from '../../utils/constants';

class AdminRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            opened: null,
            collapseID: '',
            showAuthPasswordChangeModal: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        };
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ''
        }), () => {
            if (this.state.collapseID === '') {
                this.handleCloseBackGround()
            } else {
                this.handleShowBackGround()
            }
        })
    };

    handleShowLogoutModal = () => {
        this.setState({ showModal: true })
    }

    handleCloseLogoutModal = () => {
        this.setState({
            showModal: false,
            opened: null
        })
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    toggleVisibility = e => e.classList.toggle('show')

    handleDropdown = e => {
        const clickedItem = e.parentElement.lastChild

        this.toggleVisibility(clickedItem)
        if (!this.state.opened) {
            this.setState({ opened: clickedItem })
        } else if (this.state.opened === clickedItem) {
            this.setState({ opened: null })
        } else {
            this.toggleVisibility(this.state.opened)
            this.setState({ opened: clickedItem })
        }
    }

    handleClick = e => {
        let isNoticeManagementDropDown = (e.target.id === "notice-dropdown");
        let isProductManagementDropdown = (e.target.id === "product-management-dropdown");
        let isAuthDropdown = (e.target.id === "auth-dropdown");
        let isApprovalDropdown = (e.target.id === "approval-dropdown");
        let isAccountManagementDropdown = (e.target.id === "account-management-dropdown");
        let isEmailManagementDropdown = (e.target.id === "email-management-dropdown");
        let isAccessLogDropdown = (e.target.id === "accesslog-dropdown");
        let isMobileDropdown = (e.target.classList.contains("navbar-toggler"));
        let isMobileDropdownIcon = (e.target.classList.contains("navbar-toggler-icon"));
        let isMobileSubDropdown = (e.target.classList.contains("mobile-dropdown"));
        let isMobileLink = (e.target.classList.contains("mobile-link"));

        if (isNoticeManagementDropDown || isProductManagementDropdown || isAuthDropdown || isApprovalDropdown || isAccountManagementDropdown || isEmailManagementDropdown || isAccessLogDropdown) {
            this.handleDropdown(e.target);
        } else if (this.state.opened) {
            this.toggleVisibility(this.state.opened);
            this.setState({ opened: null });
        }

        if (!isMobileSubDropdown && !isMobileDropdown && !isMobileDropdownIcon && !isMobileLink) {
            if (this.state.collapseID === "navbarCollapse1") {
                document.getElementsByClassName("navbar-toggler")[0].click()
            }
            else if (this.state.collapseID === "navbarCollapse2") {
                document.getElementsByClassName("navbar-toggler")[1].click()
            }
            this.handleCloseBackGround()
        }
    }

    validateForm = () => {
        var notEmpty = 0 < this.state.newPassword.length && 0 < this.state.confirmPassword.length && 0 < this.state.oldPassword.length;
        var hasMinLength = 4 <= this.state.newPassword.length && 4 <= this.state.confirmPassword.length && 4 <= this.state.oldPassword.length;
        return notEmpty && hasMinLength;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });

        if (this.validateForm()) {
            document.getElementById('authPasswordChangeSubmit').removeAttribute('disabled')
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.handleShowErrorAlert(CONSTANTS.UPDATE_ADMIN_PASSWORD_ERROR_PASSWORD_CONFIRM);
        }
        else {
            let data = {'old_password': this.state.oldPassword, 'password': this.state.newPassword};

            Promise.all([
                this.showLoader(),
                this.props.adminPasswordAuthUpdate(data).then(
                    () => {
                        this.handleShowSuccessAlert(this.props.passwordUpdateMessage)
                        this.setState({newPassword: '', confirmPassword: ''})
                        this.handleCloseAuthPasswordChangeModal()
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }
    }

    handleShowAuthPasswordChangeModal = () => {
        this.setState({
            showAuthPasswordChangeModal: true
        });
    }

    handleCloseAuthPasswordChangeModal = () => {
        this.setState({
            showAuthPasswordChangeModal: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    }

    handleCloseAllModal = () => {
        this.setState({
            showDiscardModal: false,
            showAuthPasswordChangeModal: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    }

    render() {
        if (this.props.path === "/admin") {
            if (this.props.isSuperuser || this.props.isPoster) {
                return <Redirect to="/admin/noticepost" />;
            }
            else if (this.props.isApprover) {
                return <Redirect to="/admin/approvalpending" />;
            }
            else if (this.props.isPortalAdmin) {
                return <Redirect to="/admin/portalusers" />;
            }
        }
        return this.props.isAuthenticated && this.props.isAdminPage ?
            <>
                <AdminMainHeader
                    {...this.state}
                    {...this.props}
                    handleShowLogoutModal={this.handleShowLogoutModal}
                    handleShowAuthPasswordChangeModal={this.handleShowAuthPasswordChangeModal} />
                {this.dismissNav({ ...this.state })}
                <MobileAdminMainHeader
                    {...this.state}
                    {...this.props}
                    handleShowLogoutModal={this.handleShowLogoutModal}
                    handleShowAuthPasswordChangeModal={this.handleShowAuthPasswordChangeModal}
                    toggleCollapse={this.toggleCollapse} />
                <Route path={this.props.path} component={this.props.component} />
                <AuthPasswordChange 
                    {...this.state}
                    {...this.props} 
                    handleChange={this.handleChange}
                    validateForm={this.validateForm}
                    handleSubmit={this.handleSubmit}
                    handleCloseAuthPasswordChangeModal={this.handleCloseAuthPasswordChangeModal}
                    handleShowDiscardModal={this.handleShowDiscardModal} />
                <LogoutModal
                    {...this.state}
                    {...this.props}
                    handleCloseLogoutModal={this.handleCloseLogoutModal} />
                {this.loader({ ...this.state })}
                {this.dismissAlert({ ...this.state })}
                {this.errorAlert({ ...this.state })}
                {this.successAlert({ ...this.state })}
                {this.discardChanges({ ...this.state })}
            </>
            : (
                <Redirect to={{
                    pathname: ADMIN_LOGIN_ROUTE,
                    state: {
                        from: this.props.location
                    }
                }} />
            )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        credentials: state.auth.credentials,
        username: state.auth.username,
        isAdmin: state.auth.isAdmin,
        isSuperuser: state.auth.isSuperuser,
        isPoster: state.auth.isPoster,
        isApprover: state.auth.isApprover,
        isPortalAdmin: state.auth.isPortalAdmin,
        passwordUpdateMessage: state.auth.passwordUpdateMessage,
    }
};

const mapDispatchToProps = {
    logoutUser: authOperations.logoutUser,
    adminPasswordAuthUpdate: authOperations.adminPasswordAuthUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withMobileNavCollapser(
        withErrorAlert(
            withSuccessAlert(
                withDiscardChangesNotification(
                    withLoader(
                        AdminRoutes
                    )
                )
            )
        )
    )
);