import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import MainHeader from './MainHeader';
import MobileCustomMainHeader from './MobileCustomMainHeader';
import AdminRoutes from './AdminRoutes';
import PrivateRoutes from './PrivateRoutes';
import { connect } from 'react-redux';
import { authOperations } from '../containers/Login/state';

const CustomRoute = (props) => {
    let header = <></>;

    if (props.isAdminPage) {
        header = <AdminRoutes {...props} />;
    }
    else if (props.isAuthenticated) {
        header = <PrivateRoutes {...props} />;
    }
    else if (props.public) {
        header = 
            <>
                <MainHeader {...props} />
                <MobileCustomMainHeader {...props} />
                <Route
                    exact={props.exact}
                    path={props.path}
                    component={props.component}
                />
            </>
    } else {
        header = <Redirect to={{
            pathname: '/',
            state: {
                from: props.location
            }
        }} />
    }

    return header;
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        credentials: state.auth.credentials,
        username: state.auth.username
    }
};

const mapDispatchToProps = {
    logoutUser: authOperations.logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomRoute);