import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminDocumentCancelModal = (WrappedComponent) => {
    return class AdminDocumentCancelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentCancelModal: false
            }
        }

        adminDocumentCancelModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDocumentCancelModal} onHide={this.handleCloseCancelModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント申請取り消し</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「ドキュメント」の申請を取り消します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitCancelModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseCancelModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowCancelModal = (value, event) => {
            event.target.blur();
            this.setState({ documentCancelId: value, showDocumentCancelModal: true })
        }

        handleCloseCancelModal = () => {
            this.setState({ showDocumentCancelModal: false });
        }

        handleSubmitCancelModal = () => {
            Promise.all([
                this.showLoader(),
                this.props.patchDocumentCancel(this.state.documentCancelId, { cancel_application: 1 }).then(
                    () => {
                        this.loadListFromServer()
                        this.handleShowSuccessAlert(this.props.documentMessages)
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseCancelModal)
        }
    }
}

export {
    withAdminDocumentCancelModal
}