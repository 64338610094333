import React, { Component } from 'react';
import CustomerAccessLoggingTemplate from "../../components/UserAccessLogging/CustomerAccessLoggingTemplate";
import { connect } from 'react-redux';
import { customerAccessLogOperations } from './state';
import { bindActionCreators } from 'redux';
import { withPaging } from '../../components/Pagination/Paging'
import { withLoginCheck } from '../../components/LoginCheck';
import { withErrorAlert } from '../../components/ErrorAlert';


class UserAccessLoggingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();

        if (!this.props.isAuthenticated) {
            let params = new URLSearchParams(this.props.location.search);
            let error = params.get('error');

            if (error) {
                this.handleShowErrorAlert(error);
            }
        }
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getCustomerAccessLogList(
                this.state.itemsCountPerPage, this.state.activePage,
                this.state.query, this.state.sort
            ).then(() => {
                this.setState({
                    rows: this.props.tableList,
                    totalItemsCount: this.props.totalItemsCount,
                });
            })
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="access_logging_template">
                        <h4 className="mr-auto page-heading">{"アクセスログ"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <CustomerAccessLoggingTemplate
                            {...this.state} />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        tableList: state.customerAccessLog.tableList,
        totalItemsCount: state.customerAccessLog.totalItemsCount,
        listOrdering: state.customerAccessLog.accessLogOrdering,
        defaultOrdering: state.customerAccessLog.defaultAccessLogOrdering
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getCustomerAccessLogList: customerAccessLogOperations.getCustomerAccessLogList
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withErrorAlert(
                UserAccessLoggingScreen
            )
        )
    )
);