import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminChooseTemplateModal = (WrappedComponent) => {
    return class AdminChooseTemplateModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showChooseTemplateModal: false,
            }
        }

        adminChooseTemplateModal = (props) => {
            let { noticeTemplateLookup } = props;
            return (
                <>
                    <div>
                        <Modal className="modal-dialog-lg" keyboard={false} size="lg" show={this.state.showChooseTemplateModal} onHide={this.handleCloseChooseTemplateModal} centered>
                            <Modal.Header closeButton style={{ borderBottom: "none" }}>
                                <Modal.Title>テンプレート選択</Modal.Title>
                            </Modal.Header>
                            <div className="content" style={{ borderBottom: "1px solid #dee2e6" }}>
                                <div className="choose-template mr-3 ml-3 mb-3" >
                                    <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                                        {this.filterSearch()}
                                    </nav>
                                    <div className="mt-3">
                                        <div className="row ml-0">
                                            {noticeTemplateLookup.length > 0 ? this.sortingTemplate() : null}
                                            {noticeTemplateLookup.length > 0 ? this.perPage() : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal.Body className="modal-body-md template-choose-modal">
                                <div>
                                    <div className="mt-3">
                                        {
                                            noticeTemplateLookup.length > 0 ?
                                            <table className="table table-bordered table-striped custom-template-choose">
                                                <tbody>
                                                    {
                                                        noticeTemplateLookup.map((template, index) => {
                                                            return (
                                                                <tr key={template.id} className="border" style={{ backgroundColor: "white" }}>
                                                                    <td className="justify-content-center">
                                                                        <input
                                                                            id={"template-dynamic-radio-" + template.id}
                                                                            className={'product-dynamic-fields-radio align-self-center'}
                                                                            type="radio"
                                                                            value={template.id}
                                                                            name={"dynamic_field_radio_template"}
                                                                            defaultChecked={index === 0}
                                                                        >
                                                                        </input>
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-break">
                                                                            <span className="custom-badge ml-0 mb-1" style={{ backgroundColor: template.product_bg_color }}>
                                                                                {template.product_name}
                                                                            </span>
                                                                            <span className="ml-2">
                                                                                <a href="# " title={template.title} onClick={() => {
                                                                                    this.handShowDetailFromNoticeModal(template)
                                                                                }}>{template.name}</a>
                                                                            </span>
                                                                            <span className="ml-2">
                                                                                {template.note}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            :
                                            <h5>検索の結果、0件でした。</h5>
                                        }
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="w-100">
                                    <div>
                                        {noticeTemplateLookup.length > 0 ? this.pager() : null}
                                    </div>
                                    <div className="w-100 border-top-0 pt-0 pb-0 modal-footer btn-cancle-template">
                                        {noticeTemplateLookup.length > 0 ? <Button variant="primary" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={this.handleSubmitChooseTemplateModal}>
                                            Ok
                                        </Button> : <></>}
                                        <Button variant="secondary" className="btn btn-90 mr-0 btn-outline-secondary" onClick={this.handleCloseChooseTemplateModal}>
                                            キャンセル
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )
        }

        getIdRadioButtonChecked = () => {
            const radio = document.querySelector('input[name="dynamic_field_radio_template"]:checked');
            return radio !== null ? Number(radio.value) : 0;
        }

        handleShowChooseTemplateModal = (id, params=undefined) => {
            // event.target.blur();
            let itemsCountPerPage = params === undefined ? this.state.itemsCountPerPage : params.itemsCountPerPage;
            let activePage = params === undefined ? this.state.activePage : params.activePage;
            let query = params === undefined ? this.state.query : params.query;
            let sort = params === undefined ? this.state.sortTemplate : params.sortTemplate;

            Promise.all([
                this.showLoader(),
                this.props.getNoticeTemplateLookup(
                    id, itemsCountPerPage, activePage, query, sort
                ).then(
                    () => { this.setState({
                        productIdInChooseTemplateModal: id,
                        showChooseTemplateModal: true,
                    })}
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseChooseTemplateModal = () => {
            this.setState({
                showChooseTemplateModal: false,
                itemsCountPerPage : 10,
                activePage: 1,
                query: '',
                sortTemplate: 'created_date',
            });
        }

        handleSubmitChooseTemplateModal = (event) => {
            let templateId = this.getIdRadioButtonChecked();
            let { noticeTemplateLookup } = this.props;
            let templateData = {};

            if (noticeTemplateLookup) {
                templateData = noticeTemplateLookup.find(item => item.id === templateId);
            }

            this.setState({
                showChooseTemplateModal: false,
                itemsCountPerPage : 10,
                activePage: 1,
                query: '',
                sortTemplate: 'created_date',
            });

            this.handleSetValueTitleContent(
                templateData ? templateData.title : '',
                templateData ? templateData.content : ''
            );
        }
    }
}

export {
    withAdminChooseTemplateModal
}