import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const withAdminChangeStatusConfirmModal = (WrappedComponent) => {
    return class AdminChangeStatusConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showAdminChangeStatusConfirmModal: false
            }
        }

        adminChangeStatusConfirmModal = (props) => {
            return (
                <Modal size="md" className="admin-reset-password-request-confirm-modal modal-dialog-md" keyboard={false} show={this.state.showAdminChangeStatusConfirmModal} onHide={this.handleCloseAdminChangeStatusConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>管理者ステータスの変更 ＞ 確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>管理者ステータスを変更してもよろしいですか？</div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.handleChangeAdminStatus}>
                            変更
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseAdminChangeStatusConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowAdminChangeStatusConfirmModal = (id, status) => {
            this.setState({
                adminStatus: {
                    id: id,
                    status: status
                },
                showAdminChangeStatusConfirmModal: true
            })
        }

        handleCloseAdminChangeStatusConfirmModal = () => {
            this.setState({
                showAdminChangeStatusConfirmModal: false
            })
        }

        handleChangeAdminStatus = () => {
            let data = {
                'id': this.state.adminStatus.id,
                'admin_status': this.state.adminStatus.status === 0 ? 1 : 0
            }

            Promise.all([
                this.props.updateAdminStatus(data).then(
                    () => {
                        this.loadListFromServer();
                    }
                ),
            ]).catch(this.handleShowErrorAlert)
            .then(this.handleCloseAdminChangeStatusConfirmModal)
        }
    }
}

export {
    withAdminChangeStatusConfirmModal
}