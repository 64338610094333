import React from 'react';
import * as Commons from '../Commons';

const MailDeliveryResultListTemplate = (props) => {
    let {
        rows,
        handleShowMailDeliveryDetailsModal
    } = props;

    return (
        <section className="list mt-3 mb-3">
            {props.totalItemsCount > 0 ?
                rows.map((mail) => {
                    var emailTime = Commons.getClientDatetimeDisplay(mail.when_added);
                    var emailAttemptedTime = Commons.getClientDatetimeDisplay(mail.when_attempted);
                    var emailAttemptedLabel = mail.result === "1" ? "送信日時" : "送信試行日時";
                    var statusText = mail.result === "1" ? "送信済み" : "送信失敗";
                    var statusBg = Commons.getMailingStatusBackground(statusText);

                    return (
                        <div className="px-3 py-3 list-group-item table-layout" key={mail.id}>
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute pt-1">
                                        <strong>宛先: </strong>
                                        {mail.message.to}
                                        <label className="float-right mb-0">
                                            <strong>作成日時: </strong>
                                            {emailTime}
                                        </label>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>件名: </strong>
                                            <a href="# " onClick={() => { handleShowMailDeliveryDetailsModal(mail) }}>{mail.message.subject}</a>
                                            <label className="float-right mb-0">
                                                <strong>{emailAttemptedLabel}: </strong>
                                                {emailAttemptedTime}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <label className="float-right rounded text-center px-2 mb-0" style={{ color: statusBg, border: "solid 1px" }}>
                                                {statusText}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                : <h4>検索の結果、0件でした。</h4>
            }

        </section>
    );
}

export default MailDeliveryResultListTemplate;