import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withCommonKintoneConfirmEditLabelModal = (WrappedComponent) => {
    return class CommonKintoneConfirmEditLabelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCommonKintoneConfirmEditLabelModal: false
            }
        }

        commonKintoneConfirmEditLabelModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showCommonKintoneConfirmEditLabelModal} onHide={this.handleCloseCommonKintoneConfirmEditLabelModal} centered>
                    <Modal.Header closeButton>
                        {/* Common attribute setting> Edit confirmation */}
                        <Modal.Title>{"共通属性設定 > 編集確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            共通属性設定を編集します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitCommonKintoneEditLabelConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseCommonKintoneConfirmEditLabelModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal >
            )
        }

        handleCloseCommonKintoneConfirmEditLabelModal = () => {
            this.setState({
                showCommonKintoneConfirmEditLabelModal: false
            });
        }

        handleSubmitCommonKintoneEditLabelConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let payload = this.state.kintoneData.payload

            Promise.all([
                this.showLoader(),
                this.props.patchKintoneCommonField(this.state.commonKintoneFieldId, payload).then(
                    () => {
                        this.handleCloseCommonKintoneEditLabelModal();
                        if (this.state.submitCommonSubKintone) {
                            this.handleShowCommonSubTableFieldsModal(this.state.parentId, this.state.parentFieldLabel);
                        } else {
                            this.loadListFromServer();
                        }
                        this.handleShowSuccessAlert(this.props.kintoneCommonMessage);
                        this.handleCloseCommonKintoneConfirmEditLabelModal();
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(() => {
                this.setState({
                    showCommonKintoneConfirmEditLabelModal: false
                });
            })
        }
    }
}

export {
    withCommonKintoneConfirmEditLabelModal
}