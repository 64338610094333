import React from 'react';
import * as Commons from '../Commons'
import { MDBTable, MDBTableBody } from 'mdbreact';

const CustomerInformationTemplate = (props) => {
    let {
        rows,
        handleShowCustomerInformationDetails
    } = props;

    return (
        <div className="mt-2">
            {props.totalItemsCount > 0 ?
                <MDBTable className="w-100">
                    <MDBTableBody>
                        {rows.length ? <tr className="border disable-table">
                            <th>ポータルログインID</th>
                            <th>契約サービス</th>
                            <th>最終ログイン日時</th>
                        </tr> : null }
                        {rows.map(customer => {
                            var lastLogin = Commons.getClientDatetimeDisplay(customer.last_login)
                            var comma_index = null;
                            var customer_contract_length = customer.contracts.length

                            return (
                                <tr key={customer.id} className="border">
                                    <td>{customer.username}</td>
                                    <td>{Object.keys(customer.contracts).map(index => {
                                        if(customer.contracts[index].contract_details.length){
                                            customer_contract_length += customer.contracts[index].contract_details.length - 1
                                        }
                                        return (Object.keys(customer.contracts[index].contract_details).map(contract_index => {
                                            return (<label className="mb-0" title={customer.contracts[index].product_name + customer.contracts[index].contract_details[contract_index].support_id}
                                                key={contract_index}>{customer.contracts[index].product_name} (<a href="# " onClick={() => { handleShowCustomerInformationDetails(customer.contracts[index].contract_details[contract_index].contract_id) }}>
                                                    {customer.contracts[index].contract_details[contract_index].support_id}</a>){(comma_index++ === customer_contract_length - 1 ? '' : `${','+String.fromCharCode(160)}`)}</label>) // Non-breakable space is char 160
                                        })
                                        )
                                    })}</td>
                                    <td>{lastLogin}</td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
                : <h4>検索の結果、0件でした。</h4>}
        </div>
    );
}

export default CustomerInformationTemplate;