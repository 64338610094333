import Error404 from "../Errors/404";
import MailTemplateScreen from "./MailTemplateScreen";
import MailSettingsScreen from "./MailSettingsScreen";
import MailPendingScreen from "./MailPendingScreen";
import MailDeliveryResultScreen from "./MailDeliveryResultScreen";

export const EMAIL_MANAGEMENT_ROUTE = '/admin/emailmanagement';
export const MAIL_TEMPLATE_SCREEN_ROUTE = '/admin/mailtemplates';
export const MAIL_SETTINGS_SCREEN_ROUTE = '/admin/mailsettings';
export const MAIL_PENDING_SCREEN_ROUTE = '/admin/mailpending';
export const MAIL_DELIVERY_RESULT_SCREEN_ROUTE = '/admin/maildeliveryresult';

export const routes = [
    {
        label: "メール管理",
        path: EMAIL_MANAGEMENT_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        dropdown: true,
        isAdminEmailManagement: true,
    },
    {
        label: "メールテンプレート",
        path: MAIL_TEMPLATE_SCREEN_ROUTE,
        component: MailTemplateScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "メールサーバ設定",
        path: MAIL_SETTINGS_SCREEN_ROUTE,
        component: MailSettingsScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "メール送信予定一覧",
        path: MAIL_PENDING_SCREEN_ROUTE,
        component: MailPendingScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "メール送信結果一覧",
        path: MAIL_DELIVERY_RESULT_SCREEN_ROUTE,
        component: MailDeliveryResultScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
]