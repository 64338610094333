import {DOCUMENT_ERROR_PRODUCT, DOCUMENT_ERROR_TITLE_1, DOCUMENT_ERROR_TITLE_2} from '../../../../utils/constants';

function checkErrors(state) {
    let errors = {};

    let product = state.DocumentForm.product;
    let title = state.DocumentForm.title.trim();

    if (product === "") {
        errors['DocumentForm.product'] = DOCUMENT_ERROR_PRODUCT;
    }
    if (title === "") {
        errors['DocumentForm.title'] = DOCUMENT_ERROR_TITLE_1;
    }
    else if (!/^.{1,64}$/i.test(title)) {
        errors['DocumentForm.title'] = DOCUMENT_ERROR_TITLE_2;
    }

    return errors;
}

export {
    checkErrors
};
