import React from 'react'

const AdminExportButton = (props) => {
    let { handleShowModalExportAdmin } = props;
    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value="エクスポート" onClick={handleShowModalExportAdmin}>エクスポート</button>
            </div>
        </div>
    )
}

export default AdminExportButton
