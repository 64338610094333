export const READ_STATE = "Approval/READ_STATE";
export const APPROVAL_HISTORY_STATE = "Approval/APPROVAL_HISTORY_STATE";
export const PRODUCT_LOOKUP_STATE = "Approval/PRODUCT_LOOKUP_STATE";
export const CATEGORY_LOOKUP_STATE = "Approval/CATEGORY_LOOKUP_STATE";
export const NOTICE_APPROVAL_RETRIEVE_STATE = "Approval/NOTICE_APPROVAL_RETRIEVE_STATE";
export const NOTICE_APPROVAL_RETRIEVE_HISTORY_STATE = "Approval/NOTICE_APPROVAL_RETRIEVE_HISTORY_STATE";
export const APPROVAL_HISTORY_NOTICE_DETAIL_STATE = "Approval/APPROVAL_HISTORY_NOTICE_DETAIL_STATE"
export const APPROVAL_STATE = "Approval/APPROVAL_STATE";
export const DOCUMENT_APPROVAL_RETRIEVE_STATE = "Approval/DOCUMENT_APPROVAL_RETRIEVE_STATE";
export const DOCUMENT_APPROVAL_RETRIEVE_HISTORY_STATE = "Approval/DOCUMENT_APPROVAL_RETRIEVE_HISTORY_STATE";
export const APPROVAL_HISTORY_DOCUMENT_DETAIL_STATE = "Approval/APPROVAL_HISTORY_DOCUMENT_DETAIL_STATE"
export const DOCUMENT_DOWNLOAD_STATE = "Approval/DOCUMENT_DOWNLOAD_STATE";
export const DOCUMENT_EDIT_RETRIEVE_STATE = "Document/DOCUMENT_EDIT_RETRIEVE_STATE";
export const NOTICE_EDIT_RETRIEVE_STATE = "Notice/NOTICE_EDIT_RETRIEVE_STATE";
export const CATEGORY_LOOKUP_EDIT_NOTICE_STATE = "Notice/CATEGORY_LOOKUP_EDIT_NOTICE_STATE";
