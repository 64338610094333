import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminDocumentFormTemplate';
import * as Commons from '../Commons';
import { Formik, Form, Field } from 'formik';


const withAdminDocumentApprovalHistoryModal = (WrappedComponent) => {
    return class AdminDocumentApprovalHistoryModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentApprovalHistoryDetail: false
            }
        }

        adminDocumentApprovalHistoryModal = (props) => {
            let template = "";
            if (props.approvalHistoryDocumentDetails) {
                var applicationDate = Commons.getClientDatetimeDisplay(props.approvalHistoryDocumentDetails.application_date)
                var checkedDate = Commons.getClientDatetimeDisplay(props.approvalHistoryDocumentDetails.checked_date)
                var statusBg = Commons.getApprovalStatusBackground(props.approvalHistoryDocumentDetails.application_status);

                template = (
                    <Modal show={this.state.showDocumentApprovalHistoryDetail} keyboard={false} size="lg" onHide={this.handleDocumentCloseApprovalHistoryDetail} className="modal-edit-post" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>承認管理＞承認履歴</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <div>
                                <label className="float-right rounded text-center px-2" style={{ color: statusBg, border: "solid 1px" }}>
                                    {props.approvalHistoryDocumentDetails.application_status}
                                </label>
                            </div>
                            <Formik>
                                <Form id={"notice-approval-" + props.approvalHistoryDocumentDetails.application_id}>
                                    <table className="configuration-table table table-bordered table-form">
                                        <tbody>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請番号</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_id_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.application_number}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請種別</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_type_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.application_type}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請者</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"applicant_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.created_by}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請日時</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_date_and_time_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={applicationDate}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請結果</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colSpan={3} className="align-middle px-2 py-2 approval-table-data-long">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_status_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.application_status}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">コメント</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colSpan={3} className="align-middle px-2 py-2 approval-table-data-long">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="textarea"
                                                        component="textarea"
                                                        name={"comment_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.remarks ? props.approvalHistoryDocumentDetails.remarks : ""}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">承認者</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"approver_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={props.approvalHistoryDocumentDetails.checked_by ? props.approvalHistoryDocumentDetails.checked_by : ""}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">承認日時</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"checked_date_and_time_" + props.approvalHistoryDocumentDetails.application_id}
                                                        value={checkedDate}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Form>
                            </Formik>
                            <label className="mb-0 approval-form-title"><strong>申請内容</strong></label>
                            <AdminFormTemplate
                                formInputs={props.approvalHistoryDocumentForm}
                                radioInput={props.radioInput}
                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                initialValues={props.initialDocumentFormHistoryValue}
                                publishDate={props.approvalHistoryDocumentDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(props.approvalHistoryDocumentDetails.publish_preferred_date)}
                                form={"admin-document-history-form"}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="pt-0 border-top-0">
                            <div className="w-100 approval-modal-footer">
                                <div className="w-100 d-inline-flex">
                                    <button className="btn btn-90 btn-secondary ml-auto" onClick={this.handleDocumentCloseApprovalHistoryDetail}>閉じる</button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                );
            }
            return template;
        }

        handleDocumentShowApprovalHistoryDetail = (application_id) => {
            Promise.all([
                this.showLoader(),
                this.props.getApprovalHistoryDocumentDetails(application_id).then(
                    () => {
                        this.setState({
                            initialDocumentFormHistoryValue: {
                                ApprovalHistoryDocumentForm: {
                                    post_id: this.props.approvalHistoryDocumentDetails.application_id,
                                    edit_date: Commons.getClientDatetimeDisplay(this.props.approvalHistoryDocumentDetails.updated_date),
                                    product: this.props.approvalHistoryDocumentDetails.product_name,
                                    title: this.props.approvalHistoryDocumentDetails.title,
                                    content: this.props.approvalHistoryDocumentDetails.content,
                                    files: this.props.approvalHistoryDocumentDetails.post_files,
                                    applicant: this.props.approvalHistoryDocumentDetails.created_by,
                                    history_publish_date: this.props.approvalHistoryDocumentDetails.publish_preferred_date == null ? 0 : 1,
                                    publish_preferred_date: Commons.getClientDatetimeDisplay(this.props.approvalHistoryDocumentDetails.publish_preferred_date),
                                    history_publish_type: this.props.approvalHistoryDocumentDetails.publish_type,
                                    remarks: this.props.approvalHistoryDocumentDetails.remarks ? this.props.approvalHistoryDocumentDetails.remarks : ""
                                }
                            },
                            showDocumentApprovalHistoryDetail: true
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleDocumentCloseApprovalHistoryDetail = () => {
            this.setState({
                showDocumentApprovalHistoryDetail: false
            })
        }

        handleDocumentFileDownload = (data) => {
            this.props.getDocumentFile(data)
            .catch(this.handleShowErrorAlert)
        }
    }
}

export {
    withAdminDocumentApprovalHistoryModal
}