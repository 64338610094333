import AdminDocumentScreen from "./AdminDocumentScreen";

export const DOCUMENT_ROUTE = '/admin/document';

export const routes = [
    {
        label: "ドキュメント",
        path: DOCUMENT_ROUTE,
        component: AdminDocumentScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        isPoster: true
    },
]