import React from 'react'
import { Alert } from 'reactstrap';


const withErrorAlert = (WrappedComponent) => {
    return class ErrorAlert extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showErrorAlert: false,
                display: "d-none",
            }
        }

        dismissAlert = (props) => {
            return (
                <div className={"overlay " + this.state.display} onClick={this.handleCloseAlerts}></div>
            )
        }

        handleCloseAlerts = () => {
            this.setState({
                showSuccessAlert: false,
                showErrorAlert: false,
                display: "d-none"
            });
        }

        errorAlert = (props) => {
            return (
                <Alert color="danger" className="message position-fixed w-50 align-self-center" isOpen={props.showErrorAlert} toggle={this.handleCloseErrorAlert}>
                    <p className="m-0" dangerouslySetInnerHTML={{ __html:props.errorMessage}}/>
                </Alert>
            )
        }

        handleShowErrorAlert = (error) => {
            this.setState({
                showErrorAlert: true,
                errorMessage: error,
                display: "block"
            })
        }

        handleCloseErrorAlert = () => {
            this.setState({
                showErrorAlert: false,
                display: "d-none"
            })
        }
    }
}

export {
    withErrorAlert
}