import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Commons from '../Commons';

const withMailDeliveryResultDetailsModal = (WrappedComponent) => {
    return class MailDeliveryResultDetailsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showMailDeliveryDetailsModal: false
            }
        }

        mailDeliveryResultDetailsModal = (props) => {
            let { mailDeliveryResultDetails } = this.state;
            if (mailDeliveryResultDetails) {
                var emailTime = Commons.getClientDatetimeDisplay(mailDeliveryResultDetails.when_added);
                var emailAttemptedTime = Commons.getClientDatetimeDisplay(mailDeliveryResultDetails.when_attempted);
                var emailAttemptedLabel = mailDeliveryResultDetails.result === "1" ? "送信日時" : "送信試行日時";
                var statusText = mailDeliveryResultDetails.result === "1" ? "送信済み" : "送信失敗";
                var statusBg = Commons.getMailingStatusBackground(statusText);
            }

            return (
                <Modal size="md" className="admin-details modal-dialog-lg" keyboard={false} show={this.state.showMailDeliveryDetailsModal} onHide={this.handleCloseMailDeliveryDetailsModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{"メール送信結果一覧 > 詳細"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        {mailDeliveryResultDetails ?
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute pt-1">
                                        <strong>送信元: </strong>
                                        {mailDeliveryResultDetails.message.from}
                                        <label className="float-right mb-0">
                                            <strong>作成日時: </strong>
                                            {emailTime}
                                        </label>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>宛先: </strong>
                                            {mailDeliveryResultDetails.message.to}
                                            <label className="float-right mb-0">
                                                <strong>{emailAttemptedLabel}: </strong>
                                                {emailAttemptedTime}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>件名: </strong>
                                            {mailDeliveryResultDetails.message.subject}
                                            <label className="float-right rounded text-center px-2 mb-0" style={{ color: statusBg, border: "solid 1px" }}>
                                                {statusText}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>内容: </strong>
                                            <pre>{mailDeliveryResultDetails.message.body}</pre>
                                        </div>
                                    </div>
                                    {mailDeliveryResultDetails.log_message ?
                                        <div className="list-item-attribute row m-0 text-break">
                                            <div className="col-sm-12 px-0">
                                                <strong>ログメッセージ: </strong>
                                                <label className="mb-0" style={{ color: "#FF0000" }}>{mailDeliveryResultDetails.log_message}</label>
                                            </div>
                                        </div> : ""}
                                </div>
                            </div> : ""}
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <button className="btn btn-90 btn-secondary" onClick={this.handleCloseMailDeliveryDetailsModal}>
                            閉じる
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowMailDeliveryDetailsModal = (mailDeliveryResultDetails) => {
            this.setState({ showMailDeliveryDetailsModal: true, mailDeliveryResultDetails: mailDeliveryResultDetails })
        }

        handleCloseMailDeliveryDetailsModal = () => {
            this.setState({
                showMailDeliveryDetailsModal: false
            });
        }
    }
}

export {
    withMailDeliveryResultDetailsModal
}