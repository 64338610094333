import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import * as Commons from './Commons';
import * as CONSTANTS from '../../utils/constants';

const withPasswordRequestModal = (WrappedComponent) => {
    return class PasswordRequestModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showPasswordRequest: false
            }
        }

        passwordRequestModal = (props) => {
            return (
                <Modal className="login-request-password modal-dialog-sm"
                    keyboard={false} size="sm"
                    show={this.state.showPasswordRequest}
                    onHide={this.handleClosePasswordRequestModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>パスワードの再設定</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-sm">
                        <div>soliton.co.jpのメールアドレスを入力の上、</div>
                        <div>メール送信ボタンを押してください。</div>
                        <div>パスワード再設定用URLが記載されたメールをお送りします。</div>

                        <div className="mt-3">
                            <input type="text" id="change-password-email"
                                value={props.changePasswordEmail}
                                className="form-control w-100"
                                placeholder="メールアドレス"
                                onChange={() => {
                                    this.setChangePasswordEmail(
                                        document.getElementById("change-password-email").value
                                        )
                                }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0">
                        <Button variant="primary"
                            onClick={this.handleSubmitChangePasswordModal}>
                            メール送信
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowPasswordRequestModal = () => {
            this.setState({
                showPasswordRequest: true
            })
        }

        handleClosePasswordRequestModal = () => {
            this.setState({
                showPasswordRequest: false,
                changePasswordEmail: ""
            })
        }

        setChangePasswordEmail = (email) => {
            this.setState({
                changePasswordEmail: email
            })
        }

        handleSubmitChangePasswordModal = () => {
            if (!Commons.isEmailValid(this.state.changePasswordEmail)) {
                this.handleShowErrorAlert(CONSTANTS.ERROR_INVALID_EMAIL);
            }
            else {
                Promise.all([
                    this.showLoader(),
                    this.props.adminPasswordChangeRequest({
                        email: this.state.changePasswordEmail
                    }).then(
                        () => {
                            this.setState({ 'changePasswordEmail': '' })
                            this.handleClosePasswordRequestModal()
                            this.handleShowSuccessAlert(this.props.passwordChangeRequestMessage)
                        }
                    ),
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
            }
        }
    }
}

export {
    withPasswordRequestModal
}