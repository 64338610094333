import Error404 from "../Errors/404";
import AdminAccessLog from "./AdminAccessLog"
import CustomerAccessLog from "./CustomerAccessLog"

export const ACCESS_LOG_ROUTE = '/admin/accesslog'
export const ADMIN_ACCESS_LOG_ROUTE = '/admin/adminaccesslog';
export const CUSTOMER_ACCESS_LOG_ROUTE = '/admin/customeraccesslog';

export const routes = [
    {
        label: "ログ管理",
        path: ACCESS_LOG_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        dropdown: true,
        isAdminAccessLogging: true,
    },
    {
        label: "管理者ログ",
        path: ADMIN_ACCESS_LOG_ROUTE,
        component: AdminAccessLog,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "顧客アクセスログ",
        path: CUSTOMER_ACCESS_LOG_ROUTE,
        component: CustomerAccessLog,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
]