import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate';
import * as Commons from '../Commons';
import { Formik, Form, Field } from 'formik';

const withAdminNoticeApprovalHistoryModal = (WrappedComponent) => {
    return class AdminNoticeApprovalHistoryModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeApprovalHistoryDetail: false
            }
        }

        adminNoticeApprovalHistoryModal = (props) => {
            let template = "";
            if (props.approvalHistoryNoticeDetails) {
                var applicationDate = Commons.getClientDatetimeDisplay(props.approvalHistoryNoticeDetails.application_date)
                var checkedDate = Commons.getClientDatetimeDisplay(props.approvalHistoryNoticeDetails.checked_date)
                var statusBg = Commons.getApprovalStatusBackground(props.approvalHistoryNoticeDetails.application_status);

                template = (
                    <Modal show={this.state.showNoticeApprovalHistoryDetail} keyboard={false} size="lg" onHide={this.handleNoticeCloseApprovalHistoryDetail} className="modal-edit-post" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>承認管理＞承認履歴</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <div>
                                <label className="float-right rounded text-center px-2" style={{ color: statusBg, border: "solid 1px" }}>
                                    {props.approvalHistoryNoticeDetails.application_status}
                                </label>
                            </div>
                            <Formik>
                                <Form id={"notice-approval-" + props.approvalHistoryNoticeDetails.application_id}>
                                    <table className="configuration-table table table-bordered table-form">
                                        <tbody>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請番号</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_id_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.application_number}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請種別</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_type_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.application_type}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請者</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"applicant_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.created_by}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請日時</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_date_and_time_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={applicationDate}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">申請結果</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colSpan={3} className="align-middle px-2 py-2 approval-table-data-long">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"application_status_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.application_status}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">コメント</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td colSpan={3} className="align-middle px-2 py-2 approval-table-data-long">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="textarea"
                                                        component="textarea"
                                                        name={"comment_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.remarks ? props.approvalHistoryNoticeDetails.remarks : ""}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">承認者</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"approver_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={props.approvalHistoryNoticeDetails.checked_by ? props.approvalHistoryNoticeDetails.checked_by : ""}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="align-middle disable-table font-weight-bold approval-table-header">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <label className="mb-0">承認日時</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle px-2 py-2 approval-table-data">
                                                    <Field
                                                        className="form-control text-left"
                                                        type="text"
                                                        name={"checked_date_and_time_" + props.approvalHistoryNoticeDetails.application_id}
                                                        value={checkedDate}
                                                        disabled={true}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Form>
                            </Formik>
                            <label className="mb-0 approval-form-title"><strong>申請内容</strong></label>
                            <AdminFormTemplate
                                formInputs={props.approvalHistoryPostForm}
                                radioInput={props.radioInput}
                                initialValues={props.initialFormHistoryValue}
                                publishDate={props.approvalHistoryNoticeDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(props.approvalHistoryNoticeDetails.publish_preferred_date)}
                                form={"admin-notice-history-form"}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="pt-0 border-top-0">
                            <div className="w-100 approval-modal-footer">
                                <div className="w-100 d-inline-flex">
                                    <button className="btn btn-90 btn-secondary ml-auto" onClick={this.handleNoticeCloseApprovalHistoryDetail}>閉じる</button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                );
            }
            return template;
        }

        handleNoticeShowApprovalHistoryDetail = (application_id) => {
            Promise.all([
                this.showLoader(),
                this.props.getApprovalHistoryNoticeDetails(application_id).then(
                    () => {
                        this.setState({
                            initialFormHistoryValue: {
                                ApprovalHistoryPostForm: {
                                    post_id: this.props.approvalHistoryNoticeDetails.application_id,
                                    edit_date: Commons.getClientDatetimeDisplay(this.props.approvalHistoryNoticeDetails.updated_date),
                                    type: this.props.approvalHistoryNoticeDetails.category_name,
                                    product: this.props.approvalHistoryNoticeDetails.product_name,
                                    title: this.props.approvalHistoryNoticeDetails.title,
                                    content: this.props.approvalHistoryNoticeDetails.content,
                                    applicant: this.props.approvalHistoryNoticeDetails.created_by,
                                    history_publish_date: this.props.approvalHistoryNoticeDetails.publish_preferred_date == null ? 0 : 1,
                                    publish_preferred_date: Commons.getClientDatetimeDisplay(this.props.approvalHistoryNoticeDetails.publish_preferred_date),
                                    history_publish_type: this.props.approvalHistoryNoticeDetails.publish_type,
                                    remarks: this.props.approvalHistoryNoticeDetails.remarks ? this.props.approvalHistoryNoticeDetails.remarks : ""
                                }
                            },
                            showNoticeApprovalHistoryDetail: true
                        })
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleNoticeCloseApprovalHistoryDetail = () => {
            this.setState({
                showNoticeApprovalHistoryDetail: false
            })
        }
    }
}

export {
    withAdminNoticeApprovalHistoryModal
}