import React from 'react';
import { Modal } from 'react-bootstrap';
import { withMailSettingsEditConfirm } from './MailSettingsEditConfirmModal';
import AdminFormTemplate from '../AdminPortalFormTemplate'
import { adminEmailManagementSelectors } from '../../containers/AdminEmailManagement/state';


const withMailSettingsEdit = (WrappedContent) => {

    return withMailSettingsEditConfirm(
        class MailSettingsEdit extends WrappedContent {
            constructor(props) {
                super(props);
                this.state = {
                    ...this.state,
                    showMailSettingsEditModal: false,
                    mailSettingsForm: {},
                    currentFormValues: {},
                    initialFormValue: {},
                };
            }

            mailSettingsEditModal = (props) => {

                let isDefault = 
                    (props.currentFormValues && props.initialFormValue) ? 
                    (props.currentFormValues.EMAIL_HOST === props.initialFormValue.MailSettingsForm.EMAIL_HOST) &&
                    (props.currentFormValues.EMAIL_PORT === props.initialFormValue.MailSettingsForm.EMAIL_PORT) &&
                    (props.currentFormValues.EMAIL_HOST_USER === props.initialFormValue.MailSettingsForm.EMAIL_HOST_USER) &&
                    (props.currentFormValues.EMAIL_HOST_PASSWORD === props.initialFormValue.MailSettingsForm.EMAIL_HOST_PASSWORD) &&
                    (props.currentFormValues.EMAIL_HOST_PASSWORD_CONFIRM === props.initialFormValue.MailSettingsForm.EMAIL_HOST_PASSWORD_CONFIRM) &&
                    (props.currentFormValues.EMAIL_TIMEOUT === props.initialFormValue.MailSettingsForm.EMAIL_TIMEOUT) &&
                    (props.currentFormValues.EMAIL_FROM === props.initialFormValue.MailSettingsForm.EMAIL_FROM) &&
                    (props.currentFormValues.EMAIL_PROTOCOL === props.initialFormValue.MailSettingsForm.EMAIL_PROTOCOL)
                    : true;

                return (
                    <Modal keyboard={false} size="lg" centered 
                        className="modal-edit-mail-settings modal-dialog-lg" 
                        show={props.showMailSettingsEditModal} 
                        onHide={ isDefault ? this.handleCloseMailSettingsEditModal : this.handleShowDiscardModal } >
                        <Modal.Header closeButton>
                            <Modal.Title>{"メールサーバー設定編集"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <AdminFormTemplate
                                form={"mail-settings-edit"}
                                editMailSettings={true}
                                formInputs={this.state.mailSettingsForm}
                                selectInput={props.selectOptions}
                                initialValues={this.state.initialFormValue}
                                handleFormOnchange={this.handleFormOnchange}
                                handleSubmitMailSettingsEdit={this.handleSubmitMailSettingsEdit}
                                handleShowMailSettingsEditConfirmModal={this.handleShowMailSettingsEditConfirmModal}
                                validate={adminEmailManagementSelectors.mailSettingsCheckErrors}
                            />
                        </Modal.Body>
                        <Modal.Footer className="border-top-0 pt-0">
                            <div className="w-100 d-inline-flex">
                                <input type="hidden" name="submit_action" />
                                <button 
                                    type="button" form="mail-settings-edit" 
                                    className="btn btn-90 mr-2 ml-auto btn-primary" 
                                    onClick={this.handleMailSettingsEditAction}>保存</button>
                                <button 
                                    className="btn btn-90 btn-secondary" 
                                    onClick={ isDefault ? this.handleCloseMailSettingsEditModal : this.handleShowDiscardModal }>キャンセル</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }

            handleFormOnchange = (values) => {
                if (values.formValues.MailSettingsForm) {
                    this.setState({ 
                        currentFormValues: values.formValues.MailSettingsForm
                    });
                }
                else if (values.formValues.TestMailServerForm) {
                    this.setState({ 
                        currentFormValues: values.formValues.TestMailServerForm
                    });
                }
            }

            handleCloseAllModal = () => {
                this.handleCloseMailSettingsEditModal();
                this.handleCloseMailSettingsTestServerModal();
                this.handleCloseDiscardModal();
            }

            handleMailSettingsEditAction = () => {
                document.querySelector('.modal.show .portalSubmitButton').click();
                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowMailSettingsEditConfirmModal";
            }

            handleShowMailSettingsEditModal = (event) => {
                event.target.blur();
                if (this.props.totalItemsCount && this.props.formInputs) {
                    this.showLoader();
                    let mailSettingsForm = {
                        model: "MailSettingsForm",
                        form: []
                    };
                    let settingKeys = this.props.mailSettingsList.map(setting=>setting.key);
                    if (this.props.formInputs.form) {
                        for (let form of this.props.formInputs.form) {
                            if (settingKeys.includes(form.name) || ["EMAIL_PROTOCOL", "EMAIL_HOST_PASSWORD_VIEW", "EMAIL_HOST_PASSWORD_CONFIRM"].includes(form.name)) {
                                mailSettingsForm.form.push(form)
                            }
                        }
                    }
                    
                    let mailSettings = this.props.mailSettingsList.reduce((acc, cur) => 
                        ({...acc, [cur.key]:cur.value}), {}
                    );

                    let protocolKeys = ['EMAIL_USE_TLS', 'EMAIL_USE_SSL'];
                    for (let attr in mailSettings) {
                        if (protocolKeys.includes(attr)) {
                            delete mailSettings[attr];
                        }
                        if ('EMAIL_HOST_PASSWORD' === attr) {
                            mailSettings['EMAIL_HOST_PASSWORD_VIEW'] = mailSettings['EMAIL_HOST_PASSWORD'];
                            mailSettings['EMAIL_HOST_PASSWORD_CONFIRM'] = "";
                        }
                    }
                    let protocolSelected = this.props.mailSettingsList.find((o) => { 
                        return ['EMAIL_USE_TLS', 'EMAIL_USE_SSL'].includes(o.key) && 
                        o.value.toLowerCase() === "true";
                    });
                    mailSettings['EMAIL_PROTOCOL'] = protocolSelected.key;

                    let initialFormValue = {MailSettingsForm: mailSettings}
                    this.setState({
                        showMailSettingsEditModal: true,
                        mailSettingsForm: mailSettingsForm,
                        initialFormValue: initialFormValue,
                    }, this.closeLoader);
                }
            }
        
            handleCloseMailSettingsEditModal = () => {
                this.setState({
                    showMailSettingsEditModal: false,
                })
            }

            handleSubmitMailSettingsEdit = (values) => {
                let payload = {
                    'email_host': values.EMAIL_HOST,
                    'email_port': values.EMAIL_PORT,
                    'email_host_user': values.EMAIL_HOST_USER,
                    'email_protocol': values.EMAIL_PROTOCOL,
                    'email_timeout': values.EMAIL_TIMEOUT,
                    'email_from': values.EMAIL_FROM
                    
                }
                if ('EMAIL_PROTOCOL' in values) {
                    if (values.EMAIL_PROTOCOL === 'EMAIL_USE_TLS') {
                        payload['email_use_tls'] = 1;
                        payload['email_use_ssl'] = 0;
                    }
                    else {
                        payload['email_use_tls'] = 0;
                        payload['email_use_ssl'] = 1;
                    }
                }
                if (values.EMAIL_HOST_PASSWORD !== this.state.initialFormValue.MailSettingsForm.EMAIL_HOST_PASSWORD) {
                    payload['email_host_password'] = values.EMAIL_HOST_PASSWORD;
                }
                this.setState({
                    mailSettingsData: {
                        payload: payload
                    }
                });
            }
        }
    )
}

export {
    withMailSettingsEdit
}
