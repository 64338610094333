import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import * as Commons from '../Commons'

const withDocumentDetailModal = (WrappedComponent) => {
    return class DocumentDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentDetailsModal: false
            }
        }

        documentDetailModal = (props) => {
            let template = [];
            let { documentDetails } = props;
            if (documentDetails) {
                let publishDate = Commons.getClientDatetimeDisplay(documentDetails.publish_date);
                let hasFiles = documentDetails.files ? documentDetails.files.some(file => Boolean(file.file_name) === true) : false;
                let hasExtFiles = documentDetails.files ? documentDetails.files.some(file => Boolean(file.url) === true) : false;

                template = (
                    <Modal className="user-document-details" size="lg" keyboard={false} show={this.state.showDocumentDetailsModal} backdrop="static" onHide={this.handleCloseDocumentDetailsModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {documentDetails.title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-2">
                                <span className="custom-badge mr-2" style={{ backgroundColor: documentDetails.product_bg_color }}>
                                    {documentDetails.product_name}
                                </span>
                            </div>
                            <div className="pt-3">
                                <span>
                                    <strong>
                                        {`サービス名: ${documentDetails.product_long_name}`}
                                    </strong>
                                </span>
                            </div>
                            <div className="py-3">
                                {publishDate}
                            </div>
                            <div>
                                {Commons.getDetailsFormattedContent(documentDetails.content)}
                            </div>
                            <div className={hasFiles ? "details-file pt-3" : "d-none"}>
                                <div className="font-weight-bold">ダウンロードファイル</div>
                                <div className="pt-2 text-left ">
                                    {
                                        hasFiles ? documentDetails.files.map((file, idx) => {
                                            if (!Boolean(file.url)) {
                                                return (<p className="file mb-0" key={idx}>
                                                    <a href="# " title={file.file_name} onClick={() => {
                                                        this.handleDocumentFileDownload(file)
                                                    }}>{file.file_name}</a>
                                                </p>)
                                            } else { return true }
                                        }) : ""
                                    }
                                </div>
                            </div>
                            <div className={hasExtFiles ? "details-url pt-3" : "d-none"}>
                                <div className="font-weight-bold">ダウンロードリンク</div>
                                <div className="pt-2 text-left ">
                                    {
                                        hasExtFiles ? documentDetails.files.map((file, idx) => {
                                            if (Boolean(file.url)) {
                                                return (<p className="url mb-0" key={idx}>
                                                    <a href={file.url} title={file.url} rel="noopener noreferrer" target="_blank">{file.url}</a>
                                                </p>)
                                            } else { return true }
                                        }) : ""
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Button variant="secondary" className="btn-90" onClick={this.handleCloseDocumentDetailsModal}>
                                閉じる
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            return template;
        }

        handleDocumentFileDownload = (data) => {
            this.props.getDocumentFile(data)
            .catch(this.handleShowErrorAlert)
        }

        handleCloseDocumentDetailsModal = () => {
            this.setState({
                showDocumentDetailsModal: false
            })
        }
    }
}

export {
    withDocumentDetailModal
}