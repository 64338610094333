import React, { Component } from 'react';


class Error403 extends Component {
    render() {
        return (
            <h4>管理機能のアクセス権限は持っていません。システム管理者へご連絡ください。</h4>
        );
    }
}

export default Error403;