import * as types from "./types";

export const readState = (value) => ({
    type: types.READ_STATE,
    payload: value
});

export const documentDetailsState = (value) => ({
    type: types.DOCUMENT_DETAILS_STATE,
    payload: value
});

export const documentPublishedVersionDetailsState = (value) => ({
    type: types.DOCUMENT_PUBLISHED_VERSION_DETAILS_STATE,
    payload: value
});

export const postDocumentCreateDetailsState = (value) => ({
    type: types.DOCUMENT_CREATE_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const getDocumentEditDetailsState = (value) => ({
    type: types.DOCUMENT_EDIT_RETRIEVE_STATE,
    payload: value
});

export const putDocumentEditDetailsState = (value) => ({
    type: types.DOCUMENT_EDIT_UPDATE_STATE,
    payload: value
});

export const patchDocumentUnpublishState = (value) => ({
    type: types.DOCUMENT_UNPUBLISH_STATE,
    payload: value
});

export const patchDocumentDeleteState = (value) => ({
    type: types.DOCUMENT_DELETE_STATE,
    payload: value
});

export const patchDocumentCancelState = (value) => ({
    type: types.DOCUMENT_CANCEL_STATE,
    payload: value
});

export const getDocumentHistoryDetailsState = (value) => ({
    type: types.DOCUMENT_RETRIEVE_HISTORY_STATE,
    payload: value
});

export const getDocumentFileState = (value) => ({
    type: types.DOCUMENT_DOWNLOAD_STATE,
    payload: value
});