import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../includes/img/SolitonCloudServicePortal_icon.svg';
import { LOGIN_ROUTE, LOGIN_LABEL } from '../containers/Login/routes';
import { ADMIN_LOGIN_ROUTE } from '../containers/AdminLogin/routes';
import { NOTICE_ROUTE } from '../containers/Notice/routes';
import {
    MDBNavbar,
    MDBContainer
} from 'mdbreact';

const MobileCustomMainHeader = (props) => {
    return (
        <>
            <header className="mobile-header py-3">
                <MDBContainer className="p-0 mw-100">
                    <MDBNavbar
                        color='light-blue lighten-4'
                        style={{ margin: '0' }}
                        light
                    >
                        <MDBContainer className="p-0 d-flex">
                            <Nav.Link as={Link} href={NOTICE_ROUTE} to={NOTICE_ROUTE} className="py-0 px-0 soliton_logo">
                                <img src={Logo}
                                    className="header-logo"
                                    alt="Soliton サービスポータル" />
                            </Nav.Link>
                            {
                                props.location.pathname === "/admin/login" || props.location.pathname === "/admin/password/update" ?
                                    <li className="nav-item">
                                        <NavLink className="nav-link text-dark" href={ADMIN_LOGIN_ROUTE} to={ADMIN_LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <NavLink className="nav-link text-dark" href={LOGIN_ROUTE} to={LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
                                    </li>
                            }
                        </MDBContainer>
                    </MDBNavbar>
                </MDBContainer>
            </header>
        </>
    );
};

export default MobileCustomMainHeader;