import * as types from "./types";

export const getMailTemplateListState = (value) => ({
    type: types.MAIL_TEMPLATE_LIST_STATE,
    payload: value
});

export const getMailTemplateEditState = (value) => ({
    type: types.MAIL_TEMPLATE_RETRIEVE_STATE,
    payload: value
});

export const putMailTemplateEditState = (value) => ({
    type: types.MAIL_TEMPLATE_EDIT_STATE,
    payload: value
});

export const getMailSettingsListState = (value) => ({
    type: types.MAIL_SETTINGS_LIST_STATE,
    payload: value
})

export const patchMailSettingsEditState = (value) => ({
    type: types.MAIL_SETTINGS_EDIT_STATE,
    payload: value
})

export const testMailServerState = (value) => ({
    type: types.MAIL_SETTINGS_TEST_SERVER_STATE,
    payload: value
})

export const getMailPendingListState = (value) => ({
    type: types.MAIL_PENDING_LIST_STATE,
    payload: value
})

export const getMailDeliveryResultListState = (value) => ({
    type: types.MAIL_DELIVERY_RESULT_LIST_STATE,
    payload: value
})