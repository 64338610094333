import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const withAdminResetPasswordRequestConfirmModal = (WrappedComponent) => {
    return class AdminResetPasswordRequestConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showAdminResetPasswordRequestConfirmModal: false
            }
        }

        adminResetPasswordRequestConfirmModal = (props) => {
            return (
                <Modal size="md" className="admin-reset-password-request-confirm-modal modal-dialog-md" keyboard={false} show={this.state.showAdminResetPasswordRequestConfirmModal} onHide={this.handleCloseAdminResetPasswordRequestConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{"パスワード変更 > 確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>ユーザにパスワード再設定メールを送信してよろしいですか？</div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn btn-90" onClick={this.handleSubmitPasswordChangeRequest}>
                            送信する
                        </Button>
                        <Button variant="secondary" className="btn btn-90" onClick={this.handleCloseAdminResetPasswordRequestConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleCloseAdminResetPasswordRequestConfirmModal = () => {
            this.setState({
                showAdminResetPasswordRequestConfirmModal: false
            })
        }

        handleSubmitPasswordChangeRequest = () => {
            Promise.all([
                this.showLoader(),
                this.props.adminPasswordChangeRequest({
                    admin_user_id: this.state.AdminResetPasswordRequestUser
                }).then(
                    () => {
                        this.handleShowSuccessAlert(this.props.passwordChangeRequestMessage)
                    }
                ),
                this.handleCloseAdminResetPasswordRequestConfirmModal(),
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            
        }
    }
}

export {
    withAdminResetPasswordRequestConfirmModal
}