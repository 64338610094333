import * as types from "./types";
import * as CONSTANTS from '../../../../utils/constants';

const defaultState = {
    postForm: {
        model: "PostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.NEWS_FORM_TYPE_MESSAGE,
                name: "type",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.FORM_PRODUCT_MESSAGE,
                name: "product",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_CHOOSE_TEMPLATE,
                label: CONSTANTS.POST_TEMPLATE,
                message: CONSTANTS.POST_TEMPLATE_MESSAGE,
                name: "template_choose",
                phase: false,
                inline: true,
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                placeholder: CONSTANTS.NEWS_FORM_PLACEHOLDER_TITLE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 100,
                name: "title",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                placeholder: CONSTANTS.NEWS_FORM_PLACEHOLDER_CONTENT,
                message: CONSTANTS.FORM_CONTENT_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                name: "content",
                default: '',
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                message: CONSTANTS.FORM_PUBLISH_DATE_MESSAGE,
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                message: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_MESSAGE,
                name: "publish_type",
                phase: false,
                inline: true,
                default: CONSTANTS.ZERO_STRING
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                placeholder: "",
                message: "",
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "remarks",
                default: '',
                required: false,
                onSetEmphasize: true,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    templateForm: {
        model: "TemplateForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "template_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_SELECT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                placeholder: CONSTANTS.FORM_PLACEHOLDER_DROPDOWN_SELECT,
                message: CONSTANTS.FORM_PRODUCT_MESSAGE,
                name: "product",
                default: '',
                required: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.TEMPLATE_FORM_NAME_LABEL,
                placeholder: CONSTANTS.NEW_TEMPLATE_FORM_PLACEHOLDER_NAME,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "name",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.TEMPLATE_FORM_NOTE_LABEL,
                placeholder: CONSTANTS.NEW_TEMPLATE_FORM_PLACEHOLDER_NOTE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "note",
                default: '',
                required: false,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.TEMPLATE_FORM_TITLE_LABEL,
                placeholder: CONSTANTS.NEW_TEMPLATE_FORM_PLACEHOLDER_TITLE,
                message: CONSTANTS.FORM_TITLE_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 64,
                name: "title",
                default: '',
                required: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.TEMPLATE_FORM_BODY_LABEL,
                placeholder: CONSTANTS.NEWS_FORM_PLACEHOLDER_CONTENT,
                message: CONSTANTS.FORM_CONTENT_MESSAGE,
                minLength: CONSTANTS.ONE,
                maxLength: 4096,
                name: "content",
                default: '',
                required: false,
                rows: 5
            },
        ]
    },
    radioOptions: [
        { name: "publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "publish_type", label: CONSTANTS.PUBLISH_TYPE_INDICATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "publish_type", label: CONSTANTS.PUBLISH_TYPE_DO_NOT_SHOW_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DATE_IMMEDIATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "history_publish_date", label: CONSTANTS.PUBLISH_DESIRED_DATE_LABEL, value: CONSTANTS.ONE_STRING },
        { name: "history_publish_type", label: CONSTANTS.PUBLISH_TYPE_INDICATE_LABEL, value: CONSTANTS.ZERO_STRING },
        { name: "history_publish_type", label: CONSTANTS.PUBLISH_TYPE_DO_NOT_SHOW_LABEL, value: CONSTANTS.ONE_STRING },
    ],
    noticeDetailPostForm: {
        model: "NoticeDetailPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                name: "type",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                name: "publish_type",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    noticeHistoryPostForm: {
        model: "NoticeHistoryPostForm",
        form: [
            {
                type: CONSTANTS.FORM_TYPE_HIDDEN,
                name: "post_id"
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_EDIT_DATE_LABEL,
                name: "edit_date",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.NEWS_FORM_TYPE_LABEL,
                name: "type",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_PRODUCT_LABEL,
                name: "product",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_TITLE_LABEL,
                name: "title",
                default: '',
                disabled: true,
                rows: 2
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.NEWS_FORM_CONTENT_LABEL,
                name: "content",
                default: '',
                disabled: true,
                rows: 5
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXT,
                label: CONSTANTS.FORM_APPLICANT_LABEL,
                name: "applicant",
                default: '',
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO_DATE,
                label: CONSTANTS.FORM_PUBLISH_DATE_LABEL,
                name: "history_publish_date",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_RADIO,
                label: CONSTANTS.NEWS_FORM_PUBLISH_TYPE_LABEL,
                name: "history_publish_type",
                inline: true,
                default: CONSTANTS.ZERO_STRING,
                disabled: true
            },
            {
                type: CONSTANTS.FORM_TYPE_TEXTAREA,
                component: "textarea",
                label: CONSTANTS.FORM_REMARKS_LABEL,
                name: "remarks",
                default: '',
                onSetEmphasize: false,
                onEmptyInvisible: true,
                disabled: true
            }
        ]
    },
    noticeOrdering: [
        {
            id: 1,
            label: "サービス名(昇順)",
            value: "product__name,-updated_date"
        },
        {
            id: 2,
            label: "サービス名(降順)",
            value: "-product__name,-updated_date"
        },
        {
            id: 3,
            label: "種別(昇順)",
            value: "post_category,-updated_date"
        },
        {
            id: 4,
            label: "種別(降順)",
            value: "-post_category,-updated_date"
        },
        {
            id: 5,
            label: "日時（昇順）",
            value: "updated_date"
        },
        {
            id: 6,
            label: "日時（降順）",
            value: "-updated_date"
        },
        {
            id: 7,
            label: "ステータス（昇順）",
            value: "post_status,-updated_date"
        },
        {
            id: 8,
            label: "ステータス（降順）",
            value: "-post_status,-updated_date"
        }
    ],
    noticeTemplateOrdering: [
        {
            id: 1,
            label: "サービス名(昇順)",
            value: "product__name,-updated_date"
        },
        {
            id: 2,
            label: "サービス名(降順)",
            value: "-product__name,-updated_date"
        },
        {
            id: 3,
            label: "日時（昇順）",
            value: "updated_date"
        },
        {
            id: 4,
            label: "日時（降順）",
            value: "-updated_date"
        }
    ],
    noticeChooseTemplateOrdering: [
        {
            id: 1,
            label: "登録順(昇順）",
            value: "created_date"
        },
        {
            id: 2,
            label: "登録順(降順）",
            value: "-created_date"
        }
    ],
    defaultNoticeOrdering: "-updated_date",
    defaultTemplateOrdering: "created_date",
    tableList: [],
    totalItemsCount: CONSTANTS.ZERO,
    productLookupList: [],
    noticeDetails: ({}),
    noticeCreateDetails: ({}),
    noticeEditDetails: ({}),
    noticeHistoryDetails: ({}),
    noticeMessages: [],
    categoryLookupList: [],
    noticeTemplateDetail: ({}),
    noticeTemplateList: ({}),
    noticeTemplateCreate: ({}),
    noticeTemplateUpdate: ({}),
    noticeTemplateLookup: [],
    totalItemsTemplateCount: CONSTANTS.ZERO,
}

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.READ_STATE:
            return { ...state, tableList: action.payload.results, totalItemsCount: action.payload.count };
        case types.PRODUCT_LOOKUP_STATE:
            return {
                ...state,
                productLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "product", label: item.name }
                })
            };
        case types.NOTICE_DETAILS_STATE:
            return { ...state, noticeDetails: action.payload };
        case types.NOTICE_PUBLISHED_VERSION_DETAILS_STATE:
            return { ...state, noticePublishedVersionDetails: action.payload };
        case types.NOTICE_CREATE_STATE:
            return { ...state, noticeCreateDetails: action.payload };
        case types.NOTICE_EDIT_RETRIEVE_STATE:
            return { ...state, noticeEditDetails: action.payload };
        case types.NOTICE_EDIT_UPDATE_STATE:
            return { ...state, noticeMessages: action.payload };
        case types.NOTICE_UNPUBLISH_STATE:
            return { ...state, noticeMessages: action.payload }
        case types.NOTICE_CANCEL_STATE:
            return { ...state, noticeMessages: action.payload }
        case types.NOTICE_DELETE_STATE:
            return { ...state, noticeMessages: action.payload }
        case types.CATEGORY_LOOKUP_STATE:
            return {
                ...state,
                categoryLookupList: action.payload.map((item) => {
                    return { id: item.id, value: item.id, name: "type", label: item.name }
                })
            };
        case types.NOTICE_RETRIEVE_HISTORY_STATE:
            return { ...state, noticeHistoryDetails: action.payload };
        case types.NOTICE_TEMPLATE_LIST_STATE:
            return { ...state, noticeTemplateList: action.payload.results, totalItemsCount: action.payload.count };
        case types.NOTICE_TEMPLATE_CREATE_STATE:
            return { ...state, noticeTemplateCreate: action.payload };
        case types.NOTICE_TEMPLATE_DETAIL_STATE:
            return { ...state, noticeTemplateDetail: action.payload };
        case types.NOTICE_TEMPLATE_UPDATE_STATE:
            return { ...state, noticeTemplateUpdate: action.payload };
        case types.NOTICE_TEMPLATE_DELETE_STATE:
            return { ...state, noticeTemplateDelete: action.payload };
        case types.NOTICE_TEMPLATE_LOOKUP_STATE:
            return { ...state, noticeTemplateLookup: action.payload.results, totalItemsTemplateCount: action.payload.count };
        default: return state;
    }
};
