import React from 'react';
import { Alert, Button, ButtonToolbar } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { isNullOrUndefined } from 'util';
import "@nateradebaugh/react-datetime/scss/styles.scss";
import FormikEffect from './FormikEffect'
import TooltipTemplate from './TooltipTemplate';
import { FormLabel, FormGroup, FormCheck } from 'react-bootstrap';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SketchPicker } from 'react-color';
import DateTime from "@nateradebaugh/react-datetime";
import { DATETIME_FORMAT_3 } from '../../utils/constants';

let AdminPortalFormTemplate = (props) => {
    //Initial values
    let initialValues = props.initialValues;

    if (isNullOrUndefined(props.initialValues)) {
        initialValues = {};
        //Initial value if tabbed or single
        if (isNullOrUndefined(props.initialValues)) {
            if (props.tabbed || props.nested) {
                props.multiFormInputs.map(tab => {
                    let modelValues = {};
                    tab.form.map(input => (
                        input.type === "datepicker" ? modelValues[input.name] = moment()
                            : modelValues[input.name] = input.default
                    ));
                    initialValues[tab.model] = modelValues;
                    return initialValues[tab.model];
                });
            } else {
                let modelValues = {};
                if (props.isServicePortal) {
                    props.servicePortalFormInputs.form.map(input => (
                        input.type === "datepicker" ? modelValues[input.name] = moment()
                            : modelValues[input.name] = input.default)
                    );
                    initialValues[props.servicePortalFormInputs.model] = modelValues;
                }
                else {
                    props.formInputs.form.map(input => (
                        input.type === "datepicker" ? modelValues[input.name] = moment()
                            : modelValues[input.name] = input.default)
                    );
                    initialValues[props.formInputs.model] = modelValues;
                }
            }
        }
    }

    function RenderInput(model, input, fieldValue, setFieldValue, errors, touched) {
        switch (input.type) {

            //renders radio button group field
            case "radio": {
                let modelField = `${model}.${input.name}`;

                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div className="d-flex row">
                                {props.radioInput.map((radio, index) => {
                                    if (radio.name === input.name) {
                                        return (
                                            <div className="form-radio col-md-4 col-6" key={input.name + index}>
                                                <FormCheck
                                                    id={input.name + index + props.form}
                                                    type="radio"
                                                    inline={input.inline}
                                                    value={radio.value}
                                                    name={modelField}
                                                    onChange={event => setFieldValue(modelField, event.target.value)}
                                                    checked={fieldValue.toString() === radio.value.toString()}
                                                    label={radio.label}
                                                    disabled={input.disabled || radio.disabled}
                                                />
                                            </div>
                                        );
                                    }
            
                                    return null;
                                })}
                            </div>
                        </td>
                    </tr>
                )
            }

            //renders checkbox button group field
            case "checkbox": {
                let modelField = `${model}.${input.name}`;

                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}
                                        {
                                            input.required && !props.editKintoneMode && !props.editKintoneCommonMode ?
                                                <span className="text-danger font-weight-bold"> * </span>
                                                : input.required && input.name === "display_label" ?
                                                    <span className="text-danger font-weight-bold"> * </span>
                                                    : null
                                        }
                                    </label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <FormCheck
                                id={input.name + props.form}
                                type="checkbox"
                                inline={input.inline}
                                value={input.id}
                                name={modelField}
                                onChange={event => {
                                    setFieldValue(modelField, event.target.checked)
                                }}
                                checked={fieldValue}
                                disabled={input.disabled}
                            />
                        </td>
                    </tr>
                )
            }

            // renders select field
            case "select": {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold" style={props.isExportAdminForm ? {width: '35%'} : {}}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control form-error" : "form-control"}
                                component="select"
                                name={modelField}
                            >
                                {!props.isExportAdminForm && <option disabled value="">{input.placeholder}</option>}
                                {props.selectInput && props.selectInput.filter((option) => option.name === input.name).map((item, i) => {
                                    return (
                                        <option
                                            key={input.name + i} value={item.value}> {item.label} </option>
                                    );
                                })}
                            </Field>
                        </td>
                    </tr>
                );
            }

            case "hidden": {
                return null;
            }

            case "table-heading": {
                return (
                    <tr key={input.label}>
                        <td colSpan={3} className="disable-table">{input.label}</td>
                    </tr>
                );
            }

            case "image": {
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : "form-group product_image"} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <FormGroup className="form-input mb-0">
                                <FormLabel className={props.image ? "" : "image_border"}>
                                    <a href="/#" className={props.image ? "d-none" : "ml-auto mr-auto"} onClick={(event) => {
                                        event.preventDefault();
                                        let link = document.querySelector('input.input-file');
                                        link.click();
                                    }}>アイコン画像<br />を選択</a>
                                    <img src="#" id="logo" alt="" className={props.image ? "" : "d-none"} />
                                </FormLabel>
                                <input className="input-file d-none" name="PortalProductForm.image" accept="image/*" type="file" onChange={(event) => {
                                    if (event.target.files.length > 0 && event.target.files[0].type.split("/")[0] === "image") {
                                        var reader = new FileReader();
                                        reader.onload = function (e) {
                                            document.getElementById('logo').setAttribute('src', e.target.result);
                                        }
            
                                        reader.readAsDataURL(event.target.files[0]);
                                        setFieldValue("PortalProductForm.image", event.target.files[0]);
                                        setFieldValue("PortalProductForm.service_icon", event.target.files[0]);
                                        props.setImageForUpload(event.target.files[0]);
                                    }
                                    else {
                                        setFieldValue("PortalProductForm.image", null);
                                        setFieldValue("PortalProductForm.service_icon", null);
                                        props.setImageForUpload(null);
                                    }
                                }} />
                            </FormGroup>
                            {props.image ?
                                <div className="mt-1">
                                    <FontAwesomeIcon icon={faEdit} className="mr-2" onClick={(event) => {
                                        event.preventDefault();
                                        let link = document.querySelector('input.input-file');
                                        link.click();
                                    }} />
                                    <FontAwesomeIcon icon={faTrashAlt} onClick={(event) => {
                                        event.preventDefault();
                                        setFieldValue("PortalProductForm.image", "delete");
                                        props.setImageForUpload(null);
                                    }} />
                                </div> : null}
                        </td>
                    </tr>
                );
            }

            case "color": {

                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div style={props.styles.swatch} onClick={props.handleClickColorPicker}>
                                <div style={props.styles.color} />
                            </div>
                            {
                                props.displayColorPicker ? <div style={props.styles.popover}>
                                    <div style={props.styles.cover} onClick={props.handleCloseColorPicker} />
                                    <SketchPicker color={props.color} onChange={props.handleChangeColorPicker} />
                                </div> : null
                            }
                        </td>
                    </tr>
                );
            }

            case "date": {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}
                                        {input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}
                                    </label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <DateTime id={input.name + "dateTime"} dateFormat={DATETIME_FORMAT_3} timeFormat={true}
                                className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                                value={props.fields[input.name]}
                                onChange={value => {
                                    props.handleDateChange(input.name, value)
                                    setFieldValue(modelField, props.fields[input.name])
                                }} disabled={input.disabled} />
                        </td>
                    </tr>
                );
            }

            case "password-view": {
                let modelField = `${model}.${input.name}`;

                return (
                    <tr key={input.name} className="password-view">
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}
                                        {
                                            input.required ?
                                                <span className="text-danger font-weight-bold"> * </span>
                                                : null
                                        }
                                    </label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle pl-3 pr-2 pt-3">
                            <span>{fieldValue} </span>
                            <a className={"float-right nav-link py-0 px-0 btn-action"} href="# " onClick={() => {
                                let hiddenPasswordInputs = document.querySelectorAll('.hidden-password');
                                for (let i = 0; i < hiddenPasswordInputs.length; i++) {
                                    hiddenPasswordInputs.item(i).classList.remove('d-none');
                                }
                                setFieldValue(`${modelField}_CHANGE`, 1);
                                let parentPasswordViewInput = document.querySelector('.password-view');
                                parentPasswordViewInput.classList.add('d-none');
                            }}>
                                <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1"></span>
                            </a>
                        </td>
                    </tr>
                );
            }

            //renders text, textarea, email, password, numbers, default input
            default: {
                let modelField = `${model}.${input.name}`;
                let modelTouch = touched[model];
                let isTouched = undefined !== touched[model] && modelTouch[input.name];
                let isError = undefined !== errors[modelField];
                let displayError = isTouched && isError;
            
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : input.hide ? "d-none hidden-password" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row d-block">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}
                                        {
                                            input.required && !props.editKintoneMode && !props.editKintoneCommonMode ?
                                                <span className="text-danger font-weight-bold"> * </span>
                                                : input.required && input.name === "display_label" ?
                                                    <span className="text-danger font-weight-bold"> * </span>
                                                    : null
                                        }
                                    </label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                                placeholder={input.placeholder}
                                component={input.component}
                                type={input.type}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onClick={event => {
                                    if (input.type === "password") {
                                        setFieldValue(modelField, "");
                                    }
                                }}
                                onChange={event => {
                                    if (input.type === "textarea" && modelField.split(".").includes("title")) {
                                        setFieldValue(modelField, event.target.value.replace(/(\r\n|\n|\r)/gm, ""));
                                    } else {
                                        setFieldValue(modelField, event.target.value);
                                    }
                                }}
                                style={{ backgroundColor: (input.onSetEmphasize && fieldValue) ? '#f9f972' : '' }}
                                disabled={(props.editKintoneMode || props.editKintoneCommonMode) && input.name === "kintone_attribute_name" ? true : input.disabled}
                                rows={input.rows}
                            />
                        </td>
                    </tr>
                );
            }
        }

    }

    //Maps form inputs
    const FormInputs = (values, setFieldValue, errors, touched) =>
        props.isServicePortal ?
            props.servicePortalFormInputs.form && props.servicePortalFormInputs.form.map((input) => {
                return RenderInput(props.servicePortalFormInputs.model, input, values[props.servicePortalFormInputs.model][input.name], setFieldValue, errors, touched)
            })
            :
            props.formInputs.form && props.formInputs.form.map((input) => {
                return RenderInput(props.formInputs.model, input, values[props.formInputs.model][input.name], setFieldValue, errors, touched)
            });

    var DisplayAlert = (errors, form) => {
        // eslint-disable-next-line
        let errorList = Object.entries(errors).map((error, idx) => {
            if (form[error[0].split(".").pop(-1)]) {
                return <p key={idx} className="mb-0">{error[1]}</p>
            }
        })
        let displayTemplate = <></>

        if (errorList.length){
            displayTemplate = <Alert variant="danger">{errorList}</Alert>
        }

        return displayTemplate;
    }

    if ((!isNullOrUndefined(props.initialValues) && props.editMode) || !props.editMode) {
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    className={props.className}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={values => {
                        return props.validate(values);
                    }}
                    onSubmit={(values) => {

                        if (values.PortalProductForm) {
                            values.PortalProductForm.bg_color = "#" + ((1 << 24) + (props.color.r << 16) + (props.color.g << 8) + props.color.b).toString(16).slice(1)
                        }

                        if (props.addMode) {
                            props.handleSubmitModalNewProduct(values.PortalProductForm);
                        }
                        else if (props.editMode && props.productDraft) {
                            if (props.isServicePortal) {
                                values.PortalProductForm['kintone_api_link'] = 'url';
                                values.PortalProductForm['kintone_api_id'] = 1;
                                values.PortalProductForm['kintone_api_token'] = 'token';
                                values.PortalProductForm['faq_link'] = 'url';
                                values.PortalProductForm['inquiry_link'] = 'url';
                                values.PortalProductForm['kintone_key_field'] = 'key';
                                values.PortalProductForm['kintone_key_value'] = 'value';
                            }
                            props.handleSubmitModalProductDraft(values.PortalProductForm.id, values.PortalProductForm);
                        }
                        else if (props.addKintoneMode) {
                            props.handleSubmitModalNewKintone(values.KintoneProductForm);
                        }
                        else if (props.addKintoneCommonMode) {
                            props.handleSubmitModalNewKintoneCommon(values.KintoneProductForm);
                        }
                        else if (props.editKintoneMode) {
                            props.handleSubmitModalProductKintoneEdit(values.KintoneProductForm);
                        }
                        else if (props.editKintoneCommonMode) {
                            props.handleSubmitModalCommonKintoneEdit(values.KintoneProductForm);
                        }
                        else if (props.editMailTemplate) {
                            props.handleSubmitMailTemplateEdit(values.MailTemplateForm);
                        }
                        else if (props.editMailSettings) {
                            props.handleSubmitMailSettingsEdit(values.MailSettingsForm);
                        }
                        else if (props.testMailServer) {
                            props.handleSubmitTestMailServer(values.TestMailServerForm);
                        }
                        else if (props.isExportAdminForm) {
                            props.handleSubmitModalExportAdmin(values.ExportAdminPostForm);
                        }
                        // else if (props.editMode) {
                        //     console.log('edit mode');
                        //     props.handleSubmitDraftModal(values.PostForm.post_id, values.PostForm);
                        // } else if (props.rejectMode) {
                        //     console.log('reject mode');
                        //     props.handleSubmitRejectModal(values.RejectForm.post_id, values.RejectForm);
                        // } else if (props.confirmApprovalMode){
                        //     props.handleSubmitApprovalModal(values.ApprovalPostForm.post_id, values.ApprovalPostForm);
                        // } else if (props.rejectApprovalMode) {
                        //     props.handleSubmitApprovalRejectModal(values.ApprovalRejectForm.post_id, values.ApprovalRejectForm);
                        // }

                        if (document.querySelector('.modal.show input[name=submit_action]')) {
                            switch (document.querySelector('.modal.show input[name=submit_action]').value) {
                                case "handleShowNewProductConfirmModal": {
                                    props.handleShowNewProductConfirmModal();
                                    break;
                                }
                                case "handleShowDraftConfirmModal": {
                                    props.handleShowDraftConfirmModal();
                                    break;
                                }
                                case "handleShowNewKintoneConfirmModal": {
                                    props.handleShowNewKintoneConfirmModal();
                                    break;
                                }
                                case "handleShowNewKintoneCommonConfirmModal": {
                                    props.handleShowNewKintoneCommonConfirmModal();
                                    break;
                                }
                                case "handleShowProductKintoneConfirmEditLabelModal": {
                                    props.handleShowProductKintoneConfirmEditLabelModal();
                                    break;
                                }
                                case "handleShowCommonKintoneConfirmEditLabelModal": {
                                    props.handleShowCommonKintoneConfirmEditLabelModal();
                                    break;
                                }
                                case "handleShowMailTemplateEditConfirmModal": {
                                    props.handleShowMailTemplateEditConfirmModal();
                                    break;
                                }
                                case "handleShowMailSettingsEditConfirmModal": {
                                    props.handleShowMailSettingsEditConfirmModal();
                                    break;
                                }
                                case "handleShowMailSettingsTestServerConfirmModal": {
                                    props.handleShowMailSettingsTestServerConfirmModal();
                                    break;
                                }
                                case "handleExportAdminDownload": {
                                    props.handleExportAdminDownload();
                                    break;
                                }
                                // case "handleShowApprovalConfirmModal" : {
                                //     props.handleShowApprovalConfirmModal()
                                //     break;
                                // }
                                // case "handleShowApprovalRejectModal" : {
                                //     props.handleShowApprovalRejectModal(values.ApprovalPostForm.post_id)
                                //     break;
                                // }
                                default: {
                                    break;
                                }
                            }
                        }
                    }}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form id={props.form}>
                            <FormikEffect delay={500} onChange={values => props.handleFormOnchange(values)} />
                            {DisplayAlert(errors, touched[Object.keys(touched)[0]])}
                            <table className="table table-bordered table-form position-relative">
                                <colgroup>
                                    <col className="colwidth4" />
                                    <col />
                                </colgroup>
                                <tbody>
                                    {FormInputs(values, setFieldValue, errors, touched)}
                                </tbody>
                            </table>
                            <ButtonToolbar className="d-none">
                                <Button type="submit" value="Submit" name="submit" className="portalSubmitButton" />
                            </ButtonToolbar>
                        </Form>
                    )}
                </Formik>
            </>
        );
    } else {
        return <p>Loading . . .</p>;
    }
}

export default AdminPortalFormTemplate;
