import React from 'react';
import * as Commons from '../Commons';

const CustomerAccessLoggingTemplate = (props) => {
    let {
        rows,
    } = props;

    return (
        <section className="list mt-3 mb-3">
            {props.totalItemsCount > 0 ?
                rows.map((log) => {
                    var accessTimeStamp = Commons.getClientDatetimeDisplay(log.access_timestamp);
                    var statusText = Commons.getStatusCodeText(log.result);
                    var statusColor = Commons.getStatusCodeColor(log.result);
                    var customStyle = { fontSize: '17.5px', fontWeight: 'bold' }

                    return (
                        <div className="px-3 py-3 list-group-item table-layout" key={log.id}>
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute pt-1">
                                        <strong style={customStyle}>{log.username}</strong>
                                        <label className="float-right mb-0">
                                            {accessTimeStamp}
                                        </label>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-md-3 col-sm-12 px-0">
                                            <strong>接続元アドレス: </strong>
                                            {log.ip_address}
                                        </div>
                                        <div className="col-md-3 col-sm-12 px-0">
                                            <strong>操作結果: </strong>
                                            <label className="mb-0" style={statusColor}>{statusText}</label>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-md-3 col-sm-12 px-0">
                                            <strong>操作: </strong>
                                            {log.operation}
                                        </div>
                                        <div className="col-md-9 col-sm-12 px-0">
                                            <strong className="align-top">補足: </strong>
                                            <div className="d-inline-block">
                                                {log.data ?
                                                    JSON.parse(log.data).map((data, index) => (
                                                        <p className="mb-0" key={index}>{data.label + " " + data.value}</p>
                                                    )) : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute">
                                        <strong>ブラウザ情報: </strong>
                                        {log.user_agent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                : <h4>検索の結果、0件でした。</h4>
            }

        </section>
    );
}

export default CustomerAccessLoggingTemplate;