import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const LoginErrorModal = (props) => {
    const { showModal, handleCloseModal } = props;

    return (
        <Modal backdrop="static" className="login-error" keyboard={false} size="md" show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header className="border-bottom-0">
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-12">
                        ログイン期限が切れました。恐れ入りますが、再度ログインしてください。
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button variant="primary" className="btn-90" onClick={handleCloseModal} href={props.login_route}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LoginErrorModal