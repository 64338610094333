import React, { Component } from "react";
import { authOperations } from '../Login/state'
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { ROOT } from "../../../config/settings";
import { bindActionCreators } from 'redux';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withLoader } from "../../components/Loader";
import { withPasswordRequestModal } from '../../components/PasswordRequestModal';

class LoginScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            logged: false,
            showPasswordReset: false,
            changePasswordEmail: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let error = params.get('error');
        let success = params.get('success');

        if (error) {
            this.handleShowErrorAlert(error);
            params.delete('error');
            window.history.replaceState(null, '', '?');
        }
        else if (success) {
            this.handleShowSuccessAlert(success);
            params.delete('success');
            window.history.replaceState(null, '', '?');
        }
    }

    validateForm() {
        return 0 < this.state.email.length && 0 < this.state.password.length;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.loginUser(this.state.email, this.state.password)
            .catch(this.handleShowErrorAlert);
    }

    render() {
        // Change the redirect to the main page of your app
        if (this.props.isAuthenticated && this.props.isAdmin) {
            if(this.props.isSuperuser || this.props.isPoster){
                return <Redirect to="/admin/noticepost" />;
            }
            else if(this.props.isApprover){
                return <Redirect to="/admin/approvalpending" />;
            }
            else if(this.props.isPortalAdmin){
                return <Redirect to="/admin/portalusers" />;
            }
            else {
                return <Redirect to="/admin" />;
            }
        }
        else if (this.props.isAuthenticated) {
            return <Redirect to={ROOT} />;
        }

        return (
            <>
                <div id="login-card" className="col-lg-4 col-md-6 col-sm-8 col-12">
                    <h4>ログイン</h4>
                    <form onSubmit={this.handleSubmit}>
                        <h6 className="mt-2">ログイン名</h6>
                        <input type="text" id="email"
                            value={this.state.email}
                            onChange={this.handleChange} className="form-control w-100" />
                        <h6 className="mt-2">パスワード</h6>
                        <input type="password" id="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            onKeyPress={event => {
                                if (event.key === "Enter" && document.getElementById('email').value) {
                                    this.handleSubmit(event)
                                }
                            }}
                            className="form-control w-100" />
                        <br />
                        <div className="w-100 d-inline mt-2">
                            <a href="# " onClick={this.handleShowPasswordRequestModal}>パスワードをお忘れの方</a>
                            <button type="button" id="login-button"
                                className="btn btn-primary ml-auto float-right"
                                disabled={!this.validateForm()}
                                onClick={this.handleSubmit}>ログイン</button>
                        </div>
                    </form>
                </div>
                {this.loader({ ...this.state })}
                {this.dismissAlert({ ...this.state })}
                {this.errorAlert({ ...this.state })}
                {this.successAlert({ ...this.state })}
                {this.passwordRequestModal({ ...this.state, ...this.props })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isAdmin: state.auth.isAdmin,
        isPoster: state.auth.isPoster,
        isApprover: state.auth.isApprover,
        isPortalAdmin: state.auth.isPortalAdmin,
        isSuperuser: state.auth.isSuperuser,
        passwordChangeRequestMessage: state.auth.passwordChangeRequestMessage,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        loginUser: authOperations.loginUser,
        logoutUser: authOperations.logoutUser,
        adminPasswordChangeRequest: authOperations.adminPasswordChangeRequest
    },
    dispatch
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withLoader(
            withErrorAlert(
                withSuccessAlert(
                    withPasswordRequestModal(
                        LoginScreen
                    )
                )
            )
        )
    )
);