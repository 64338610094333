import React, { Component } from 'react';
import ControlPanelTemplate from "../../components/ControlPanel/ControlPanelTemplate";
import { connect } from 'react-redux';
import { controlPanelOperations } from './state';
import { bindActionCreators } from 'redux';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withLoginCheck } from '../../components/LoginCheck';
import { withPaging } from '../../components/Pagination/Paging'
import { withDocumentDetailModal } from '../../components/ControlPanel/DocumentDetailModal';
import { withDocumentListModal } from '../../components/ControlPanel/DocumentListModal';
import { withOtherFeatureModal } from '../../components/ControlPanel/OtherFeatureModal';
import { withKintoneDetailsModal } from '../../components/ControlPanel/KintoneDetailsModal';
import { withSubTableDataModal } from '../../components/SubTableDataModal';
import { withSubListTableDataModal } from '../../components/SubListTableDataModal';

class ControlPanelScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            kintoneDetailsRows: [],
            documentDetails: [],
            all_contracts_expired: true,
            otherFields: []
        };
    }

    componentDidMount() {
        this.setState({ totalItemsCount: this.props.totalItemsCount });
        Promise.all([
            this.showLoader(),
            this.props.getServiceList().then(
                () => {
                    this.setState({
                        data: this.props.serviceList,
                        totalItemsCount: this.props.totalItemsCount
                    });
                }
            )
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
        .then(() => {
            this.setState({
                all_contracts_expired: this.state.data.all_contracts_expired ? this.state.data.all_contracts_expired : false
            })
            if (!("all_contracts_expired" in this.state.data)) {
                this.state.data.map((service, idx) => {
                    if (service.product.image) {
                        this.imageOnload(service.product.image, idx)
                    }
                    return false;
                })
            }
        })
    }

    imageOnload = (image, idx) => {
        var default_image = require(`../../../includes/img/default_image.PNG`);
        var request = new XMLHttpRequest();
        request.open('GET', image, true);
        request.responseType = 'blob';
        var scope = this;
        request.onload = function () {
            var reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
                var type = request.response.type.split("/")[0]
                if (type === "image") {
                    scope.setState(prevState => {
                        let data = prevState.data
                        data[idx].product.image = e.target.result;
                        return { data }
                    })
                }
                else {
                    this.setState(prevState => {
                        let data = prevState.data
                        data[idx].product.image = default_image;
                        return { data }
                    })
                }
            };
        };
        request.send();
    };

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="control_panel_template w-100">
                        <div className="page-heading-container">
                            <h4 className="mr-auto page-heading">{"サービス管理"}</h4>
                        </div>
                        <ControlPanelTemplate {...this.state}
                            isAdmin={this.props.isAdmin}
                            isSuperuser={this.props.isSuperuser}
                            handleShowOtherFeature={this.handleShowOtherFeature}
                            handleShowKintoneDetails={this.handleShowKintoneDetails}
                            handleShowDocumentListModal={this.handleShowDocumentListModal} />
                    </div>
                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.documentDetailModal({ ...this.state, ...this.props })}
                    {this.documentListModal({ ...this.state, ...this.props })}
                    {this.otherFeatureModal({ ...this.state, ...this.props })}
                    {this.kintoneDetailsModal({ ...this.state, ...this.props })}
                    {this.subTableDataModal({ ...this.state, ...this.props })}
                    {this.subListTableDataModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isSuperuser: state.auth.isSuperuser,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        serviceList: state.controlPanel.serviceList,
        kintoneDetails: state.controlPanel.kintoneDetails,
        totalItemsCount: state.controlPanel.totalItemsCount,
        documentList: state.controlPanel.documentList,
        totalDocumentsListItemsCount: state.controlPanel.totalDocumentsListItemsCount,
        documentDetails: state.controlPanel.documentDetails,
        otherFeature: state.controlPanel.otherFeature,
        subTableKintoneProductOtherFeatureData: state.controlPanel.subTableKintoneProductOtherFeatureData,
        subTableKintoneProductContractInforData: state.controlPanel.subTableKintoneProductContractInforData,
        subTableKintoneCommonContractInforData: state.controlPanel.subTableKintoneCommonContractInforData
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getServiceList: controlPanelOperations.getServiceList,
        getKintoneDetails: controlPanelOperations.getKintoneDetails,
        getDocumentList: controlPanelOperations.getDocumentList,
        getDocumentDetails: controlPanelOperations.getDocumentDetails,
        getDocumentFile: controlPanelOperations.getDocumentFile,
        getOtherFeature: controlPanelOperations.getOtherFeature,
        getKintoneProductOtherFeatureSubTableData: controlPanelOperations.getKintoneProductOtherFeatureSubTableData,
        getKintoneProductContractInforSubTableData: controlPanelOperations.getKintoneProductContractInforSubTableData,
        getKintoneCommonContractInforSubTableData: controlPanelOperations.getKintoneCommonContractInforSubTableData
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withErrorAlert(
                withDocumentDetailModal(
                    withDocumentListModal(
                        withSubTableDataModal(
                            withSubListTableDataModal(
                                withOtherFeatureModal(
                                    withKintoneDetailsModal(
                                        ControlPanelScreen
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);
