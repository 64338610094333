import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import * as Commons from '../Commons';

const withNoticeDetailModal = (WrappedComponent) => {
    return class NoticeDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showNoticeDetailsModal: false
            }
        }

        noticeDetailModal = (props) => {
            let template;
            let { noticeDetails } = props;
            if (noticeDetails) {
                var publishDate = Commons.getClientDatetimeDisplay(noticeDetails.publish_date);

                template = (
                    <Modal className="user-notice-details" keyboard={false} size="lg" show={this.state.showNoticeDetailsModal} onHide={this.handleCloseNoticeDetailsModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{noticeDetails.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="my-2">
                                <span className="custom-badge mr-2" style={{ backgroundColor: noticeDetails.category_bg_color }}>
                                    {noticeDetails.category_name}
                                </span>
                                <span className="custom-badge" style={{ backgroundColor: noticeDetails.product_bg_color }}>
                                    {noticeDetails.product_name}
                                </span>
                            </div>
                            <div className="pt-3">
                                <span>
                                    <strong>
                                        {`サービス名: ${noticeDetails.product_long_name}`}
                                    </strong>
                                </span>
                            </div>
                            <div className="pt-3">
                                {publishDate}
                            </div>
                            <div className="pt-3">
                                {Commons.getDetailsFormattedContent(noticeDetails.content)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="border-top-0">
                            <Button variant="secondary" className="btn-90" onClick={this.handleCloseNoticeDetailsModal}>
                                閉じる
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            return template;
        }

        handleShowNoticeDetailsModal = (value) => {
            Promise.all([
                this.showLoader(),
                this.props.getNoticeDetails(value).then(
                    () => {
                        this.setState({
                            showNoticeDetailsModal: true
                        });
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseNoticeDetailsModal = () => {
            this.setState({
                showNoticeDetailsModal: false
            })
        }
    }
}

export {
    withNoticeDetailModal
}