import React, { Component } from "react";
import { authOperations } from '../Login/state';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { withErrorAlert } from '../../components/ErrorAlert';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { withLoader } from "../../components/Loader";
import * as CONSTANTS from '../../../utils/constants';
import PasswordStrengthBar from 'react-password-strength-bar';


class AdminPasswordUpdateScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            successUpdate: false,
            hasAccess: false,
        };
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let access = params.get('access_token');
        
        if (access) {
            this.props.setUser({access: access, refresh: ''}, false);
            this.setState({hasAccess: true});
        }

    }

    validateForm() {
        var notEmpty = 0 < this.state.newPassword.length && 0 < this.state.confirmPassword.length;
        var hasMinLength = 4 <= this.state.newPassword.length && 4 <= this.state.confirmPassword.length;
        return notEmpty && hasMinLength;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.newPassword !== this.state.confirmPassword) {
            this.handleShowErrorAlert(CONSTANTS.UPDATE_ADMIN_PASSWORD_ERROR_PASSWORD_CONFIRM);
        }
        else if (!this.props.access) {
            return this.props.redirect('/admin/login');
        }
        else {
            let data = {'password': this.state.newPassword};

            Promise.all([
                this.showLoader(),
                this.props.adminPasswordUpdate(data).then(
                    () => {
                        this.setState({newPassword: '', confirmPassword: '', successUpdate: true})
                        this.props.setUser({access: '', refresh: ''}, false)
                        this.props.redirect(`/admin/login?success=${this.props.passwordUpdateMessage}`);
                    }
                )
            ]).catch((error) => {
                this.props.redirect(`/admin/login?error=${error}`)
            })
            .then(this.closeLoader)
        }
    }

    render() {
        let params = new URLSearchParams(this.props.location.search);
        let access = params.get('access_token');

        if (access && !this.state.successUpdate) {

            params.delete('access_token');
            window.history.replaceState(null, '', '?');
            return (
                <>
                    <div id="resetpass-card" className="col-lg-4 col-md-6 col-sm-8 col-12">
                        <h4>パスワードの再設定</h4>
                        <form onSubmit={this.handleSubmit}>
                            <h6 className="mt-2">新しいパスワード</h6>
                            <input type="password" id="newPassword"
                                value={this.state.newPassword}
                                onChange={this.handleChange} className="form-control w-100" />
                            <PasswordStrengthBar 
                                className={this.state.newPassword.length ? '':'d-none'} 
                                minLength={4}
                                shortScoreWord={'パスワードが短すぎ'} 
                                scoreWords={['パスワード強度:とても弱い', 'パスワード強度:弱い', 'パスワード強度:平均', 'パスワード強度:強い', 'パスワード強度:とても強い']} 
                                password={this.state.newPassword} />
                            <h6 className="mt-2">新しいパスワードを再入力</h6>
                            <input type="password" id="confirmPassword"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                onKeyPress={event => {
                                    if (event.key === "Enter" && document.getElementById('newPassword').value) {
                                        this.handleSubmit(event)
                                    }
                                }}
                                className="form-control w-100" />
                            <p className={this.state.newPassword.length && this.state.newPassword === this.state.confirmPassword ? "mt-1 mb-0 text-right" : "d-none"} style={{color: "rgb(137, 135, 146)"}}>パスワードが一致しました</p>
                            <br />
                            <div className="w-100 d-inline mt-2">
                                <button type="button" id="resetpass-button"
                                    className="btn btn-primary ml-auto float-right"
                                    disabled={!this.validateForm()}
                                    onClick={this.handleSubmit}>{ "パスワード設定" }</button>
                            </div>
                        </form>
                    </div>

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                </>
            );
        }
        else {
            return <Redirect to="/admin/login" />;
        }

    }
}

const mapStateToProps = (state) => {
    return {
        passwordUpdateMessage: state.auth.passwordUpdateMessage,
        access: (state.auth.credentials && state.auth.credentials.access) || false,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        adminPasswordUpdate: authOperations.adminPasswordUpdate,
        adminPasswordAuthUpdate: authOperations.adminPasswordAuthUpdate,
        adminPasswordReset: authOperations.adminPasswordReset,
        redirect: authOperations.redirect,
        setUser: authOperations.setUser,
        logoutUser: authOperations.logoutUser
    },
    dispatch
);

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withLoader(
            withErrorAlert(
                withSuccessAlert(
                    AdminPasswordUpdateScreen
                )
            )
        )
    )
);