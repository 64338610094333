import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const LogoutModal = (props) => {
    const {
        showModal,
        handleCloseLogoutModal
    } = props;

    return (
        <Modal className="modal-dialog-sm" keyboard={false} size="sm" show={showModal} onHide={handleCloseLogoutModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>ログアウト</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-sm">
                <div>
                    ログアウトします。
                </div>
                <div>
                    よろしいですか？
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <Button variant="primary" className="btn-90" onClick={() => {
                    if (props.isAdminPage || props.isSuperuser) {
                        props.logoutUser()
                    }
                    else {
                        props.getSLO();
                    }
                }}>
                    Ok
                </Button>
                <Button variant="secondary" className="btn-90" onClick={handleCloseLogoutModal}>
                    キャンセル
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LogoutModal