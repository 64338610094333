import ControlPanelScreen from "./ControlPanelScreen";

export const CONTROL_PANEL_ROUTE = '/controlpanel';

export const routes = [
    {
        label: "サービス管理",
        path: CONTROL_PANEL_ROUTE,
        component: ControlPanelScreen,
        exact: true,
        showNavPrivate: true //if true, displays the label in the mainHeader.js component
    },
]