import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminDocumentApplicationConfirmModal = (WrappedComponent) => {
    return class AdminDocumentApplicationConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentApplicationConfirmModal: false
            }
        }

        adminDocumentApplicationConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDocumentApplicationConfirmModal} onHide={this.handleCloseApplicationConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント公開申請確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                    <div>
                        「ドキュメント」公開を申請します。後ほど一覧画面にて状態確認をお願いします。
                    </div>
                    <div>
                        よろしいですか？
                    </div>
                </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitApplicationConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseApplicationConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowApplicationConfirmModal = () => {
            this.setState({
                showDocumentApplicationConfirmModal: true
            });
        }

        handleCloseApplicationConfirmModal = () => {
            this.setState({
                showDocumentApplicationConfirmModal: false
            });
        }

        handleSubmitApplicationConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let id = this.state.documentData.id;
            let type = this.state.documentData.type;
            let payload = this.state.documentData.payload
            payload.append('is_draft', 0);
            if (type === "draft") {
                Promise.all([
                    this.showLoader(),
                    this.props.putDocumentEditDetails(id, payload).then(
                        () => {
                            this.loadListFromServer();
                            this.handleCloseDraftModal();
                            this.handleShowSuccessAlert(this.props.documentMessages)
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseApplicationConfirmModal)
            } else if (type === "create") {
                Promise.all([
                    this.showLoader(),
                    this.props.postDocumentCreateDetails(payload).then(
                        () => {
                            this.loadListFromServer();
                            this.handleCloseModalNewDocument();
                            if (this.props.documentCreateDetails.message) {
                                this.handleShowSuccessAlert(this.props.documentCreateDetails.message);
                            } else {
                                this.handleShowSuccessAlert(this.props.documentCreateDetails);
                            }
                        }
                    )
                ]).catch(this.handleShowErrorAlert)
                .then(this.closeLoader)
                .then(this.handleCloseApplicationConfirmModal)
            }
        }
    }
}

export {
    withAdminDocumentApplicationConfirmModal
}