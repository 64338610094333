import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import AdminFormTemplate from '../AdminNoticeFormTemplate';
import * as Commons from '../Commons';

const withAdminNoticeDetailModal = (WrappedComponent) => {
    return class AdminNoticeDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalDetail: false,
            }
        }

        adminNoticeDetailModal = (props) => {
            let template = [];
            const { noticeDetails } = props
            if (noticeDetails) {
                var statusBg = Commons.getStatusBackground(noticeDetails.status);
                var customStyle = { color: statusBg, border: "solid 1px" };
                var hasHistoryDetails = props.noticeHistoryDetails.length > 0;

                var initialFormValue = {
                    NoticeDetailPostForm: {
                        post_id: noticeDetails.id,
                        edit_date: noticeDetails.updated_date ? Commons.getClientDatetimeDisplay(noticeDetails.updated_date) : "",
                        type: noticeDetails.category_name,
                        product: noticeDetails.product_name,
                        title: noticeDetails.title,
                        content: noticeDetails.content,
                        applicant: noticeDetails.applied_by ? noticeDetails.applied_by : "",
                        publish_date: noticeDetails.publish_preferred_date == null ? "0" : "1",
                        publish_preferred_date: Commons.getClientDatetimeDisplay(noticeDetails.publish_preferred_date),
                        publish_type: noticeDetails.publish_type,
                        remarks: noticeDetails.remarks ? noticeDetails.remarks : ""
                    }
                };

                template = (
                    <Modal className="modal-dialog-lg admin-notice-details" keyboard={false} size="lg" show={this.state.showModalDetail} onHide={this.handleCloseModalDetail} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>お知らせ管理 ＞ お知らせ詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4 pb-0">
                            <div className="d-inline-flex w-100">
                                {noticeDetails.post_application_id ?
                                    <div className="mb-2">
                                        <strong>申請番号: </strong>
                                        {noticeDetails.post_application_id}
                                    </div>
                                    : ""}
                                <div className="ml-auto mb-2">
                                    <label className="rounded text-center px-2 my-0" style={customStyle}>
                                        {noticeDetails.status}
                                    </label>
                                </div>
                            </div>
                            <AdminFormTemplate
                                formInputs={props.noticeDetailPostForm}
                                radioInput={props.radioInput}
                                initialValues={initialFormValue}
                                publishDate={noticeDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(noticeDetails.publish_preferred_date)}
                                form={"admin-notice-form" + noticeDetails.id}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="px-0 border-top-0">
                            <div className="w-100 admin-detail-modal-footer">
                                <div className={`w-100 d-inline-flex flex-row-reverse ${hasHistoryDetails ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                    <Button variant="secondary" className="btn btn-135 mr-4" onClick={this.handleCloseModalDetail}>
                                        お知らせ一覧に戻る
                                    </Button>
                                </div>
                                {
                                    hasHistoryDetails ?
                                        <>
                                            <h5 className="mx-4">修正履歴</h5>
                                            {
                                                props.noticeHistoryDetails.map((news, idx) => {
                                                    var initialFormHistoryValue = {
                                                        NoticeHistoryPostForm: {
                                                            post_id: news.id,
                                                            edit_date: news.updated_date ? Commons.getClientDatetimeDisplay(news.updated_date) : "",
                                                            type: news.category_name,
                                                            product: news.product_name,
                                                            title: news.title,
                                                            content: news.content,
                                                            applicant: news.applied_by ? news.applied_by : "",
                                                            history_publish_date: news.publish_preferred_date == null ? "0" : "1",
                                                            publish_preferred_date: Commons.getClientDatetimeDisplay(news.publish_preferred_date),
                                                            history_publish_type: news.publish_type,
                                                            remarks: news.remarks ? news.remarks : ""
                                                        }
                                                    };

                                                    return (
                                                        <div className="mx-4 bg-light" key={news.post_id + idx}>
                                                            <AdminFormTemplate
                                                                formInputs={props.noticeHistoryPostForm}
                                                                radioInput={props.radioInput}
                                                                initialValues={initialFormHistoryValue}
                                                                publishDate={news.publish_preferred_date === null ? "0" : "1"}
                                                                publishPreferredDate={Commons.getClientDatetimeDisplay(news.publish_preferred_date)}
                                                                form={"admin-history-form" + news.id}
                                                                handleFormOnchange={this.handleFormOnchange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }
            return template;
        }

        handleShowModalDetail = (postDetailId, postId) => {
            Promise.all([
                this.showLoader(),
                this.props.getNoticeDetails(postDetailId).then(
                    () => {
                        this.props.getNoticeHistoryDetails(postId).then(
                            () => {
                                this.setState({ showModalDetail: true })
                            }
                        )
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseModalDetail = () => {
            this.setState({
                showModalDetail: false,
            });
        }
    }
}

export {
    withAdminNoticeDetailModal
}