import React from 'react';
import * as Commons from '../Commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faUserLock, faWindowClose, faEdit, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AdminNoticeTemplate = (props) => {
    let {
        rows,
        handleShowModalDetail,
        handleShowDraftModal,
        handleShowStopPublishModal,
        handleShowCancelModal,
        handleShowModalPublishedVersionDetail,
        handleShowTemplateConfirmModal,
        handleShowModalTemplateFormDetail,
        handleShowTemplateEditModal,
    } = props;

    return (
        <section className="list mt-3 mb-3">
            {
                props.totalItemsCount > 0 ?
                    props.isNoticeTemplate ?
                        rows.map((news) => {
                            var createdDate = Commons.getClientDatetimeDisplay(news.created_date);
                            var editDate = Commons.getClientDatetimeDisplay(news.updated_date);
                            return (
                                <div className="px-3 py-3 list-group-item table-layout" key={news.id}>
                                    <div className="list-group">
                                        <div className="px-2 pb-0 table-layout-body">
                                            <div className="list-item-attribute">
                                                <span className="custom-badge ml-0 mb-1 mr-2 text-center" style={{ backgroundColor: news.product_bg_color }}>
                                                    {news.product_name}
                                                </span>
                                            </div>
                                            <div className="text-break">
                                                <a href="# " style={{ fontSize: '15px' }} className="font-weight-bold" value={news.id} onClick={(event) => { handleShowModalTemplateFormDetail(news.id, event) }}>
                                                    {news.name}
                                                </a>
                                            </div>
                                            <div className="list-item-attribute pt-1 row">
                                                <div className="col-sm-12 pr-0 text-break">
                                                    <strong>備考: </strong>
                                                    {news.note}
                                                </div>
                                            </div>
                                            <div className="list-item-attribute pt-1 row">
                                                <div className="col-md-3 col-sm-12 pr-0">
                                                    <strong>作成日: </strong>
                                                    {createdDate}
                                                </div>
                                                <div className="col-md-3 col-sm-12 p-0">
                                                    <strong className="pl-3">更新日: </strong>
                                                    {editDate}
                                                </div>
                                            </div>
                                            <div className="list-item-attribute row">
                                                <div className="col-md-3 col-sm-12 pr-0">
                                                    <strong>作成者: </strong>
                                                    {news.created_by}
                                                </div>
                                                <div className="col-md-3 col-sm-12 p-0">
                                                    <strong className="pl-3">更新者: </strong>
                                                    {news.updated_by}
                                                </div>
                                            </div>
                                            <ul className="list-item-actions nav justify-content-end">
                                                <li className="nav-item">
                                                    <a className={"nav-link py-0 px-3 btn-action"} href="# " onClick={() => { handleShowTemplateEditModal(news.id) }}>
                                                        <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1">修正</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link py-0 pl-3 pr-0 btn-action`} href="# " onClick={(event) => { handleShowTemplateConfirmModal(news.id) }} >
                                                        <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">削除</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        rows.map((news) => {
                            var createdDate = Commons.getClientDatetimeDisplay(news.created_date);
                            var editDate = Commons.getClientDatetimeDisplay(news.edit_date);
                            var statusBg = Commons.getStatusBackground(news.info.status_main);
                            var customStyle = { color: statusBg, border: "solid 1px" };
                            var customButtonStyle = { marginRight: '8px' };
                            if (news.info.access_edit && news.info.access_unpublish) {
                                customButtonStyle.marginLeft = '8px';
                            }
                            return (
                                <div className="px-3 py-3 list-group-item table-layout" key={news.post_id}>
                                    <div className="list-group">
                                        <div className="px-0 pb-0 table-layout-body">
                                            <div className="list-item-attribute">
                                                <span className="custom-badge mb-1 text-center" style={{ backgroundColor: news.category_bg_color }}>
                                                    {news.category_name}
                                                </span>
                                                <span className="custom-badge ml-1 mb-1 mr-2 text-center" style={{ backgroundColor: news.product_bg_color }}>
                                                    {news.product_name}
                                                </span>
                                                {news.info.status_main ?
                                                    <span className="rounded text-center px-2 mb-1 d-inline-block" style={customStyle}>
                                                        {news.info.status_main}
                                                    </span>
                                                    : null}
                                                <ul className="list-item-actions nav float-right">
                                                    {
                                                        news.info.published ?
                                                            news.info.login_only ?
                                                                <a href="# " className={"nav-link py-0 pr-0 pl-3 btn-action"} onClick={() => { handleShowModalPublishedVersionDetail(news.published_version) }}>
                                                                    <FontAwesomeIcon icon={faUserLock} size="lg" /><span className="pl-1">限定公開中</span>
                                                                </a>
                                                                :
                                                                <a href="# " className={"nav-link py-0 px-0 btn-action"} onClick={() => { handleShowModalPublishedVersionDetail(news.published_version) }}>
                                                                    <FontAwesomeIcon icon={faGlobeAmericas} size="lg" /><span className="pl-1">公開中</span>
                                                                </a>
                                                            :
                                                            <a href="# " className={"nav-link py-0 px-0 btn-action disabled"}>
                                                                <FontAwesomeIcon icon={faEyeSlash} size="lg" /><span className="pl-1">非公開</span>
                                                            </a>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="text-break">
                                                <a href="# " style={{ fontSize: '15px' }} className="font-weight-bold" value={news.post_id} onClick={() => { handleShowModalDetail(news.post_detail_id, news.post_id) }}>
                                                    {news.title}
                                                </a>
                                            </div>
                                            <div className="list-item-attribute pt-1 row">
                                                <div className="col-md-3 col-sm-12 pr-0">
                                                    <strong>作成日: </strong>
                                                    {createdDate}
                                                </div>
                                                <div className="col-md-3 col-sm-12 p-0">
                                                    <strong className="pl-3">更新日: </strong>
                                                    {editDate}
                                                </div>
                                            </div>
                                            <div className="list-item-attribute row">
                                                <div className="col-md-3 col-sm-12 pr-0">
                                                    <strong>作成者: </strong>
                                                    {news.created_by}
                                                </div>
                                                <div className="col-md-3 col-sm-12 p-0">
                                                    <strong className="pl-3">更新者: </strong>
                                                    {news.edited_by}
                                                </div>
                                            </div>
                                            <ul className="list-item-actions nav justify-content-end">
                                                {news.info.access_edit ?
                                                    <li className="nav-item">
                                                        <a className={"nav-link py-0 px-3 btn-action"} href="# " onClick={(event) => { handleShowDraftModal(news.post_id, event) }}>
                                                            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faEdit} /><span className="pl-1">修正</span>
                                                        </a>
                                                    </li> : ""}
                                                {news.info.access_unpublish ?
                                                    <li className="nav-item">
                                                        <a className={`nav-link py-0 pl-3 pr-0 btn-action`} href="# " onClick={(event) => { handleShowStopPublishModal(news.post_id, event) }} >
                                                            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">公開中止</span>
                                                        </a>
                                                    </li> : ""}
                                                {news.info.access_request_cancel ?
                                                    <li className="nav-item">
                                                        <a className={`nav-link py-0 pl-3 pr-0 btn-action`} href="# " onClick={(event) => { handleShowCancelModal(news.post_id, event) }} >
                                                            <FontAwesomeIcon style={{ fontSize: '14px' }} icon={faWindowClose} /><span className="pl-1">申請取り消し</span>
                                                        </a>
                                                    </li> : ""}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :
                    <h4>検索の結果、0件でした。</h4>
            }

        </section>
    );
}

export default AdminNoticeTemplate;