import apiService from '../../../../utils/apiService';
import * as Path from './apiRoutes';
import * as Actions from "./actions";
import fileDownload from 'js-file-download';

export const getServiceList = () => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.CONTROL_PANEL)
                .then((response) => {
                    dispatch(Actions.readState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getKintoneDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.KINTONE_DETAILS + id + '/')
                .then((response) => {
                    dispatch(Actions.getKintoneDetailsState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getOtherFeature = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.OTHER_FEATURE + id + '/')
                .then((response) => {
                    dispatch(Actions.getOtherFeatureState(response.data));
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentList = (itemsCountPerPage, activePage, query, product_number) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENTS, {
                responseType: 'json',
                params: {
                    num: itemsCountPerPage,
                    page: activePage,
                    search: query,
                    product: product_number
                }
            }).then(response => {
                dispatch(Actions.getDocumentListState(response.data));
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentDetails = (id) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            return apiService.get(Path.DOCUMENTS + id + '/')
                .then(response => {
                    dispatch(Actions.documentDetailsState(response.data))
                })
        })
        .catch(apiService.handleDisplayError);
}

export const getDocumentFile = (data) => (dispatch) => {
    return apiService.refreshToken()
        .then(() => {
            let id = data.id;
            return apiService.get(Path.DOCUMENT_DOWNLOAD + id + '/', {
                responseType: 'blob'
            }).then((response) => {
                fileDownload(response.data, data.file_name, response.headers['content-type']);
                // if (response.status === 200) {
                //     dispatch(Actions.getDocumentFileState(["File downloaded successfully"]));
                // }
                // else {
                //     return "File Download failed"
                // }
            })
        })
        .catch(apiService.handleDisplayError);
}

export const getKintoneProductOtherFeatureSubTableData = (contractId, kintoneId, isShowIn, itemsCountPerPage, activePage) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.KINTONE_PRODUCT_SUB_TABLE_DATA + contractId + '/', {
            params: {
                kintoneId: kintoneId,
                isShowIn: isShowIn,
                num: itemsCountPerPage,
                page: activePage
            }
        })
        .then((response) => {
            dispatch(Actions.getKintoneProductOtherFeatureSubTableData(response.data))
        })
    })
    .catch(apiService.handleDisplayError);
}

export const getKintoneProductContractInforSubTableData = (contractId, kintoneId, isShowIn, itemsCountPerPage, activePage) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.KINTONE_PRODUCT_SUB_TABLE_DATA + contractId + '/', {
            params: {
                kintoneId: kintoneId,
                isShowIn: isShowIn,
                num: itemsCountPerPage,
                page: activePage
            }
        })
        .then((response) => {
            dispatch(Actions.getKintoneProductContractInforSubTableData(response.data))
        })
    })
    .catch(apiService.handleDisplayError);
}

export const getKintoneCommonContractInforSubTableData = (contractId, kintoneId, isShowIn, itemsCountPerPage, activePage) => (dispatch) => {
    return apiService.refreshToken()
    .then(() => {
        return apiService.get(Path.KINTONE_COMMON_SUB_TABLE_DATA + contractId + '/', {
            params: {
                kintoneId: kintoneId,
                isShowIn: isShowIn,
                num: itemsCountPerPage,
                page: activePage
            }
        })
        .then((response) => {
            dispatch(Actions.getKintoneCommonContractInforSubTableData(response.data))
        })
    })
    .catch(apiService.handleDisplayError);
}
