import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const withAdminDocumentDraftConfirmModal = (WrappedComponent) => {
    return class AdminDocumentDraftConfirmModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showDocumentDraftConfirmModal: false
            }
        }

        adminDocumentDraftConfirmModal = (props) => {
            return (
                <Modal className="modal-dialog-md" keyboard={false} size="md" show={this.state.showDocumentDraftConfirmModal} onHide={this.handleCloseDraftConfirmModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>ドキュメント管理 ＞ ドキュメント下書保存確認</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body-md">
                        <div>
                            「ドキュメント」を下書・保存します。後ほど一覧画面にて状態確認をお願いします。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <Button variant="primary" className="btn-90" onClick={this.handleSubmitDraftConfirmModal}>
                            Ok
                        </Button>
                        <Button variant="secondary" className="btn-90" onClick={this.handleCloseDraftConfirmModal}>
                            キャンセル
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowDraftConfirmModal = () => {
            this.setState({
                showDocumentDraftConfirmModal: true
            });
        }

        handleCloseDraftConfirmModal = () => {
            this.setState({
                showDocumentDraftConfirmModal: false
            });
        }

        handleSubmitDraftConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let id = this.state.documentData.id;
            let type = this.state.documentData.type;
            let payload = this.state.documentData.payload

            payload.append('is_draft', 1);
            if (type === "draft") {
                this.showLoader();
                this.props.putDocumentEditDetails(id, payload)
                    .then((result) => {
                        if (result) {
                            this.handleCloseDraftConfirmModal();
                            this.handleShowErrorAlert(result);
                        }
                        else {
                            this.loadListFromServer();
                            this.handleCloseDraftConfirmModal();
                            this.handleCloseDraftModal();
                            this.handleShowSuccessAlert(this.props.documentMessages)
                        }
                    })
                    .then(this.closeLoader);
            } else if (type === "create") {
                this.showLoader();
                this.props.postDocumentCreateDetails(payload)
                    .then((result) => {
                        if (result) {
                            this.handleCloseDraftConfirmModal();
                            this.handleShowErrorAlert(result);
                        }
                        else {
                            this.loadListFromServer();
                            this.handleCloseDraftConfirmModal();
                            this.handleCloseModalNewDocument();
                            this.handleShowSuccessAlert(this.props.documentCreateDetails)
                        }
                    })
                    .then(this.closeLoader);
            }
        }
    }
}

export {
    withAdminDocumentDraftConfirmModal
}