import React from 'react';
import { Modal } from 'react-bootstrap';
import AdminFormTemplate from '../AdminPortalFormTemplate'

const withCommonKintoneEditLabelModal = (WrappedComponent) => {
    return class CommonKintoneEditLabelModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showCommonKintoneEditLabel: false
            }
        }

        commonKintoneEditLabelModal = (props) => {
            var areAllDefault = props.currentKintoneFormValues && props.initialKintoneFormValue ? props.currentKintoneFormValues.display_label === props.initialKintoneFormValue.KintoneProductForm.display_label &&
                props.currentKintoneFormValues.show_customer === props.initialKintoneFormValue.KintoneProductForm.show_customer &&
                props.currentKintoneFormValues.format_data === props.initialKintoneFormValue.KintoneProductForm.format_data ? true : false : true

            return (
                <Modal keyboard={false} size="lg" show={this.state.showCommonKintoneEditLabel} onHide={areAllDefault ? this.handleCloseCommonKintoneEditLabelModal : this.handleShowDiscardModal} className="modal-new-kintone" centered>
                    <Modal.Header closeButton>
                        {/* Common attribute settings> Edit */}
                        <Modal.Title>{"共通属性設定 > 編集"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <AdminFormTemplate
                            formInputs={props.kintoneFormInputs}
                            radioInput={props.radioInput}
                            initialValues={props.initialKintoneFormValue}
                            validate={props.adminProductManagementSelectors.kintoneCheckErrors}
                            form={"admin-edit-kintone"}
                            handleShowCommonKintoneConfirmEditLabelModal={this.handleShowCommonKintoneConfirmEditLabelModal}
                            handleSubmitModalCommonKintoneEdit={this.handleSubmitModalCommonKintoneEdit}
                            editKintoneCommonMode={true}
                            handleFormOnchange={this.handleFormOnchange}
                        />
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <input type="hidden" name="submit_action" />
                            <button type="button" form="admin-edit-kintone" className="btn btn-90 mr-2 ml-auto btn-primary" onClick={() => {
                                document.querySelector('.modal.show .portalSubmitButton').click()
                                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowCommonKintoneConfirmEditLabelModal"
                            }}>保存</button>
                            <button className="btn btn-90 btn-secondary" onClick={areAllDefault ? this.handleCloseCommonKintoneEditLabelModal : this.handleShowDiscardModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowCommonKintoneEditLabelModal = (id, field_name, field_label, is_table, show_customer) => {
            this.setState({
                initialKintoneFormValue: {
                    KintoneProductForm: {
                        id: id,
                        kintone_attribute_name: field_name,
                        display_label: field_label,
                        format_data: is_table ? "1" : "0",
                        show_customer: show_customer
                    }
                },
                showCommonKintoneEditLabel: true,
                commonKintoneFieldId: id
            });
        }

        handleCloseCommonKintoneEditLabelModal = () => {
            this.setState({
                showCommonKintoneEditLabel: false
            });
        }

        handleShowCommonKintoneConfirmEditLabelModal = () => {
            this.setState({
                showCommonKintoneConfirmEditLabelModal: true
            });
        }

        handleSubmitModalCommonKintoneEdit = (values) => {
            let formattedValue = {
                field_label: values.display_label,
                is_table: values.format_data,
                show_customer: values.show_customer
            }
            if (this.state.submitCommonSubKintone) {
                formattedValue.parent_id = this.state.parentId;
            }
            this.setState({
                kintoneData: {
                    payload: formattedValue
                }
            });
        }
    }
}

export {
    withCommonKintoneEditLabelModal
}