import React from 'react';
import { Modal } from 'react-bootstrap';
import { MDBTable, MDBTableBody } from 'mdbreact';

const withAdminDetailsModal = (WrappedComponent) => {
    return class AdminDetailsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showAdminDetailsModal: false
            }
        }

        adminDetailsModal = (props) => {
            let { adminDetails } = this.state;
            return (
                <Modal size="md" className="admin-details modal-dialog-md" keyboard={false} show={this.state.showAdminDetailsModal} onHide={this.handleCloseAdminDetailsModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{"管理者情報"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="mt-2">
                            <nav className="navbar navbar-expand navbar-light bg-menu mb-3">
                                <button className="btn btn-secondary" value="パスワード変更" onClick={() => { this.handleShowAdminResetPasswordRequestConfirmModal(adminDetails.id) }}>パスワード変更</button>
                            </nav>
                            {adminDetails ?
                                <>
                                    <h6 className="font-weight-bold">基本情報</h6>
                                    <MDBTable bordered className="mb-3 w-100">
                                        <MDBTableBody>
                                            <tr>
                                                <td className="disable-table font-weight-bold">ユーザ名</td>
                                                <td>{adminDetails.username}</td>
                                            </tr>
                                            <tr>
                                                <td className="disable-table font-weight-bold">氏名</td>
                                                <td>{adminDetails.full_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="disable-table font-weight-bold">所属</td>
                                                <td>{adminDetails.department}</td>
                                            </tr>
                                            <tr>
                                                <td className="disable-table font-weight-bold">メールアドレス</td>
                                                <td>{adminDetails.email}</td>
                                            </tr>
                                        </MDBTableBody>
                                    </MDBTable>

                                    <h6 className="font-weight-bold">グループ・権限</h6>
                                    <MDBTable bordered className="w-100">
                                        <MDBTableBody>
                                            <tr>
                                                <td className="disable-table font-weight-bold">投稿ADグループ</td>
                                                <td>
                                                    {
                                                        adminDetails.poster_ad_group.map((ad, idx) => {
                                                            return (<span key={idx}>{ad}<br /></span>)
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="disable-table font-weight-bold">承認ADグループ</td>
                                                <td>
                                                    {
                                                        adminDetails.approver_ad_group.map((ad, idx) => {
                                                            return (<span key={idx}>{ad}<br /></span>)
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="disable-table font-weight-bold">ポータル管理者</td>
                                                <td>{adminDetails.admin_ad_group.length !== 0 ? "〇" : ""}</td>
                                            </tr>
                                        </MDBTableBody>
                                    </MDBTable>
                                </>
                                : ""}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <button className="btn btn-90 btn-secondary" onClick={this.handleCloseAdminDetailsModal}>
                            閉じる
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowAdminDetailsModal = (id, event) => {
            event.target.blur();
            Promise.all([
                this.showLoader(),
                this.props.getAdminDetails(id).then(
                    () => {
                        this.setState({
                            showAdminDetailsModal: true,
                            adminDetails: this.props.adminDetails
                        })
                    }
                ),
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseAdminDetailsModal = () => {
            this.setState({
                showAdminDetailsModal: false
            });
        }

        handleShowAdminResetPasswordRequestConfirmModal = (id) => {
            this.setState({
                AdminResetPasswordRequestUser: id,
                showAdminResetPasswordRequestConfirmModal: true
            })
        }
    }
}

export {
    withAdminDetailsModal
}