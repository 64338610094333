import React, { Component } from 'react';
import AdminNoticeTemplate from "../../components/AdminNotice/AdminNoticeTemplate";
import AdminNoticeButtons from "../../components/Pagination/AdminNoticeButtons";
import { withPaging } from '../../components/Pagination/Paging'
import { withErrorAlert } from '../../components/ErrorAlert';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { connect } from 'react-redux';
import { adminNoticeSelectors } from './state';
import { adminNoticeOperations } from './state';
import { bindActionCreators } from 'redux';
import { withNewNoticePost } from '../../components/AdminNotice/AdminNewPostTemplate';
import { withAdminNoticeApplicationConfirmModal } from '../../components/AdminNotice/AdminNoticeApplicationConfirmModal';
import { withAdminNoticeCancelModal } from '../../components/AdminNotice/AdminNoticeCancelModal';
import { withAdminNoticeDeleteModal } from '../../components/AdminNotice/AdminNoticeDeleteModal';
import { withAdminNoticeDraftConfirmModal } from '../../components/AdminNotice/AdminNoticeDraftConfirmModal';
import { withAdminNoticeDraftModal } from '../../components/AdminNotice/AdminNoticeDraftModal';
import { withAdminNoticeStopPublishModal } from '../../components/AdminNotice/AdminNoticeStopPublishModal';
import { withAdminNoticeDetailModal } from '../../components/AdminNotice/AdminNoticeDetailModal';
import { withAdminNoticePublishedVersionDetailModal } from '../../components/AdminNotice/AdminNoticePublishedVersionDetailModal';
import { withAdminChooseTemplateModal } from '../../components/AdminNotice/AdminChooseTemplateModal';
import { withAdminNoticeTemplateDetailModal } from '../../components/AdminNotice/AdminNoticeTemplateDetailModal';
import { withAdminTemplateDetailFromNoticeModal } from '../../components/AdminNotice/AdminTemplateDetailModalFromTemplateChooseModal';


class AdminNoticeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            noticeUnpublishId: 0,
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount,
            defaultTemplateOrdering: this.props.defaultTemplateOrdering,
            listTemplateOrdering: this.props.listTemplateOrdering,
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getNoticeList(
                this.state.itemsCountPerPage, this.state.activePage, this.state.query,
                this.state.category_number, this.state.product_number, this.state.sort
            ).then(() => {
                this.setState({
                    rows: this.props.tableList,
                    totalItemsCount: this.props.totalItemsCount
                });
            }),
            this.props.getNoticeProductList().then(() => {
                this.setState({
                    productLookupList: this.props.productLookupList
                });
            }),
            this.props.getNoticeCategoryList().then(() => {
                this.setState({
                    categoryLookupList: this.props.categoryLookupList
                });
            }),
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    handleFormOnchange = (values) => {
        this.setState({ currentFormValues: values.formValues.PostForm })
    }

    handleCloseAllModal = () => {
        this.setState({
            showModalNewPost: false,
            showDiscardModal: false,
            showNoticeDraft: false
        });
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="admin_notice_template">
                        <h4 className="mr-auto page-heading">{"お知らせ管理 > お知らせ一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            <AdminNoticeButtons handleShowModalNewPost={this.handleShowModalNewPost} />
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <AdminNoticeTemplate {...this.state}
                            handleShowModalDetail={this.handleShowModalDetail}
                            handleShowModalPublishedVersionDetail={this.handleShowModalPublishedVersionDetail}
                            handleShowDraftModal={this.handleShowDraftModal}
                            handleShowStopPublishModal={this.handleShowStopPublishModal}
                            handleShowCancelModal={this.handleShowCancelModal}
                            handleShowChooseTemplateModal={this.handleShowChooseTemplateModal}
                            handleShowModalTemplateFormDetail={this.handleShowModalTemplateFormDetail}
                            handShowDetailFromNoticeModal={this.handShowDetailFromNoticeModal}
                        />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.newNoticePostModal({ ...this.state, ...this.props, adminNoticeSelectors })}
                    {this.noticeApplicationConfirmModal({ ...this.state })}
                    {this.adminNoticeCancelModal({ ...this.state })}
                    {this.adminNoticeDeleteModal({ ...this.state })}
                    {this.adminNoticeDraftConfirmModal({ ...this.state })}
                    {this.adminNoticeDraftModal({ ...this.state, ...this.props, adminNoticeSelectors })}
                    {this.adminNoticeStopPublishModal({ ...this.state })}
                    {this.adminNoticeDetailModal({ ...this.state, ...this.props })}
                    {this.adminNoticePublishedVersionDetailModal({ ...this.state, ...this.props })}
                    {this.adminChooseTemplateModal({ ...this.state, ...this.props })}
                    {this.adminNoticeTemplateDetailModal({ ...this.state, ...this.props })}
                    {this.adminTemplateDetailFromNoticeModal({ ...this.state })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        tableList: state.adminNotice.tableList,
        totalItemsCount: state.adminNotice.totalItemsCount,
        productLookupList: state.adminNotice.productLookupList,
        noticeDetails: state.adminNotice.noticeDetails,
        noticePublishedVersionDetails: state.adminNotice.noticePublishedVersionDetails,
        formInputs: state.adminNotice.postForm,
        selectInput: state.adminNotice.categoryLookupList.concat(state.adminNotice.productLookupList),
        radioInput: state.adminNotice.radioOptions,
        noticeEditDetails: state.adminNotice.noticeEditDetails,
        noticeMessages: state.adminNotice.noticeMessages,
        noticeHistoryDetails: state.adminNotice.noticeHistoryDetails,
        noticeHistoryPostForm: state.adminNotice.noticeHistoryPostForm,
        noticeDetailPostForm: state.adminNotice.noticeDetailPostForm,
        noticeCreateDetails: state.adminNotice.noticeCreateDetails,
        listOrdering: state.adminNotice.noticeOrdering,
        listTemplateOrdering: state.adminNotice.noticeChooseTemplateOrdering,
        defaultOrdering: state.adminNotice.defaultNoticeOrdering,
        noticeTemplateLookup: state.adminNotice.noticeTemplateLookup,
        totalItemsTemplateCount: state.adminNotice.totalItemsTemplateCount,
        noticeTemplateDetail: state.adminNotice.noticeTemplateDetail,
        defaultTemplateOrdering: state.adminNotice.defaultTemplateOrdering,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getNoticeList: adminNoticeOperations.getNoticeList,
        getNoticeProductList: adminNoticeOperations.getNoticeProductList,
        getNoticeCategoryList: adminNoticeOperations.getNoticeCategoryList,
        getNoticeDetails: adminNoticeOperations.getNoticeDetails,
        getNoticePublishedVersionDetails: adminNoticeOperations.getNoticePublishedVersionDetails,
        getNoticeEditDetails: adminNoticeOperations.getNoticeEditDetails,
        getNoticeHistoryDetails: adminNoticeOperations.getNoticeHistoryDetails,
        putNoticeEditDetails: adminNoticeOperations.putNoticeEditDetails,
        patchNoticeUnpublish: adminNoticeOperations.patchNoticeUnpublish,
        patchNoticeCancel: adminNoticeOperations.patchNoticeCancel,
        postNoticeCreateDetails: adminNoticeOperations.postNoticeCreateDetails,
        patchNoticeDelete: adminNoticeOperations.patchNoticeDelete,
        getNoticeTemplateLookup: adminNoticeOperations.getNoticeTemplateLookup,
        getNoticeTemplateDetail: adminNoticeOperations.getNoticeTemplateDetail,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withAdminNoticeTemplateDetailModal(
                            withAdminTemplateDetailFromNoticeModal(
                                withAdminChooseTemplateModal(
                                    withNewNoticePost(
                                        withAdminNoticeApplicationConfirmModal(
                                            withAdminNoticeCancelModal(
                                                withAdminNoticeDeleteModal(
                                                    withAdminNoticeDraftConfirmModal(
                                                        withAdminNoticeDraftModal(
                                                            withAdminNoticeStopPublishModal(
                                                                withAdminNoticeDetailModal(
                                                                    withAdminNoticePublishedVersionDetailModal(AdminNoticeScreen)
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);