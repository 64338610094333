import Error404 from "../Errors/404";
import CustomerInformationScreen from "./CustomerInformationScreen";
import ListOfAdminScreen from "./ListOfAdminScreen";

export const ACCOUNT_MANAGEMENT_ROUTE = '/admin/accountmanagement';
export const CUSTOMER_INFO_ROUTE = '/admin/portalusers';
export const LIST_OF_ADMIN_ROUTE = '/admin/portaladmin';

export const routes = [
    {
        label: "アカウント管理",
        path: ACCOUNT_MANAGEMENT_ROUTE,
        component: Error404,
        exact: true,
        isAdminPage: true,
        showAdminNav: true,
        dropdown: true,
        isAdminAccountManagement: true,
    },
    {
        label: "顧客情報一覧",
        path: CUSTOMER_INFO_ROUTE,
        component: CustomerInformationScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    },
    {
        label: "管理者一覧",
        path: LIST_OF_ADMIN_ROUTE,
        component: ListOfAdminScreen,
        exact: true,
        isAdminPage: true,
        showAdminNav: false
    }
]