import React from 'react';
import * as Commons from '../Commons'
import { MDBTable, MDBTableBody } from 'mdbreact';

const NoticeTemplate = (props) => {

    let {
        rows,
        handleShowNoticeDetailsModal
    } = props

    return (
        <div className="mt-2">
            {props.totalItemsCount > 0 ?
                <MDBTable borderless>
                    <MDBTableBody>
                        {rows.map(news => {
                            var publishDate = Commons.getClientDatetimeDisplay(news.publish_date);
                            return (
                                <tr className="border" key={news.id}>
                                    <td className="py-3 pr-3 pl-3 align-middle text-right">{publishDate}</td>
                                    <td className="py-3 pr-2 align-middle text-break">
                                        <div>
                                            <span className="custom-badge mr-1 mb-1 text-center" style={{ backgroundColor: news.category_bg_color }}>
                                                {news.category_name}
                                            </span>
                                            <span className="custom-badge text-center" style={{ backgroundColor: news.product_bg_color }}>
                                                {news.product_name}
                                            </span>
                                        </div>
                                        <a href="# " style={{ fontSize: '15px' }} className="font-weight-bold" value={news.id} onClick={() => { handleShowNoticeDetailsModal(news.id) }}>{news.title}</a>
                                    </td>
                                </tr>
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
                : <h4>検索の結果、0件でした。</h4>}
        </div>
    );
}

export default NoticeTemplate;