import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faEdit, faTable } from '@fortawesome/free-solid-svg-icons'
import TooltipTemplate from '../TooltipTemplate';

const withProductDynamicSubTableFieldsModal = (WrappedComponent) => {
    return class ProductDynamicSubTableFieldsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showProductDynamicSubTableFieldsModal: false,
                productDynamicSubTableFields: []
            };
            this.parentObject = {};
            this.parentObjectList = [];
        }

        productDynamicSubTableFieldsModal = (props) => {
            let { productDynamicSubTableFields } = this.state;

            let okButtonAction = this.handleCloseProductSubTableDynamicFieldsModal;

            let cancelButtonAction = this.handleCloseProductSubTableDynamicFieldsModal;

            let parentFieldLabelList = this.parentObjectList.map(parentObject => parentObject.parentFieldLabel);
            let fieldLabel = parentFieldLabelList.join(' ＞ ');

            return (
                <Modal show={this.state.showProductDynamicSubTableFieldsModal} keyboard={false} size="lg" onHide={cancelButtonAction} className="modal-add-product-dynamic-fields" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>製品管理 ＞製品情報　Kintone属性設定 ＞ {fieldLabel}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="w-100">
                            <div className="font-weight-bold d-flex">
                                <label className="mb-0 mr-1">Kintone属性設定</label>
                                <div>
                                    <TooltipTemplate message="Kintoneからサービスポータルに取り込むフィールドコードを「項目」に、サービスポータル上での表示タイトル名を「表示ラベル」に設定してください。" />
                                </div>
                            </div>
                        </div>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-2">
                            <div className="form-inline mr-auto">
                                <div className="btn-group navbar-left">
                                    <button className="btn btn-secondary" value="共通属性設定" onClick={this.handleShowModalNewSubKintone}>属性設定登録</button>
                                    <button className="btn btn-secondary" value="Sorting" onClick={this.handleShowProductKintoneSortingSubModal}>表示順変更</button>
                                </div>
                            </div>
                        </nav>

                        <Form id={'form-add-product-sub-table-dynamic-fields'}>
                            <table className="table table-bordered table-striped table-form">
                                <tbody>
                                    <tr className="d-flex w-100">
                                        <th className="custom-border sub-table-column-3 disable-table font-weight-bold align-middle d-inline-flex justify-content-center">項⽬</th>
                                        <th className="custom-border sub-table-column-4 disable-table font-weight-bold align-middle d-inline-flex justify-content-center">表⽰ラベル</th>
                                        <th className="custom-border sub-table-column-6 disable-table font-weight-bold text-center dynamic-show-customer-header dynamic-header">契約情報に<br />表示</th>
                                        <th className="custom-border sub-table-column-6 disable-table font-weight-bold text-center dynamic-show-customer-header dynamic-header">その他の機能に<br />表示</th>
                                        <th className="custom-border sub-table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                        <th className="custom-border sub-table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                        <th className="custom-border sub-table-column-5 disable-table font-weight-bold d-inline-flex"></th>
                                    </tr>
                                    {productDynamicSubTableFields.length === 0 ? <tr><td className="font-weight-normal d-inline-block w-100"><center>設定情報はありません。</center></td></tr> : null}
                                    {productDynamicSubTableFields.map((field) => {
                                        return (
                                            <tr key={field.id} className={"d-flex"}>
                                                <td className="align-middle sub-table-column-3 table-column-3-column custom-border px-2 py-0"><div className="dynamic-content"><label className="mb-0">{field.field_name}</label></div></td>
                                                <td className="align-middle sub-table-column-4 table-column-4-column custom-border px-2 py-0"><div className="dynamic-content"><label className="mb-0">{field.field_label}</label></div></td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-6 custom-border">
                                                    {field.show_customer === 1 ? "〇" : ""}
                                                </td>
                                                <td className="d-inline-flex justify-content-center sub-table-column-6 custom-border">
                                                    {field.show_customer === 2 ? "〇" : ""}
                                                </td>
                                                <td className="sub-table-column-5 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faEdit} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowProductKintoneEditLabelSubTableModal(field.id, field.field_name, field.field_label, field.is_table, field.show_customer) }} />
                                                    </div>
                                                </td>
                                                <td className="sub-table-column-5 custom-border p-0 dynamic-icon-container">
                                                    {field.is_table &&
                                                        <div>
                                                            <FontAwesomeIcon icon={faTable} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowKintoneProductSubTableFieldsModal(field.product_id, field.id, field.field_label, field.show_customer) }} />
                                                        </div>
                                                    }
                                                </td>
                                                <td className="sub-table-column-5 custom-border p-0 dynamic-icon-container">
                                                    <div>
                                                        <FontAwesomeIcon icon={faWindowClose} className="fa-window-close pointer align-self-center" onClick={() => { this.handleShowDeleteKintoneProductSubTableConfirmation(field.id) }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                            <div className="form-group">
                                <div className="float-right">
                                    <Button variant="primary" className="btn btn-90 mr-2" onClick={okButtonAction}>Ok</Button>
                                    <Button variant="secondary" className="btn btn-90" onClick={cancelButtonAction}>キャンセル</Button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            );
        }

        handleSaveParentKintoneProductData = (productId, parentId, parentFieldLabel, parentShowCustomer) => {
            let object = {
                productId: productId,
                parentId: parentId,
                parentFieldLabel: parentFieldLabel,
                parentShowCustomer: parentShowCustomer
            }
            const isParentIdExsited = this.parentObjectList.some(object => object.parentId === parentId);
            if (!isParentIdExsited) {
                this.parentObjectList.push(object);
            }
        }

        handleDeleteParentKintoneProductData = () => {
            this.parentObjectList.pop();
            if (this.parentObjectList.length > 0) {
                this.parentObject = this.parentObjectList[this.parentObjectList.length - 1];
            }
        };

        handleShowKintoneProductSubTableFieldsModal = (product_id, kintone_id, field_label, show_customer) => {
            Promise.all([
                this.showLoader(),
                this.props.getKintoneProductSubTableFieldList(product_id, kintone_id).then(
                    () => {
                        this.setState({
                            productId: product_id,
                            parentId: kintone_id,
                            parentFieldLabel: field_label,
                            parentShowCustomer: show_customer,
                            productDynamicSubTableFields: this.props.kintoneProductSubTableFieldList,
                            showProductDynamicSubTableFieldsModal: true,
                            submitSubKintone: true
                        }, () => this.handleSaveParentKintoneProductData(product_id, kintone_id, field_label, show_customer));
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseProductSubTableDynamicFieldsModal = () => {
            this.handleDeleteParentKintoneProductData();
            if (this.parentObjectList.length === 0) {
                this.setState({
                    submitSubKintone: false,
                    showProductDynamicSubTableFieldsModal: false,
                    productDynamicSubTableFields: []
                })
            } else {
                this.handleShowKintoneProductSubTableFieldsModal(this.parentObject.productId, this.parentObject.parentId, this.parentObject.parentFieldLabel, this.parentObject.parentShowCustomer);
            }
        }

        handleShowDeleteKintoneProductSubTableConfirmation = (kintone_id) => {
            this.setState({
                showDeleteKintoneProductConfirmation: true,
                kintoneDeleteId: kintone_id
            })
        }

        handleShowModalNewSubKintone = () => {
            this.setState({
                showModalNewKintone: true
            })
        }

        handleShowProductKintoneEditLabelSubTableModal = (id, field_name, field_label, is_table, show_customer) => {
            this.setState({
                initialKintoneFormValue: {
                    KintoneProductForm: {
                        id: id,
                        kintone_attribute_name: field_name,
                        display_label: field_label,
                        format_data: is_table ? "1" : "0",
                        show_customer: show_customer
                    }
                },
                showProductKintoneEditLabel: true,
                productKintoneFieldId: id,
            });
        }

        handleShowProductKintoneSortingSubModal = () => {
            this.setState({
                showProductDynamicSortingModal: true,
                productDynamicFieldsDummy: this.state.submitSubKintone ? this.state.productDynamicSubTableFields : this.state.productDynamicFields
            })
        }
    }
}

export {
    withProductDynamicSubTableFieldsModal
}