import React from 'react';
import FilterSearch from './FilterSearch';
import Filter from './Filter';
import PaginationPerPage from './PaginationPerPage';
import Sorting from './Sorting';
import NoticeButtons from './NoticeButtons';
import { withLoader } from '../Loader';
import Pagination from 'react-js-pagination';
import SortingTemplate from '../AdminNotice/SortingTemplate';


const withPaging = (WrappedComponent) => {
    return withLoader(
        class Paging extends WrappedComponent {

            constructor(props) {
                super(props);
                this.state = {
                    ...this.state,
                    rows: [],
                    activePage: 1,
                    itemsOffset: 0,
                    totalItemsCount: 0,
                    itemsCountPerPage: 10,
                    pageRangeDisplayed: 3,
                    query: '',
                    category_number: '',
                    filter_value: '',
                    product_number: '',
                    product_name: '',
                    sort: '',
                    sortTemplate: '',
                    productLookupList: [],
                    categoryLookupList: [],
                    filterList: [],
                    initialFormValue: null,
                    image: null,
                    data: [],
                    listOrdering: [],
                    hideServiceDropdown: false,
                    isDocumentList: false
                };
            }

            handlePageChange = page => {
                if ((this.state.totalItemsCount && this.state.totalDocumentsListItemsCount && this.state.totalItemsTemplateCount === undefined) || this.state.totalDocumentsListItemsCount) {
                    document.getElementsByClassName('previous-button')[0].classList.remove('disabled')
                    document.getElementsByClassName('next-button')[0].classList.remove('disabled')

                    if (page === 1 && ((this.state.itemsCountPerPage >= this.state.totalItemsCount && this.state.totalDocumentsListItemsCount === undefined && this.state.totalItemsTemplateCount === undefined)
                        || (this.state.itemsCountPerPage >= this.state.totalDocumentsListItemsCount && this.state.totalItemsCount === undefined && this.state.totalItemsTemplateCount === undefined)
                        || (this.state.itemsCountPerPage >= this.state.totalItemsTemplateCount && this.state.totalItemsCount === undefined && this.state.totalDocumentsListItemsCount === undefined))
                    ) {
                        document.getElementsByClassName('previous-button')[0].classList.add('disabled')
                        document.getElementsByClassName('next-button')[0].classList.add('disabled')
                    } else if (page === 1) {
                        document.getElementsByClassName('previous-button')[0].classList.add('disabled')
                    } else if ((page === Math.ceil(this.state.totalItemsCount / this.state.itemsCountPerPage) && this.state.totalDocumentsListItemsCount === undefined && this.state.totalItemsTemplateCount === undefined)
                        || (page === Math.ceil(this.state.totalDocumentsListItemsCount / this.state.itemsCountPerPage) && this.state.totalItemsCount === undefined && this.state.totalItemsTemplateCount === undefined)
                        || (page === Math.ceil(this.state.totalItemsTemplateCount / this.state.itemsCountPerPage) && this.state.totalItemsCount === undefined && this.state.totalDocumentsListItemsCount === undefined)) {
                        document.getElementsByClassName('next-button')[0].classList.add('disabled')
                    }
                }

                let callback = this.state.isDocumentList ?
                    () => { this.handleShowDocumentListModal(this.state.product_number, this.state.product_name) }
                    : this.state.showChooseTemplateModal ?
                    () => {this.handleShowChooseTemplateModal(this.state.productIdInChooseTemplateModal)}
                    : this.loadListFromServer;

                this.setState({
                    activePage: page,
                    itemsOffset: (page - 1) * this.state.itemsCountPerPage
                }, callback);
            }

            handleSearchChange = event => {
                this.setState({
                    query: event.target.value
                })
            }

            handleSearchClick = event => {
                event.preventDefault();
                this.handlePageChange(1);
            }

            handleProductLookupChange = event => {
                event.preventDefault();
                this.setState({
                    product_number: event.target.value,
                    product_name: event.target.options.selectedIndex === 0 ? "" : event.target.options[event.target.options.selectedIndex].text
                }, this.handlePageChange.bind(null, 1))
            }

            handleSortChange = event => {
                event.preventDefault();
                this.setState({
                    sort: event.target.value,
                }, this.handlePageChange.bind(null, 1))
            }

            handleSortTemplateChange = event => {
                event.preventDefault();
                this.setState({
                    sortTemplate: event.target.value,
                },this.handlePageChange.bind(null, 1))
            }

            getIdByCategoryName = (name) => {
                let id = 0;
                for (let i = 0; i < this.state.categoryLookupList.length; i++) {
                    if (this.state.categoryLookupList[i].name === name) {
                        id = this.state.categoryLookupList[i].id;
                        break;
                    }
                }
                return id;
            }

            handleSearchFilter = event => {
                let element = document.querySelectorAll(".highlight");
                [].forEach.call(element, function (button) {
                    button.classList.remove("highlight");
                });
                document.getElementById(event.target['id']).classList.add("highlight");
                event.preventDefault();
                let filter = event.target['value'];
                if (filter === "1" || filter === "0") {
                    filter = ""
                }
                this.handlePageChange(1);
                this.setState({
                    category_number: filter
                })
            }

            handlePerPageClick = event => {
                event.preventDefault();
                let target = event.target;
                let perPage = this.state.itemsCountPerPage;
                if (target.classList.contains('option')) {
                    let currentActive = document.querySelector('.per-page-pagination .page-link.active');
                    currentActive.classList.remove('active');
                    currentActive.parentElement.classList.remove('active');
                    target.classList.add('active');
                    target.parentElement.classList.add('active');
                    perPage = parseInt(event.target.innerHTML);
                }

                this.setState({
                    itemsCountPerPage: perPage
                }, () => {
                    this.handlePageChange(1);
                });
            }

            sorting = () => {
                return <Sorting
                    sort={this.state.sort}
                    listOrdering={this.state.listOrdering}
                    sortTemplate={this.state.sortTemplate}
                    defaultOrdering={this.state.defaultOrdering}
                    handleSortChange={this.handleSortChange} />
            }

            sortingTemplate = () => {
                return <SortingTemplate
                    listTemplateOrdering={this.props.listTemplateOrdering}
                    defaultTemplateOrdering={this.state.defaultTemplateOrdering}
                    sortTemplate={this.state.sortTemplate}
                    handleSortChange={this.handleSortTemplateChange} />
            }

            noticeButtons = () => {
                return <NoticeButtons
                    handleSearchFilter={this.handleSearchFilter}
                    getIdByCategoryName={this.getIdByCategoryName} />
            }

            filterSearch = () => {
                return <FilterSearch
                    showChooseTemplateModal={this.state.showChooseTemplateModal}
                    productIdInChooseTemplateModal={this.state.productIdInChooseTemplateModal}
                    productLookupList={this.state.productLookupList}
                    handleProductLookupChange={this.handleProductLookupChange}
                    handleSearchChange={this.handleSearchChange}
                    handlePageChange={this.handlePageChange}
                    handleSearchClick={this.handleSearchClick}
                    handleSearchFilter={this.handleSearchFilter}
                    getIdByCategoryName={this.getIdByCategoryName}
                    hideServiceDropdown={this.state.hideServiceDropdown} />
            }

            filter = () => {
                return <Filter
                    filterList={this.state.filterList}
                    handleFilterChange={this.handleFilterChange}/>
            }

            handleFilterChange = event => {
                event.preventDefault();
                this.setState({
                    filter_value: event.target.value,
                    // product_name: event.target.options.selectedIndex === 0 ? "" : event.target.options[event.target.options.selectedIndex].text
                }, this.handlePageChange.bind(null, 1))
            }

            perPage = () => {
                return <PaginationPerPage
                    {...this.state}
                    totalItemsTemplateCount={this.props.totalItemsTemplateCount}
                    handlePerPageClick={this.handlePerPageClick}
                    handlePageChange={this.handlePageChange} />
            }

            pager = () => {
                return <Pagination
                    firstPageText={'最初'}
                    prevPageText={'前へ'}
                    nextPageText={'次へ'}
                    lastPageText={'最後'}

                    innerClass={'pagination pagination-sm justify-content-center'}
                    activeClass={'active'}
                    activeLinkClass={'active'}
                    itemClass={'page-item'}
                    linkClass={'page-link'}

                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={
                        this.state.isDocumentList ?
                            this.state.totalDocumentsListItemsCount
                            :
                            this.state.showChooseTemplateModal ?
                                this.props.totalItemsTemplateCount
                                :
                                this.state.totalItemsCount
                    }
                    pageRangeDisplayed={this.state.pageRangeDisplayed}
                    onChange={this.handlePageChange}
                />
            }

        }
    )
}

export {
    withPaging
}
