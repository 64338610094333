import * as types from "./types";

export const getCustomerListState = (value) => ({
    type: types.CUSTOMER_LIST_STATE,
    payload: value
});

export const getCustomerInformationDetailsState = (value) => ({
    type: types.CUSTOMER_INFO_DETAILS_RETRIEVE_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const getAdminListState = (value) => ({
    type: types.ADMIN_LIST_STATE,
    payload: value
});

export const getAdminDetailsState = (value) => ({
    type: types.ADMIN_DETAILS_STATE,
    payload: value
});

export const adminPasswordChangeRequestState = (value) => ({
    type: types.ADMIN_PASSWORD_CHANGE_REQUEST_STATE,
    payload: value
});

export const getAdminKintoneProductSubTableData = (value) => ({
    type: types.ADMIN_KINTONE_PRODUCT_DATA_SUB_TABLE_STATE,
    payload: value
});

export const getAdminKintoneCommonSubTableData = (value) => ({
    type: types.ADMIN_KINTONE_COMMON_DATA_SUB_TABLE_STATE,
    payload: value
});

export const adminStatusState = (value) => ({
    type: types.ADMIN_STATUS_STATE,
    payload: value
})
