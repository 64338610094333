import * as types from "./types";

export const setUserAuth = (data, authenticate, expired = false) => ({
    type: types.SET_USER_AUTH,
    payload: data,
    isAuthenticated: authenticate,
    isExpiredRefreshToken: expired
});

export const adminPasswordChangeRequest = (data) => ({
    type: types.ADMIN_PASSWORD_CHANGE_REQUEST,
    payload: data,
});

export const adminUpdatePassword = (data) => ({
    type: types.ADMIN_UPDATE_PASSWORD,
    payload: data,
});