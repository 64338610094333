import * as types from "./types";

export const readState = (value) => ({
    type: types.READ_STATE,
    payload: value
});

export const noticeDetailsState = (value) => ({
    type: types.NOTICE_DETAILS_STATE,
    payload: value
});

export const noticePublishedVersionDetailsState = (value) => ({
    type: types.NOTICE_PUBLISHED_VERSION_DETAILS_STATE,
    payload: value
})

export const postNoticeCreateDetailsState = (value) => ({
    type: types.NOTICE_CREATE_STATE,
    payload: value
});

export const getNoticeEditDetailsState = (value) => ({
    type: types.NOTICE_EDIT_RETRIEVE_STATE,
    payload: value
});

export const putNoticeEditDetailsState = (value) => ({
    type: types.NOTICE_EDIT_UPDATE_STATE,
    payload: value
});

export const patchNoticeUnpublishState = (value) => ({
    type: types.NOTICE_UNPUBLISH_STATE,
    payload: value
});

export const patchNoticeCancelState = (value) => ({
    type: types.NOTICE_CANCEL_STATE,
    payload: value
});

export const patchNoticeDeleteState = (value) => ({
    type: types.NOTICE_DELETE_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const categoryLookupListState = (value) => ({
    type: types.CATEGORY_LOOKUP_STATE,
    payload: value
});

export const getNoticeHistoryDetailsState = (value) => ({
    type: types.NOTICE_RETRIEVE_HISTORY_STATE,
    payload: value
});

export const getNoticeTemplateListState = (value) => ({
    type: types.NOTICE_TEMPLATE_LIST_STATE,
    payload: value
});

export const createNoticeTemplateState = (value) => ({
    type: types.NOTICE_TEMPLATE_CREATE_STATE,
    payload: value
});

export const getNoticeTemplateDetailState = (value) => ({
    type: types.NOTICE_TEMPLATE_DETAIL_STATE,
    payload: value
});

export const updateNoticeTemplateState = (value) => ({
    type: types.NOTICE_TEMPLATE_UPDATE_STATE,
    payload: value
});

export const deleteNoticeTemplateState = (value) => ({
    type: types.NOTICE_TEMPLATE_DELETE_STATE,
    payload: value
});

export const getNoticeTemplateLookupState = (value) => ({
    type: types.NOTICE_TEMPLATE_LOOKUP_STATE,
    payload: value
});
