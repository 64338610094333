import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Commons from '../Commons';

const withMailPendingDetailsModal = (WrappedComponent) => {
    return class MailPendingDetailsModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showMailPendingDetailsModal: false
            }
        }

        mailPendingDetailsModal = (props) => {
            let { mailPendingDetails } = this.state;
            if (mailPendingDetails) {
                var emailTime = Commons.getClientDatetimeDisplay(mailPendingDetails.when_added);
                var statusText = mailPendingDetails.priority === 2 ? "送信予定" : "再送予定";
                var statusBg = Commons.getMailingStatusBackground(statusText);
            }

            return (
                <Modal size="md" className="admin-details modal-dialog-lg" keyboard={false} show={this.state.showMailPendingDetailsModal} onHide={this.handleCloseMailPendingDetailsModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{"メール送信予定 > 詳細"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        {mailPendingDetails ?
                            <div className="list-group">
                                <div className="px-0 pb-0 table-layout-body">
                                    <div className="list-item-attribute pt-1">
                                        <strong>送信元: </strong>
                                        {mailPendingDetails.message.from}
                                        <label className="float-right mb-0">
                                            {emailTime}
                                        </label>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>宛先: </strong>
                                            {mailPendingDetails.message.to}
                                            <label className="float-right rounded text-center px-2 mb-0" style={{ color: statusBg, border: "solid 1px" }}>
                                                {statusText}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>件名: </strong>
                                            {mailPendingDetails.message.subject}
                                        </div>
                                    </div>
                                    <div className="list-item-attribute row m-0 text-break">
                                        <div className="col-sm-12 px-0">
                                            <strong>内容: </strong>
                                            <pre>{mailPendingDetails.message.body}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <button className="btn btn-90 btn-secondary" onClick={this.handleCloseMailPendingDetailsModal}>
                            閉じる
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }

        handleShowMailPendingDetailsModal = (mailPendingDetails) => {
            this.setState({ showMailPendingDetailsModal: true, mailPendingDetails: mailPendingDetails })
        }

        handleCloseMailPendingDetailsModal = () => {
            this.setState({
                showMailPendingDetailsModal: false
            });
        }
    }
}

export {
    withMailPendingDetailsModal
}