import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowClose, faEdit, faTable } from '@fortawesome/free-solid-svg-icons'
import TooltipTemplate from '../TooltipTemplate';


const ProductCommonFieldsTemplate = (props) => {
    let {
        kintoneProductCommonFieldList,
        handleShowDeleteKintoneCommonProductConfirmation,
        handleShowCommonKintoneEditLabelModal,
        handleShowCommonSubTableFieldsModal
    } = props

    return (
        <Form id={'form-add-product-common-fields'}>
            <table className="table table-bordered table-striped table-form">
                <tbody>
                    <tr>
                        <th className="table-column-1 disable-table font-weight-bold"></th>
                        <th className="table-column-2 disable-table font-weight-bold align-middle text-center">項⽬</th>
                        <th className="table-column-3 disable-table font-weight-bold align-middle text-center">表⽰ラベル</th>
                        <th className="table-column-4 disable-table font-weight-bold text-center">顧客画面<br />表示</th>
                        <th className="table-column-5 disable-table font-weight-bold"></th>
                        <th className="table-column-5 disable-table font-weight-bold"></th>
                        <th className="table-column-5 disable-table font-weight-bold"></th>
                    </tr>
                    <tr className="disable-table font-weight-bold form-group">
                        <th rowSpan={kintoneProductCommonFieldList.length + 1} className="kintone-common-th">
                            <div className="row">
                                <div className="col-sm-12">
                                    共通属性設定
                                    <div className="float-right">
                                        <TooltipTemplate message={'Kintoneからサービスポータルに取り込むフィールドコードを「項目」に、サービスポータル上での表示タイトル名を「表示ラベル」に設定してください。'} />
                                    </div>
                                </div>
                            </div>
                        </th>
                        {kintoneProductCommonFieldList.length === 0 ? <td colSpan={6} className="font-weight-normal"> {props.totalItemsCount === 0 ? <center>設定情報はありません。</center> : null}</td> : null}
                    </tr>
                    {
                        kintoneProductCommonFieldList.map((field) => {
                            return (
                                <tr key={field.id} className="form-group">
                                    <td className="product-field-name align-middle">{field.field_name}</td>
                                    <td className="product-field-label align-middle">{field.field_label}</td>
                                    <td className="align-middle text-center">
                                        {field.show_customer ? "〇" : ""}
                                    </td>
                                    <td className="align-middle text-center">
                                        <FontAwesomeIcon icon={faEdit} className="fa-window-close pointer" onClick={() => { handleShowCommonKintoneEditLabelModal(field.id, field.field_name, field.field_label, field.is_table, field.show_customer) }} />
                                    </td>
                                    <td className="align-middle text-center">
                                        {field.is_table &&
                                            <FontAwesomeIcon icon={faTable} className="fa-window-close pointer" onClick={() => { handleShowCommonSubTableFieldsModal(field.id, field.field_label) }} />
                                        }
                                    </td>
                                    <td className="align-middle text-center">
                                        <FontAwesomeIcon icon={faWindowClose} className="fa-window-close pointer" onClick={() => { handleShowDeleteKintoneCommonProductConfirmation(field.id) }} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Form>
    );
}

export default ProductCommonFieldsTemplate;