export const READ_STATE = "Document/READ_STATE";
export const PRODUCT_LOOKUP_STATE = "Document/PRODUCT_LOOKUP_STATE";
export const DOCUMENT_DETAILS_STATE = "Document/DOCUMENT_DETAILS_STATE";
export const DOCUMENT_PUBLISHED_VERSION_DETAILS_STATE = "Document/DOCUMENT_PUBLISHED_VERSION_DETAILS_STATE";
export const DOCUMENT_CREATE_STATE = "Document/DOCUMENT_CREATE_STATE";
export const DOCUMENT_EDIT_RETRIEVE_STATE = "Document/DOCUMENT_EDIT_RETRIEVE_STATE";
export const DOCUMENT_EDIT_UPDATE_STATE = "Document/DOCUMENT_EDIT_UPDATE_STATE";
export const DOCUMENT_UNPUBLISH_STATE = "Document/DOCUMENT_UNPUBLISH_STATE";
export const DOCUMENT_DELETE_STATE = "Document/DOCUMENT_DELETE_STATE";
export const DOCUMENT_CANCEL_STATE = "Document/DOCUMENT_CANCEL_STATE";
export const DOCUMENT_RETRIEVE_HISTORY_STATE = "Document/DOCUMENT_RETRIEVE_HISTORY_STATE";
export const DOCUMENT_DOWNLOAD_STATE = "Document/DOCUMENT_DOWNLOAD_STATE";