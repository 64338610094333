import * as types from "./types";
import * as Commons from '../../../components/Commons';

const defaultState = {
    credentials: [{}],
    isAuthenticated: false,
    isExpiredRefreshToken: false,
    passwordChangeRequestMessage: [],
    passwordUpdateMessage: []
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case types.SET_USER_AUTH:
            let decodedAccess = Commons.getDecodedAuth(action.payload.access);
            return {
                ...state,
                ...decodedAccess,
                credentials: action.payload,
                isAuthenticated: action.isAuthenticated,
                isExpiredRefreshToken: action.isExpiredRefreshToken
            };
        case types.ADMIN_PASSWORD_CHANGE_REQUEST:
            return {
                ...state,
                passwordChangeRequestMessage: action.payload
            };
        case types.ADMIN_UPDATE_PASSWORD:
            return {
                ...state,
                passwordUpdateMessage: action.payload
            };
        default: return state;
    }
};
