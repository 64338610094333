export const NOTICE = "/api/admin/news/list/"
export const NOTICE_PUBLISHED_VERSION = "/api/admin/news/published/"
export const NOTICE_CREATE = "/api/admin/news/add/"
export const NOTICE_UPDATE = "/api/admin/news/edit/"
export const NOTICE_UNPUBLISH = "/api/admin/news/unpublish/"
export const NOTICE_CANCEL = "/api/admin/news/cancel-request/"
export const NOTICE_HISTORY_DETAILS = "api/admin/news/history/"
export const PRODUCTLOOKUP = "api/productlookup/"
export const CATEGORYLOOKUP = "api/postcategorylookup/"
export const NOTICE_TEMPLATE_LIST = "api/admin/posttemplate/list/"
export const NOTICE_TEMPLATE_CRUD = "api/admin/posttemplate/"
export const NOTICE_TEMPLATE_LOOKUP = "api/admin/posttemplatelookup/"
