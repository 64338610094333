import React from 'react'

const AdminDocumentButtons = (props) => {
    let { handleShowModalNewDocument } = props
    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value="新規登録" onClick={handleShowModalNewDocument}>新規登録</button>
            </div>
        </div>
    );
}

export default AdminDocumentButtons