export const READ_STATE = "Notice/READ_STATE";
export const PRODUCT_LOOKUP_STATE = "Notice/PRODUCT_LOOKUP_STATE";
export const NOTICE_DETAILS_STATE = "Notice/NOTICE_DETAILS_STATE";
export const NOTICE_PUBLISHED_VERSION_DETAILS_STATE = "Notice/NOTICE_PUBLISHED_VERSION_DETAILS_STATE";
export const CATEGORY_LOOKUP_STATE = "Notice/CATEGORY_LOOKUP_STATE";
export const NOTICE_CREATE_STATE = "Notice/NOTICE_CREATE_STATE";
export const NOTICE_EDIT_RETRIEVE_STATE = "Notice/NOTICE_EDIT_RETRIEVE_STATE";
export const NOTICE_EDIT_UPDATE_STATE = "Notice/NOTICE_EDIT_UPDATE_STATE";
export const NOTICE_UNPUBLISH_STATE = "Notice/NOTICE_UNPUBLISH_STATE";
export const NOTICE_CANCEL_STATE = "Notice/NOTICE_CANCEL_STATE";
export const NOTICE_DELETE_STATE = "Notice/NOTICE_DELETE_STATE";
export const NOTICE_RETRIEVE_HISTORY_STATE = "Notice/NOTICE_RETRIEVE_HISTORY_STATE";
export const NOTICE_TEMPLATE_LIST_STATE = "Notice/NOTICE_TEMPLATE_LIST_STATE";
export const NOTICE_TEMPLATE_CREATE_STATE = "Notice/NOTICE_TEMPLATE_CREATE_STATE";
export const NOTICE_TEMPLATE_UPDATE_STATE = "Notice/NOTICE_TEMPLATE_UPDATE_STATE";
export const NOTICE_TEMPLATE_DETAIL_STATE = "Notice/NOTICE_TEMPLATE_DETAIL_STATE";
export const NOTICE_TEMPLATE_DELETE_STATE = "Notice/NOTICE_TEMPLATE_DELETE_STATE";
export const NOTICE_TEMPLATE_LOOKUP_STATE = "Notice/NOTICE_TEMPLATE_LOOKUP_STATE";
