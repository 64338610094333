import React from 'react';
import { Modal } from 'react-bootstrap';


const withMailSettingsEditConfirm = (WrappedContent) => {

    return class MailSettingsEditConfirm extends WrappedContent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showMailSettingsEditConfirmModal: false
            };
        }

        mailSettingsEditConfirmModal = (props) => {
            return (
                <Modal keyboard={false} size="md" centered 
                    className="modal-edit-confirm-mail-settings modal-dialog-md" 
                    show={props.showMailSettingsEditConfirmModal} 
                    onHide={this.handleCloseMailSettingsEditConfirmModal} >
                    <Modal.Header closeButton>
                        <Modal.Title>{"メールサーバー設定の更新 > 更新確認"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div>
                            メールサーバー設定を更新します。
                        </div>
                        <div>
                            よろしいですか？
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 pt-0">
                        <div className="w-100 d-inline-flex">
                            <button 
                                type="button"
                                className="btn btn-90 mr-2 ml-auto btn-primary" 
                                onClick={this.handleSubmitMailSettingsEditConfirmModal}>Ok</button>
                            <button 
                                className="btn btn-90 btn-secondary" 
                                onClick={this.handleCloseMailSettingsEditConfirmModal}>キャンセル</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )
        }

        handleShowMailSettingsEditConfirmModal = () => {
            this.setState({
                showMailSettingsEditConfirmModal: true,
            })
        }

        handleCloseMailSettingsEditConfirmModal = () => {
            this.setState({
                showMailSettingsEditConfirmModal: false
            })
        }

        handleSubmitMailSettingsEditConfirmModal = (event) => {
            event.target.setAttribute("disabled", "disabled");
            let payload = this.state.mailSettingsData.payload;

            Promise.all([
                this.showLoader(),
                this.props.patchMailSettings(payload).then(
                    () => {
                        this.handleCloseMailSettingsEditModal();
                        this.loadListFromServer();
                        this.handleShowSuccessAlert(this.props.mailSettingsMessage);
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
            .then(this.handleCloseMailSettingsEditConfirmModal)
        }
    }

}

export {
    withMailSettingsEditConfirm
}
