import React from 'react';
import { Button, ButtonToolbar, FormCheck, Alert } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import { isNullOrUndefined } from 'util';
import TooltipTemplate from './TooltipTemplate';
import DateTime from "@nateradebaugh/react-datetime";
import "@nateradebaugh/react-datetime/scss/styles.scss";
import { DATETIME_FORMAT_3, NEW_ERROR_PRODUCT_TEMPLATE } from '../../utils/constants';
import FormikEffect from './FormikEffect';

let AdminFormTemplate = (props) => {
    //Initial values
    let initialValues = props.initialValues;
    let initialPublishPreferredDate = null;
    let templateDataChoosen = null;
    (props.publishDate === "1") ?
        initialPublishPreferredDate = new Date(props.publishPreferredDate) :
        initialPublishPreferredDate = new Date();
    const [dateValue, setValue] = React.useState(new Date(initialPublishPreferredDate))
    const [choosetemplate, setChooseTemplate] = React.useState(false);

    if (isNullOrUndefined(props.initialValues)) {
        initialValues = {};
        //Initial value if tabbed or single
        if (isNullOrUndefined(props.initialValues)) {
            if (props.tabbed || props.nested) {
                props.multiFormInputs.map(tab => {
                    let modelValues = {};
                    tab.form.map(input => (
                        input.type === "datepicker" ? modelValues[input.name] = moment()
                            : modelValues[input.name] = input.default
                    ));
                    initialValues[tab.model] = modelValues;
                    return initialValues[tab.model];
                });
            } else {
                let modelValues = {};
                props.formInputs.form.map(input => (
                    input.type === "datepicker" ? modelValues[input.name] = moment()
                        : modelValues[input.name] = input.default)
                );
                initialValues[props.formInputs.model] = modelValues;
            }
        }
    }

    function RenderInput(model, input, fieldValue, setFieldValue, errors, touched) {
        let modelField = `${model}.${input.name}`;
        let modelTouch = touched[model];
        let isTouched = undefined !== touched[model] && modelTouch[input.name];
        let isError = undefined !== errors[modelField];
        let displayError = isTouched && isError;
        templateDataChoosen = props.templateDataChoosen;

        React.useEffect(() => {
            if ((templateDataChoosen !== null && props.showModalNewPost) || (templateDataChoosen !== null && props.showNoticeDraft) || (templateDataChoosen !== null && props.showApprovalEditNotice)) {
                if(modelField === "PostForm.title") {
                    setFieldValue(modelField, templateDataChoosen.title)
                }
                if(modelField === "PostForm.content") {
                    setFieldValue(modelField, templateDataChoosen.content)
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [templateDataChoosen]);

        switch (input.type) {

            //renders radio button group field
            case "radio": {
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div className="d-flex row">
                                {props.radioInput.map((radio, index) => {
                                    if (radio.name === input.name) {
                                        return (
                                            <div className="form-radio col-md-4 col-6" key={input.name + index}>
                                                <FormCheck
                                                    id={input.name + index + props.form}
                                                    type="radio"
                                                    inline={input.inline}
                                                    value={radio.value}
                                                    name={modelField}
                                                    onChange={event => setFieldValue(modelField, event.target.value)}
                                                    checked={fieldValue.toString() === radio.value.toString()}
                                                    label={radio.label}
                                                    disabled={input.disabled}
                                                />
                                            </div>
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                        </td>
                    </tr>
                )
            }

            case "radio-date": {
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <div className="d-flex row">
                                {props.radioInput.map((radio, index) => {
                                    if (radio.name === input.name) {
                                        return (
                                            <div className="form-radio col-md-4 col-6" key={input.name + index}>
                                                <FormCheck
                                                    id={input.name + index + props.form}
                                                    className={props.form + "-publish-preferred-date-radio"}
                                                    type="radio"
                                                    inline={input.inline}
                                                    value={radio.value}
                                                    name={modelField}
                                                    onChange={event => {
                                                        setFieldValue(modelField, event.target.value);
                                                        let radioOptions = document.querySelectorAll(`input[name="${modelField}"]`)
                                                        if (radioOptions.item(1).checked) {
                                                            document.getElementById(props.form + "dateTime").setAttribute("class", "form-datetime form-control-sm align-middle")
                                                        }
                                                        else {
                                                            document.getElementById(props.form + "dateTime").setAttribute("class", "d-none")
                                                        }
                                                    }}
                                                    checked={fieldValue.toString() === radio.value.toString()}
                                                    label={radio.label}
                                                    disabled={input.disabled}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                                <div className="form-datetime-container col-md-4 col-12">
                                    <DateTime id={props.form + "dateTime"} dateFormat={DATETIME_FORMAT_3} timeFormat={true} className={props.publishDate === "1" ? "form-datetime form-control-sm align-middle" : "d-none"} value={dateValue} onFocus={() => {
                                        let radioOptions = document.querySelectorAll("." + props.form + "-publish-preferred-date-radio label");
                                        radioOptions && radioOptions.length === 2 && radioOptions.item(1).click();
                                    }} onChange={setValue} disabled={input.disabled} />
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }

            // renders select field
            case "select": {
                return (
                    <tr key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control form-error" : "form-control"}
                                component="select"
                                name={modelField}
                                id={modelField + "-select-product"}
                            >
                                <option disabled value="">{input.placeholder}</option>
                                {props.selectInput && props.selectInput.filter((option) => option.name === input.name).map((item, i) => {
                                    return (
                                        <option
                                            key={input.name + i} value={item.value}> {item.label} </option>
                                    );
                                })}
                            </Field>
                        </td>
                    </tr>
                );
            }

            case "hidden": {
                return null;
            }

            case "text-remarks": {
                return (
                    <div key={input.name}>
                        {CustomError(errors[modelField], displayError)}
                        <Field
                            className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                            placeholder={input.placeholder}
                            component={input.component}
                            type={input.type}
                            name={modelField}
                            maxLength={input.maxLength}
                            minLength={input.minLength}
                            min={input.min}
                            max={input.max}
                            onChange={event => setFieldValue(modelField, event.target.value)}
                            disabled={input.disabled}
                        />
                    </div>
                );
            }

            case "choosetemplate": {
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""} key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2 file">
                            <div className="d-flex">
                                <div className="col-sm-7"><label className="mb-0"></label></div>
                                <div className="col-sm-5">
                                    <a href='# ' onClick={() => {setAction()}}>テンプレートファイルを選択</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }

            //renders text, textarea, email, password, numbers, default input
            default: {
                return (
                    <tr className={input.onEmptyInvisible && !Boolean(fieldValue) ? "d-none" : ""}key={input.name}>
                        <td className="align-middle disable-table font-weight-bold">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="mb-0">{input.label}{input.required ? <span className="text-danger font-weight-bold"> * </span> : ""}</label>
                                    <div className={input.message ? "float-right" : "d-none"}>
                                        <TooltipTemplate message={input.message} />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="align-middle px-2 py-2">
                            <Field
                                className={displayError ? "form-control text-left form-error" : "form-control text-left"}
                                placeholder={props.showModalTemplateDetail ? "" : input.placeholder}
                                component={input.component}
                                type={input.type}
                                name={modelField}
                                maxLength={input.maxLength}
                                minLength={input.minLength}
                                min={input.min}
                                max={input.max}
                                onChange={event => {
                                    if ((input.type === "textarea" && modelField.split(".").includes("title"))
                                        || ((props.isNoticeTemplate || props.showModalTemplateDetail || props.form === "admin-edit-template") && input.type === "textarea" && modelField.split(".").includes("name"))) {
                                        setFieldValue(modelField, event.target.value.replace(/(\r\n|\n|\r)/gm, ""));
                                    } else {
                                        setFieldValue(modelField, event.target.value);
                                    }
                                }}
                                style={{ backgroundColor: (input.onSetEmphasize && fieldValue) ? '#f9f972' : '' }}
                                disabled={input.disabled}
                                rows={input.rows}
                            />
                        </td>
                    </tr>
                );
            }
        }

    }

    //Renders form buttons
    // const FormButtons = props.formButtons && props.formButtons.map((button, i) => {
    //     return (
    //         <Button type={button.submit ? "submit" : "button"} key={i} className={button.className} variant={button.variant} size={button.size} onClick={button.onClick}>
    //             {button.label}
    //         </Button>
    //     );
    // });

    const setAction = () => {
        let productId = props.productId
        let product = document.getElementById("PostForm.product-select-product");
        if (product !== null) {
            productId = product.value;
        }
        if (productId === "") {
            setChooseTemplate(true);
        } else {
            setChooseTemplate(false);
            props.handleShowChooseTemplateModal(
                Number(productId),
                {
                    'itemsCountPerPage': 10,
                    'activePage': 1,
                    'query': '',
                    'sortTemplate': 'created_date',
                }
            );
        }
    }

    //Error messages
    const CustomError = (error, displayError) => {
        return displayError ? <p className="formik-error">{error}</p> : null;
    }

    const CustomTemplateError = (error) => {
        return <Alert variant="danger">{error}</Alert>;
    }

    //Maps form inputs
    const FormInputs = (values, setFieldValue, errors, touched) => props.formInputs.form && props.formInputs.form.map((input) => {
        if (choosetemplate) {
            errors = []
        }
        return RenderInput(props.formInputs.model, input, values[props.formInputs.model][input.name], setFieldValue, errors, touched)
    });

    var DisplayAlert = (errors, form) => {
        // eslint-disable-next-line
        let errorList = Object.entries(errors).map((error, idx) => {
            if (form[error[0].split(".").pop(-1)]) {
                return <p key={idx} className="mb-0">{error[1]}</p>
            }
        })
        let displayTemplate = <></>

        if (errorList.length){
            displayTemplate = <Alert variant="danger">{errorList}</Alert>
        }

        return displayTemplate;
    }

    if ((!isNullOrUndefined(props.initialValues) && props.editMode) || !props.editMode) {
        return (
            <Formik
                initialValues={initialValues}
                className={props.className}
                validateOnChange={false}
                validateOnBlur={false}
                validate={values => {
                    setChooseTemplate(false);
                    return props.validate(values);
                }}
                onSubmit={(values) => {
                    if (values.PostForm) {
                        values.PostForm.publish_date === "1" ?
                            values.PostForm.publish_preferred_date = new Date(dateValue) :
                            values.PostForm.publish_preferred_date = undefined;
                    } else if (values.RejectForm) {
                        values.RejectForm.publish_date === "1" ?
                            values.RejectForm.publish_preferred_date = new Date(dateValue) :
                            values.RejectForm.publish_preferred_date = undefined;
                    }

                    // console.log("VALUES: ", values);
                    if (props.addMode) {
                        // console.log('add mode');
                        props.handleSubmitModalNewPost(props.isNoticeTemplate ? values.TemplateForm : values.PostForm, props.isNoticeTemplate);
                    } else if (props.editMode) {
                        // console.log('edit mode');
                        if (props.showApprovalEditNotice) {
                            props.handleSubmitApprovalEditNoticeModal(values.PostForm);
                        } else {
                            props.handleSubmitDraftModal(values.PostForm.post_id, values.PostForm);
                        }
                    } else if (props.rejectMode) {
                        // console.log('reject mode');
                        props.handleSubmitRejectModal(values.RejectForm.post_id, values.RejectForm);
                    } else if (props.editTemplateMode) {
                        props.handleSubmitTemplateEditModal(values.TemplateForm.id, values.TemplateForm);
                    }

                    if (document.querySelector('.modal.show input[name=submit_action]')) {
                        switch (document.querySelector('.modal.show input[name=submit_action]').value) {
                            case "handleShowTemplateConfirmModal": {
                                if (props.editTemplateMode) {
                                    props.handleShowTemplateConfirmModal(undefined, props.editTemplateMode)
                                } else if (props.editMode || props.addMode) {
                                    props.handleShowTemplateConfirmModal()
                                }
                                break;
                            }
                            case "handleShowApplicationConfirmModal": {
                                props.handleShowApplicationConfirmModal()
                                break;
                            }
                            case "handleShowDraftConfirmModal": {
                                props.handleShowDraftConfirmModal()
                                break;
                            }
                            case "handleShowApprovalConfirmModal": {
                                props.handleShowApprovalConfirmModal()
                                break;
                            }
                            case "handleShowApprovalRejectModal": {
                                props.handleShowApprovalRejectModal(values.ApprovalPostForm.post_id)
                                break;
                            }
                            default: {
                                break;
                            }
                        }
                    }
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form id={props.form}>
                        <FormikEffect delay={500} onChange={values => props.handleFormOnchange(values)} />
                        {
                            choosetemplate ?
                            CustomTemplateError(NEW_ERROR_PRODUCT_TEMPLATE)
                            :
                            DisplayAlert(errors, touched[Object.keys(touched)[0]])
                        }
                        <table className="configuration-table table table-bordered table-form">
                            <colgroup>
                                <col className="colwidth4" />
                                <col />
                            </colgroup>
                            <tbody>
                                {FormInputs(values, setFieldValue, errors, touched)}
                            </tbody>
                        </table>
                        <ButtonToolbar className="d-none">
                            <Button type="submit" value="Submit" name="submit" className={props.showApprovalEditNotice ? "submitApprovalNoticeDocument" : "noticeSubmitButton"} />
                        </ButtonToolbar>
                    </Form>
                )}
            </Formik>
        );
    } else {
        return <p>Loading . . .</p>;
    }
}

export default AdminFormTemplate;