import * as types from "./types";

export const readState = (value) => ({
    type: types.READ_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const noticeDetailsState = (value) => ({
    type: types.NOTICE_DETAILS_STATE,
    payload: value
});

export const categoryLookupListState = (value) => ({
    type: types.CATEGORY_LOOKUP_STATE,
    payload: value
});