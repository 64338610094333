import React, { Component } from 'react';
import AdminNoticeTemplate from "../../components/AdminNotice/AdminNoticeTemplate";
import AdminNoticeButtons from "../../components/Pagination/AdminNoticeButtons";
import { withPaging } from '../../components/Pagination/Paging'
import { withErrorAlert } from '../../components/ErrorAlert';
import { withDiscardChangesNotification } from '../../components/DiscardChanges';
import { withLoginCheck } from '../../components/LoginCheck';
import { withSuccessAlert } from '../../components/SuccessAlert';
import { connect } from 'react-redux';
import { adminNoticeSelectors } from './state';
import { adminNoticeOperations } from './state';
import { bindActionCreators } from 'redux';
import { withNewNoticePost } from '../../components/AdminNotice/AdminNewPostTemplate';
import { withAdminNoticeTemplateConfirmModal } from '../../components/AdminNotice/AdminNoticeTemplateComfirmModal'
import { withAdminNoticeCancelModal } from '../../components/AdminNotice/AdminNoticeCancelModal';
import { withAdminNoticeTemplateEditModal } from '../../components/AdminNotice/AdminNoticeTemplateEditModal';
import { withAdminNoticeTemplateDetailModal } from '../../components/AdminNotice/AdminNoticeTemplateDetailModal';


class AdminNoticeTemplateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            isNoticeTemplate: false
        };
    }

    componentDidMount() {
        this.setState({
            listOrdering: this.props.listOrdering,
            defaultOrdering: this.props.defaultOrdering,
            totalItemsCount: this.props.totalItemsCount
        });
        this.loadListFromServer();
    }

    loadListFromServer = () => {
        Promise.all([
            this.showLoader(),
            this.props.getNoticeTemplateList(
                this.state.itemsCountPerPage, this.state.activePage,
                this.state.query, this.state.product_number, this.state.sort
            ).then(() => {
                this.setState({
                    rows: this.props.noticeTemplateList,
                    totalItemsCount: this.props.totalItemsCount,
                    isNoticeTemplate: true,
                });
            }),
            this.props.getNoticeProductList().then(() => {
                this.setState({
                    productLookupList: this.props.productLookupList
                });
            }),
        ]).catch(this.handleShowErrorAlert)
        .then(this.closeLoader)
    }

    handleFormOnchange = (values) => {
        this.setState({ currentFormValues: values.formValues.TemplateForm })
    }

    handleCloseAllModal = () => {
        this.setState({
            showNoticeTemplateEdit: false,
            showModalNewPost: false,
            showDiscardModal: false,
            showNoticeDraft: false
        });
    }

    render() {
        return (
            this.props.isExpiredRefreshToken ?
                this.loginError({ ...this.state, ...this.props })
                :
                <>
                    <div className="admin_notice_template">
                        <h4 className="mr-auto page-heading">{"テンプレート管理 > テンプレート一覧"}</h4>
                        <nav className="navbar navbar-expand navbar-light bg-menu mb-1">
                            <AdminNoticeButtons
                                isNoticeTemplate={this.state.isNoticeTemplate}
                                handleShowModalNewPost={this.handleShowModalNewPost}
                            />
                            {this.filterSearch()}
                        </nav>
                        <div className="content">
                            <div className="row">
                                {this.state.rows.length > 0 ? this.sorting() : null}
                                {this.state.rows.length > 0 ? this.perPage() : null}
                            </div>
                        </div>
                        <AdminNoticeTemplate {...this.state}
                            isNoticeTemplate={this.state.isNoticeTemplate}
                            handleShowModalTemplateFormDetail={this.handleShowModalTemplateFormDetail}
                            handleShowModalDetail={this.handleShowModalDetail}
                            handleShowCancelModal={this.handleShowCancelModal}
                            handleShowTemplateConfirmModal={this.handleShowTemplateConfirmModal}
                            handleShowTemplateEditModal={this.handleShowTemplateEditModal}
                        />
                        {this.state.rows.length > 0 ? this.pager() : null}
                    </div>

                    {this.loader({ ...this.state })}
                    {this.dismissAlert({ ...this.state })}
                    {this.errorAlert({ ...this.state })}
                    {this.successAlert({ ...this.state })}
                    {this.discardChanges({ ...this.state })}
                    {this.newNoticePostModal({ ...this.state, ...this.props, adminNoticeSelectors })}
                    {this.adminNoticeCancelModal({ ...this.state })}
                    {this.noticeTemplateConfirmModal({ ...this.state })}
                    {this.adminNoticeTemplateEditModal({ ...this.state, ...this.props, adminNoticeSelectors })}
                    {this.adminNoticeTemplateDetailModal({ ...this.state, ...this.props })}
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin,
        isExpiredRefreshToken: state.auth.isExpiredRefreshToken,
        totalItemsCount: state.adminNotice.totalItemsCount,
        productLookupList: state.adminNotice.productLookupList,
        noticeTemplateCreate: state.adminNotice.noticeTemplateCreate,
        noticeTemplateDetail: state.adminNotice.noticeTemplateDetail,
        noticeTemplateUpdate: state.adminNotice.noticeTemplateUpdate,
        noticeTemplateDelete: state.adminNotice.noticeTemplateDelete,
        noticeTemplateList: state.adminNotice.noticeTemplateList,
        formInputs: state.adminNotice.templateForm,
        selectInput: state.adminNotice.productLookupList,
        listOrdering: state.adminNotice.noticeTemplateOrdering,
        defaultOrdering: state.adminNotice.defaultNoticeOrdering,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getNoticeTemplateList: adminNoticeOperations.getNoticeTemplateList,
        getNoticeProductList: adminNoticeOperations.getNoticeProductList,
        getNoticeTemplateDetail : adminNoticeOperations.getNoticeTemplateDetail,
        createNoticeTemplate : adminNoticeOperations.createNoticeTemplate,
        updateNoticeTemplate : adminNoticeOperations.updateNoticeTemplate,
        deleteNoticeTemplate : adminNoticeOperations.deleteNoticeTemplate,
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(
    withPaging(
        withLoginCheck(
            withDiscardChangesNotification(
                withSuccessAlert(
                    withErrorAlert(
                        withAdminNoticeTemplateEditModal(
                            withAdminNoticeTemplateConfirmModal(
                                withNewNoticePost(
                                    withAdminNoticeTemplateDetailModal(
                                        withAdminNoticeCancelModal(AdminNoticeTemplateScreen)
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);
