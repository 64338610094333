import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { rootRoutes } from '../../app.modules';
import Logo from '../../includes/img/SolitonCloudServicePortal_logo_C.svg'
import { LOGIN_ROUTE, LOGIN_LABEL } from '../containers/Login/routes';
import { ADMIN_LOGIN_ROUTE } from '../containers/AdminLogin/routes';
import { NOTICE_ROUTE } from '../containers/Notice/routes';

const UserMenu = (props) => {
    let menu = undefined;

    if (props.isAuthenticated) {
        menu =
        <li className="nav-item dropdown">
            <a className={"nav-link dropdown-toggle dropdown"} href="# " id="auth-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.username}&nbsp;
            </a>
            <div className="dropdown-menu auth-dropdown" aria-labelledby="auth-dropdown">
                {
                    props.isAdminPage || props.isSuperuser ?
                        <>
                            <NavLink exact className="nav-link dropdown-item p-2 px-3" to={props.adminLink}>管理画面に切り替え</NavLink>
                            <a className="nav-link dropdown-item p-2 px-3" href="# " onClick={props.handleShowAuthPasswordChangeModal}>パスワード変更</a>
                        </>
                        : ""
                }

                <a className="dropdown-item p-2 px-3" href="# " onClick={props.handleShowLogoutModal}>ログアウト</a>
            </div>
        </li>;
    }
    else if (props.location.pathname === "/admin/login" || props.location.pathname === "/admin/password/update") {
        menu =
        <li className="nav-item">
            <NavLink className="nav-link" to={ADMIN_LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
        </li>;
    }
    else {
        menu = 
        <li className="nav-item">
            <NavLink className="nav-link" to={LOGIN_ROUTE}>{LOGIN_LABEL}</NavLink>
        </li>
    }

    return menu;
}

const MainHeader = (props) => {
    var adminLink = null;
    if(props.isSuperuser || props.isPoster){
        adminLink = "/admin/noticepost";
    }
    else if(props.isApprover){
        adminLink = "/admin/approvalpending";
    }
    else if(props.isPortalAdmin){
        adminLink = "/admin/portalusers";
    }
    else {
        adminLink = "/admin";
    }

    return (
        <>
            <header className="desktop-header py-3">
                <Nav.Link as={Link} to={NOTICE_ROUTE} className="py-0 px-0 soliton_logo">
                    <img src={Logo}
                        className="header-logo"
                        alt="Soliton サービスポータル" />
                </Nav.Link>
            </header>
            <nav id="menu" className="desktop-header navbar navbar-expand-sm navbar-light bg-menu py-0">
                <div className="w-100 d-inline-flex row ml-0">
                    <ul className="navbar-nav mr-auto">
                        {
                            rootRoutes.map((route, i) => {
                                let link = undefined;

                                if (route.path && (route.showNavPublic || (route.showNavPrivate && props.isAuthenticated))) {
                                    link =
                                    <li className="nav-item" key={i} >
                                        <NavLink exact className="nav-link" to={route.path}>{route.label}</NavLink>
                                    </li>;
                                }
                                else {
                                    link = "";
                                }
                                return link;
                            })
                        }
                    </ul>
                    <ul className="navbar-nav">
                        <UserMenu {...props} adminLink={adminLink} />
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default MainHeader;