import * as Path from './apiRoutes';
import * as Actions from "./actions";
import clearAuth from "../../../../utils/clearAuth";
import ApiService from "../../../../utils/apiService";
import { UNABLE_TO_PROCESS_RESET_PASSWORD } from '../../../../utils/constants';


const getSSO = () => (dispatch) => {
    return ApiService.get(Path.GET_SSO)
        .then((response) => {
            if (response.data && response.data.redirect) {
                ApiService.redirectTo(document, response.data.redirect);
            }
        })
}

const logoutUser = () => (dispatch) => {
    dispatch(Actions.setUserAuth([], false));
    clearAuth();
};

const getSLO = () => (dispatch) => {
    dispatch(Actions.setUserAuth([], false));
    clearAuth();
    return ApiService.get(Path.GET_SLO)
    //     .then((response) => {
    //         ApiService.redirectTo(document, response.data.redirect)
    //     })
}

const setUser = (params, authenticated) => (dispatch) => {
    return Promise.resolve()
        .then(() => {
            dispatch(Actions.setUserAuth(params, authenticated))
        });
};

const loginUser = (username, password) => (dispatch) => {
    let payload = { username, password };

    return ApiService.token(payload)
        .then((response) => {
            dispatch(Actions.setUserAuth(response.data, true));
        })
        .catch((error) => {
            return ApiService.handleDisplayError(error);
        })
};


const adminPasswordChangeRequest = (email) => (dispatch) => {
    return ApiService.post(Path.ADMIN_PASSWORD_CHANGE_REQUEST, email)
        .then((response) => {
            dispatch(Actions.adminPasswordChangeRequest(response.data));
        })
        .catch((error) => {
            return ApiService.handleDisplayError(error);
        })
}

const adminPasswordUpdate = (data) => (dispatch) => {
    return ApiService.post(Path.ADMIN_UPDATE_PASSWORD, data)
        .then((response) => {
            dispatch(Actions.adminUpdatePassword(response.data));
        })
        .catch((error) => {
            return ApiService.handleDisplayError(error);
        })
}

const adminPasswordAuthUpdate = (data) => (dispatch) => {
    return ApiService.refreshToken()
        .then(() => {
            return ApiService.post(Path.ADMIN_AUTH_UPDATE_PASSWORD, data)
                .then((response) => {
                    dispatch(Actions.adminUpdatePassword(response.data));
                })
        })
        .catch((error) => {
            return ApiService.handleDisplayError(error);
        })
}

const adminPasswordReset = (uidb64, token) => (dispatch) => {
    return ApiService.get(`${Path.ADMIN_PASSWORD_RESET}${uidb64}/${token}/`)
        .then((response)=> {
            if (response.data && response.data.access_token) {
                ApiService.redirectTo(document, `/admin/password/update?access_token=${response.data.access_token}`);
            }
            else {
                ApiService.redirectTo(document, `/admin/login?error=${UNABLE_TO_PROCESS_RESET_PASSWORD}`)
            }
        })
        .catch((error) => {
            return ApiService.handleDisplayError(error);
        })
}

const redirect = (url) => (dispatch) => {
    return ApiService.redirectTo(document, url);
}

export {
    setUser,
    getSSO,
    getSLO,
    loginUser,
    logoutUser,
    adminPasswordChangeRequest,
    adminPasswordUpdate,
    adminPasswordAuthUpdate,
    adminPasswordReset,
    redirect
};
