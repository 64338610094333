import * as types from "./types";

export const getProductListState = (value) => ({
    type: types.PRODUCT_LIST_STATE,
    payload: value
});

export const productLookupListState = (value) => ({
    type: types.PRODUCT_LOOKUP_STATE,
    payload: value
});

export const categoryLookupListState = (value) => ({
    type: types.CATEGORY_LOOKUP_STATE,
    payload: value
});

export const getCustomerInformationState = (value) => ({
    type: types.CUSTOMER_INFO_RETRIEVE_STATE,
    payload: value
});

export const getProductDeleteState = (value) => ({
    type: types.PRODUCT_DELETE_STATE,
    payload: value
});

export const postCreateProductState = (value) => ({
    type: types.CREATE_PRODUCT_STATE,
    payload: value
});

export const getProductEditDetailsState = (value) => ({
    type: types.PRODUCT_EDIT_RETRIEVE_STATE,
    payload: value
});

export const putProductEditDetailsState = (value) => ({
    type: types.PRODUCT_EDIT_UPDATE_STATE,
    payload: value
});

export const getKintoneProductFieldListState = (value) => ({
    type: types.KINTONE_PRODUCT_FIELD_LIST_STATE,
    payload: value
});

export const getKintoneProductSubTableFieldListState = (value) => ({
    type: types.KINTONE_PRODUCT_SUBTABLE_FIELD_LIST_STATE,
    payload: value
});

export const getKintoneCommonSubTableFieldListState = (value) => ({
    type: types.KINTONE_PRODUCT_COMMON_SUB_TABLE_FIELD_LIST_STATE,
    payload: value
});

export const postCreateKintoneProductFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_FIELD_CREATE_STATE,
    payload: value
});

export const deleteKintoneProductFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_FIELD_DELETE_STATE,
    payload: value
});

export const patchKintoneProductFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_FIELD_PATCH_STATE,
    payload: value
});

export const patchKintoneCommonFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_COMMON_FIELD_PATCH_STATE,
    payload: value
});

export const getKintoneProductCommonFieldListState = (value) => ({
    type: types.KINTONE_PRODUCT_COMMON_FIELD_LIST_STATE,
    payload: value
});

export const postCreateKintoneProductCommonFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_COMMON_FIELD_CREATE_STATE,
    payload: value
});

export const deleteKintoneProductCommonFieldState = (value) => ({
    type: types.KINTONE_PRODUCT_COMMON_FIELD_DELETE_STATE,
    payload: value
});