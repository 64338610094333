import React from 'react';
import * as Commons from '../Commons'

const AdminApprovalHistoryTemplate = (props) => {
    let {
        rows,
        handleNoticeShowApprovalHistoryDetail,
        handleDocumentShowApprovalHistoryDetail
    } = props;

    return (
        <section className="list mt-3 mb-3">
            <div className="mt-2">
                {props.totalItemsCount > 0 ?
                    <>
                        {rows.map(application => {
                            var updatedDate = Commons.getClientDatetimeDisplay(application.updated_date)
                            var statusBg = Commons.getApprovalStatusBackground(application.application_status);
                            return (
                                <div className="px-3 py-3 list-group-item table-layout" key={application.application_id}>
                                    <div className="list-group">
                                        <div className="px-0 py-0 table-layout-body">
                                            <div>
                                                <label className="float-right mb-0">
                                                    <strong>更新日時: </strong>
                                                    {updatedDate}
                                                </label>
                                                <label className="mb-0">
                                                    <strong>申請番号: </strong>
                                                    {application.application_number}
                                                </label>
                                            </div>
                                            <div className="my-2">
                                                <span className="custom-badge mr-2" style={{ background: application.category_bg_color }}>
                                                    {application.category_name}
                                                </span>
                                                <span className="custom-badge mr-2" style={{ background: application.product_bg_color }}>
                                                    {application.product_name}
                                                </span>
                                                <label className="float-right rounded text-center px-2 mb-0" style={{ color: statusBg, border: "solid 1px" }}>
                                                    {application.application_status}
                                                </label>
                                            </div>
                                            <div>
                                                <label className="col-sm-12 pl-0 text-break">
                                                    <a href="# " className="font-weight-bold approval-list-title" value={application.post_id} onClick={() => {
                                                        application.category_type === "1" ?
                                                            handleNoticeShowApprovalHistoryDetail(application.application_id) :
                                                            handleDocumentShowApprovalHistoryDetail(application.application_id)
                                                        }}>
                                                        {application.title}
                                                    </a>
                                                </label>
                                            </div>
                                            <div className="list-item-attribute mb-0 p-0 row">
                                                <div className="col-lg-3 col-sm-12">
                                                    <strong>申請種別: </strong>
                                                    <label>{application.application_type}</label>
                                                </div>
                                                <div className="col-lg-3 col-sm-12">
                                                    <strong>申請者: </strong>
                                                    <label className="application_history">{application.created_by}</label>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <strong>承認者: </strong>
                                                    <label className="application_history">{application.checked_by}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    : <h4>検索の結果、0件でした。</h4>}
            </div>
        </section>
    );
}

export default AdminApprovalHistoryTemplate;