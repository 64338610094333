import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import * as Commons from '../Commons'
import AdminFormTemplate from '../AdminDocumentFormTemplate';

const withAdminDocumentDetailModal = (WrappedComponent) => {
    return class AdminDocumentDetailModal extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showModalDetail: false
            }
        }

        adminDocumentDetailModal = (props) => {
            let template;
            const { documentDetails } = props;
            if (documentDetails) {
                var statusBg = Commons.getStatusBackground(documentDetails.status);
                var customStyle = { color: statusBg, border: "solid 1px" };
                var hasHistoryDetails = props.documentHistoryDetails.length > 0;

                var initialFormValue = {
                    DocumentDetailPostForm: {
                        post_id: documentDetails.id,
                        edit_date: documentDetails.updated_date ? Commons.getClientDatetimeDisplay(documentDetails.updated_date) : "",
                        product: documentDetails.product_name,
                        title: documentDetails.title,
                        content: documentDetails.content,
                        files: documentDetails.files,
                        applicant: documentDetails.applied_by ? documentDetails.applied_by : "",
                        publish_date: documentDetails.publish_preferred_date === null ? "0" : "1",
                        publish_preferred_date: Commons.getClientDatetimeDisplay(documentDetails.publish_preferred_date),
                        remarks: documentDetails.remarks ? documentDetails.remarks : ""
                    }
                };

                template = (
                    <Modal className="admin-document-details" keyboard={false} size="lg" show={this.state.showModalDetail} onHide={this.handleCloseModalDetail} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>ドキュメント管理 ＞ ドキュメント詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="px-4 pb-0">
                            <div className="d-inline-flex w-100">
                                {documentDetails.post_application_id ?
                                    <div className="mb-2">
                                        <strong>申請番号: </strong>
                                        {documentDetails.post_application_id}
                                    </div>
                                    : ""}
                                <div className="ml-auto mb-2">
                                    <label className="rounded text-center px-2 my-0" style={customStyle}>
                                        {documentDetails.status}
                                    </label>
                                </div>
                            </div>
                            <AdminFormTemplate
                                formInputs={props.documentDetailPostForm}
                                radioInput={props.radioInput}
                                initialValues={initialFormValue}
                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                publishDate={documentDetails.publish_preferred_date === null ? "0" : "1"}
                                publishPreferredDate={Commons.getClientDatetimeDisplay(documentDetails.publish_preferred_date)}
                                form={"admin-document-form" + documentDetails.id}
                                handleFormOnchange={this.handleFormOnchange}
                            />
                        </Modal.Body>
                        <Modal.Footer className="px-0 border-top-0">
                            <div className="w-100 admin-detail-modal-footer">
                                <div className={`w-100 d-inline-flex flex-row-reverse ${hasHistoryDetails ? "pb-3 mb-3 bottom-divider" : ""}`}>
                                    <Button variant="secondary" className="btn btn-150 mr-4" onClick={this.handleCloseModalDetail}>
                                        ドキュメント一覧に戻る
                                    </Button>
                                </div>
                                {
                                    hasHistoryDetails ?
                                        <>
                                            <h5 className="mx-4">修正履歴</h5>
                                            {
                                                props.documentHistoryDetails.map((docs, idx) => {
                                                    var initialFormHistoryValue = {
                                                        DocumentHistoryPostForm: {
                                                            post_id: docs.id,
                                                            edit_date: docs.updated_date ? Commons.getClientDatetimeDisplay(docs.updated_date) : "",
                                                            product: docs.product_name,
                                                            title: docs.title,
                                                            content: docs.content,
                                                            files: docs.files,
                                                            applicant: docs.applied_by ? docs.applied_by : "",
                                                            history_publish_date: docs.publish_preferred_date === null ? "0" : "1",
                                                            publish_preferred_date: Commons.getClientDatetimeDisplay(docs.publish_preferred_date),
                                                            remarks: docs.remarks ? docs.remarks : ""
                                                        }
                                                    };

                                                    return (
                                                        <div className="mx-4 bg-light" key={docs.id + "-" + idx}>
                                                            <AdminFormTemplate
                                                                formInputs={props.documentHistoryPostForm}
                                                                radioInput={props.radioInput}
                                                                initialValues={initialFormHistoryValue}
                                                                handleDocumentFileDownload={this.handleDocumentFileDownload}
                                                                publishDate={docs.publish_preferred_date === null ? "0" : "1"}
                                                                publishPreferredDate={Commons.getClientDatetimeDisplay(docs.publish_preferred_date)}
                                                                form={"admin-history-form" + docs.id}
                                                                handleFormOnchange={this.handleFormOnchange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }
            return template;
        }

        handleShowModalDetail = (postDetailId, postId) => {
            Promise.all([
                this.showLoader(),
                this.props.getDocumentDetails(postDetailId).then(
                    () => {
                        this.props.getDocumentHistoryDetails(postId).then(
                            () => {
                                this.setState({ showModalDetail: true });
                            }
                        )
                    }
                )
            ]).catch(this.handleShowErrorAlert)
            .then(this.closeLoader)
        }

        handleCloseModalDetail = () => {
            this.setState({ showModalDetail: false });
        }
    }
}

export {
    withAdminDocumentDetailModal
}