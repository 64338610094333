import React from 'react';
import AdminFormTemplate from '../AdminPortalFormTemplate';
import { Modal } from 'react-bootstrap';
import { withMailSettingsTestServerConfirm } from './MailSettingsTestServerConfirmModal';
import { adminEmailManagementSelectors } from '../../containers/AdminEmailManagement/state';


const withMailSettingsTestServer = (WrappedContent) => {

    return withMailSettingsTestServerConfirm(
        class MailSettingsTestServer extends WrappedContent {
            constructor(props) {
                super(props);
                this.state = {
                    ...this.state,
                    showMailSettingsTestServerModal: false,
                    currentFormValues: {},
                };
            }

            mailSettingsTestServerModal = (props) => {
                let isDefault = 
                    (props.currentFormValues) ? 
                    (props.currentFormValues.email_to === "")
                    : true;

                return (
                    <Modal keyboard={false} size="md" centered 
                        className="modal-test-server-mail-settings modal-dialog-md" 
                        show={props.showMailSettingsTestServerModal} 
                        onHide={isDefault ? this.handleCloseMailSettingsTestServerModal : this.handleShowDiscardModal } >
                        <Modal.Header closeButton>
                            <Modal.Title>{"テストメールを送信 > 送信先"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-0">
                            <AdminFormTemplate
                                    form={"test-mail-server"}
                                    testMailServer={true}
                                    formInputs={props.testMailServerForm}
                                    handleFormOnchange={this.handleFormOnchange}
                                    handleSubmitTestMailServer={this.handleSubmitTestMailServer}
                                    handleShowMailSettingsTestServerConfirmModal={this.handleShowMailSettingsTestServerConfirmModal}
                                    validate={adminEmailManagementSelectors.testMailServerCheckErrors}
                                />
                        </Modal.Body>
                        <Modal.Footer className="border-top-0 pt-0">
                            <div className="w-100 d-inline-flex">
                                <input type="hidden" name="submit_action" />
                                <button 
                                    type="button"
                                    className="btn btn-90 mr-2 ml-auto btn-primary" 
                                    onClick={this.handleTestMailServerAction}>Ok</button>
                                <button 
                                    className="btn btn-90 btn-secondary" 
                                    onClick={this.handleCloseMailSettingsTestServerModal}>キャンセル</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )
            }

            handleFormOnchange = (values) => {
                if (values.formValues.MailSettingsForm) {
                    this.setState({ 
                        currentFormValues: values.formValues.MailSettingsForm
                    });
                }
                else if (values.formValues.TestMailServerForm) {
                    this.setState({ 
                        currentFormValues: values.formValues.TestMailServerForm
                    });
                }
            }

            handleShowMailSettingsTestServerModal = (event) => {
                event.target.blur();
                this.setState({
                    showMailSettingsTestServerModal: true,
                })
            }

            handleCloseMailSettingsTestServerModal = () => {
                this.setState({
                    showMailSettingsTestServerModal: false
                })
            }

            handleCloseAllModal = () => {
                this.handleCloseMailSettingsEditModal();
                this.handleCloseMailSettingsTestServerModal();
                this.handleCloseDiscardModal();
            }

            handleTestMailServerAction = () => {
                document.querySelector('.modal.show .portalSubmitButton').click();
                document.querySelector('.modal.show input[name=submit_action]').value = "handleShowMailSettingsTestServerConfirmModal";
            }

            handleSubmitTestMailServer = (values) => {
                this.setState({
                    testMailServerData: {
                        payload: values
                    }
                });
            }

        }
    )

}

export {
    withMailSettingsTestServer
}
