export const PRODUCTLOOKUP = "api/productlookup/";
export const CATEGORYLOOKUP = "api/postcategorylookup/";
export const CONFIGURATION = "api/admin/settings/";
export const PRODUCT_INFO = "api/admin/product/list/";
export const PRODUCT_CREATE = "api/admin/product/add/";
export const PRODUCT_UPDATE = "/api/admin/product/edit/";
export const PRODUCT_DELETE = "/api/admin/product/delete/";
export const KINTONE_PRODUCT_FIELD_LIST = "api/admin/kintoneproduct/list/";
export const KINTONE_PRODUCT_FIELD_CREATE = "api/admin/kintoneproduct/add/";
export const KINTONE_PRODUCT_FIELD_DELETE = "api/admin/kintoneproduct/delete/";
export const KINTONE_PRODUCT_CONTROL_PANEL_PATCH = "api/admin/kintoneproduct/control-panel-url/";
export const KINTONE_PRODUCT_FIELD_LABEL_PATCH = "api/admin/kintoneproduct/field-label/";
export const KINTONE_PRODUCT_FIELD_SORTING_PATCH = "api/admin/kintoneproduct/sorting/";
export const KINTONE_PRODUCT_COMMON_FIELD_LIST = "api/admin/kintonecommon/list/";
export const KINTONE_PRODUCT_COMMON_FIELD_CREATE = "api/admin/kintonecommon/add/";
export const KINTONE_PRODUCT_COMMON_FIELD_DELETE = "api/admin/kintonecommon/delete/";
export const KINTONE_PRODUCT_COMMON_FIELD_EDIT = "api/admin/kintonecommon/edit/";
export const KINTONE_COMMON_FIELD_SORTING_PATCH = "api/admin/kintonecommon/sorting/";
export const KINTONE_PRODUCT_FIELD_SUB_TABLE_LIST = "api/admin/kintoneproduct-subtable/";
export const KINTONE_COMMON_FIELD_SUB_TABLE_LIST = "api/admin/kintonecommon-subtable/";
