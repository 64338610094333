import React from 'react'

const AdminProductButton = (props) => {
    let { handleShowModalNewProduct } = props
    return (
        <div className="form-inline mr-auto">
            <div className="btn-group navbar-left">
                <button className="btn btn-secondary" value="新規登録" onClick={handleShowModalNewProduct}>製品情報登録</button>
            </div>
        </div>
    );
}

export default AdminProductButton